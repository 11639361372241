import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';
import { translate } from 'versafleet-core';
import styles from 'stylesheets/modules/task.styl';

class RejectAllocationWindowForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      isActionCompleted: false,
      showConfirmationOptions: false,
    };
  }

  _onSubmit = async () => {
    const { dataIds, onCompleted, onSubmit } = this.props;
    if (!Helper.isNullOrUndefined(onSubmit)) {
      onSubmit();
    }

    await this.props.dispatch(ReduxActions.task.rejectAllocationMultiple({
      ids: dataIds,
      reason: this.state.reason,
    }));

    // After onCompleted is run the page will reload automatically,
    // So use timeout here to delay the function call and show the success message
    this.setState({ isActionCompleted: true }, () => {
      setTimeout(() => {
        if (!Helper.isNullOrUndefined(onCompleted)) {
          onCompleted();
        }
      }, 1500);
    });
  }

  _renderReasonInputForm = () => (
    <React.Fragment>
      <Component.Input.Textbox
        onChange={reason => this.setState({ reason })}
        placeholder={this.props.strings.placeholder}
        trailingIcon="versa-edit"
        value={this.state.reason}
        width="full"
      />
      <Component.Button
        className={styles['vf-task__reject-allocation__form__button']}
        disabled={!Helper.isNonEmptyString(this.state.reason)}
        iconClassName="versa-completed"
        onClick={() => this.setState({ showConfirmationOptions: true })}
      >
        {this.props.strings.submit}
      </Component.Button>
    </React.Fragment>
  )

  _renderConfirmationOptions = () => (
    <React.Fragment>
      <Component.Button
        className={styles['vf-task__reject-allocation__form__button']}
        iconClassName="versa-completed"
        loading={this.props.isUpdating}
        onClick={this._onSubmit}
      >
        {this.props.strings.confirmation.Yes}
      </Component.Button>
      <Component.Button
        className={styles['vf-task__reject-allocation__form__button']}
        iconClassName="versa-close"
        onClick={() => this.setState({ showConfirmationOptions: false })}
      >
        {this.props.strings.confirmation.No}
      </Component.Button>
    </React.Fragment>
  )

  _renderActionCompletedForm = () => (
    <Component.Button
      className={classNames(
        styles['vf-task__reject-allocation__form__button'],
        styles['vf-task__reject-allocation__form__button--completed'],
      )}
      disabled
      iconClassName="versa-completed"
      onClick={() => {}}
    >
      {this.props.strings.taskDeclinedSuccessfully}
    </Component.Button>
  )

  render() {
    return (
      <div className={styles['vf-task__reject-allocation__form']}>
        <Component.Window.FieldEdit
          fieldName={this.state.showConfirmationOptions
            ? this.props.strings.confirmation.title : this.props.strings.title}
          isRequired
        >
          {!this.state.showConfirmationOptions && this._renderReasonInputForm()}
          {this.state.showConfirmationOptions && !this.state.isActionCompleted
            && this._renderConfirmationOptions()}
          {this.state.isActionCompleted && this._renderActionCompletedForm()}
        </Component.Window.FieldEdit>
      </div>
    );
  }
}

RejectAllocationWindowForm.displayName = 'Reject Allocation Window Form';

RejectAllocationWindowForm.propTypes = {
  dataIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  dispatch: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  onCompleted: PropTypes.func,
  onSubmit: PropTypes.func,
  strings: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
    submit: PropTypes.string,
    confirmation: PropTypes.shape({
      title: PropTypes.string,
      Yes: PropTypes.string,
      No: PropTypes.string,
    }),
    taskDeclinedSuccessfully: PropTypes.string,
  }).isRequired,
};

RejectAllocationWindowForm.defaultProps = {
  isUpdating: false,
  onCompleted: undefined,
  onSubmit: undefined,
};

export default connect()(translate(
  'Planning.Pending.allocation.rejectForm',
)(RejectAllocationWindowForm));
