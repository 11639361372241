import React from 'react';
import PropTypes from 'prop-types';
import Block from './block';

const MultipleGroupHeader = props => (
  <Block contentClassName="vf-form__multiple-group-header" level={0}>
    {props.children}
  </Block>
);

MultipleGroupHeader.displayName = 'Form MultipleGroupHeader';

MultipleGroupHeader.propTypes = {
  children: PropTypes.node,
};

MultipleGroupHeader.defaultProps = {
  children: null,
};

export default MultipleGroupHeader;
