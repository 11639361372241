import Router from 'utils/router';
import { Utils } from 'versafleet-core';

const {
  RequestHelper: {
    request,
    convertDataToParams,
    requestCustomBody,
    requestBlob,
  },
} = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveVehicle(vehicle) {
  return {
    type: 'RECEIVE_VEHICLE',
    vehicle,
  };
}

function receiveVehicles(result, timestamp) {
  return {
    type: 'RECEIVE_VEHICLES',
    vehicles: result.vehicles,
    meta: result.meta,
    timestamp,
  };
}

function receiveVehicleSkills(skills) {
  return {
    type: 'RECEIVE_VEHICLE_SKILLS',
    skills,
  };
}

function receiveVehicleSystemTags(systemTags) {
  return {
    type: 'RECEIVE_VEHICLE_SYSTEM_TAGS',
    systemTags,
  };
}

function receiveVehicleTypes(vehicleTypes) {
  return {
    type: 'RECEIVE_VEHICLE_TYPES',
    vehicleTypes,
  };
}

function receiveVehicleUpdateSuccessful(vehicle) {
  return {
    type: 'RECEIVE_VEHICLE_UPDATE_SUCCESSFUL',
    vehicle,
  };
}

function receiveArchivedVehicles(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_VEHICLES',
    vehicles: result.vehicles,
    meta: result.meta,
    timestamp,
  };
}

function receiveOptionVehicles(optionVehicles) {
  return {
    type: 'RECEIVE_OPTION_VEHICLES',
    optionVehicles,
  };
}

function setIsFetchingOptionVehicles(isFetchingOptionVehicles) {
  return {
    type: 'SET_IS_FETCHING_OPTION_VEHICLES',
    isFetchingOptionVehicles,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_VEHICLE_FILTER',
    filter,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/vehicles/${id}`, {
      method: 'GET',
    });
    dispatch(receiveVehicle(result.vehicle));
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.vehicle.vehicleMeta.page,
      ...baseFilter,
      ...state.vehicle.filter,
      archived: false,
    };
    const param = convertDataToParams(data);
    const result = await request(`/vehicles${param}`, {
      method: 'GET',
    });
    dispatch(receiveVehicles(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.vehicle.archivedVehicleMeta.page,
      ...baseFilter,
      ...state.vehicle.filter,
      archived: true,
    };
    const param = convertDataToParams(data);
    const result = await request(`/vehicles${param}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedVehicles(result, timestamp));
  };
}

function fetchOptionList(query) {
  return async (dispatch, getState) => {
    const { isFetchingOptionVehicles } = getState().vehicle;

    if (isFetchingOptionVehicles) {
      return;
    }

    try {
      dispatch(setIsFetchingOptionVehicles(true));

      const param = convertDataToParams(query);
      const result = await request(`/vehicles/list${param}`, {
        method: 'GET',
      });
      dispatch(receiveOptionVehicles(result.vehicles));
    } finally {
      dispatch(setIsFetchingOptionVehicles(false));
    }
  };
}

function fetchSkills() {
  return async (dispatch) => {
    const result = await request('/vehicles/skills', {
      method: 'GET',
    });
    dispatch(receiveVehicleSkills(result.skills));
    return result.skills;
  };
}

function fetchSystemTags() {
  return async (dispatch) => {
    const result = await request('/vehicles/system_tags', {
      method: 'GET',
    });
    dispatch(receiveVehicleSystemTags(result.tags));
    return result.tags;
  };
}

function fetchVehicleTypes() {
  return async (dispatch) => {
    const result = await request('/vehicles/vehicle_types', {
      method: 'GET',
    });
    dispatch(receiveVehicleTypes(result));
    return result;
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/vehicles', {
      method: 'POST',
    }, {
      vehicle: query,
    });
    dispatch(receiveVehicleUpdateSuccessful(result.vehicle));
    dispatch(fetchList());
    return result.vehicle;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/vehicles/${id}`, {
      method: 'PUT',
    }, {
      vehicle: query,
    });
    dispatch(receiveVehicleUpdateSuccessful(result.vehicle));
    return result.vehicle;
  };
}

function setDriver(id, driverId) {
  return async (dispatch) => {
    const result = await request(`/vehicles/${id}/driver_default_vehicle`, {
      method: 'PUT',
    }, {
      vehicle: {
        driver_id: driverId,
      },
    });
    dispatch(receiveVehicleUpdateSuccessful(result.vehicle));
    return result.vehicle;
  };
}

function archive(id) {
  return async () => {
    const result = await request(`/vehicles/${id}/archive`, {
      method: 'PUT',
    });
    return result.vehicle;
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/vehicles/${id}/unarchive`, {
      method: 'PUT',
    });
    dispatch(receiveVehicle(result.vehicle));
    dispatch(fetchArchivedList());
    return result.vehicle;
  };
}

function archiveMultiple(ids) {
  return async () => {
    const result = await request('/vehicles/archive', {
      method: 'PUT',
    }, {
      ids,
    });
    return result;
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    const result = await request('/vehicles/unarchive', {
      method: 'PUT',
    }, {
      ids,
    });
    dispatch(fetchArchivedList());
    return result;
  };
}

function importData(query) {
  return async (dispatch) => {
    await requestCustomBody('/vehicles/import', {
      method: 'POST',
    }, query);
    Router.transitionTo('/fleet/vehicles/import?success=true');
    dispatch(fetchList());
  };
}

async function getImportTemplate() {
  const data = await requestBlob('/vehicles/import/template', {
    method: 'GET',
  });

  Utils.Helper.saveBlob(data, 'Import_Vehicle.xlsx');
}

export default {
  setFilter,
  fetch,
  fetchList,
  fetchArchivedList,
  fetchOptionList,
  fetchSkills,
  fetchSystemTags,
  fetchVehicleTypes,
  create,
  update,
  setDriver,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  importData,
  getImportTemplate,
};
