import React from 'react';
import ListItem from './listItem';
import ViewForm from './viewForm';
import EditForm from './editForm';
import CustomAddress from './customAddress';
import CustomDateTime from './customDateTime';
import FilterTagButton from './filter/tagButton';
import FilterTimeWindowButton from './filter/timeWindowButton';
import LabelConstants from './labelConstants';

const ImportForm = React.lazy(() => import('./importForm'));

export default {
  ListItem,
  ViewForm,
  EditForm,
  CustomAddress,
  CustomDateTime,
  ImportForm,
  FilterTagButton,
  FilterTimeWindowButton,
  LabelConstants,
};
