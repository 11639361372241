import React from 'react';
import PropTypes from 'prop-types';
import { Components } from 'versafleet-core';
import Helper from 'utils/helper';
import Item from './item';

class InputRadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.buttonList = null;
    this.radioParent = null;
  }

  _onChange = (value) => {
    this.props.onChange(value);
  }

  _renderRadioButtonItem(item) {
    return (
      <div
        className="vf-radio-button__item"
        id={Helper.isNullOrUndefined(this.props.id) ? undefined
          : `${this.props.id}__${item.value}`}
        key={`radio-${this.props.name}-${item.value}`}
      >
        <Components.Input.Radio.Item
          disabled={this.props.disabled}
          onChange={this._onChange}
          selectedValue={this.props.value}
          value={item.value}
        >
          {item.text}
        </Components.Input.Radio.Item>
      </div>
    );
  }

  _renderInput() {
    return (
      <div
        className="radiobutton-list"
        ref={(buttonList) => { this.buttonList = buttonList; }}
      >
        {this.props.options.map(option => this._renderRadioButtonItem(option))}
      </div>
    );
  }

  _renderInputText() {
    let text = '';
    Object.keys(this.props.options).forEach((optionKey) => {
      if (this.props.options[optionKey].value === this.props.value) {
        text = this.props.options[optionKey].text;
      }
    });
    return (
      <div className="input-view">
        {text}
      </div>
    );
  }

  render() {
    const className = classNames('v-input v-input-radiobutton',
      this.props.className);
    return (
      <div className={className} ref={(radioParent) => { this.radioParent = radioParent; }}>
        <div className="input-wrapper">
          {
            typeof this.props.iconClassName === 'string'
              ? (
                <div className="input-icon">
                  <i className={this.props.iconClassName}>
                    {this.props.iconText}
                  </i>
                </div>
              ) : false
          }
          {this.props.editable ? this._renderInput()
            : this._renderInputText()}
        </div>
        {
          this.props.error ? (
            <div className="input-error">
              {this.props.error.join(', ')}
            </div>
          ) : false
        }
      </div>
    );
  }
}

InputRadioButton.displayName = 'Input Radio Button';

InputRadioButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  iconClassName: PropTypes.string,
  iconText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node,
    value: PropTypes.node,
  })),
  value: PropTypes.node.isRequired,
};

InputRadioButton.defaultProps = {
  className: '',
  disabled: false,
  editable: true,
  error: [],
  iconClassName: '',
  iconText: '',
  id: null,
  options: [],
};

InputRadioButton.Item = Item;

export default InputRadioButton;
