import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

const GroupItemSubmitButton = (props) => {
  const { onSubmit } = props;
  const id = Helper.isNullOrUndefined(props.id)
    ? undefined
    : `${props.id}__submit-button`;

  return (
    <div className="vf-form__group-item__buttons__submit" id={id} onClick={onSubmit}>
      <i className="versa-completed" />
    </div>
  );
};

GroupItemSubmitButton.displayName = 'Group Item Submit Button';

GroupItemSubmitButton.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func,
};

GroupItemSubmitButton.defaultProps = {
  id: null,
  onSubmit: null,
};

export default GroupItemSubmitButton;
