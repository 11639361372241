import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonUpdateVersaDrive = (props) => {
  const { strings, loading, disabled } = props;
  const className = classNames('vf-button-update-vd', props.classNames);
  let hint;

  if (loading) {
    hint = strings.loadingText;
  } else if (disabled) {
    hint = strings.disabledText;
  } else {
    hint = strings.text;
  }

  return (
    <Button
      className={className}
      color={Button.Color.GREEN}
      fillColor
      hint={hint}
      iconClassName={disabled ? 'versa-completed' : 'versa-updatevd'}
      {...props}
    >
      {props.children}
    </Button>
  );
};

ButtonUpdateVersaDrive.displayName = 'Button Update VersaDrive';

ButtonUpdateVersaDrive.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  strings: PropTypes.shape().isRequired,
};

ButtonUpdateVersaDrive.defaultProps = {
  children: null,
  classNames: null,
  disabled: false,
  loading: false,
};

export default translate('Component.Button.UpdateVersaDrive')(ButtonUpdateVersaDrive);
