import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Color from 'utils/color';
import Component from 'components/component';

const cancelWarningModal = (props) => {
  const { onClick, strings, visible } = props;
  return (
    <Component.Modal.Warning
      headerIconClassName="versa-info"
      headerIconOuterColor={Color.$desc}
      headerText={strings.header}
      rightButtonOnClick={onClick}
      rightButtonText={strings.ok}
      visible={visible}
    />
  );
};

cancelWarningModal.displayName = 'Optimize Cancelled Task Warning Modal';

cancelWarningModal.propTypes = {
  onClick: PropTypes.func,
  strings: PropTypes.shape({
    header: PropTypes.string,
    ok: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool,
};

cancelWarningModal.defaultProps = {
  onClick: null,
  visible: false,
};

export default (translate('Task.CancelWarningModal')(cancelWarningModal));
