import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Component from 'components/component';
import ReduxActions from 'reduxActions';
import { translate } from 'versafleet-core';
import Helper from 'utils/helper';

class JobCustomDateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this._getDefaultForm(props.value),
    };
  }

  componentDidMount() {
    this.props.dispatch(ReduxActions.account.fetchTimeWindows());
  }

  _getDefaultForm = (data) => {
    const form = {};
    form.time_window_id = data.time_window_id;
    form.date = ((data.time_from || data.time_to)
      && moment(data.time_from || data.time_to).format('YYYY-MM-DD'))
      || moment().format('YYYY-MM-DD');
    if (['custom', 'am', 'pm', 'all_day'].includes(data.time_type)) {
      form.time_type = 'custom';
      const from = data.time_from && new Date(data.time_from);
      form.time_from = !Helper.isNullOrUndefined(from)
        ? (from.getHours() * 60) + from.getMinutes() : null;
      const to = data.time_to && new Date(data.time_to);
      form.time_to = !Helper.isNullOrUndefined(to) ? (to.getHours() * 60) + to.getMinutes() : null;
    } else {
      form.time_type = null;
      form.time_from = null;
      form.time_to = null;
    }
    return form;
  }

  _getDefaultTimeOptions = () => [{
    value: -1,
    text: (
      <div>
        <div>Custom</div>
        <div className="vf-job__custom-time__time-windows-input__option__time">
          ___________ to ___________
        </div>
      </div>
    ),
  }];

  _getTimeWindowOptions() {
    return this.props.timeWindows.map(timeWindow => ({
      value: timeWindow.id,
      text: (
        <div>
          <div>{timeWindow.name}</div>
          <div className="vf-job__custom-time__time-windows-input__option__time">
            {`${Helper.getTimeFromSecond(timeWindow.time_from)} to
              ${Helper.getTimeFromSecond(timeWindow.time_to)}`}
          </div>
        </div>
      ),
    })).concat(this._getDefaultTimeOptions());
  }

  _formatTimeWithTimeWindows = (form) => {
    const timeWindow = this.props.timeWindows.find(tw => tw.id === form.time_window_id);
    if (!Helper.isNullOrUndefined(timeWindow)) {
      const timeFrom = moment(form.date).seconds(timeWindow.time_from).format('YYYY-MM-DD HH:mm');
      const timeTo = moment(form.date).seconds(timeWindow.time_to).format('YYYY-MM-DD HH:mm');
      return { timeFrom, timeTo };
    }

    return {
      timeFrom: form.date,
      timeTo: form.date,
    };
  }

  _onChange = () => {
    const { form } = this.state;
    let timeFrom;
    let timeTo;

    // If time_window_id and time_from is empty
    if (Helper.isNullOrUndefined(form.time_window_id) && Helper.isNullOrUndefined(form.time_from)) {
      timeFrom = null;
    } else {
      timeFrom = `${form.date} ${Math.floor(form.time_from / 60) < 10 ? '0' : ''}`
      + `${Math.floor(form.time_from / 60)}:${form.time_from % 60 < 10 ? '0' : ''}`
      + `${form.time_from % 60}`;
    }

    // If time_window_id and time_to is empty
    if (Helper.isNullOrUndefined(form.time_window_id) && Helper.isNullOrUndefined(form.time_to)) {
      timeTo = null;
    } else {
      timeTo = `${form.date} ${Math.floor(form.time_to / 60) < 10 ? '0' : ''}`
      + `${Math.floor(form.time_to / 60)}:${form.time_to % 60 < 10 ? '0' : ''}`
      + `${form.time_to % 60}`;
    }

    // If time_window_id is present
    if (!Helper.isNullOrUndefined(form.time_window_id)) {
      const fTime = this._formatTimeWithTimeWindows(form);
      timeFrom = fTime.timeFrom;
      timeTo = fTime.timeTo;
    }

    this.props.onChange({
      time_type: form.time_type,
      time_window_id: form.time_window_id,
      time_from: timeFrom,
      time_to: timeTo,
    });
  }

  _onFormChange = (fieldName, value) => {
    this.setState((prevState) => {
      const form = { ...prevState.form };
      form[fieldName] = value;

      if (fieldName === 'time_window_id') {
        if (value === -1) {
          form.time_window_id = null;
          form.time_type = 'custom';
          form.time_from = null;
          form.time_to = null;
        } else {
          form.time_window_id = value;
          form.time_type = null;
          form.time_from = null;
          form.time_to = null;
        }
      } else if (fieldName === 'time_from' && Helper.isNullOrUndefined(form.time_to)) {
        form.time_to = value;
      } else if (fieldName === 'time_to' && Helper.isNullOrUndefined(form.time_from)) {
        form.time_from = value;
      }
      return {
        form,
      };
    }, this._onChange);
  }

  _renderCustomTimeInput() {
    const { error, id } = this.props;
    const { form } = this.state;

    return (
      <div className="vf-job__custom-time__time">
        <Component.Input.TimePicker
          className="vf-job__custom-time__time__input"
          error={error.time_from}
          id={Helper.isNullOrUndefined(id) ? undefined
            : `${id}__time-from-input`}
          onChange={value => this._onFormChange('time_from', value)}
          placeholder="Start Time"
          value={form.time_from}
          width="narrow"
        />
        -
        <Component.Input.TimePicker
          className="vf-job__custom-time__time__input"
          error={error.time_to}
          id={Helper.isNullOrUndefined(id) ? undefined
            : `${id}__time-to-input`}
          onChange={value => this._onFormChange('time_to', value)}
          placeholder="End Time"
          value={form.time_to}
          width="narrow"
        />
      </div>
    );
  }

  render() {
    const { error, id, strings } = this.props;
    const { form } = this.state;

    return (
      <div className="vf-job__custom-time">
        <Component.Form.Block iconClassName="versa-calendar-day" level={2}>
          <Component.Input.DatePicker
            fromDate="2020-01-01"
            id={Helper.isNullOrUndefined(id) ? undefined
              : `${id}__date-input`}
            onChange={value => this._onFormChange('date', value)}
            placeholder="Date"
            value={form.date}
          />
        </Component.Form.Block>

        <Component.Form.Divider size="SMALL" />

        <Component.Form.Block
          iconClassName="versa-time"
          level={2}
        >
          <Component.Input.UntypableSelect
            className="vf-job__custom-time__time-windows-input"
            error={error.time_window || error.time_type}
            id={Helper.isNullOrUndefined(id)
              ? undefined
              : `${id}__time-window-select-input`}
            onChange={value => this._onFormChange('time_window_id', value)}
            options={this._getTimeWindowOptions()}
            placeholder={strings.Job.form.customDateTime.selectTimeWindow}
            textClassName="vf-job__custom-time__time-windows-input__text"
            value={form.time_type === 'custom' ? -1 : form.time_window_id}
          />
          {form.time_type === 'custom' ? this._renderCustomTimeInput() : false}
        </Component.Form.Block>
      </div>
    );
  }
}

JobCustomDateTime.displayName = 'Job Custom Date Time';

JobCustomDateTime.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape({
    time_from: PropTypes.string,
    time_to: PropTypes.string,
    time_type: PropTypes.string,
    time_window: PropTypes.string,
  }),
  id: PropTypes.string,
  onChange: PropTypes.func,
  strings: PropTypes.shape({
    Job: PropTypes.shape(),
  }).isRequired,
  timeWindows: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.shape({}),
};

JobCustomDateTime.defaultProps = {
  error: {},
  id: null,
  onChange: null,
  timeWindows: [],
  value: null,
};

function mapStateToProps(state) {
  const { account } = state;
  return {
    timeWindows: account.timeWindows || [],
  };
}

export default connect(mapStateToProps)(translate('')(JobCustomDateTime));
