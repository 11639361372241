import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveCustomLayout(customLayout) {
  return {
    type: 'RECEIVE_CUSTOM_LAYOUT',
    customLayout,
  };
}

function receiveCustomTaskLayoutTags(customTaskLayoutTags) {
  return {
    type: 'RECEIVE_CUSTOM_TASK_LAYOUT_TAGS',
    customTaskLayoutTags,
  };
}

function fetch() {
  return async (dispatch) => {
    const result = await request('/custom_layout', {
      method: 'GET',
    });
    dispatch(receiveCustomLayout(result.custom_layout));
    return result.custom_layout;
  };
}

function updateFleet(query) {
  return async (dispatch) => {
    const result = await request('/custom_layout/custom_fleet_layout_update', {
      method: 'PUT',
    }, {
      custom_layout: query,
    });
    dispatch(receiveCustomLayout(result.custom_layout));
    return result.custom_layout;
  };
}

function updateTask(query) {
  return async (dispatch) => {
    const result = await request('/custom_layout/custom_task_layout_update', {
      method: 'PUT',
    }, {
      custom_layout: {
        custom_task_layout: query,
      },
    });
    dispatch(receiveCustomLayout(result.custom_layout));
    return result.custom_layout;
  };
}

function updateRunsheetBoard(query) {
  return async (dispatch) => {
    const result = await request('/custom_layout/custom_runsheet_board_layout_update', {
      method: 'PUT',
    }, {
      custom_layout: {
        custom_runsheet_board_layout: query,
      },
    });
    dispatch(receiveCustomLayout(result.custom_layout));
    return result.custom_layout;
  };
}

function updateMyJobsTask(query) {
  return async (dispatch) => {
    const result = await request('/custom_layout/custom_myjobs_task_layout_update', {
      method: 'PUT',
    }, {
      custom_layout: {
        custom_myjobs_task_layout: query,
      },
    });
    dispatch(receiveCustomLayout(result.custom_layout));
    return result.custom_layout;
  };
}

function fetchCustomTaskLayoutTags() {
  return async (dispatch) => {
    const result = await request('/custom_layout/custom_task_layout/tags', {
      method: 'GET',
    });
    dispatch(receiveCustomTaskLayoutTags(result.tags));
    return result.tags;
  };
}

export default {
  fetch,
  updateFleet,
  updateTask,
  updateRunsheetBoard,
  updateMyJobsTask,
  fetchCustomTaskLayoutTags,
};
