import React from 'react';
import PropTypes from 'prop-types';
import TranslationHelper from 'utils/translationHelper';
import { translate } from 'versafleet-core';

const CompleteSuccessMessage = props => ((
  <div className="vf-task__complete-success-message">
    <div className="vf-task__complete-success-message__icon">
      <i className="versa-completed" />
    </div>
    <div>
      {
        TranslationHelper.replaceTag(props.strings.page.completeSuccess, {
          count: props.count,
        })
      }
    </div>
  </div>
));

CompleteSuccessMessage.propTypes = {
  count: PropTypes.number,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

CompleteSuccessMessage.defaultProps = {
  count: 1,
};

export default translate('Task')(CompleteSuccessMessage);
