import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helper from 'utils/helper';
import AccessRightHelper from 'utils/accessRightHelper';

export default function authorize(key) {
  return (Component) => {
    class AuthorizedComponent extends React.Component {
      render() {
        if (Helper.isNullOrUndefined(this.props.profile)) {
          return <Component {...this.props} {...this.state} />;
        }

        if (Helper.isNullOrUndefined(this.props.accessRightKey)) {
          // Key is just an empty string, immediately return the component
          if (!Helper.isNonEmptyString(key)) {
            return <Component {...this.props} {...this.state} />;
          }
          // Key is not an empty string, determine access right availability
          if (AccessRightHelper.hasAccess(this.props.accessRights, key)) {
            return <Component {...this.props} {...this.state} />;
          }
        } else if (typeof this.props.accessRightKey === 'object' // Props override the key param
            && !Helper.isNullOrUndefined(this.props.accessRightKey.length)) {
          // AccessRightKey props is an array of keys
          for (let i = 0; i < this.props.accessRightKey.length; i += 1) {
            if (AccessRightHelper.hasAccess(
              this.props.accessRights, this.props.accessRightKey[i],
            )) {
              return <Component {...this.props} {...this.state} />;
            }
          }
        } else {
          // AccessRightKey props is a single key
          // Key is just an empty string, immediately return the component
          if (!Helper.isNonEmptyString(this.props.accessRightKey)) {
            return <Component {...this.props} {...this.state} />;
          }
          // Key is not an empty string, determine access right availability
          if (AccessRightHelper.hasAccess(this.props.accessRights, this.props.accessRightKey)) {
            return <Component {...this.props} {...this.state} />;
          }
        }

        return false;
      }
    }

    AuthorizedComponent.propTypes = {
      accessRightKey: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      accessRights: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
      profile: PropTypes.shape({}).isRequired,
    };

    AuthorizedComponent.defaultProps = {
      accessRightKey: null,
    };

    function mapStateToProps(state) {
      const { profile } = state;
      return {
        profile: profile.profile,
        accessRights: profile.profile ? (profile.profile.access_rights || []) : [],
      };
    }

    return connect(mapStateToProps)(AuthorizedComponent);
  };
}
