import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Helper from 'utils/helper';
import Button from './authorizedButton';

const ButtonDone = props => (
  <Button
    hint={Helper.isNullOrUndefined(props.children) ? props.strings.text : null}
    iconClassName="versa-completed"
    {...props}
  >
    {props.withDefaultText ? props.strings.text : props.children}
  </Button>
);

ButtonDone.displayName = 'Button Done';

ButtonDone.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonDone.defaultProps = {
  children: null,
  loading: false,
  withDefaultText: false,
};

export default translate('Component.Button.Done')(ButtonDone);
