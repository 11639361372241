import { Utils } from 'versafleet-core';
import { useQuery } from '@tanstack/react-query';

const { RequestHelper: { request } } = Utils;

// eslint-disable-next-line import/prefer-default-export
export function useProfile() {
  const {
    isLoading: isLoadingProfile,
    error: errorProfile,
    data: profile,
  } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const result = await request('/profile', {
        method: 'GET',
      });

      return result.profile;
    },
  });

  return {
    isLoadingProfile,
    errorProfile,
    profile,
  };
}
