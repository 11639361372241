const initialState = {
  vfSetting: {},
};

function vfSetting(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VF_SETTING':
      return {
        ...state,
        vfSetting: action.vfSetting,
      };
    default:
      return state;
  }
}

export default vfSetting;
