import React from 'react';
import ListItem from './listItem';
import EditForm from './editForm';
import RecipientVerificationForm from './recipientVerification';
import GroupItem from './groupItem';
import BulkEditButton from './bulkEdit/button';
import BulkArchiveButton from './archive/bulkArchiveButton';
import AssignButton from './assign/assignButton';
import UnassignButton from './assign/unassignButton';
import AllocateButton from './allocate/allocateButton';
import RejectAllocationButton from './allocate/rejectAllocationButton';
import TagButton from './tag/tagButton';
import DeleteButton from './archive/deleteButton';
import BulkDeleteButton from './archive/bulkDeleteButton';
import CompleteButton from './complete/completeButton';
import CancelDialog from './cancel/cancelDialog';
import FilterDateButton from './filter/dateButton';
import FilterStatusButton from './filter/statusButton';
import FilterTagButton from './filter/tagButton';
import FilterTimeWindowButton from './filter/timeWindowButton';
import FilterJobTimeWindowButton from './filter/jobTimeWindowButton';
import FilterCustomerButton from './filter/customerButton';
import FilterAccountButton from './filter/accountButton';
import FilterTaskContactPersonButton from './filter/taskContactPersonButton';
import FilterDriverButton from './filter/driverButton';
import FilterVehicleButton from './filter/vehicleButton';
import FilterTypeButton from './filter/typeButton';
import FilterCustomFieldButton from './filter/customFieldButton';
import FilterTransportersButton from './filter/transportersButton';
import FilterAllocationStatusButton from './filter/allocationStatusButton';
import FilterAddressButton from './filter/addressButton';
import FilterIncompletionReasons from './filter/incompletionReasons';
import LabelConstants from './labelConstants';
import CancelWarningModal from './cancelWarningModal';
import ExportTemplate from './export';

const ViewForm = React.lazy(() => import('./viewForm'));
const HistoryForm = React.lazy(() => import('./history/index'));

export default {
  ListItem,
  ViewForm,
  EditForm,
  GroupItem,
  HistoryForm,
  RecipientVerificationForm,
  BulkEditButton,
  AssignButton,
  UnassignButton,
  AllocateButton,
  RejectAllocationButton,
  TagButton,
  BulkArchiveButton,
  DeleteButton,
  BulkDeleteButton,
  CompleteButton,
  CancelDialog,
  FilterDateButton,
  FilterStatusButton,
  FilterTagButton,
  FilterTimeWindowButton,
  FilterJobTimeWindowButton,
  FilterCustomerButton,
  FilterAccountButton,
  FilterTaskContactPersonButton,
  FilterDriverButton,
  FilterVehicleButton,
  FilterTypeButton,
  FilterCustomFieldButton,
  FilterTransportersButton,
  FilterAllocationStatusButton,
  FilterAddressButton,
  FilterIncompletionReasons,
  ExportTemplate,
  LabelConstants,
  CancelWarningModal,
};
