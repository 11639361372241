import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  vroomTimerGroups: {},
  vroomTimerGroupIds: [],
};

function vroomTimerGroup(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VROOM_TIMER_GROUPS_CREATE_SUCCESSFUL':
    case 'RECEIVE_VROOM_TIMER_GROUP_SUCCESSFUL':
    case 'RECEIVE_VROOM_TIMER_GROUPS_UPDATE_SUCCESSFUL':
      return {
        ...state,
        vroomTimerGroups: ReduxHelper.mergeDataWithObject(
          state.vroomTimerGroups, action.data.vroom_timer_group,
        ),
      };

    case 'RECEIVE_VROOM_TIMER_GROUPS_SUCCESSFUL':
      return {
        ...state,
        vroomTimerGroups: ReduxHelper.mergeDataWithList(
          state.vroomTimerGroups, action.data.vroom_timer_groups,
        ),
        vroomTimerGroupIds: action.data.vroom_timer_groups.map(t => t.id),
      };
    default:
      return state;
  }
}

export default vroomTimerGroup;
