import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonEdit = props => (
  <Button
    iconClassName="versa-edit"
    {...props}
  >
    {props.withDefaultText ? props.strings.text : props.children}
  </Button>
);

ButtonEdit.displayName = 'Button Edit';

ButtonEdit.propTypes = {
  children: PropTypes.node,
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonEdit.defaultProps = {
  children: null,
  withDefaultText: false,
};

export default translate('Component.Button.Edit')(ButtonEdit);
