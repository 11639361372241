const initialState = {
  tasks: [],
  jobs: [],
  timestamp: null,
};

function globalSearch(state = initialState, action) {
  switch (action.type) {
    case 'RESET_SEARCH_RESULTS':
      return {
        ...state,
        tasks: [],
        jobs: [],
        timestamp: null,
      };
    case 'RECEIVE_SEARCH_RESULTS':
      if (action.timestamp > state.timestamp) {
        return {
          ...state,
          tasks: action.tasks,
          jobs: action.jobs,
          timestamp: action.timestamp,
        };
      }
      return state;
    default:
      return state;
  }
}

export default globalSearch;
