import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

class InputSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderHint: false,
    };
    this.slider = null;
  }

  componentDidMount() {
    if (typeof componentHandler !== 'undefined') {
      window.componentHandler.upgradeElement(this.slider);
    }
    this._attachHintHoverEvents();
  }

  // This component is only used in subscriptionPlanForm.js, not going to fix.
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.slider.MaterialSlider.change(nextProps.value);
  }

  _toggleRenderHint(shouldRender) {
    this.setState({ renderHint: shouldRender });
  }

  _attachHintHoverEvents() {
    if (this.props.shouldRenderHintOnHover) {
      this.slider.MaterialSlider.element_.onmouseover = this._toggleRenderHint.bind(this, true);
      this.slider.MaterialSlider.element_.onmouseout = this._toggleRenderHint.bind(this, false);
    } else {
      this._toggleRenderHint(true);
    }
  }

  _onChange() {
    this.props.onChange(parseFloat(this.slider.value));
  }

  _getLeftPosition() {
    const value = Math.min(Math.max(this.props.value, this.props.min), this.props.max);
    const width = this.slider.MaterialSlider.element_.clientWidth - 14;
    const position = (value - this.props.min) / (this.props.max - this.props.min) * width;

    return position;
  }

  _getHintPosition() {
    const parent = this.slider;
    let position;

    switch (this.props.hintPosition) {
      case InputSlider.HintPosition.TOP:
        position = -(parent.offsetHeight + 63);
        break;
      case InputSlider.HintPosition.BOTTOM:
        position = (parent.offsetHeight + 7);
        break;
      default:
    }

    return position;
  }

  _renderHint() {
    const leftPosition = this._getLeftPosition();
    const arrowClassName = classNames({
      'vf-input-slider__hint__arrow-down': this.props.hintPosition === InputSlider.HintPosition.TOP,
      'vf-input-slider__hint__arrow-up': this.props.hintPosition === InputSlider.HintPosition.BOTTOM,
    });
    const style = { left: `${leftPosition}px` };
    style[this.props.hintPosition.toLowerCase()] = `${this._getHintPosition()}px`;
    return (
      <div
        className="vf-input-slider__hint"
        style={style}
      >
        <div className={arrowClassName} />
        <div className="vf-input-slider__hint__content">
          {this.props.hint}
        </div>
      </div>
    );
  }

  render() {
    const className = classNames('vf-input-slider', this.props.className);

    return (
      <div className={className}>
        <input
          className="mdl-slider mdl-js-slider"
          max={this.props.max}
          min={this.props.min}
          onChange={this._onChange.bind(this)}
          ref={(input) => { this.slider = input; }}
          step={this.props.step}
          tabIndex="0"
          type="range"
          value={this.props.value}
        />
        {
          // component sort of loses track of the parent div, and during _renderHint does not know
          // where to insert the hint <div> at.
          // rendering an empty div when the hint is not shown resolves that.
          this.state.renderHint && !Helper.isNullOrUndefined(this.props.hint)
            ? this._renderHint() : <div />
        }
      </div>
    );
  }
}

InputSlider.displayName = 'Input Slider';

InputSlider.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.node,
  hintPosition: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  shouldRenderHintOnHover: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.number,
};

InputSlider.HintPosition = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};

InputSlider.defaultProps = {
  className: '',
  hint: '',
  hintPosition: InputSlider.HintPosition.BOTTOM,
  min: 0,
  max: 100,
  shouldRenderHintOnHover: false,
  value: 0,
  step: 1,
};

export default InputSlider;
