/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { translate, Utils } from 'versafleet-core';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Component from 'components/component';
import Router from 'utils/router';
import Helper from 'utils/helper';
import TranslationHelper from 'utils/translationHelper';
import AccessRightHelper from 'utils/accessRightHelper';
import { useProfile } from 'queryActions/useProfile';
import {
  useCreateTasksRescheduling,
  useDeleteTasksRescheduling,
  useEditTasksRescheduling,
  useGetTasksReschedulingId,
} from 'queryActions/useTasksRescheduling';
import { useOptionCustomersList } from 'queryActions/useCustomers';
import { useConstants } from 'queryActions/useConstants';

const defaultForm = {
  name: 'Rescheduling Incomplete tasks',
  task_states: ['failed'],
  scheduled_time: 1439,
  working_days: [false, true, true, true, true, true, false],
  date_selection_offset: 1,
  is_enabled: false,
  // customer_ids: [],
  // task_tags: [],
  // max_rescheduling_limit: 1,
};

function EditTasksRescheduling({ strings }) {
  const [form, setForm] = useState(defaultForm);
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState({});
  const {
    tasksRescheduling,
    isLoadingTasksRescheduling,
    errorTasksRescheduling,
  } = useGetTasksReschedulingId();

  const navigate = useNavigate();

  const isNew = Router.getRoutes()[3]?.path === 'new';
  const isEdit = Router.getRoutes()[4]?.path === 'edit';
  const isView = !isNew && !isEdit;

  useEffect(() => {
    if (tasksRescheduling && (isView || isEdit)) {
      setForm(tasksRescheduling);
    } else if (isNew) {
      setForm(defaultForm);
    }
    setIsChanged(false);
  }, [tasksRescheduling, isView, isEdit]);

  const handleFormChange = (fieldName, value) => {
    setForm(prevForm => Helper.setFilter(prevForm, fieldName, value, null, false));
    setIsChanged(true);
  };

  if (!isNew && errorTasksRescheduling) {
    return (
      <Component.Window
        isChanged={isChanged}
        onClose={() => navigate('..')}
      >
        <Component.Window.Breadcrumb>
          <Component.Window.Breadcrumb.Item
            isChanged={isChanged}
            onClick={() => Router.transitionFromSubpath('tasks_rescheduling')}
          >
            {strings.title}
          </Component.Window.Breadcrumb.Item>
        </Component.Window.Breadcrumb>

        <Component.Window.Body>
          <Component.Error
            errors={errorTasksRescheduling}
          />
        </Component.Window.Body>
      </Component.Window>
    );
  }

  return (
    <Component.Window
      isChanged={isChanged}
      onClose={() => navigate('..')}
    >
      <Component.Window.Breadcrumb>
        <Component.Window.Breadcrumb.Item
          isChanged={isChanged}
          onClick={() => Router.transitionFromSubpath('tasks_rescheduling')}
        >
          {strings.title}
        </Component.Window.Breadcrumb.Item>
      </Component.Window.Breadcrumb>

      <Component.Window.Body>
        {isLoadingTasksRescheduling
          ? <Component.EllipsisLoading />
          : (
            <React.Fragment>
              <RuleName
                error={error}
                form={form}
                isView={isView}
                onFormChange={handleFormChange}
                strings={strings.ruleName}
              />
              <TaskFilter
                error={error}
                form={form}
                isView={isView}
                onFormChange={handleFormChange}
                strings={strings.taskFilter}
              />
              <WorkingDays
                error={error}
                form={form}
                isView={isView}
                onFormChange={handleFormChange}
                strings={strings.workingDays}
              />
              <ScheduledTime
                error={error}
                form={form}
                isView={isView}
                onFormChange={handleFormChange}
                strings={strings.selectTime}
              />
              <TaskDate
                error={error}
                form={form}
                isView={isView}
                onFormChange={handleFormChange}
                strings={strings.taskDate}
              />
            </React.Fragment>
          )}
      </Component.Window.Body>

      <ActionPanel
        form={form}
        isChanged={isChanged}
        isNew={isNew}
        isView={isView}
        onError={setError}
        strings={strings}
      />
    </Component.Window>
  );
}

function RuleName({
  strings,
  form,
  onFormChange,
  error,
  isView,
}) {
  const { editTasksRescheduling, isLoadingEditTasksRescheduling } = useEditTasksRescheduling();

  const handleEnableRule = () => {
    if (isView) {
      editTasksRescheduling({
        ...form,
        is_enabled: !form.is_enabled,
      });
    } else {
      onFormChange('is_enabled', !form.is_enabled);
    }
  };

  return (
    <Component.Window.Section>
      {isView ? (
        <Component.Window.Section.Heading>
          {form.name}
        </Component.Window.Section.Heading>
      ) : (
        <Component.Window.Content className="vf-tasks-rescheduling--no-margin">
          <Component.Window.FieldEdit.Textbox
            description={strings.description}
            error={error?.name}
            fieldName={strings.title}
            isRequired
            onChange={value => onFormChange('name', value)}
            value={form.name}
          />
        </Component.Window.Content>
      )}

      <Component.Window.Subsection className="vf-tasks-rescheduling__rule-name">
        {isLoadingEditTasksRescheduling
          ? <Component.EllipsisLoading />
          : (
            <React.Fragment>
              <Component.Status.Enabled
                active={form.is_enabled}
              />
              <Component.Link onClick={handleEnableRule}>
                {TranslationHelper.replaceTag(strings.isDisable, {
                  isDisable: form.is_enabled ? strings.disable : strings.enable,
                })}
              </Component.Link>
            </React.Fragment>
          )}
      </Component.Window.Subsection>
    </Component.Window.Section>
  );
}

function TaskFilter({
  strings,
  form,
  onFormChange,
  error,
  isView,
}) {
  const [isAllCustomers, setIsAllCustomers] = useState(true);
  const [isAllTags, setIsAllTags] = useState(true);
  const [isMaxLimitActive, setIsMaxLimitActive] = useState(false);

  const { optionCustomersList } = useOptionCustomersList();
  const { constants } = useConstants();

  // useEffect(() => {
  //   if (form.customer_ids.length > 0) {
  //     setIsAllCustomers(false);
  //   } else {
  //     setIsAllCustomers(true);
  //   }
  // }, [form.customer_ids]);

  return (
    <Component.Window.Section>
      <Component.Window.Section.Heading>
        {strings.title}
      </Component.Window.Section.Heading>
      <Component.Window.Description>
        {strings.description}
      </Component.Window.Description>

      {/* Task Status */}
      <Component.Window.Subsection className="vf-tasks-rescheduling--no-margin">
        <Component.Window.Subsection.Heading>
          {strings.taskStatus.title}
        </Component.Window.Subsection.Heading>

        {isView ? (
          <Component.Window.Content className="vf-tasks-rescheduling__content">
            {form.task_states.length === Object.keys(strings.taskStatus.options).length
              ? strings.taskStatus.allState
              : Object.entries(strings.taskStatus.options)
                .filter(([key]) => form.task_states.includes(key))
                .map(([, value]) => value)
                .join(', ')}
          </Component.Window.Content>
        ) : (
          <Component.Input.CheckboxGroup.WithArray
            error={error?.task_states}
            onChange={value => onFormChange('task_states', value)}
            showIncludeAll={false}
            value={form.task_states}
          >
            {Object.entries(strings.taskStatus.options)
              .map(([key, value]) => (
                <Component.Input.Checkbox.Item key={key} value={key}>
                  {value}
                </Component.Input.Checkbox.Item>
              ))}
          </Component.Input.CheckboxGroup.WithArray>
        )}
      </Component.Window.Subsection>

      {/* Will uncommented on phase 2 */}
      {/* Customers */}
      {/* <Component.Window.Subsection className="vf-tasks-rescheduling--no-margin">
        <Component.Window.Subsection.Heading>
          {strings.customers.title}
        </Component.Window.Subsection.Heading>

        {isView ? (
          <Component.Window.Content className="vf-tasks-rescheduling__content">
            {form.customer_ids.length === 0
              ? strings.customers.allCustomers
              : optionCustomersList.filter(({ id }) => form.customer_ids
                .includes(id))
                .map(({ name }) => <div>{name}</div>)}
          </Component.Window.Content>
        ) : (
          <Component.Window.FieldEdit.Radio
            onChange={(value) => {
              if (value) {
                onFormChange('customer_ids', []);
              }
              setIsAllCustomers(value);
            }}
            value={isAllCustomers}
          >
            All Customers
            <Component.Input.Radio.Item value>
              {strings.customers.allCustomers}
            </Component.Input.Radio.Item>

            Specific Customers
            <Component.Input.Radio.Item value={false}>
              {strings.customers.selectedCustomers}
              {
                !isAllCustomers && (
                  <Component.Input.CheckboxGroup.WithArray
                    onChange={value => onFormChange('customer_ids', value)}
                    options={optionCustomersList?.map(({ id, name }) => ({
                      text: name,
                      value: id,
                    }))}
                    value={form.customer_ids}
                  />
                )
              }
            </Component.Input.Radio.Item>
          </Component.Window.FieldEdit.Radio>
        )}
      </Component.Window.Subsection> */}

      {/* Task Tags */}
      {/* <Component.Window.Subsection className="vf-tasks-rescheduling--no-margin">
        <Component.Window.Subsection.Heading>
          {strings.taskTags.title}
        </Component.Window.Subsection.Heading>

        {isView ? (
          <Component.Window.Content className="vf-tasks-rescheduling__content">
            {form.task_tags.length === 0
              ? strings.taskTags.allTags
              : form.task_tags.map(tag => tag).join(', ')}
          </Component.Window.Content>
        ) : (
          <Component.Window.FieldEdit.Radio
            onChange={(value) => {
              if (value) {
                onFormChange('task_tags', []);
              }
              setIsAllTags(value);
            }}
            value={isAllTags}
          >
            All Tags
            <Component.Input.Radio.Item value>
              {strings.taskTags.allTags}
            </Component.Input.Radio.Item>

            Specific Tags
            <Component.Input.Radio.Item value={false}>
              {strings.taskTags.selectedTags}
              {
                !isAllTags && (
                  <Component.Input.Tag
                    onChange={value => onFormChange('task_tags', value)}
                    options={constants.tasks?.tags?.map(tag => ({
                      text: tag,
                      value: tag,
                    }))}
                    placeholder={strings.taskTags.placeholder}
                    value={form.task_tags}
                  />
                )
              }
            </Component.Input.Radio.Item>
          </Component.Window.FieldEdit.Radio>
        )}
      </Component.Window.Subsection> */}

      {/* Rescheduling Limit */}
      {/* <Component.Window.Subsection className="vf-tasks-rescheduling--no-margin">
        <Component.Window.Subsection.Heading>
          {strings.reschedulingLimit.title}
        </Component.Window.Subsection.Heading>
        <Component.Window.Description>
          {strings.reschedulingLimit.description}
        </Component.Window.Description>

        {isView ? (
          <Component.Window.Content className="vf-tasks-rescheduling__content">
            {form.max_rescheduling_limit}
          </Component.Window.Content>
        ) : (
          <React.Fragment>
            <Component.Input.Checkbox
              description={strings.reschedulingLimit.description}
              fieldName={strings.reschedulingLimit.title}
              onChange={() => setIsMaxLimitActive(cur => !cur)}
              value={isMaxLimitActive}
            >
              {strings.reschedulingLimit.max}
            </Component.Input.Checkbox>
            <Component.Window.Content className="vf-tasks-rescheduling__limit-field">
              <Component.Input.Textbox
                onChange={value => onFormChange('max_rescheduling_limit', value)}
                readOnly={!isMaxLimitActive}
                type="number"
                value={form.max_rescheduling_limit}
                width="narrow"
              />
            </Component.Window.Content>
          </React.Fragment>
        )}
      </Component.Window.Subsection> */}
    </Component.Window.Section>
  );
}

function WorkingDays({
  strings,
  form,
  onFormChange,
  error,
  isView,
}) {
  const daysOfWeek = Object.entries(strings.days).map(([key]) => key);

  return (
    <Component.Window.Section>
      <Component.Window.Section.Heading>
        {strings.title}
      </Component.Window.Section.Heading>
      <Component.Window.Description>
        {strings.description}
      </Component.Window.Description>

      {isView ? (
        <Component.Window.Content className="vf-tasks-rescheduling__content">
          {form.working_days.every(isEnable => isEnable)
            ? strings.allDays
            : form.working_days
              .map((isEnable, index) => (isEnable ? strings.days[daysOfWeek[index]] : null))
              .filter(Boolean)
              .join(', ')}
        </Component.Window.Content>
      ) : (
        <Component.Window.Subsection className="vf-tasks-rescheduling--no-margin">
          <Component.Input.CheckboxGroup.WithArray
            error={error?.working_days}
            onChange={(value) => {
              // Convert the selected values to boolean array
              const booleanArray = daysOfWeek.map(day => value.includes(day));
              onFormChange('working_days', booleanArray);
            }}
            showIncludeAll={false}
            value={
              form.working_days
                .map((isEnabled, index) => (isEnabled
                  ? daysOfWeek[index] : null))
                .filter(Boolean)
            }
          >
            {Object.entries(strings.days).map(([key, value]) => (
              <Component.Input.Checkbox.Item key={key} value={key}>
                {value}
              </Component.Input.Checkbox.Item>
            ))}
          </Component.Input.CheckboxGroup.WithArray>
        </Component.Window.Subsection>
      )}
    </Component.Window.Section>
  );
}

function ScheduledTime({
  strings,
  form,
  onFormChange,
  error,
  isView,
}) {
  function formatTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = mins < 10 ? `0${mins}` : mins;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  return (
    <Component.Window.Section>
      <Component.Window.Section.Heading>
        {strings.title}
      </Component.Window.Section.Heading>
      <Component.Window.Description>
        {strings.description}
      </Component.Window.Description>

      {isView
        ? (
          <Component.Window.Content className="vf-tasks-rescheduling__content">
            {formatTime(form.scheduled_time)}
          </Component.Window.Content>
        ) : (
          <Component.Input.TimePicker
            error={error?.scheduled_time}
            onChange={value => onFormChange('scheduled_time', value)}
            placeholder="Select Time"
            value={form.scheduled_time}
          />
        )}

    </Component.Window.Section>
  );
}

function TaskDate({
  strings,
  form,
  onFormChange,
  error,
  isView,
}) {
  const options = [1, 2, 3, 4];

  return (
    <Component.Window.Section>
      <Component.Window.Section.Heading>
        {strings.title}
      </Component.Window.Section.Heading>
      <Component.Window.Description>
        {isView ? strings.descriptionView : strings.descriptionEdit}
      </Component.Window.Description>

      {!isView && (
        <React.Fragment>
          <br />
          <Component.Window.Description>
            <div>{strings.example}</div>
            <div>{strings.example2}</div>
            <div>{strings.example3}</div>
            <div>{strings.example4}</div>
          </Component.Window.Description>
        </React.Fragment>
      )}

      {isView ? (
        <Component.Window.Content className="vf-tasks-rescheduling__content">
          {Utils.TranslationHelper
            .replaceTag(strings.nextDay.title, {
              day: `${options.find(value => value === form.date_selection_offset)} ${form.date_selection_offset === 1 ? 'day' : 'days'}`,
            })}
        </Component.Window.Content>
      ) : (
        <Component.Input.Radio
          error={error?.date_selection_offset}
          onChange={value => onFormChange('date_selection_offset', value)}
          value={form.date_selection_offset}
        >
          {options.map(value => (
            <Component.Input.Radio.Item key={value} value={value}>
              {Utils.TranslationHelper
                .replaceTag(strings.nextDay.title, {
                  day: value === 1 ? '1 day' : `${value} days`,
                })}
              <Component.Window.Description>
                {Utils.TranslationHelper
                  .replaceTag(strings.nextDay.description, {
                    day: value,
                  })}
              </Component.Window.Description>
            </Component.Input.Radio.Item>
          ))}
        </Component.Input.Radio>
      )}
    </Component.Window.Section>
  );
}

function ActionPanel({
  strings,
  form,
  onError,
  isChanged,
  isView,
  isNew,
}) {
  const { profile, isLoadingProfile } = useProfile();
  const accessRights = profile?.access_rights || [];
  const {
    createTasksRescheduling,
    isLoadingCreateTasksRescheduling,
    errorCreateTasksRescheduling,
  } = useCreateTasksRescheduling();
  const {
    editTasksRescheduling,
    isLoadingEditTasksRescheduling,
    errorEditTasksRescheduling,
  } = useEditTasksRescheduling();
  const {
    deleteTasksRescheduling,
    isLoadingDeleteTasksRescheduling,
    errorDeleteTasksRescheduling,
  } = useDeleteTasksRescheduling();
  const navigate = useNavigate();

  const validateForm = (formData) => {
    const requiredFields = {
      name: value => !!value,
      task_states: value => Array.isArray(value) && value.length > 0,
      scheduled_time: value => typeof value === 'number' && value > 0,
      working_days: value => Array.isArray(value) && value.some(day => day === true),
      // customer_ids
      // task_tags
      // rescheduling_limit: value => typeof value === 'number' && value > 0,
    };

    return Object.entries(requiredFields).every(([key, validator]) => validator(formData[key]));
  };

  const isValidForm = validateForm(form);
  const isActive = isView || (isNew && isValidForm) || (isValidForm && isChanged);

  const handleSubmit = async () => {
    if (isNew) {
      await createTasksRescheduling(form);
    } else {
      await editTasksRescheduling(form);
    }

    if (errorCreateTasksRescheduling) {
      onError({ ...errorCreateTasksRescheduling });
    } else if (errorEditTasksRescheduling) {
      onError({ ...errorEditTasksRescheduling });
    } else {
      navigate('..', { relative: 'path' });
    }
  };

  const handleDelete = async () => {
    await deleteTasksRescheduling();

    if (errorDeleteTasksRescheduling) {
      onError({ ...errorDeleteTasksRescheduling });
    } else {
      navigate('/settings/add_ons/tasks_rescheduling');
    }
  };

  return (
    <Component.Window.ActionPanel>
      {isLoadingProfile && <Component.EllipsisLoading />}
      {
        AccessRightHelper.hasAccess(accessRights, 'addons_setting.edit') && (
          <Component.Window.ActionPanel.Button.Primary
            className="vf-tasks-rescheduling__action-button"
            disabled={!isActive}
            fillColor={!isView}
            iconClassName={isView ? 'versa-settings' : 'versa-add'}
            loading={isLoadingCreateTasksRescheduling || isLoadingEditTasksRescheduling}
            onClick={() => {
              if (isView) {
                navigate('edit');
              } else {
                handleSubmit();
              }
            }}
          >
            {isView ? strings.buttons.configure : strings.buttons.saveRule}
          </Component.Window.ActionPanel.Button.Primary>
        )
      }

      {/* Delete will be available on phase 2 */}
      {/* {!isView && !isNew
        && AccessRightHelper.hasAccess(accessRights, 'addons_setting.edit')
        && (
          <Component.Window.ActionPanel.Button.Hidden
            className="vf-tasks-rescheduling__action-button"
            iconClassName="versa-delete"
            loading={isLoadingDeleteTasksRescheduling}
            onClick={handleDelete}
          >
            {strings.buttons.delete}
          </Component.Window.ActionPanel.Button.Hidden>
        )} */}
    </Component.Window.ActionPanel>
  );
}

const formProptypes = {
  error: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    task_states: PropTypes.arrayOf(PropTypes.string),
    working_days: PropTypes.arrayOf(PropTypes.bool),
    scheduled_time: PropTypes.number,
    date_selection_offset: PropTypes.number,
    is_enabled: PropTypes.bool,
    customer_ids: PropTypes.arrayOf(PropTypes.number),
    customer_names: PropTypes.arrayOf(PropTypes.string),
    task_tags: PropTypes.arrayOf(PropTypes.string),
    max_rescheduling_limit: PropTypes.number,
  }).isRequired,
  isView: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  strings: PropTypes.shape().isRequired,
};

EditTasksRescheduling.propTypes = {
  strings: PropTypes.shape().isRequired,
};
RuleName.propTypes = formProptypes;
TaskFilter.propTypes = formProptypes;
WorkingDays.propTypes = formProptypes;
ScheduledTime.propTypes = formProptypes;
TaskDate.propTypes = formProptypes;
ActionPanel.propTypes = {
  form: formProptypes.form.isRequired,
  isChanged: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isView: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  strings: PropTypes.shape().isRequired,
};

export default translate('Settings.Addons.tasksRescheduling')(EditTasksRescheduling);
