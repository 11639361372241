import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveVroomTimersSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMERS_SUCCESSFUL',
    data,
  };
}

function receiveVroomTimerSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMER_SUCCESSFUL',
    data,
  };
}

function receiveVroomTimerUpdateSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMER_UPDATE_SUCCESSFUL',
    data,
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/vroom_timer', {
      method: 'GET',
    });
    dispatch(receiveVroomTimersSuccessful(result));
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/vroom_timer/${id}`, {
      method: 'GET',
    });
    dispatch(receiveVroomTimerSuccessful(result));
  };
}

function update(data, id) {
  return async (dispatch) => {
    const result = await request(`/vroom_timer/${id}`, {
      method: 'PUT',
    }, data);
    dispatch(receiveVroomTimerUpdateSuccessful(result));
    return result;
  };
}

function deleteVroomTimer(id) {
  return async () => {
    await request(`/vroom_timer/${id}`, {
      method: 'DELETE',
    });
  };
}

export default {
  fetchList,
  fetch,
  update,
  delete: deleteVroomTimer,
};
