const initialState = {
  notificators: [],
  taskNotificators: [],
  jobNotificators: [],
  tags: [],
  systemTags: {},
};

function notificationSetting(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_NOTIFICATION_SETTING':
      action.notificators.sort((a, b) => {
        const keyA = a.id;
        const keyB = b.id;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      return {
        ...state,
        notificators: action.notificators,
      };
    case 'RECEIVE_NOTIFICATION_SETTING_CREATE_SUCCESSFUL':
      return {
        ...state,
        notificators: state.notificators.concat([action.notificator]),
      };
    case 'RECEIVE_NOTIFICATION_SETTING_UPDATE_SUCCESSFUL': {
      const notificators = [];
      for (let i = 0; i < state.notificators.length; i += 1) {
        const notificator = state.notificators[i];
        if (action.notificator.id === notificator.id) {
          notificators.push(action.notificator);
        } else {
          notificators.push(notificator);
        }
      }
      return {
        ...state,
        notificators,
      };
    }
    case 'RECEIVE_TASK_NOTIFICATION_SETTING':
      return {
        ...state,
        taskNotificators: action.taskNotificators,
      };
    case 'RECEIVE_JOB_NOTIFICATION_SETTING':
      return {
        ...state,
        jobNotificators: action.jobNotificators,
      };
    case 'RECEIVE_TASK_NOTIFICATION_SETTING_UPDATE_SUCCESSFUL': {
      const taskNotificators = [];
      for (let i = 0; i < state.taskNotificators.length; i += 1) {
        const notificator = state.taskNotificators[i];
        if ((action.taskNotificator.event_name === notificator.event_name)
          && (action.taskNotificator.recipient === notificator.recipient)
          && (action.taskNotificator.job_type === notificator.job_type)) {
          taskNotificators.push(action.taskNotificator);
        } else {
          taskNotificators.push(notificator);
        }
      }
      return {
        ...state,
        taskNotificators,
      };
    }
    case 'RECEIVE_JOB_NOTIFICATION_SETTING_UPDATE_SUCCESSFUL': {
      const jobNotificators = [];
      for (let i = 0; i < state.jobNotificators.length; i += 1) {
        const notificator = state.jobNotificators[i];
        if ((action.jobNotificator.event_name === notificator.event_name)
          && (action.jobNotificator.recipient === notificator.recipient)
          && (action.jobNotificator.job_type === notificator.job_type)) {
          jobNotificators.push(action.jobNotificator);
        } else {
          jobNotificators.push(notificator);
        }
      }
      return {
        ...state,
        jobNotificators,
      };
    }
    case 'RECEIVE_SYSTEM_TAGS':
      return {
        ...state,
        systemTags: action.systemTags,
      };
    default:
      return state;
  }
}

export default notificationSetting;
