import React from 'react';
import PropTypes from 'prop-types';

const RightPanel = props => (
  <div className="vf-form__right-panel">
    {props.children}
  </div>
);

RightPanel.displayName = 'Form Right Panel';

RightPanel.propTypes = {
  children: PropTypes.node,
};

RightPanel.defaultProps = {
  children: null,
};

export default RightPanel;
