import { Utils } from 'versafleet-core';

function receiveVehiclePartSettings(vehiclePartSettings) {
  return {
    type: 'RECEIVE_VEHICLE_PART_SETTINGS',
    vehiclePartSettings,
  };
}

function receiveVehiclePartSettingsUpdate(vehiclePartSettings) {
  return {
    type: 'RECEIVE_VEHICLE_PART_SETTINGS_UPDATE',
    vehiclePartSettings,
  };
}

function fetch() {
  return async (dispatch) => {
    try {
      const result = await Utils.RequestHelper.request('/vehicle_part_setting', {
        method: 'GET',
      });
      dispatch(receiveVehiclePartSettings(result.vehicle_part_setting));
      return result.vehicle_part_setting;
    } catch (error) {
      if (Array.isArray(error) && error.length > 0 && error[0].includes('Not subscribed')) {
        // Ignore this error to reduce Sentry usage
        return error;
      }
      throw error;
    }
  };
}

function update(query) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/vehicle_part_setting', {
      method: 'PUT',
    }, {
      vehicle_part_setting: query,
    });
    dispatch(receiveVehiclePartSettingsUpdate(result.vehicle_part_setting));
    return result.vehicle_part_setting;
  };
}
export default {
  fetch,
  update,
};
