import React from 'react';
import PropTypes from 'prop-types';
import NewDialog from 'utils/newDialog';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import authorize from 'authorize';
import DeleteDialog from './deleteDialog';

class TaskBulkDeleteButton extends React.Component {
  _openDialog = () => {
    NewDialog.DialogManager.open((
      <DeleteDialog
        dataIds={this.props.dataIds}
      />
    ));
  }

  render() {
    return (
      <Component.Button
        accessRightKey="task.archive"
        className="vf-task__bulk-delete-button"
        hint={this.props.strings.page.buttons.delete}
        iconClassName="versa-delete"
        onClick={this._openDialog}
      />
    );
  }
}

TaskBulkDeleteButton.displayName = 'Task Bulk Delete Button';

TaskBulkDeleteButton.propTypes = {
  dataIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

export default translate('Task')(authorize('')(TaskBulkDeleteButton));
