import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveSearchResults(result, timestamp) {
  return {
    type: 'RECEIVE_SEARCH_RESULTS',
    tasks: result.tasks,
    jobs: result.jobs,
    timestamp,
  };
}

function resetSearchResults() {
  return {
    type: 'RESET_SEARCH_RESULTS',
  };
}

function search(keyword) {
  return async (dispatch) => {
    const timestamp = new Date();
    const result = await request(`/search?keyword=${keyword}`, {
      method: 'GET',
    });
    dispatch(receiveSearchResults(result, timestamp));
  };
}

export default {
  search,
  resetSearchResults,
};
