import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonMoreAction = props => (
  <Button
    hint={props.withDefaultText ? null : props.strings.text}
    iconClassName="versa-more-vert"
    {...props}
  >
    {props.withDefaultText ? props.strings.text : false}
  </Button>
);

ButtonMoreAction.displayName = 'Button More Action';

ButtonMoreAction.propTypes = {
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonMoreAction.defaultProps = {
  withDefaultText: false,
};

export default translate('Component.Button.MoreAction')(ButtonMoreAction);
