import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { connect } from 'react-redux';
import Router from 'utils/router';
import { Utils, translate } from 'versafleet-core';
import ReduxActions from 'reduxActions';
import AccessRightHelper from 'utils/accessRightHelper';
import addOnsSettings from 'pages/addOns/addOnsConstants';
import { withRouterHooks } from '../../utils/router';

const { Helper, TranslationHelper } = Utils;

class TaskRecipientVerificationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hasRequested: false,
      showPinSentModal: false,
      errors: [],
    };
    this.timer = null;
  }

  componentDidMount() {
    this.props.dispatch(ReduxActions.task.fetch(Router.getParams().id));
    this.props.dispatch(ReduxActions.versadriveSettings.fetchVersadriveSettings());
    this.props.dispatch(ReduxActions.task.fetchRecipientVerificationHistory(Router.getParams().id));
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  _closeWindow = () => {
    this.props.navigate('..');
  }

  _routeToJob = () => {
    if (!Helper.isNullOrUndefined(this.props.data.job)) {
      Router.transitionFromSubpath('tasks', `jobs/${this.props.data.job.id}`);
    }
  }

  _routeToTask = () => {
    if (!Helper.isNullOrUndefined(this.props.data.id)) {
      Router.transitionFromSubpath(`${this.props.data.id}`);
    }
  }

  _requestPin = async () => {
    this.setState({ isLoading: true });
    try {
      await this.props.dispatch(ReduxActions.task.requestPin(this.props.data.id));
      this.setState({
        errors: [],
        isLoading: false,
        hasRequested: true,
        showPinSentModal: true,
      });
      this.timer = setTimeout(() => this.setState({
        hasRequested: false, showPinSentModal: false,
      }), 2000);
    } catch (errors) {
      this.setState({ errors, isLoading: false });
    }
  }

  _renderBreadcrumb = () => (
    <Component.Window.Breadcrumb>
      <Component.Window.Breadcrumb.Item onClick={this._routeToJob}>
        {!Helper.isNullOrUndefined(this.props.data.job)
          ? (this.props.data.job || {}).guid : false}
      </Component.Window.Breadcrumb.Item>
      <Component.Window.Breadcrumb.Item onClick={this._routeToTask}>
        {this.props.data.guid}
      </Component.Window.Breadcrumb.Item>
      <Component.Window.Breadcrumb.Item>
        {this.props.strings.header}
      </Component.Window.Breadcrumb.Item>
    </Component.Window.Breadcrumb>
  );

  _renderHeader = () => (
    <Component.Window.Header>
      <Component.Window.Header.Title>
        {this.props.strings.header}
      </Component.Window.Header.Title>
      <Component.Window.Description>
        {this.props.data.guid}
      </Component.Window.Description>
    </Component.Window.Header>
  );

  _renderTimeStampPanel = history => (
    <div className="vf-task__recipient-verification__item__left-panel">
      <Component.Window.Content textClassName="vf-task__recipient-verification__item__left-panel__datetime">
        {moment(history.created_at).format('DD MMM YYYY')}
      </Component.Window.Content>
      <Component.Window.Content textClassName="vf-task__recipient-verification__item__left-panel__datetime">
        {moment(history.created_at).format('hh:mm:ss A')}
      </Component.Window.Content>
      <Component.Window.Description>
        {history.user && history.user.name}
      </Component.Window.Description>
    </div>
  );

  _renderContent = (history) => {
    const { strings } = this.props;
    if (history.auditable_type === 'TaskPinHistory') {
      return (
        <Component.Window.Subsection>
          <Component.Window.Subsection.Heading>
            {strings.mainContent.pinRequested}
          </Component.Window.Subsection.Heading>
          <div className="vf-task__recipient-verification__item__content">
            <Component.Window.Description className="vf-task__recipient-verification__item__content__heading">
              {strings.mainContent.pinRequestAttempt}
            </Component.Window.Description>
            {history.attemptNo}
          </div>
        </Component.Window.Subsection>
      );
    }

    if (history.auditable_type === 'SuccessfulPinVerificationHistory') {
      return (
        <Component.Window.Subsection>
          <Component.Window.Subsection.Heading>
            {strings.mainContent.pinVerified}
          </Component.Window.Subsection.Heading>
        </Component.Window.Subsection>
      );
    }

    if (history.auditable_type === 'TaskCompletionHistory') {
      const headingText = history.is_recipient_verification_required
        ? strings.mainContent.pinUnverified : strings.mainContent.verificationNotRequired;

      return (
        <Component.Window.Subsection>
          <Component.Window.Subsection.Heading>
            {headingText}
          </Component.Window.Subsection.Heading>
          {
            history.audited_changes
              && Helper.isNonEmptyString(history.audited_changes.verification_failure_reason)
              && (
                <div className="vf-task__recipient-verification__item__content">
                  <Component.Window.Description className="vf-task__recipient-verification__item__content__heading">
                    {strings.mainContent.reason}
                  </Component.Window.Description>
                  {history.audited_changes.verification_failure_reason}
                </div>
              )
          }
        </Component.Window.Subsection>
      );
    }

    return false;
  };

  _renderVerificationItem = history => (
    <Component.Window.Subsection className="vf-task__recipient-verification__item">
      {this._renderTimeStampPanel(history)}
      <div className="vf-task__recipient-verification__item__content">
        <div className="vf-task__recipient-verification__item__divider">|</div>
        <div>
          {this._renderContent(history)}
        </div>
      </div>
    </Component.Window.Subsection>
  );

  _renderMainContent = () => {
    const { data, strings } = this.props;
    let attemptNo = 0;
    let wasNotRequiringVerification = false;
    const isFinished = Helper.isNullOrUndefined(data.job) ? false
      : (data.job.job_type === 'pickup' && data.state === 'collected') || data.state === 'successful' || data.state === 'failed';
    const histories = Object.values(data.verificationHistory || {});
    const verificationHistory = histories.map((history, i) => {
      if (history.auditable_type === 'TaskCompletionHistory') {
        // reset attempt count after task is restarted (task completed/failed)
        attemptNo = 0;
        if (i === histories.length - 1 && !history.is_recipient_verification_required) {
          wasNotRequiringVerification = true;
        }
      }

      if (history.auditable_type === 'TaskPinHistory') {
        attemptNo += 1;
        return {
          ...history,
          attemptNo,
        };
      }

      return history;
    }).reverse();

    return (
      <Component.Window.Section>
        {
          !data.is_recipient_verification_required || (wasNotRequiringVerification && isFinished)
            ? (
              <Component.Window.Content>
                {strings.mainContent.verificationNotRequiredForTask}
              </Component.Window.Content>
            ) : (
              <React.Fragment>
                {verificationHistory.length === 0 && (
                  <React.Fragment>
                    <Component.Window.Content>
                      {strings.mainContent.noPinRequest}
                    </Component.Window.Content>
                    <Component.Window.Content>
                      {strings.mainContent.pinNotVerified}
                    </Component.Window.Content>
                  </React.Fragment>
                )}
                {verificationHistory.map(history => this._renderVerificationItem(history))}
              </React.Fragment>
            )
        }
      </Component.Window.Section>
    );
  };

  _renderPinSentModal = () => (
    <Component.Modal
      headerIconClassName="versa-completed"
      headerText={this.props.strings.pinRequest}
      visible={this.state.showPinSentModal}
    >
      {this.props.strings.pinSent}
    </Component.Modal>
  );

  _renderActionPanel = () => {
    const {
      addonsSetting, data, strings, versadriveSetting,
    } = this.props;
    const current = data.pin_requests_sent_by_operator;
    const total = versadriveSetting.recipient_verification_operator_pin_request_number;
    const isFinished = Helper.isNullOrUndefined(data.job) ? false
      : (data.job.job_type === 'pickup' && data.state === 'collected') || data.state === 'successful' || data.state === 'failed';
    const { isLoading, hasRequested, errors } = this.state;
    let buttonText;

    if (isLoading) {
      buttonText = strings.requestingPin;
    } else if (hasRequested) {
      buttonText = strings.pinRequested;
    } else {
      buttonText = current > 0 ? (
        <React.Fragment>
          {strings.requestPin}
          <br />
          {TranslationHelper.replaceTag(strings.pinRequestCount, {
            current,
            total,
          })}
        </React.Fragment>
      ) : strings.requestPin;
    }

    return (
      <Component.Window.ActionPanel>
        {addonsSetting.recipient_verification_by_operator === addOnsSettings.subscribed
          && versadriveSetting.recipient_verification_operator_pin_request
          && AccessRightHelper.hasAccess(this.props.accessRights, 'task.request_pin') && (
            <React.Fragment>
              <Component.Error errors={errors} />
              <Component.Window.ActionPanel.Button.Primary
                disabled={current >= total || isFinished
                  || !data.is_recipient_verification_required}
                disabledComplete={hasRequested}
                iconClassName={hasRequested ? 'versa-completed' : 'versa-textsms'}
                loading={isLoading}
                onClick={this._requestPin}
              >
                {buttonText}
              </Component.Window.ActionPanel.Button.Primary>
            </React.Fragment>
        )}
      </Component.Window.ActionPanel>
    );
  }

  render() {
    return (
      <Component.Window onClose={this._closeWindow}>
        {this._renderBreadcrumb()}
        <Component.Window.Body>
          {this._renderHeader()}
          {this._renderMainContent()}
          {this._renderPinSentModal()}
        </Component.Window.Body>
        {this._renderActionPanel()}
      </Component.Window>
    );
  }
}

TaskRecipientVerificationForm.displayName = 'Task Recipient Verification Form';

TaskRecipientVerificationForm.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addonsSetting: PropTypes.shape({
    recipient_verification_by_operator: PropTypes.number,
  }),
  data: PropTypes.shape({
    guid: PropTypes.string,
    id: PropTypes.number,
    is_recipient_verification_required: PropTypes.bool,
    job: PropTypes.shape({
      id: PropTypes.number,
      job_type: PropTypes.string,
    }),
    pin_requests_sent_by_operator: PropTypes.number,
    state: PropTypes.string,
    verificationHistory: PropTypes.arrayOf(PropTypes.shape({
      action: PropTypes.string,
      auditable_type: PropTypes.string,
      audited_changes: PropTypes.shape({
        verification_failure_reason: PropTypes.string,
      }),
      created_at: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number,
        model: PropTypes.string,
        name: PropTypes.string,
      }),
    })),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  strings: PropTypes.shape({
    header: PropTypes.string,
    pinRequest: PropTypes.string,
    mainContent: PropTypes.shape({
      noPinRequest: PropTypes.string,
      pinNotVerified: PropTypes.string,
      verificationNotRequiredForTask: PropTypes.string,
      verificationNotRequired: PropTypes.string,
      pinRequested: PropTypes.string,
      pinRequestAttempt: PropTypes.string,
      pinVerified: PropTypes.string,
      pinUnverified: PropTypes.string,
      reason: PropTypes.string,
    }),
    requestPin: PropTypes.string,
    pinRequestCount: PropTypes.string,
    requestingPin: PropTypes.string,
    pinRequested: PropTypes.string,
    pinSent: PropTypes.string,
  }).isRequired,
  versadriveSetting: PropTypes.shape({
    recipient_verification_operator_pin_request: PropTypes.bool,
    recipient_verification_operator_pin_request_number: PropTypes.number,
  }),
};

TaskRecipientVerificationForm.defaultProps = {
  addonsSetting: {},
  versadriveSetting: {},
};

function mapStateToProps(state) {
  const {
    account, profile, task, versadriveSettings,
  } = state;
  return {
    accessRights: profile.profile.access_rights,
    addonsSetting: (account.account && account.account.addons_setting) || {},
    data: task.tasks[Router.getParams().id] || {},
    versadriveSetting: versadriveSettings.versadriveSettings,
  };
}

export default withRouterHooks(connect(mapStateToProps)(translate('Task.recipientVerification')(TaskRecipientVerificationForm)));
