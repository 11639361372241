import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Components } from 'versafleet-core';
import Helper from 'utils/helper';
import authorize from 'authorize';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: this.props.showByDefault,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showByDefault !== this.props.showByDefault) {
      // It is okay to use setState if there is a condition preventing infinite loops.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showDropdown: this.props.showByDefault });
    }
  }

  render() {
    const id = Helper.isNullOrUndefined(this.props.id) ? undefined : `${this.props.id}__dropdown`;
    const className = classNames('vf-form__dropdown', this.props.className);

    return (
      <div className={className}>
        <div
          className="vf-form__dropdown__parent"
          id={id}
          onClick={() => {
            const selection = window.getSelection();
            if (selection.toString().length === 0) {
              this.setState(prevState => ({
                showDropdown: !prevState.showDropdown,
              }));
            }
          }}
        >
          {this.props.parent}
          <Components.CircleIcon
            className="vf-form__dropdown__parent__arrow noselect"
            iconClassName={classNames({
              'versa-chevron-down': this.state.showDropdown,
              'versa-chevron-left': !this.state.showDropdown,
            })}
          />
        </div>
        <CSSTransition
          classNames="expandable"
          in={this.state.showDropdown}
          timeout={200}
          unmountOnExit
        >
          <div>
            {this.props.children}
          </div>
        </CSSTransition>
      </div>
    );
  }
}

Dropdown.displayName = 'Form Dropdown';

Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  parent: PropTypes.node.isRequired,
  showByDefault: PropTypes.bool,
};

Dropdown.defaultProps = {
  className: null,
  children: null,
  id: null,
  showByDefault: true,
};

export default authorize('')(Dropdown);
