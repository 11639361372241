/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import { useNavigate } from 'react-router-dom';

import AccessRightHelper from 'utils/accessRightHelper';
import Component from 'components/component';
import { useProfile } from 'queryActions/useProfile';
import { useGetTasksReschedulingList } from 'queryActions/useTasksRescheduling';
import ListTasksRescheduling from './listTasksRescheduling';

function ViewTasksRescheduling({
  strings,
}) {
  const { profile, isLoadingProfile } = useProfile();
  const accessRights = profile?.access_rights || [];
  const {
    tasksRescheduling,
    isLoadingTasksRescheduling,
  } = useGetTasksReschedulingList();

  const navigate = useNavigate();

  // Will be removed in phase 2
  useEffect(() => {
    if (!isLoadingTasksRescheduling) {
      if (tasksRescheduling.length > 0) {
        navigate(`${tasksRescheduling[0].id}`);
      } else if (tasksRescheduling.length === 0) {
        navigate('new');
      }
    }
  }, [isLoadingTasksRescheduling, tasksRescheduling, navigate]);

  // Will be uncommented in phase 2
  return (
    <Component.Window onClose={() => navigate('..')}>
      {/* Breadcrumb */}
      <Component.Window.Breadcrumb>
        <Component.Window.Breadcrumb.Item>
          {strings.title}
        </Component.Window.Breadcrumb.Item>
      </Component.Window.Breadcrumb>

      <Component.Window.Body>
        {isLoadingTasksRescheduling && <Component.EllipsisLoading />}
        {/* Header */}
        {/* <Component.Window.Header>
          <Component.Window.Header.Title>
            {strings.title}
          </Component.Window.Header.Title>
          <Component.Window.Description>
            {strings.description}
          </Component.Window.Description>
        </Component.Window.Header> */}

        {/* Content */}
        {/* <ListTasksRescheduling /> */}
      </Component.Window.Body>

      {/* Action panel */}
      {/* <Component.Window.ActionPanel>
        {isLoadingProfile
          ? <Component.EllipsisLoading />
          : AccessRightHelper.hasAccess(accessRights, 'addons_setting.edit')
            && (
            <Component.Window.ActionPanel.Button.Primary
              className="vf-tasks-rescheduling__action-button"
              fillColor
              iconClassName="versa-add"
              onClick={() => navigate('new')}
            >
              {strings.buttons.addRule}
            </Component.Window.ActionPanel.Button.Primary>
            )}
      </Component.Window.ActionPanel> */}
    </Component.Window>
  );
}

ViewTasksRescheduling.propTypes = {
  strings: PropTypes.shape().isRequired,
};

export default translate('Settings.Addons.tasksRescheduling')(ViewTasksRescheduling);
