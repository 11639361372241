import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  taskEmailTriggers: {},
  taskSmsTriggers: {},
  jobEmailTriggers: {},
  jobSmsTriggers: {},
};

const categoryMap = {
  task: {
    email: 'taskEmailTriggers',
    sms: 'taskSmsTriggers',
  },
  job: {
    email: 'jobEmailTriggers',
    sms: 'jobSmsTriggers',
  },
};

const statusTrigger = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_STATUS_TRIGGERS': {
      const category = categoryMap[action.model][action.triggerType];

      return {
        ...state,
        [category]: ReduxHelper.mergeDataWithList({}, action.statusTriggers),
      };
    }
    case 'RECEIVE_STATUS_TRIGGER': {
      const category = categoryMap[action.statusTrigger.model][action.statusTrigger.trigger_type];

      return {
        ...state,
        [category]: ReduxHelper.mergeDataWithObject(state[category], action.statusTrigger),
      };
    }
    default:
      return state;
  }
};

export default statusTrigger;
