import { addDefinition, changeLanguage, Utils } from 'versafleet-core';

const { RequestHelper: { request, requestBlob }, Helper } = Utils;

const importedLanguage = {
  hi: false,
  id: false,
  ms: false,
  th: false,
  tl: false,
  vi: false,
  zh_CN: false,
  my: false,
};

function receiveAccount(account) {
  return {
    type: 'RECEIVE_ACCOUNT',
    account,
  };
}

function receiveEPODSetting(epodSetting) {
  return {
    type: 'RECEIVE_EPOD_SETTING',
    epodSetting,
  };
}

function receiveTaskLabel(taskLabel) {
  return {
    type: 'RECEIVE_TASK_LABEL',
    taskLabel,
  };
}

function receiveTrackingSetting(trackingSetting) {
  return {
    type: 'RECEIVE_TRACKING_SETTING',
    trackingSetting,
  };
}

function receiveAccountUpdateSuccessful(account) {
  return {
    type: 'RECEIVE_ACCOUNT_UPDATE_SUCCESSFUL',
    account,
  };
}

function receiveAccountTrackingSettingTags(trackingSettingTags) {
  return {
    type: 'RECEIVE_TRACKING_SETTING_TAGS',
    trackingSettingTags,
  };
}

function receiveAccountCustomEpodSettingTags(customEpodSettingTagsBlocks) {
  return {
    type: 'RECEIVE_CUSTOM_EPOD_SETTING_TAGS',
    customEpodSettingTagsBlocks,
  };
}

function receiveAccountCustomTaskLabelTags(customTaskLabelTags) {
  return {
    type: 'RECEIVE_CUSTOM_TASK_LABEL_TAGS',
    customTaskLabelTags,
  };
}

function receiveTimeWindows(timeWindows) {
  return {
    type: 'RECEIVE_TIME_WINDOWS',
    timeWindows,
  };
}

function receiveRunsheetManagerSetting(runsheetManagerSetting) {
  return {
    type: 'RECEIVE_RUNSHEET_MANAGER_SETTING',
    runsheetManagerSetting,
  };
}

function receiveOptionChildAccounts(optionChildAccounts) {
  return {
    type: 'RECEIVE_OPTION_CHILD_ACCOUNTS',
    optionChildAccounts,
  };
}

function setIsFetchingTimeWindows(isFetchingTimeWindows) {
  return {
    type: 'SET_IS_FETCHING_TIME_WINDOWS',
    isFetchingTimeWindows,
  };
}

function setIsFetchingOptionChildAccounts(isFetchingOptionChildAccounts) {
  return {
    type: 'SET_IS_FETCHING_OPTION_CHILD_ACCOUNTS',
    isFetchingOptionChildAccounts,
  };
}

async function setupLanguage(language) {
  if (language !== 'en' && !importedLanguage[language]) {
    let languageFile;
    switch (language) {
      case 'hi':
        languageFile = await import('languages/hi.json');
        break;
      case 'id':
        languageFile = await import('languages/id.json');
        break;
      case 'ms':
        languageFile = await import('languages/ms.json');
        break;
      case 'th':
        languageFile = await import('languages/th.json');
        break;
      case 'tl':
        languageFile = await import('languages/tl.json');
        break;
      case 'vi':
        languageFile = await import('languages/vi.json');
        break;
      case 'zh-CN':
        languageFile = await import('languages/zh-CN.json');
        break;
      case 'my':
        languageFile = await import('languages/my.json');
        break;
      default:
        break;
    }
    await addDefinition(language, languageFile.default);
    importedLanguage[language] = true;
  }
}

function fetch() {
  return async (dispatch, getState) => {
    const result = await request('/account', {
      method: 'GET',
    });
    dispatch(receiveAccount(result.account));
    localStorage.setItem('bearerToken', result.account.jwt_token);
    if (result.account.language !== getState().account.language) {
      await setupLanguage(result.account.language);
      changeLanguage(result.account.language);
    }
    return result;
  };
}

function update(query) {
  return async (dispatch, getState) => {
    const result = await request('/account', {
      method: 'PUT',
    }, {
      account: query,
    });
    if (result.account.language !== getState().account.language) {
      await setupLanguage(result.account.language);
      changeLanguage(result.account.language);
    }
    dispatch(receiveAccountUpdateSuccessful(result));
  };
}

function updateAddOnsSettingInterested(query) {
  return async (dispatch) => {
    const result = await request('/account/addons_setting_interested', {
      method: 'PUT',
    }, {
      addons_name: query,
    });
    dispatch(receiveAccountUpdateSuccessful(result));
  };
}

function fetchEPODSetting() {
  return async (dispatch) => {
    const result = await request('/account/epod_setting', {
      method: 'GET',
    });
    dispatch(receiveEPODSetting(result.epod_setting));
  };
}

function updateEPODSetting(query) {
  return async (dispatch) => {
    const result = await request('/account/epod_setting', {
      method: 'PUT',
    }, {
      epod_setting: query,
    });
    dispatch(receiveAccountUpdateSuccessful({
      epodSetting: result.epod_setting,
    }));
  };
}

function fetchTaskLabel() {
  return async (dispatch) => {
    const result = await request('/account/task_label', {
      method: 'GET',
    });
    dispatch(receiveTaskLabel(result.task_label));
  };
}

function updateTaskLabel(query) {
  return async (dispatch) => {
    const result = await request('/account/task_label', {
      method: 'PUT',
    }, {
      task_label: query,
    });
    dispatch(receiveAccountUpdateSuccessful({
      taskLabel: result.task_label,
    }));
  };
}

function fetchTimeWindows() {
  return async (dispatch, getState) => {
    const { isFetchingTimeWindows } = getState().account;

    if (isFetchingTimeWindows) {
      return;
    }

    try {
      dispatch(setIsFetchingTimeWindows(true));

      const result = await request('/account/time_windows', {
        method: 'GET',
      });
      dispatch(receiveTimeWindows(result.time_windows));
    } finally {
      dispatch(setIsFetchingTimeWindows(false));
    }
  };
}

function updateTimeWindows(query) {
  return async (dispatch) => {
    const result = await request('/account/time_windows', {
      method: 'PUT',
    }, {
      account: query,
    });
    dispatch(receiveTimeWindows(result.time_windows));
  };
}

function fetchTrackingSetting() {
  return async (dispatch) => {
    const result = await request('/account/tracking_setting', {
      method: 'GET',
    });
    dispatch(receiveTrackingSetting(result.tracking_setting));
  };
}

function updateTrackingSetting(query) {
  return async (dispatch) => {
    const result = await request('/account/tracking_setting', {
      method: 'PUT',
    }, {
      tracking_setting: query,
    });
    dispatch(receiveAccountUpdateSuccessful({
      trackingSetting: result.tracking_setting,
    }));
  };
}

function fetchRunsheetManagerSetting() {
  return async (dispatch) => {
    const result = await request('/account/runsheet_manager_setting', {
      method: 'GET',
    });
    dispatch(receiveRunsheetManagerSetting(result.runsheet_manager_setting));
  };
}

function updateRunsheetManagerSetting(query) {
  return async (dispatch) => {
    const result = await request('/account/runsheet_manager_setting', {
      method: 'PUT',
    }, {
      runsheet_manager_setting: query,
    });
    dispatch(receiveAccountUpdateSuccessful({
      runsheetManagerSetting: result.runsheet_manager_setting,
    }));
  };
}

function updateSMSTriggerActiveState(query) {
  return async (dispatch) => {
    const result = await request('/account', {
      method: 'PUT',
    }, {
      account: {
        task_notification_sms_enabled: query,
      },
    });
    dispatch(receiveAccountUpdateSuccessful(result));
  };
}

function updateEmailTriggerActiveState(query) {
  return async (dispatch) => {
    const result = await request('/account', {
      method: 'PUT',
    }, {
      account: {
        task_notification_email_enabled: query,
      },
    });
    dispatch(receiveAccountUpdateSuccessful(result));
  };
}

function fetchTrackingSettingTags() {
  return async (dispatch) => {
    const result = await request('/account/tracking_setting/tags', {
      method: 'GET',
    });
    dispatch(receiveAccountTrackingSettingTags(result.tags));
  };
}

function fetchCustomEpodSettingTags() {
  return async (dispatch) => {
    const result = await request('/account/custom_epod_setting/tags', {
      method: 'GET',
    });
    dispatch(receiveAccountCustomEpodSettingTags(result.tags));
  };
}

function fetchCustomTaskLabelTags() {
  return async (dispatch) => {
    const result = await request('/account/custom_task_label/tags', {
      method: 'GET',
    });
    dispatch(receiveAccountCustomTaskLabelTags(result.tags));
  };
}

async function getCustomEpodSettingPreview(query) {
  const data = await requestBlob('/account/custom_epod_setting/preview', {
    method: 'POST',
  }, query);

  Helper.saveBlob(data, 'epod_preview.pdf');
}

async function getCustomTaskLabelPreview(query) {
  const data = await requestBlob('/account/custom_task_label/preview', {
    method: 'POST',
  }, query);

  const objectURL = URL.createObjectURL(data);
  window.open(objectURL, '_blank');
  window.URL.revokeObjectURL(objectURL);
}

function setupVersadriveDemo(query) {
  return () => request('/account/setup_versadrive_demo', {
    method: 'POST',
  }, query);
}

function receiveSubaccountTags(subaccountTags) {
  return {
    type: 'RECEIVE_SUBACCOUNT_TAGS',
    subaccountTags,
  };
}

function fetchSubaccountTags() {
  return async (dispatch) => {
    const result = await request('/account/subaccount_tags', {
      method: 'GET',
    });
    dispatch(receiveSubaccountTags(result.subaccount_tags));
  };
}

function updateSubaccountTags(query) {
  return () => request('/account/subaccount_tags', {
    method: 'PUT',
  }, query);
}

function fetchChildAccountOptionList() {
  return async (dispatch, getState) => {
    const { isFetchingOptionChildAccounts } = getState().account;

    if (isFetchingOptionChildAccounts) {
      return;
    }

    try {
      dispatch(setIsFetchingOptionChildAccounts(true));

      const result = await request('/account/child_accounts/list', {
        method: 'GET',
      });
      dispatch(receiveOptionChildAccounts(result.account));
    } finally {
      dispatch(setIsFetchingOptionChildAccounts(false));
    }
  };
}

export default {
  fetch,
  update,
  updateAddOnsSettingInterested,
  fetchTaskLabel,
  updateTaskLabel,
  fetchEPODSetting,
  updateEPODSetting,
  fetchTimeWindows,
  updateTimeWindows,
  fetchTrackingSetting,
  updateTrackingSetting,
  fetchTrackingSettingTags,
  fetchCustomEpodSettingTags,
  fetchCustomTaskLabelTags,
  setupVersadriveDemo,
  getCustomEpodSettingPreview,
  getCustomTaskLabelPreview,
  updateSMSTriggerActiveState,
  updateEmailTriggerActiveState,
  fetchSubaccountTags,
  updateSubaccountTags,
  fetchRunsheetManagerSetting,
  updateRunsheetManagerSetting,
  fetchChildAccountOptionList,
};
