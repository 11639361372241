import Router from 'utils/router';
import { Utils } from 'versafleet-core';
import AccountActions from './account';

const { RequestHelper: { convertDataToParams, request, requestBlob }, Helper } = Utils;

function receiveTransactionHistories(result) {
  return {
    type: 'RECEIVE_TRANSACTION_HISTORIES',
    transactionHistories: result,
  };
}

function receiveEmailMonthlyCount(result) {
  return {
    type: 'RECEIVE_EMAIL_MONTHLY_COUNT',
    emailRecords: result,
  };
}

function receiveSMSMonthlyCount(result) {
  return {
    type: 'RECEIVE_SMS_MONTHLY_COUNT',
    smsRecords: result,
  };
}

// Action
function fetchTransactionHistories(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/payment/transaction_histories${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveTransactionHistories(result.transaction_histories));
  };
}

function exportTransactionHistories(query) {
  return async () => {
    const data = await requestBlob('/payment/export_transaction_histories', {
      method: 'POST',
    }, query);

    Helper.saveBlob(data, 'transaction_histories_export.xlsx');
  };
}

function fetchPaypalToken(query) {
  return async () => {
    const queryString = convertDataToParams(query);
    const result = await request(`/payment/get_paypal_token${queryString}`, {
      method: 'GET',
    });
    window.location.href = process.env.paypalPaymentUrl + result.paypal_token;
  };
}

function topupSMS(query) {
  return async (dispatch) => {
    await request('/payment/topup_sms', {
      method: 'POST',
    }, query);
    dispatch(AccountActions.fetch());
    Router.replace('/billing/triggers');
  };
}

function exportEmailHistories(query) {
  return async () => {
    const data = await requestBlob('/email_histories/export', {
      method: 'POST',
    }, query);

    Helper.saveBlob(data, 'email_history_export.xlsx');
  };
}

function fetchEmailMonthlyCount(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/email_histories/monthly_email${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveEmailMonthlyCount(result.monthly_email));
  };
}

function exportSMSHistories(query) {
  return async () => {
    const data = await requestBlob('/sms_histories/export', {
      method: 'POST',
    }, query);

    Helper.saveBlob(data, 'sms_history_export.xlsx');
  };
}

function fetchSMSMonthlyCount(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/sms_histories/monthly_sms${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveSMSMonthlyCount(result.monthly_sms));
  };
}

function stripeSubscribe(query) {
  return async (dispatch) => {
    await request('/payment/stripe_subscribe', {
      method: 'POST',
    }, {
      subscription_info: query,
    });
    dispatch(AccountActions.fetch());
    Router.transitionFromSubpath('subscriptions');
  };
}

export default {
  fetchTransactionHistories,
  exportTransactionHistories,
  fetchPaypalToken,
  topupSMS,
  exportEmailHistories,
  fetchEmailMonthlyCount,
  exportSMSHistories,
  fetchSMSMonthlyCount,
  stripeSubscribe,
};
