import { Utils } from 'versafleet-core';

const { RequestHelper: { request, convertDataToParams } } = Utils;

const baseFilter = {
  per_page: 20,
};

function setFilter(filter) {
  return {
    type: 'SET_NOTIFICATION_FILTER',
    filter,
  };
}

function receiveNotifications(result) {
  return {
    type: 'RECEIVE_NOTIFICATIONS',
    notifications: result.notifications,
    meta: result.meta,
  };
}

function receivePanelNotifications(result) {
  return {
    type: 'RECEIVE_PANEL_NOTIFICATIONS',
    panelNotifications: result.notifications,
  };
}

function receiveCount(count) {
  return {
    type: 'RECEIVE_COUNT',
    count,
  };
}

function updateNewNotifications(newNotifications) {
  return {
    type: 'UPDATE_NEW_NOTIFICATIONS',
    newNotifications,
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const state = getState();
    const data = {
      page: state.notification.notificationMeta.page,
      ...baseFilter,
      ...state.notification.filter,
    };
    const param = convertDataToParams(data);
    const result = await request(`/notifications${param}`, {
      method: 'GET',
    });
    dispatch(receiveNotifications(result));
  };
}

function fetchPanelList() {
  return async (dispatch) => {
    const data = {
      per_page: 6,
      page: 1,
      from_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    };
    const param = convertDataToParams(data);
    const result = await request(`/notifications${param}`, {
      method: 'GET',
    });
    dispatch(receivePanelNotifications(result));
  };
}

function loadNewCount() {
  return async (dispatch) => {
    const result = await request('/notifications/new_count', {
      method: 'GET',
    });
    dispatch(receiveCount(result.count));
  };
}

function resetNewCount() {
  return async (dispatch) => {
    await request('/notifications/update_new_count', {
      method: 'PUT',
    });
    dispatch(receiveCount(0));
  };
}

export default {
  fetchList,
  fetchPanelList,
  loadNewCount,
  resetNewCount,
  updateNewNotifications,
};
