import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

const GroupItemCancelButton = (props) => {
  const { onCancel } = props;
  const id = Helper.isNullOrUndefined(props.id)
    ? undefined
    : `${props.id}__cancel-button`;

  return (
    <div className="vf-form__group-item__buttons__cancel" id={id} onClick={onCancel}>
      <i className="versa-close" />
    </div>
  );
};

GroupItemCancelButton.displayName = 'Group Item Cancel Button';

GroupItemCancelButton.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
};

GroupItemCancelButton.defaultProps = {
  id: null,
  onCancel: null,
};

export default GroupItemCancelButton;
