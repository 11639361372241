import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const className = classNames({
    'vf-authentication__logo': true,
  }, props.className);

  return (
    <img
      alt="Logo"
      className={className}
      src="/assets/images/versafleet.png"
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
};

Logo.displayName = 'Logo';

export default Logo;
