import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReduxActions from 'reduxActions';
import ListWindow from '../list';

const TaskSmsTriggerEdit = React.lazy(() => import('./edit'));
const TaskSmsTriggerView = React.lazy(() => import('./view'));

class TaskSmsTrigger extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.statusTrigger.fetchByModelType('task', 'sms'));
  }

  render() {
    return <ListWindow data={this.props.data} model="task" type="sms" />;
  }
}

TaskSmsTrigger.displayName = 'Task SMS Trigger';

TaskSmsTrigger.propTypes = {
  data: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

TaskSmsTrigger.Edit = TaskSmsTriggerEdit;

TaskSmsTrigger.View = TaskSmsTriggerView;

const mapStateToProps = state => ({
  data: state.statusTrigger.taskSmsTriggers,
});

export default connect(mapStateToProps)(TaskSmsTrigger);
