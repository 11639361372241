import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';

class ActionPanelFilterDriverButton extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.driver.fetchOptionList());
  }

  render() {
    const {
      id, value, onChange, optionDrivers, strings,
      className, filterClassName, children,
    } = this.props;
    const options = (optionDrivers || []).map(option => ({ value: option.id, text: option.name }));

    let text;
    if (value.length <= 1) {
      text = strings.driver.item;
    } else {
      text = `${value.length > 1 && value.length < options.length
        ? strings.filter.multiple : strings.filter.all} ${strings.driver.itemPlural}`;
    }

    const subText = value.length === 1 && options.length > 0 ? (options.find(
      option => value[0] === option.value,
    ) || {}).text : undefined;

    // Filter values that are available in the driver options
    const filteredValues = value.filter(driverId => options.some(
      option => option.value === driverId,
    ));

    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
        showArrow
        subText={subText}
        text={text}
      >
        {children}
        <Component.ActionPanel.FilterTagDropdown
          className={filterClassName}
          error={strings.driver.error}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-tag-dropdown`}
          onChange={v => onChange(v)}
          options={options}
          placeholder={strings.driver.placeholder}
          value={filteredValues}
        />
      </Component.ActionPanel.DropdownButton>
    );
  }
}

ActionPanelFilterDriverButton.displayName = 'Action Panel Filter Driver Button';

ActionPanelFilterDriverButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  optionDrivers: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    driver: PropTypes.shape({
      error: PropTypes.string,
      item: PropTypes.string,
      itemPlural: PropTypes.string,
      placeholder: PropTypes.string,
    }),
    filter: PropTypes.shape({
      multiple: PropTypes.string,
      all: PropTypes.string,
    }),
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
};

ActionPanelFilterDriverButton.defaultProps = {
  className: '',
  children: null,
  filterClassName: '',
  id: '',
  onChange: null,
  optionDrivers: [],
  value: [],
};

function mapStateToProps(state) {
  const { driver } = state;
  return {
    optionDrivers: driver.optionDrivers,
  };
}

export default connect(mapStateToProps)(translate('Task')(ActionPanelFilterDriverButton));
