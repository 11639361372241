import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Router from 'utils/router';
import Formatter from 'utils/formatter';

class UserListItem extends React.Component {
  _view() {
    Router.transitionFromSubpath(null, this.props.data.id);
  }

  _renderActionButtons() {
    return this.props.renderActionButtons(this.props.data);
  }

  render() {
    return (
      <Component.LineItem
        checkItem={this.props.checkItem}
        checkedStatus={this.props.checkedStatus}
        id="vf-user__list-item"
        itemID={this.props.data.id}
        onClick={() => this._view()}
      >
        <Component.LineItem.IconBlock
          iconClassName="versa-person"
        />

        <Component.LineItem.Block size="MEDIUM">
          <Component.LineItem.BlockContent
            fontColor="BLACK"
            fontSize="LARGE"
          >
            {this.props.data.first_name}
          </Component.LineItem.BlockContent>
          <Component.LineItem.BlockContent fontColor="TRANSLUCENT">
            {
              this.props.data.roles.map(role => Formatter.capitalizeFirstLetter(
                Formatter.removeUnderscore(role),
              )).join(', ')
            }
          </Component.LineItem.BlockContent>
        </Component.LineItem.Block>

        <Component.LineItem.Divider />

        <Component.LineItem.Block>
          <Component.LineItem.BlockContent>
            <i className="versa-mobile" />
            {this.props.data.contact_number}
          </Component.LineItem.BlockContent>
          <Component.LineItem.BlockContent>
            <i className="versa-email" />
            {this.props.data.email}
          </Component.LineItem.BlockContent>
        </Component.LineItem.Block>

        {this._renderActionButtons()}

      </Component.LineItem>
    );
  }
}

UserListItem.displayName = 'Module User List Item';

UserListItem.propTypes = {
  checkItem: PropTypes.func.isRequired,
  checkedStatus: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    contact_number: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    first_name: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  renderActionButtons: PropTypes.func.isRequired,
};

export default UserListItem;
