import React from 'react';
import PropTypes from 'prop-types';
import { Utils, translate } from 'versafleet-core';
import Component from 'components/component';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import styles from 'stylesheets/modules/job.styl';
import ExportConstants from './exportConstants';

const { Helper } = Utils;

class ExportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this._getDefaultForm(),
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(ReduxActions.customer.fetchOptionCustomerList());
    dispatch(ReduxActions.driver.fetchOptionList());
    dispatch(ReduxActions.task.fetchExportTags());
    dispatch(ReduxActions.account.fetchTimeWindows());
    dispatch(ReduxActions.exportTemplate.fetchList());
  }

  componentDidUpdate(prevProps, prevState) {
    const { account, exportTags, data } = this.props;
    if (prevProps.data !== data && !Helper.isNullOrUndefined(data.id)) {
      this.setState({ form: this._getDefaultForm(data) });
    }

    if (!Helper.isNullOrUndefined(exportTags.base)
      && exportTags.base !== prevProps.exportTags.base) {
      const itemDetailTags = [...prevState.form.item_detail_tags];

      if (!Helper.isNullOrUndefined(account.shipping_factor)
        && itemDetailTags.indexOf('item_volume_weight') === -1) {
        itemDetailTags.push('item_volume_weight');
      }

      this._onFormChange('item_detail_tags', itemDetailTags);
    }
  }

  _getDefaultForm = (data = {}) => ({
    name: data.name || '',
    job_detail_tags: data.job_detail_tags || [],
    task_detail_tags: data.task_detail_tags || [],
    item_detail_tags: data.item_detail_tags || [],
    custom_field_tags: data.custom_field_tags || [],
    task_custom_note_tags: data.task_custom_note_tags || [],
    job_custom_note_tags: data.job_custom_note_tags || [],
    export_format: data.export_format || 'xlsx',
    task_from_date: data.task_from_date || null,
    task_to_date: data.task_to_date || null,
    customer_ids: data.customer_ids || [],
    driver_ids: data.driver_ids || [],
    job_states: data.job_states || this._getJobStatusOptions().map(status => status.value),
    task_states: data.task_states || this._getTaskStatusOptions().map(status => status.value),
    include_archived_task: data.include_archived_task,
    include_archived_job: data.include_archived_job,
    time_window_ids: !Helper.isNullOrUndefined(data.time_window_ids)
      ? data.time_window_ids : [],
    time_window_time_from: data.time_window_time_from || null,
    time_window_time_to: data.time_window_time_to || null,
    creation_from_date: data.creation_from_date || null,
    creation_to_date: data.creation_to_date || null,
    schedule_enabled: data.schedule_enabled || false,
    schedule_frequency: data.schedule_frequency || 'daily',
    schedule_weekly_day: data.schedule_weekly_day || 'monday',
    schedule_monthly_date: data.schedule_monthly_date || 1,
    schedule_export_time: data.schedule_export_time || 0,
    schedule_recipient_emails: data.schedule_recipient_emails || '',
    include_all_time_windows: !Helper.isNullOrUndefined(data.include_all_time_windows)
      ? data.include_all_time_windows : true,
    include_all_customers: !Helper.isNullOrUndefined(data.include_all_customers)
      ? data.include_all_customers : true,
    include_all_drivers: !Helper.isNullOrUndefined(data.include_all_drivers)
      ? data.include_all_drivers : true,
  })

  _getJobStatusOptions() {
    const strings = this.props.strings.jobStatus;
    return [{
      text: strings.in_progress,
      value: 'in_progress',
    }, {
      text: strings.completed,
      value: 'completed',
    }, {
      text: strings.cancelled,
      value: 'cancelled',
    }];
  }

  _getTaskStatusOptions() {
    const strings = this.props.strings.taskStatus;
    return [{
      text: strings.unassigned,
      value: 'unassigned',
    }, {
      text: strings.assigned,
      value: 'assigned',
    }, {
      text: strings.waiting_for_acknowledgement,
      value: 'waiting_for_acknowledgement',
    }, {
      text: strings.acknowledged,
      value: 'acknowledged',
    }, {
      text: strings.started,
      value: 'started',
    }, {
      text: strings.successful,
      value: 'successful',
    }, {
      text: strings.arrived,
      value: 'arrived',
    }, {
      text: strings.failed,
      value: 'failed',
    }, {
      text: strings.declined,
      value: 'declined',
    }, {
      text: strings.cancelled,
      value: 'cancelled',
    }, {
      text: strings.collected,
      value: 'collected',
    }];
  }

  _onFormChange(fieldName, value) {
    this.setState((prevState) => {
      const form = Helper.setFilter(prevState.form, fieldName, value);
      this.props.onFormChange(form);

      return { form };
    });
  }

  _renderLoading() {
    const { isLoading } = this.props;
    return (
      <Component.LightBoxLoading
        overlay={isLoading}
        title={this.props.strings.loadingDetails}
        visible={isLoading}
      />
    );
  }

  _renderScheduleDetails() {
    const strings = this.props.strings.schedule;
    const { error } = this.props;

    return (
      <React.Fragment>
        <Component.Window.Section.Heading>
          {strings.schedule}
        </Component.Window.Section.Heading>
        <Component.Window.Description>
          {strings.scheduleDescription}
        </Component.Window.Description>
        <Component.Window.Content
          className={this.state.form.schedule_enabled ? null : styles['vf-job__export-form__margin-bottom-regular2x']}
        >
          <Component.Input.Radio
            onChange={value => this._onFormChange('schedule_enabled', value)}
            value={this.state.form.schedule_enabled}
          >
            <Component.Input.Radio.Item value={false}>
              <Component.Window.Content>
                {strings.disable}
              </Component.Window.Content>
              <Component.Window.Description>
                {strings.disableDescription}
              </Component.Window.Description>
            </Component.Input.Radio.Item>

            <Component.Input.Radio.Item value>
              <Component.Window.Content>
                {strings.enable}
              </Component.Window.Content>
              <Component.Window.Description>
                {strings.enableDescription}
              </Component.Window.Description>
            </Component.Input.Radio.Item>
          </Component.Input.Radio>
        </Component.Window.Content>

        { this.state.form.schedule_enabled
          ? (
            <Component.Window.Subsection>
              <Component.Window.FieldEdit
                fieldName={strings.scheduleTime}
                isRequired
              >
                <Component.Input.Radio
                  onChange={value => this._onFormChange('schedule_frequency', value)}
                  value={this.state.form.schedule_frequency}
                >
                  <Component.Input.Radio.Item value="daily">
                    <Component.Window.Content>
                      {strings.daily}
                    </Component.Window.Content>
                    <Component.Window.Description>
                      {strings.dailyDescription}
                    </Component.Window.Description>
                  </Component.Input.Radio.Item>

                  <Component.Input.Radio.Item value="weekly">
                    <Component.Window.Content>
                      {strings.weekly}
                    </Component.Window.Content>
                    <Component.Window.Description>
                      {strings.weeklyDescription}
                    </Component.Window.Description>
                  </Component.Input.Radio.Item>
                  { this.state.form.schedule_frequency === 'weekly'
                    ? (
                      <div>
                        <Component.Input.Radio
                          onChange={value => this._onFormChange('schedule_weekly_day', value)}
                          value={this.state.form.schedule_weekly_day}
                        >
                          {Object.keys(strings.days).map(day => (
                            <Component.Input.Radio.Item
                              className={styles['vf-job__export-form__margin-left-regular']}
                              key={day}
                              value={day}
                            >
                              <Component.Window.Content>
                                {strings.days[day]}
                              </Component.Window.Content>
                            </Component.Input.Radio.Item>
                          ))}
                        </Component.Input.Radio>
                      </div>
                    )
                    : <React.Fragment />}

                  <Component.Input.Radio.Item value="monthly">
                    <Component.Window.Content>
                      {strings.monthly}
                    </Component.Window.Content>
                    <Component.Window.Description>
                      {strings.monthlyDescription}
                    </Component.Window.Description>
                  </Component.Input.Radio.Item>
                  { this.state.form.schedule_frequency === 'monthly'
                    ? (
                      <React.Fragment>
                        <Component.Input.Textbox
                          className={classNames({
                            [styles['vf-job__export-form__margin-right-regular']]: true,
                            [styles['vf-job__export-form__margin-bottom-small']]: true,
                          })}
                          onChange={value => this._onFormChange('schedule_monthly_date', value)}
                          value={this.state.form.schedule_monthly_date}
                          width="slim"
                        />
                        {strings.everyMonth}
                        <Component.Error
                          errors={error.schedule_monthly_date}
                        />
                      </React.Fragment>
                    )
                    : <React.Fragment />}
                </Component.Input.Radio>

                <span className={styles['vf-job__export-form__margin-right-regular']}>
                  {strings.startExportingDataAt}
                </span>
                <span>
                  <Component.Input.TimePicker
                    onChange={value => this._onFormChange('schedule_export_time', value)}
                    value={this.state.form.schedule_export_time}
                    width="narrow"
                  />
                </span>
                <Component.Error
                  errors={error.schedule_export_time}
                />
                <Component.Window.Description className={styles['vf-job__export-form__margin-bottom-small']}>
                  {strings.startExportingDataAtDescription}
                </Component.Window.Description>
              </Component.Window.FieldEdit>

              <Component.Window.FieldEdit
                description={strings.recipientsEmailsDescription}
                fieldName={strings.recipientsEmails}
              >
                <Component.Input.Textarea
                  className={styles['vf-job__export-form__margin-bottom-regular2x']}
                  error={error.schedule_recipient_emails}
                  onChange={value => this._onFormChange('schedule_recipient_emails', value)}
                  placeholder={strings.recipientsEmailsPlaceholder}
                  value={this.state.form.schedule_recipient_emails}
                />
              </Component.Window.FieldEdit>
            </Component.Window.Subsection>
          )
          : false}
      </React.Fragment>
    );
  }

  _renderCustomerOptions() {
    const { strings } = this.props;
    const customerIds = [...this.state.form.customer_ids];

    return (
      <React.Fragment>
        <Component.Window.FieldEdit.Radio
          fieldName={strings.Form.customer}
          isRequired
          onChange={value => this._onFormChange('include_all_customers', value)}
          value={this.state.form.include_all_customers}
        >
          <Component.Input.Radio.Item value>
            {strings.Form.selectAllCustomer}
            <Component.Window.Description>
              {strings.Form.selectAllCustomerDescription}
            </Component.Window.Description>
          </Component.Input.Radio.Item>
          <Component.Input.Radio.Item
            value={false}
          >
            {strings.Form.selectedCustomer}

          </Component.Input.Radio.Item>
        </Component.Window.FieldEdit.Radio>

        {!this.state.form.include_all_customers
          && (
            <Component.Window.FieldEdit.CheckboxGroup.WithArray
              onChange={value => this._onFormChange('customer_ids', value)}
              showIncludeAll={false}
              value={customerIds}
            >
              {
                this.props.optionCustomers.map(option => (
                  <Component.Input.Checkbox.Item value={option.id}>
                    {option.name}
                  </Component.Input.Checkbox.Item>
                ))
              }
            </Component.Window.FieldEdit.CheckboxGroup.WithArray>
          )}
      </React.Fragment>
    );
  }

  _renderDriverOptions() {
    const { strings } = this.props;
    return (
      <React.Fragment>
        <Component.Window.FieldEdit.Radio
          fieldName={strings.Form.driver}
          isRequired
          onChange={value => this._onFormChange('include_all_drivers', value)}
          value={this.state.form.include_all_drivers}
        >
          <Component.Input.Radio.Item value>
            {strings.Form.selectAllDriver}
            <Component.Window.Description>
              {strings.Form.selectAllDriverDescription}
            </Component.Window.Description>
          </Component.Input.Radio.Item>
          <Component.Input.Radio.Item
            value={false}
          >
            {strings.Form.selectedDriver}

          </Component.Input.Radio.Item>
        </Component.Window.FieldEdit.Radio>

        {!this.state.form.include_all_drivers
          && (
            <Component.Window.FieldEdit.CheckboxGroup.WithArray
              onChange={value => this._onFormChange('driver_ids', value)}
              showIncludeAll={false}
              value={this.state.form.driver_ids}
            >
              {
                this.props.optionDrivers.map(option => (
                  <Component.Input.Checkbox.Item value={option.id}>
                    {option.name}
                  </Component.Input.Checkbox.Item>
                ))
              }
            </Component.Window.FieldEdit.CheckboxGroup.WithArray>
          )}
      </React.Fragment>
    );
  }

  _renderTaskSelectableDetails() {
    const { strings } = this.props;

    return (
      <React.Fragment>
        <Component.Window.FieldEdit.Radio
          fieldName={strings.Form.timeWindow}
          isRequired
          onChange={(value) => {
            this._onFormChange('include_all_time_windows', value);
            this._onFormChange('time_window_ids', []);
          }}
          value={this.state.form.include_all_time_windows}
        >
          <Component.Input.Radio.Item value>
            {strings.Form.selectAllTimeWindow}
            <Component.Window.Description>
              {strings.Form.selectAllTimeWindowDescription}
            </Component.Window.Description>
          </Component.Input.Radio.Item>
          <Component.Input.Radio.Item
            value={false}
          >
            {strings.Form.selectedTimeWindow}

          </Component.Input.Radio.Item>
        </Component.Window.FieldEdit.Radio>

        {!this.state.form.include_all_time_windows && (
          <Component.Input.CheckboxGroup.WithArray
            onChange={value => this._onFormChange('time_window_ids', value)}
            showIncludeAll={false}
            value={this.state.form.time_window_ids}
          >
            {
              this.props.timeWindows.map(timeWindow => (
                <Component.Input.Checkbox.Item
                  key={timeWindow.id}
                  value={timeWindow.id.toString()}
                >
                  {timeWindow.name}
                </Component.Input.Checkbox.Item>
              ))
            }
            <Component.Input.Checkbox.Item value="custom">
              {this.props.strings.Form.customRange}
              <div
                className="vf-job__custom-time__time"
                onClick={e => e.stopPropagation()}
              >
                <Component.Input.TimePicker
                  className="vf-job__custom-time__time__input"
                  id="time-from-input"
                  onChange={value => this._onFormChange('time_window_time_from', value)}
                  value={this.state.form.time_window_time_from}
                  width="narrow"
                />
                -
                <Component.Input.TimePicker
                  className="vf-job__custom-time__time__input"
                  id="time-to-input"
                  onChange={value => this._onFormChange('time_window_time_to', value)}
                  value={this.state.form.time_window_time_to}
                  width="narrow"
                />
              </div>
            </Component.Input.Checkbox.Item>
          </Component.Input.CheckboxGroup.WithArray>
        )}

        {this._renderCustomerOptions()}
        {this._renderDriverOptions()}

        <Component.Window.FieldEdit.CheckboxGroup.WithArray
          fieldName={this.props.strings.Form.jobState}
          onChange={value => this._onFormChange('job_states', value)}
          options={this._getJobStatusOptions()}
          value={this.state.form.job_states}
        />

        <Component.Window.FieldEdit.CheckboxGroup.WithArray
          fieldName={this.props.strings.Form.status}
          onChange={value => this._onFormChange('task_states', value)}
          options={this._getTaskStatusOptions()}
          value={this.state.form.task_states}
        />
      </React.Fragment>
    );
  }

  _renderExportTagsSelectableDetailsForm() {
    const { account, exportTags, strings } = this.props;
    const fieldOrder = ['job', 'job_custom_notes', 'task', 'task_custom_notes', 'item', 'custom_field_groups'];
    const base = exportTags.base || {};
    return (
      <React.Fragment>
        {
          fieldOrder.map((field) => {
            const tags = [
              'custom_field_groups',
              'task_custom_notes',
              'job_custom_notes',
            ].includes(field)
              ? (exportTags[field] || [])
              : (base[field] || []);

            if (tags.length === 0) {
              return false;
            }

            const options = tags.map(option => ({ text: option.title, value: option.tag }));
            if (Helper.isNullOrUndefined(account.shipping_factor)) {
              const optionIndex = options.findIndex(option => option.value === 'item_volume_weight');
              if (optionIndex > -1) {
                options.splice(optionIndex, 1);
              }
            }

            let fieldName;
            let exportTemplateField;
            switch (field) {
              case 'job':
                fieldName = strings.Form.allJobDetails;
                exportTemplateField = 'job_detail_tags';
                break;
              case 'job_custom_notes':
                fieldName = strings.Form.jobCompletionCustomNotes;
                exportTemplateField = 'job_custom_note_tags';
                break;
              case 'task':
                fieldName = strings.Form.allTaskDetails;
                exportTemplateField = 'task_detail_tags';
                break;
              case 'task_custom_notes':
                fieldName = strings.Form.taskCompletionCustomNotes;
                exportTemplateField = 'task_custom_note_tags';
                break;
              case 'item':
                fieldName = strings.Form.allItemDetails;
                exportTemplateField = 'item_detail_tags';
                break;
              default:
                fieldName = strings.Form.allCustomFieldGroupsDetails;
                exportTemplateField = 'custom_field_tags';
                break;
            }

            return (
              <Component.Window.FieldEdit.CheckboxGroup.WithArray
                fieldName={fieldName}
                key={field}
                onChange={value => this._onFormChange(exportTemplateField, value)}
                options={options}
                value={this.state.form[exportTemplateField]}
              />
            );
          })
        }
      </React.Fragment>
    );
  }

  render() {
    const {
      strings,
      isEdit,
      error,
    } = this.props;
    const { form } = this.state;

    return (
      <React.Fragment>
        {isEdit && (
          <React.Fragment>
            <Component.Window.Section.Heading isRequired>
              {strings.Form.templateName}
            </Component.Window.Section.Heading>
            <Component.Input.Textbox
              className={styles['vf-job__export-form__margin-bottom-regular2x']}
              error={error.name}
              onChange={value => this._onFormChange('name', value)}
              placeholder={strings.Form.templateName}
              type="text"
              value={form.name}
            />
          </React.Fragment>
        )}

        {this._renderScheduleDetails()}

        <Component.Window.Section.Heading>
          {strings.dataSelection}
        </Component.Window.Section.Heading>
        <Component.Window.Description>
          {strings.dataSelectionDescription}
        </Component.Window.Description>
        {
          !this.state.form.schedule_enabled && !isEdit
            ? (
              <Component.Window.FieldEdit
                fieldName={strings.dateRange}
                isRequired
              >
                <Component.Window.Content
                  textClassName={styles['vf-job__export-form__date-picker']}
                >
                  <Component.Input.DatePicker
                    fromDate="2020-01-01"
                    onChange={value => this._onFormChange('task_from_date', value)}
                    placeholder={strings.startDate}
                    trailingIcon="versa-calendar-day"
                    value={form.task_from_date}
                  />
                  <div className={styles['vf-job__export-form__date-picker__dash']}>
                    -
                  </div>
                  <Component.Input.DatePicker
                    error={error.to_date}
                    fromDate="2020-01-01"
                    onChange={value => this._onFormChange('task_to_date', value)}
                    placeholder={strings.endDate}
                    trailingIcon="versa-calendar-day"
                    value={form.task_to_date}
                  />
                </Component.Window.Content>
              </Component.Window.FieldEdit>
            ) : false
        }

        {!form.schedule_enabled && ((form.task_from_date && form.task_to_date) && !isEdit) ? (
          <Component.Window.FieldEdit
            fieldName={strings.creationDateRange}
          >
            <Component.Window.Content
              textClassName={styles['vf-job__export-form__date-picker']}
            >
              <Component.Input.DatePicker
                fromDate="2020-01-01"
                isDeleteEnabled
                onChange={value => this._onFormChange('creation_from_date', value)}
                placeholder={strings.startDate}
                trailingIcon="versa-calendar-day"
                value={form.creation_from_date}
              />
              <div className={styles['vf-job__export-form__date-picker__dash']}>
                -
              </div>
              <Component.Input.DatePicker
                error={error.creation_to_date}
                fromDate="2020-01-01"
                isDeleteEnabled
                onChange={value => this._onFormChange('creation_to_date', value)}
                placeholder={strings.endDate}
                trailingIcon="versa-calendar-day"
                value={form.creation_to_date}
              />
            </Component.Window.Content>
          </Component.Window.FieldEdit>
        )
          : false}

        {form.schedule_enabled || (form.task_from_date && form.task_to_date) || isEdit
          ? this._renderTaskSelectableDetails()
          : false}
        {form.schedule_enabled || (form.task_from_date && form.task_to_date) || isEdit ? (
          <React.Fragment>
            <Component.Window.FieldEdit.Select
              fieldName={strings.Form.format}
              hasEmptyOption={false}
              onChange={value => this._onFormChange('export_format', value)}
              options={ExportConstants.formatOptions}
              value={form.export_format}
            />
            {this._renderExportTagsSelectableDetailsForm()}
            <Component.Window.FieldEdit.Checkbox
              onChange={value => this._onFormChange('include_archived_task', value)}
              value={form.include_archived_task}
            >
              {strings.Form.includeArchivedTask}
            </Component.Window.FieldEdit.Checkbox>
            <Component.Window.FieldEdit.Checkbox
              onChange={value => this._onFormChange('include_archived_job', value)}
              value={form.include_archived_job}
            >
              {strings.Form.includeArchivedJob}
            </Component.Window.FieldEdit.Checkbox>
          </React.Fragment>
        ) : null}

        {this._renderLoading()}
      </React.Fragment>
    );
  }
}

ExportForm.displayName = 'Job Export Form';

ExportForm.propTypes = {
  account: PropTypes.shape({
    shipping_factor: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.number,
    customer_ids: PropTypes.arrayOf(PropTypes.number),
    driver_ids: PropTypes.arrayOf(PropTypes.number),
  }),
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape({
    name: PropTypes.string,
    to_date: PropTypes.string,
    creation_to_date: PropTypes.string,
    schedule_export_time: PropTypes.arrayOf(PropTypes.string),
    schedule_monthly_date: PropTypes.arrayOf(PropTypes.string),
    schedule_recipient_emails: PropTypes.arrayOf(PropTypes.string),
  }),
  exportTags: PropTypes.shape({
    base: PropTypes.shape({}),
    task_custom_notes: PropTypes.arrayOf(PropTypes.shape()),
    job_custom_notes: PropTypes.arrayOf(PropTypes.shape()),
    custom_field_groups: PropTypes.arrayOf(PropTypes.shape()),
  }),
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFormChange: PropTypes.func.isRequired,
  optionCustomers: PropTypes.arrayOf(PropTypes.shape()),
  optionDrivers: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    Form: PropTypes.shape({
      timeWindow: PropTypes.string,
      customer: PropTypes.string,
      driver: PropTypes.string,
      customRange: PropTypes.string,
      jobState: PropTypes.string,
      status: PropTypes.string,
      format: PropTypes.string,
      allJobDetails: PropTypes.string,
      allTaskDetails: PropTypes.string,
      jobCompletionCustomNotes: PropTypes.string,
      taskCompletionCustomNotes: PropTypes.string,
      allItemDetails: PropTypes.string,
      allCustomFieldGroupsDetails: PropTypes.string,
      includeArchivedTask: PropTypes.string,
      includeArchivedJob: PropTypes.string,
      templateName: PropTypes.string,
      selectAllTimeWindow: PropTypes.string,
      selectAllTimeWindowDescription: PropTypes.string,
      selectedTimeWindow: PropTypes.string,
      selectAllCustomer: PropTypes.string,
      selectAllCustomerDescription: PropTypes.string,
      selectedCustomer: PropTypes.string,
      selectAllDriver: PropTypes.string,
      selectAllDriverDescription: PropTypes.string,
      selectedDriver: PropTypes.string,
    }),
    dataSelection: PropTypes.string,
    dataSelectionDescription: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    dateRange: PropTypes.string,
    creationDateRange: PropTypes.string,
    allDrivers: PropTypes.string,
    taskStatus: PropTypes.shape({
      unassigned: PropTypes.string,
      assigned: PropTypes.string,
      waiting_for_acknowledgement: PropTypes.string,
      acknowledged: PropTypes.string,
      started: PropTypes.string,
      arrived: PropTypes.string,
      successful: PropTypes.string,
      failed: PropTypes.string,
      declined: PropTypes.string,
      cancelled: PropTypes.string,
      collected: PropTypes.string,
    }),
    allTaskStatus: PropTypes.string,
    jobStatus: PropTypes.shape({
      in_progress: PropTypes.string,
      completed: PropTypes.string,
      cancelled: PropTypes.string,
    }),
    allJobStatus: PropTypes.string,
    loadingDetails: PropTypes.string,
    schedule: PropTypes.shape({
      schedule: PropTypes.string,
      scheduleDescription: PropTypes.string,
      disable: PropTypes.string,
      disableDescription: PropTypes.string,
      enable: PropTypes.string,
      enableDescription: PropTypes.string,
      scheduleTime: PropTypes.string,
      daily: PropTypes.string,
      dailyDescription: PropTypes.string,
      weekly: PropTypes.string,
      weeklyDescription: PropTypes.string,
      monthly: PropTypes.string,
      monthlyDescription: PropTypes.string,
      days: PropTypes.shape({}),
      everyMonth: PropTypes.string,
      startExportingDataAt: PropTypes.string,
      startExportingDataAtDescription: PropTypes.string,
      recipientsEmails: PropTypes.string,
      recipientsEmailsDescription: PropTypes.string,
      recipientsEmailsPlaceholder: PropTypes.string,
    }),
  }).isRequired,
  timeWindows: PropTypes.arrayOf(PropTypes.shape()),
};

ExportForm.defaultProps = {
  account: {},
  data: {},
  error: {},
  exportTags: null,
  optionCustomers: [],
  optionDrivers: [],
  timeWindows: [],
  isEdit: false,
  isLoading: false,
};

function mapStateToProps(state) {
  const {
    account, driver, task, customer,
  } = state;

  return {
    account: account.account,
    exportTags: task.exportTags,
    optionCustomers: (Helper.isNullOrUndefined(customer)) ? [] : customer.optionCustomers,
    optionDrivers: (Helper.isNullOrUndefined(driver)) ? [] : driver.optionDrivers,
    timeWindows: account.timeWindows,
  };
}

export default connect(mapStateToProps)(translate('Task.JobExport')(ExportForm));
