import { Utils } from 'versafleet-core';

function receiveDriverSettings(driverSettings) {
  return {
    type: 'RECEIVE_DRIVER_SETTINGS',
    driverSettings,
  };
}

function receiveDriverSettingsUpdate(driverSettings) {
  return {
    type: 'RECEIVE_DRIVER_SETTINGS_UPDATE',
    driverSettings,
  };
}

function fetch() {
  return async (dispatch) => {
    try {
      const result = await Utils.RequestHelper.request('/driver_setting', {
        method: 'GET',
      });
      dispatch(receiveDriverSettings(result.driver_setting));
      return result.driver_setting;
    } catch (error) {
      if (Array.isArray(error) && error.length > 0 && error[0] === 'Not subscribed to driver config') {
        // Ignore this error to reduce Sentry usage
        return error;
      }
      throw error;
    }
  };
}

function update(query) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/driver_setting', {
      method: 'PUT',
    }, {
      driver_setting: query,
    });
    dispatch(receiveDriverSettingsUpdate(result.driver_setting));
    return result.driver_setting;
  };
}
export default {
  fetch,
  update,
};
