import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  visualTimestamp: null,
  runsheets: {},
  visualRunsheetIds: [],
  dailyRunsheetIds: [],
  weeklyRunsheet: [],
  monthlyRunsheet: [],
  boardRunsheets: [],
  filter: {},
  boardFilter: {},
  boardMeta: {},
};

function runsheet(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_RUNSHEET': {
      const { tasks, ...newRunsheet } = action.runsheet;
      newRunsheet.task_assignments = tasks.map(task => ({
        ...task.task_assignment,
        task_id: task.id,
      }));
      return {
        ...state,
        runsheets: ReduxHelper.mergeDataWithObject(state.runsheets, newRunsheet),
      };
    }
    case 'RECEIVE_VISUAL_RUNSHEETS':
      if (Helper.isNullOrUndefined(state.visualTimestamp)
        || state.visualTimestamp < action.timestamp) {
        return {
          ...state,
          visualRunsheetIds: action.runsheets.map(_runsheet => _runsheet.id),
          runsheets: ReduxHelper.mergeDataWithList(state.runsheets, action.runsheets),
          visualTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_DAILY_RUNSHEETS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          dailyRunsheetIds: action.runsheets.map(_runsheet => _runsheet.id),
          runsheets: ReduxHelper.mergeDataWithList(state.runsheets, action.runsheets),
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_WEEKLY_RUNSHEETS':
      return {
        ...state,
        weeklyRunsheet: action.weeklyRunsheet,
      };
    case 'RECEIVE_MONTHLY_RUNSHEETS':
      return {
        ...state,
        monthlyRunsheet: action.monthlyRunsheet,
      };
    case 'RECEIVE_RUNSHEET_PRINT_SETTING':
      return {
        ...state,
        runsheetPrintSetting: action.runsheetPrintSetting,
      };
    case 'SET_RUNSHEET_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    case 'RECEIVE_BOARD_RUNSHEETS': {
      let runsheetList = [];
      if (action.keepCurrent) {
        runsheetList = [...state.boardRunsheets];
        action.runsheets.forEach((newRunsheet) => {
          const index = runsheetList.findIndex(
            oldRunsheet => newRunsheet.driver.id === oldRunsheet.driver.id
              && newRunsheet.vehicle.id === oldRunsheet.vehicle.id,
          );
          if (index !== -1) {
            // when filter include_drivers_without_tasks is true we remove the column
            // when the column does not have task and the vehicle is not driver's default vehicle,
            // when filter is off remove column when the column does not have task
            const isEmptyTask = newRunsheet.task_assignments.length === 0;
            const isEmptyTaskAndNotUseDefaultVehicle = isEmptyTask
              && runsheetList[index].driver.default_vehicle_id !== newRunsheet.vehicle.id;
            const shouldRemoveRunsheet = state.boardFilter.include_drivers_without_tasks
              ? isEmptyTaskAndNotUseDefaultVehicle : isEmptyTask;
            if (shouldRemoveRunsheet) {
              runsheetList.splice(index, 1);
            } else {
              runsheetList[index] = newRunsheet;
            }
          } else {
            runsheetList.push(newRunsheet);
          }
        });
      } else {
        runsheetList = action.runsheets;
      }
      return {
        ...state,
        boardRunsheets: runsheetList,
        boardMeta: action.meta,
      };
    }
    case 'SET_RUNSHEET_BOARD_FILTER':
      return {
        ...state,
        boardFilter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default runsheet;
