import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utils } from 'versafleet-core';
import Component from 'components/component';
import ReduxActions from 'reduxActions';
import styles from 'stylesheets/modules/task.styl';
import AllocateWindowForm from './allocateWindowForm';

const { Helper } = Utils;

class AllocateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      assigned_account_id: null,
      isAllocatingTask: false,
    };
  }

  _toggleDropdown = () => {
    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown,
    }));
  }

  _onBlur = () => {
    if (!this.mouseDown) {
      this.setState({ showDropdown: false });
    }
  }

  _allocate = async () => {
    const {
      dispatch, taskId, taskIds, onSuccessfulUpdate,
    } = this.props;

    this.setState({ isAllocatingTask: true });

    try {
      if (Helper.isNullOrUndefined(taskId)) {
        await dispatch(
          ReduxActions.task.allocateMultiple({
            ids: taskIds,
            assigned_account_id: this.state.assigned_account_id,
          }),
        );
      } else {
        await dispatch(
          ReduxActions.task.allocate(
            taskId,
            {
              assigned_account_id: this.state.assigned_account_id,
            },
          ),
        );
      }
      onSuccessfulUpdate();
      this._toggleDropdown();
    } catch {
      //
    }
    this.setState({ isAllocatingTask: false });
  }

  _renderButton() {
    return (
      <Component.Button
        {...this.props}
        onClick={this._toggleDropdown}
      />
    );
  }

  _renderDropdown() {
    if (this.state.showDropdown) {
      const containerClassName = classNames({
        [styles['vf-task__allocate__form-container']]: true,
        [styles['vf-task__allocate__form-container--right']]: this.props.alignRight,
      });

      return (
        <Component.AutoPositionContainer className={containerClassName}>
          <AllocateWindowForm
            allocate={this._allocate}
            data={this.state.assigned_account_id}
            id={this.props.id}
            isAllocatingTask={this.state.isAllocatingTask}
            onFormChange={(value) => { this.setState({ assigned_account_id: value }); }}
            onMouseDown={() => { this.mouseDown = true; }}
            onMouseUp={() => { this.mouseDown = false; }}
          />
        </Component.AutoPositionContainer>
      );
    }
    return false;
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div className={styles['vf-task__allocate']} onBlur={this._onBlur} tabIndex="0">
        {this._renderButton()}
        {this._renderDropdown()}
      </div>
    );
  }
}

AllocateButton.displayName = 'Allocate Button';

AllocateButton.propTypes = {
  alignRight: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  onSuccessfulUpdate: PropTypes.func,
  taskId: PropTypes.number,
  taskIds: PropTypes.arrayOf(PropTypes.number),
};

AllocateButton.defaultProps = {
  alignRight: false,
  id: null,
  onSuccessfulUpdate: () => {},
  taskId: null,
  taskIds: [],
};

export default connect()(AllocateButton);
