import { Utils } from 'versafleet-core';

// Disable infinite scroll
const baseFilter = {
  // per_page: 10,
  per_page: 1000,
};

const {
  RequestHelper: {
    convertDataToParams, request,
  },
} = Utils;

function receiveRunsheet(result) {
  return {
    type: 'RECEIVE_RUNSHEET',
    runsheet: result,
  };
}

function receiveVisualRunsheets(result, timestamp) {
  return {
    type: 'RECEIVE_VISUAL_RUNSHEETS',
    runsheets: result,
    timestamp,
  };
}

function receiveDailyRunsheets(result, timestamp) {
  return {
    type: 'RECEIVE_DAILY_RUNSHEETS',
    runsheets: result,
    timestamp,
  };
}

function receiveBoardRunsheets(result, meta, keepCurrent) {
  return {
    type: 'RECEIVE_BOARD_RUNSHEETS',
    runsheets: result,
    meta,
    keepCurrent,
  };
}

function processRunsheetData(data, query, type) {
  let currIndex = 0;
  const result = [];
  const currDate = moment(query.from_date);
  const days = type === 'week' ? 7 : moment(query.to_date).diff(
    moment(query.from_date), 'days',
  ) + 1; // Add 1 day to include start day itself in difference

  for (let i = 1; i <= days; i += 1) {
    if ((currIndex === data.length) || (!currDate.isSame(moment(data[currIndex].date)))) {
      // No data for this date, push empty data
      result.push({
        date: currDate.format('YYYY-MM-DD'),
        drivers: [],
      });
    } else {
      result.push(data[currIndex]);
      currIndex += 1;
    }
    currDate.add(1, 'days');
  }
  return result;
}

function receiveWeeklyRunsheets(result, query) {
  return {
    type: 'RECEIVE_WEEKLY_RUNSHEETS',
    weeklyRunsheet: processRunsheetData(result, query, 'week'),
  };
}

function receiveMonthlyRunsheets(result, query) {
  return {
    type: 'RECEIVE_MONTHLY_RUNSHEETS',
    monthlyRunsheet: processRunsheetData(result, query, 'month'),
  };
}

function receiveRunsheetPrintSetting(result) {
  return {
    type: 'RECEIVE_RUNSHEET_PRINT_SETTING',
    runsheetPrintSetting: result,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_RUNSHEET_FILTER',
    filter,
  };
}

function setBoardFilter(filter) {
  return {
    type: 'SET_RUNSHEET_BOARD_FILTER',
    filter,
  };
}

function fetch(id, query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/runsheets/drivers/${id}${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveRunsheet(result.driver));
  };
}

function fetchVisual(id, query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/runsheets/drivers/${id}/visual${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveRunsheet(result.driver));
  };
}

function fetchList(query) {
  return async (dispatch) => {
    dispatch(setFilter(query));
    const timestamp = new Date();
    const queryString = convertDataToParams(query);
    const result = await request(`/runsheets/drivers${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveDailyRunsheets(result.drivers, timestamp));
  };
}

// KeepCurrent and useCurrentMeta is used for the infinite scroll
function fetchBoardList(
  query, keepCurrent = false, useCurrentMeta = false, driverVehiclePair = [],
) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(setBoardFilter(query));
    const params = {
      ...baseFilter,
      ...query,
    };

    const queryString = convertDataToParams(params);
    const result = await request(`/runsheets/drivers/board${queryString}`, {
      method: 'GET',
    });

    const runsheets = result.runsheets;

    // When we want to update the runsheet board manually (keepCurrent = true), EXP: rearrange task,
    // the code inside this "if" will add empty runsheet
    // for those runsheets which does not have any task assignment.
    // Runsheet reducer will remove a runsheet when the runsheet does not have any task assignment.

    // Note: To make this function works, this function receive a driver-vehicle pair array:
    // { driverId: driver's id, vehicleId: vehicle's id }
    if (keepCurrent && driverVehiclePair.length > 0) {
      driverVehiclePair.forEach((pair) => {
        const index = runsheets.findIndex(runsheet => (
          runsheet.driver.id === pair.driverId && runsheet.vehicle.id === pair.vehicleId
        ));

        if (index === -1) {
          runsheets.push({
            driver: {
              id: pair.driverId,
            },
            vehicle: {
              id: pair.vehicleId,
            },
            task_assignments: [],
          });
        }
      });
    }

    // If KeepCurrent is true or page is not first page,
    // boardRunsheets state is not overwritten with new value, append new value instead.
    // Else boardRunsheets should overwrite with new value.
    // (when re-enter runsheet board page or change any filter value)

    // If useCurrentMeta is true, boardMeta is not changed with new meta.
    // This is used in reschedule function only. Other function should update the meta
    // because reschedule function will call fetchBoardList with custom filters.
    dispatch(receiveBoardRunsheets(
      runsheets,
      useCurrentMeta ? state.runsheet.boardMeta : result.meta,
      (keepCurrent || (params.page && params.page !== 1)),
    ));
  };
}

function updateTaskOrder(id, query) {
  return async (dispatch, getState) => {
    const state = getState();
    await request(`/runsheets/drivers/${id}/update_rank`, {
      method: 'POST',
    }, query);
    dispatch(fetch(id, state.runsheet.filter));
  };
}

function fetchVisualList(query) {
  return async (dispatch) => {
    dispatch(setFilter(query));
    const timestamp = new Date();
    const queryString = convertDataToParams(query);
    const result = await request(`/runsheets/drivers/visual${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveVisualRunsheets(result.drivers, timestamp));
  };
}

function fetchWeeklyRunsheets(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/runsheets/drivers/daily_count${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveWeeklyRunsheets(result.runsheets, query));
  };
}

function fetchMonthlyRunsheets(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/runsheets/drivers/daily_count${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveMonthlyRunsheets(result.runsheets, query));
  };
}

function updateRunsheetPrintSetting(query) {
  return () => request('/runsheets/print_setting', {
    method: 'PUT',
  }, {
    runsheet_print_setting: query,
  });
}

function fetchVisualMultiple(ids, query) {
  return (dispatch) => {
    for (let i = 0; i < ids.length; i += 1) {
      dispatch(fetchVisual(ids[i], query));
    }
  };
}

function fetchRunsheetPrintSetting() {
  return async (dispatch) => {
    const result = await request('/runsheets/print_setting', {
      method: 'GET',
    });
    dispatch(receiveRunsheetPrintSetting(result));
  };
}

function scheduleTask(id, query, oldDriverId) {
  return async (dispatch, getState) => {
    const state = getState();
    await request(`/runsheets/task_assignments/${id}`, {
      method: 'PUT',
    }, query);
    if (oldDriverId !== query.task_assignment.driver_id) {
      dispatch(fetchVisualMultiple([query.task_assignment.driver_id, oldDriverId],
        state.filterSettings.filter.planning));
    } else {
      dispatch(fetchVisual(query.task_assignment.driver_id, state.filterSettings.filter.planning));
    }
  };
}

function scheduleBoard(id, query, originalColumn) {
  return async (dispatch, getState) => {
    const state = getState();
    await request(`/runsheets/reschedule_board/${id}`, {
      method: 'PUT',
    }, query);

    // Only fetch the drivers which are affected.
    const driverIds = [query.task_assignment.driver_id, originalColumn.driverId];
    const vehicleIds = [query.task_assignment.vehicle_id, originalColumn.vehicleId];
    const filter = {
      ...state.runsheet.boardFilter,
      driver_id: driverIds,
      vehicle_id: vehicleIds,
      page: 1,
    };

    const driverVehiclePair = [{
      driverId: query.task_assignment.driver_id,
      vehicleId: query.task_assignment.vehicle_id,
    }, originalColumn];

    await dispatch(fetchBoardList(filter, true, true, driverVehiclePair));
  };
}

export default {
  fetch,
  fetchVisual,
  fetchList,
  fetchBoardList,
  fetchVisualList,
  fetchWeeklyRunsheets,
  fetchMonthlyRunsheets,
  updateTaskOrder,
  updateRunsheetPrintSetting,
  fetchRunsheetPrintSetting,
  scheduleBoard,
  scheduleTask,
};
