import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Router, { withRouterHooks } from 'utils/router';
import { Utils } from 'versafleet-core';
import NewDialog from 'utils/newDialog';
import styles from 'stylesheets/main/mainContainer.styl';

class App extends React.Component {
  componentDidMount() {
    Router.setRouter(
      this._transitionTo.bind(this),
      this._replace.bind(this),
      this._getRoutes.bind(this),
      this._getParams.bind(this),
      this._getPath.bind(this),
      this._getQuery.bind(this),
    );
  }

  _transitionTo(route) {
    this.props.navigate(route);
  }

  _replace(route) {
    this.props.navigate(route, { replace: true });
  }

  _getRoutes() {
    // updated for the router.pop and router.getPoppedPath in VF-core
    // TODO: better change to use navigate(-1 or something)
    const routes = this.props.location.pathname.split('/')
      .filter(segment => segment)
      .map(segment => ({ path: segment }));

    return routes;
  }

  _getParams() {
    return this.props.params;
  }

  _getPath() {
    return this.props.location.pathname;
  }

  _getQuery() {
    const [query] = this.props.searchParams;
    const allQuery = Object.fromEntries([...query]);
    return allQuery;
  }

  render() {
    return (
      <div className={styles.versafleet}>
        <NewDialog.DialogComponent />
        <Utils.SuccessMessage.SuccessMessageComponent
          location={Utils.SuccessMessage.SuccessMessageComponent.locationConst.OUTER}
        />
        <Outlet />
      </div>
    );
  }
}

App.displayName = 'App';

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({}),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  searchParams: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
  ).isRequired,
};

export default withRouterHooks(App);
