/* global Pusher */
import moment from 'moment';

const PusherHelper = {
  pusher: new Pusher(process.env.pusherKey),
  channel: {
    account: null,
    user: null,
  },
  getNewPusherId: () => moment().format('YYYYMMDDHHmmss'),
};

PusherHelper.pusher.connection.bind('connected', () => {
  console.log({
    message: 'Connected to pusher',
    timestamp: new Date().toString(),
  });
});

PusherHelper.pusher.connection.bind('error', (error) => {
  let logData = {
    message: 'Failed to connect pusher',
    timestamp: new Date().toString(),
  };
  if (error && error.error && error.error.data) {
    logData = {
      ...logData,
      extra: {
        errorCode: error.error.data.code,
        error: error.error.data.message,
      },
    };
  }
  console.log(logData);
});

export default PusherHelper;
