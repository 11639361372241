import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  archivedBillingAccountIds: [],
  archivedBillingAccountMeta: {},
  billingAccounts: {},
  billingAccountIds: [],
  billingAccountMeta: {},
  filter: {},
  isFetchingOptionBillingAccounts: false,
  optionBillingAccounts: [],
};

function billingAccount(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_BILLING_ACCOUNT':
      return {
        ...state,
        billingAccounts: ReduxHelper.mergeDataWithObject(state.billingAccounts,
          action.billingAccount),
      };
    case 'RECEIVE_BILLING_ACCOUNTS':
      return {
        ...state,
        billingAccountIds: action.billingAccounts.map(_billingAccount => _billingAccount.id),
        billingAccounts: ReduxHelper.mergeDataWithList(state.billingAccounts,
          action.billingAccounts),
        billingAccountMeta: action.meta,
      };
    case 'RECEIVE_OPTION_BILLING_ACCOUNTS':
      return {
        ...state,
        optionBillingAccounts: action.optionBillingAccounts,
      };
    case 'SET_BILLING_ACCOUNT_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    case 'RECEIVE_CUSTOMER':
      return {
        ...state,
        billingAccounts: ReduxHelper.mergeDataWithList(state.billingAccounts,
          action.customer.billing_accounts),
      };
    case 'RECEIVE_CUSTOMER_BILLING_ACCOUNTS':
      return {
        ...state,
        billingAccounts: ReduxHelper.mergeDataWithList(state.billingAccounts,
          action.billing_accounts),
      };
    case 'RECEIVE_ARCHIVED_BILLING_ACCOUNTS':
      return {
        ...state,
        archivedBillingAccountIds: action.billingAccounts.map(account => account.id),
        billingAccounts: ReduxHelper.mergeDataWithList(
          state.billingAccounts, action.billingAccounts,
        ),
        archivedBillingAccountMeta: action.meta,
      };
    case 'SET_IS_FETCHING_OPTION_BILLING_ACCOUNTS':
      return {
        ...state,
        isFetchingOptionBillingAccounts: action.isFetchingOptionBillingAccounts,
      };
    default:
      return state;
  }
}

export default billingAccount;
