import { Utils } from 'versafleet-core';

const receiveStatusTriggers = ({ model, triggerType, statusTriggers }) => ({
  type: 'RECEIVE_STATUS_TRIGGERS',
  model,
  triggerType,
  statusTriggers,
});

const receiveStatusTrigger = statusTrigger => ({
  type: 'RECEIVE_STATUS_TRIGGER',
  statusTrigger,
});

const createStatusTrigger = statusTrigger => ({
  type: 'RECEIVE_STATUS_TRIGGER',
  statusTrigger,
});

const updateStatusTrigger = statusTrigger => ({
  type: 'RECEIVE_STATUS_TRIGGER',
  statusTrigger,
});

const archiveStatusTrigger = statusTrigger => ({
  type: 'RECEIVE_STATUS_TRIGGER',
  statusTrigger,
});

const fetchByModelType = (model, triggerType) => (
  async (dispatch) => {
    const params = Utils.RequestHelper.convertDataToParams({ model, trigger_type: triggerType });
    const result = await Utils.RequestHelper.request(`/status_triggers${params}`, {
      method: 'GET',
    });
    const data = { model, triggerType, statusTriggers: result.status_triggers };
    dispatch(receiveStatusTriggers(data));
    return data;
  }
);

const fetchById = id => (
  async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/status_triggers/${id}`, {
      method: 'GET',
    });
    dispatch(receiveStatusTrigger(result.status_trigger));
    return result.status_trigger;
  }
);

const create = query => (
  async (dispatch) => {
    const result = await Utils.RequestHelper.request('/status_triggers', {
      method: 'POST',
    }, {
      status_trigger: query,
    });
    dispatch(createStatusTrigger(result.status_trigger));
    return result.status_trigger;
  }
);

const update = query => (
  async (dispatch) => {
    const { id, ...form } = query;
    const result = await Utils.RequestHelper.request(`/status_triggers/${id}`, {
      method: 'PUT',
    }, {
      status_trigger: form,
    });
    dispatch(updateStatusTrigger(result.status_trigger));
    return result.status_trigger;
  }
);

const archive = id => (
  async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/status_triggers/${id}/archive`, {
      method: 'PUT',
    });
    dispatch(archiveStatusTrigger(result.status_trigger));
    return result.status_trigger;
  }
);

const sendEmailPreview = query => (
  () => Utils.RequestHelper.request('/status_triggers/preview_email', { method: 'POST' }, query)
);

const sendSMSPreview = query => (
  () => Utils.RequestHelper.request('/status_triggers/preview_sms', { method: 'POST' }, query)
);

export default {
  fetchByModelType,
  fetchById,
  create,
  update,
  archive,
  sendEmailPreview,
  sendSMSPreview,
};
