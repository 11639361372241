import React from 'react';
import authorize from 'authorize';
import Button from '../button/authorizedButton';

const MultipleGroupButton = props => (
  <Button
    className="vf-form__multiple-group-button"
    {...props}
  />
);

export default authorize('')(MultipleGroupButton);
