const initialState = {
  lineItemValidationSettings: {},
  taskIncompletionSettings: {},
  versadriveSettings: {},
  systemTags: null,
};

function versadriveSettings(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VERSADRIVE_SETTINGS':
      return {
        ...state,
        versadriveSettings: action.versadriveSettings,
      };
    case 'RECEIVE_VERSADRIVE_SETTINGS_UPDATE_SUCCESSFUL':
      return {
        ...state,
        versadriveSettings: action.versadriveSettings,
      };
    case 'RECEIVE_TASK_INCOMPLETION_SETTINGS':
      return {
        ...state,
        taskIncompletionSettings: action.taskIncompletionSettings,
      };
    case 'RECEIVE_LINE_ITEM_VALIDATION_SETTINGS':
      return {
        ...state,
        lineItemValidationSettings: action.lineItemValidationSettings,
      };
    case 'RECEIVE_SYSTEM_TAGS':
      return {
        ...state,
        systemTags: action.systemTags,
      };
    default:
      return state;
  }
}

export default versadriveSettings;
