import { Theme } from 'versafleet-core';

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function hexToRgb(hex) {
  if (!hex.includes('rgb') && !hex.includes('hsl')) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const expandedHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expandedHex);
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
  }

  // Value is already rgb or hsl
  return hex;
}

const colors = {};
Object.keys(Theme.Color).forEach((color) => {
  colors[color] = hexToRgb(Theme.Color[color]);
});

colors.getRgba = (color, opacity) => {
  if (color.includes('rgb')) {
    const rgbRegex = /rgb.?\((.*)\)/;
    const [r, g, b] = rgbRegex.exec(color)[1].split(',');
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  if (color.includes('hsl')) {
    const hslRegex = /hsl.?\((.*)\)/;
    const [h, s, l] = hslRegex.exec(color)[1].split(',');
    return `hsla(${h}, ${s}, ${l}, ${opacity})`;
  }
  const [r, g, b] = hexToRgb(color).slice(4, -1).split(',');
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export default colors;
