const initialState = {
  scan_to_identify_configurations: {},
  tags: {},
};

function scanToIdentifyConfiguration(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_SCAN_TO_IDENTIFY_CONFIGURATION_UPDATE_SUCCESSFUL':
    case 'RECEIVE_SCAN_TO_IDENTIFY_CONFIGURATION_SUCCESSFUL':
      return {
        ...state,
        scan_to_identify_configurations: action.data.scan_to_identify_configuration,
      };
    case 'RECEIVE_SCAN_TO_IDENTIFY_TAGS_SUCCESSFUL':
      return {
        ...state,
        tags: action.data.tags,
      };
    default:
      return state;
  }
}

export default scanToIdentifyConfiguration;
