import Router from 'utils/router';
import { Utils } from 'versafleet-core';
import ProfileActions from './profile';

const { RequestHelper: { convertDataToParams, request, requestBlob } } = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveUser(user) {
  return {
    type: 'RECEIVE_USER',
    user,
  };
}

function receiveUsers(result, timestamp) {
  return {
    type: 'RECEIVE_USERS',
    users: result.users,
    meta: result.meta,
    timestamp,
  };
}

function receiveUserUpdateSuccessful(user) {
  return {
    type: 'RECEIVE_USER_UPDATE_SUCCESSFUL',
    user,
  };
}

function receiveArchivedUsers(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_USERS',
    users: result.users,
    meta: result.meta,
    timestamp,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_USER_FILTER',
    filter,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/users/${id}`, {
      method: 'GET',
    });
    dispatch(receiveUser(result.user));
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.user.userMeta.page,
      ...baseFilter,
      ...state.user.filter,
      archived: false,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/users${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveUsers(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const state = getState();
    const timestamp = new Date();
    const data = {
      page: state.user.archivedUserMeta.page,
      ...baseFilter,
      ...state.user.filter,
      archived: true,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/users${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedUsers(result, timestamp));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/users', {
      method: 'POST',
    }, {
      user: query,
    });
    dispatch(receiveUserUpdateSuccessful(result.user));
    dispatch(fetchList());
    return result;
  };
}

function update(id, query) {
  return async (dispatch, getState) => {
    const state = getState();
    const result = await request(`/users/${id}`, {
      method: 'PUT',
    }, {
      user: query,
    });
    dispatch(receiveUserUpdateSuccessful(result.user));
    if (id === state.profile.profile.id) {
      dispatch(ProfileActions.getProfile());
    }
    return result;
  };
}

function archive(id) {
  return async () => {
    await request(`/users/${id}/archive`, {
      method: 'PUT',
    });
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/users/${id}/unarchive`, {
      method: 'PUT',
    });
    Router.transitionFromSubpath('users');
    dispatch(receiveUser(result.user));
    dispatch(fetchArchivedList());
  };
}

function archiveMultiple(ids) {
  return async () => {
    await request('/users/update_multiple', {
      method: 'PUT',
    }, {
      ids,
      action_type: 'archive',
    });
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    await request('/users/update_multiple', {
      method: 'PUT',
    }, {
      ids,
      action_type: 'unarchive',
    });
    dispatch(fetchArchivedList());
  };
}

function activateEmail(query) {
  return async () => {
    await request('/users/activate', {
      method: 'PUT',
    }, query);
    Router.transitionTo('/home');
  };
}

function resendActivationEmail(query) {
  return async () => {
    await request('/users/resend_activation_email', {
      method: 'POST',
    }, query);
    Router.transitionTo('/auth/resend_activation?success=true');
  };
}

function forgotPassword(query) {
  return async () => {
    await request('/users/forgot_password', {
      method: 'POST',
    }, query);
    Router.transitionTo('/auth/forget_password?success=true');
  };
}

function resetPassword(query) {
  return async () => {
    await request('/users/reset_password', {
      method: 'PUT',
    }, query);
    Router.transitionTo('/auth/reset_password?success=true');
    setTimeout(() => Router.transitionTo('/home'), 1000);
  };
}

function resetExpiredPassword(query) {
  return async () => {
    await request('/users/reset_expired_password', {
      method: 'POST',
    }, query);
  };
}

function getTotpQrImage() {
  return async () => {
    const result = await requestBlob('/users/get_qr_totp', {
      method: 'GET',
    });

    return result;
  };
}

export default {
  create,
  update,
  fetch,
  fetchList,
  fetchArchivedList,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  activateEmail,
  resendActivationEmail,
  forgotPassword,
  resetPassword,
  resetExpiredPassword,
  getTotpQrImage,
};
