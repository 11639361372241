import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Component from 'components/component';
import { Utils, translate } from 'versafleet-core';

class MeasurementEditItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useDimensions: (
        !Utils.Helper.isNullOrUndefined(props.data.volume_height)
          || !Utils.Helper.isNullOrUndefined(props.data.volume_length)
          || !Utils.Helper.isNullOrUndefined(props.data.volume_width)),
    };
  }

  _onFormChange(fieldName, value, isDelete) {
    const { data } = this.props;
    if (!Utils.Helper.isNullOrUndefined(fieldName)) {
      data[fieldName] = value;
    }

    const obj = data;
    if (this.state.useDimensions) {
      obj.volume_length = data.volume_length;
      obj.volume_width = data.volume_width;
      obj.volume_height = data.volume_height;
      obj.volume = null;
    } else {
      obj.volume_length = null;
      obj.volume_width = null;
      obj.volume_height = null;
      obj.volume = data.volume;
    }

    this.props.onFormChange(
      {
        ...data,
        ...obj,
      },
      this.props.index,
      isDelete,
    );
  }

  _switchVolumeInput() {
    if (this.state.useDimensions === true) {
      // Change to volume input, clear out dimensions
      this.setState({
        useDimensions: false,
      });
    } else {
      this.setState({
        useDimensions: true,
      });
    }
    this._onFormChange(null);
  }

  render() {
    const {
      id, account, strings, error, data, displayedIndex, versadriveSettings,
    } = this.props;

    // eslint-disable-next-line no-underscore-dangle
    if (data._destroy) {
      return false;
    }

    const hasDimensions = (
      !Utils.Helper.isNullOrUndefined(data.volume_length)
        && !Utils.Helper.isNullOrUndefined(data.volume_height)
        && !Utils.Helper.isNullOrUndefined(data.volume_width)
    );
    const hasVolume = !Utils.Helper.isNullOrUndefined(data.volume);

    // Calculate the volume
    const volume = (
      !Utils.Helper.isNullOrUndefined(data.volume_length)
        && !Utils.Helper.isNullOrUndefined(data.volume_height)
        && !Utils.Helper.isNullOrUndefined(data.volume_width)
        ? data.volume_length * data.volume_width * data.volume_height
        : data.volume
    );

    const measurementUnits = Utils.Helper.getMeasurementUnits();
    const volumeUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].volumeUnit : 'in³';
    const dimensionUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].dimensionUnit : 'in';
    const weightUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].weightUnit : 'lb';
    const shippingFactorUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].shippingFactorUnit : 'in³/lb';

    const editCustomId = versadriveSettings.delivery_job_scan_enabled
      || versadriveSettings.delivery_task_scan_enabled
      || versadriveSettings.pickup_job_scan_enabled
      || versadriveSettings.pickup_task_scan_enabled;

    return (
      <div className="vf-measurement-edit-item">
        <div className="vf-measurement-edit-item__index">
          {`${displayedIndex + 1}.`}
        </div>

        <div className="vf-measurement-edit-item__content">

          <div className="vf-measurement-edit-item__quantity">
            <Component.Input.Textbox
              className="vf-measurement-edit-item__textbox"
              error={error.quantity}
              id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__quantity-input`}
              onChange={value => this._onFormChange('quantity', value)}
              placeholder={strings.measurements.quantityHint}
              value={data.quantity}
              width="narrow"
            />
            <span className="vf-measurement-edit-item__text
              vf-measurement-edit-item__multiplier"
            >
              x
            </span>
            <Component.Input.Textbox
              className="vf-measurement-edit-item__textbox"
              error={error.quantity_unit}
              id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__quantity-unit-input`}
              onChange={value => this._onFormChange('quantity_unit', value)}
              placeholder={strings.measurements.unitHint}
              value={data.quantity_unit}
              width={editCustomId ? 'narrow' : 'wide'}
            />
            {editCustomId && (
              <Component.Input.Textbox
                error={error.custom_item_id}
                id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__custom-item-id-input`}
                onChange={value => this._onFormChange('custom_item_id', value)}
                placeholder={strings.measurements.customItemId}
                value={data.custom_item_id}
                width="narrow"
              />
            )}
          </div>

          <Component.Input.Textbox
            error={error.description}
            id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__description-input`}
            onChange={value => this._onFormChange('description', value)}
            placeholder={strings.measurements.descriptionHint}
            value={data.description}
            width="full"
          />

          <div className="vf-measurement-edit-item__volume">

            <div className="vf-measurement-edit-item__volume__block">
              <div className="vf-measurement-edit-item__volume__block__header">Actual weight</div>
              <div className="vf-measurement-edit-item__volume__block__text">
                <Component.Input.Textbox
                  className="vf-measurement-edit-item__textbox"
                  error={error.weight}
                  id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__actual-weight-input`}
                  onChange={value => this._onFormChange('weight', value)}
                  placeholder="0"
                  value={data.weight}
                  width="narrow"
                />
                <span className="vf-measurement-edit-item__text">{weightUnit}</span>
              </div>
            </div>

            <div className="vf-measurement-edit-item__volume__block">
              <div className="vf-measurement-edit-item__volume__block__header">
                {strings.measurements.volume}
              </div>
              <div className="vf-measurement-edit-item__volume__block__text">
                {this.state.useDimensions ? [
                  <Component.Input.Textbox
                    className="vf-measurement-edit-item__textbox"
                    error={error.volume_length}
                    id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__volume-length-input`}
                    onChange={value => this._onFormChange('volume_length', value)}
                    placeholder={strings.measurements.length}
                    value={data.volume_length}
                    width="narrow"
                  />,
                  <span className="vf-measurement-edit-item__text
                    vf-measurement-edit-item__multiplier"
                  >
                    x
                  </span>,
                  <Component.Input.Textbox
                    className="vf-measurement-edit-item__textbox"
                    error={error.volume_width}
                    id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__volume-width-input`}
                    onChange={value => this._onFormChange('volume_width', value)}
                    placeholder={strings.measurements.width}
                    value={data.volume_width}
                    width="narrow"
                  />,
                  <span className="vf-measurement-edit-item__text
                    vf-measurement-edit-item__multiplier"
                  >
                    x
                  </span>,
                  <Component.Input.Textbox
                    className="vf-measurement-edit-item__textbox"
                    error={error.volume_height}
                    id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__volume-height-input`}
                    onChange={value => this._onFormChange('volume_height', value)}
                    placeholder={strings.measurements.height}
                    value={data.volume_height}
                    width="narrow"
                  />,
                  <span className="vf-measurement-edit-item__text">{dimensionUnit}</span>,
                ] : [
                  <Component.Input.Textbox
                    className="vf-measurement-edit-item__textbox"
                    error={error.volume}
                    id={Utils.Helper.isNullOrUndefined(id) ? undefined : `${id}__volume-input`}
                    onChange={value => this._onFormChange('volume', value)}
                    placeholder={strings.measurements.volume}
                    value={data.volume}
                    width="narrow"
                  />,
                  <span className="vf-measurement-edit-item__text">{volumeUnit}</span>,
                ]}
              </div>
              <div
                className="vf-measurement-edit-item__volume__block__subtext
                  vf-measurement-edit-item__volume__block__input-toggler"
                id={Utils.Helper.isNullOrUndefined(id) ? undefined
                  : `${id}__use-${this.state.useDimensions ? 'volume' : 'dimension'}`}
                onClick={this._switchVolumeInput.bind(this)}
              >
                {this.state.useDimensions ? strings.measurements.useVolume
                  : strings.measurements.useDimension}
              </div>
            </div>

            <div className="vf-measurement-edit-item__volume__block">
              <div className="vf-measurement-edit-item__volume__block__header">Volumetric Weight</div>
              <div className="vf-measurement-edit-item__volume__block__text">
                {(!hasVolume && !hasDimensions)
                  || Utils.Helper.isNullOrUndefined(account.shipping_factor)
                  ? '-' : `${Utils.Helper.calculateVolumetricWeight(
                    volume,
                    account.shipping_factor,
                    account.unit_system,
                  )}${weightUnit}`}
              </div>
              {(!hasVolume && !hasDimensions)
                || Utils.Helper.isNullOrUndefined(account.shipping_factor)
                ? false
                : (
                  <div className="vf-measurement-edit-item__volume__block__subtext">
                    {`${strings.constants.by} ${
                      account.shipping_factor} ${shippingFactorUnit}`}
                  </div>
                )}
            </div>

          </div>
        </div>

        <div
          className="vf-measurement-edit-item__delete"
        >
          <i
            className="versa-delete"
            onClick={() => this._onFormChange(null, null, true)}
          />
        </div>
      </div>
    );
  }
}

MeasurementEditItem.displayName = 'Measurement Edit Item';

MeasurementEditItem.propTypes = {
  account: PropTypes.shape({
    shipping_factor: PropTypes.number,
    unit_system: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(),
  displayedIndex: PropTypes.number,
  error: PropTypes.shape(),
  id: PropTypes.string,
  index: PropTypes.number,
  onFormChange: PropTypes.func,
  strings: PropTypes.shape().isRequired,
  versadriveSettings: PropTypes.shape({
    delivery_job_scan_enabled: PropTypes.bool,
    delivery_task_scan_enabled: PropTypes.bool,
    pickup_job_scan_enabled: PropTypes.bool,
    pickup_task_scan_enabled: PropTypes.bool,
  }).isRequired,
};

MeasurementEditItem.defaultProps = {
  data: null,
  displayedIndex: null,
  error: {},
  id: null,
  index: null,
  onFormChange: null,
};

function mapStateToProps(state) {
  const { account, versadriveSettings } = state;
  return {
    account: account.account,
    versadriveSettings: versadriveSettings.versadriveSettings,
  };
}

export default connect(mapStateToProps)(translate('Task')(MeasurementEditItem));
