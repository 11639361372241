import EditItem from './editItem';
import ViewItem from './viewItem';
import Edit from './edit';
import View from './view';

export default {
  EditItem,
  ViewItem,
  Edit,
  View,
};
