import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';
import Router from 'utils/router';
import Color from 'utils/color';
import Block from './block';
import CloseWarningModal from './closeWarningModal';
import Divider from './divider';
import LeftPanel from './leftPanel';
import MainHeader from './mainHeader';
import RightPanel from './rightPanel';
import Title from './title';
import Dropdown from './dropdown';
import GroupItem from './groupItem';
import MultipleGroupButton from './multipleGroupButton';
import MultipleGroupHeader from './multipleGroupHeader';
import InputGroupList from './inputGroupList';
import Button from '../button/button';

const ESC_KEY_CODE = 27;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // The data is used in components that inherit from this Form.
      // eslint-disable-next-line react/no-unused-state
      data: props.data,
      // The error is used in components that inherit from this Form.
      // eslint-disable-next-line react/no-unused-state
      error: {},
      form: this._getDefaultForm(props.data),
      isChanged: false,
      isUpdating: false,
      showCloseModal: false,
    };
    this._handleEscKey = this._handleEscKey.bind(this);
    this.outerContainer = null;
    this.innerContainer = null;
  }

  componentDidMount() {
    // Add listener to div instead of window
    this.outerContainer.addEventListener(
      'keydown', this._handleEscKey, false,
    );
    if (!this.outerContainer.contains(document.activeElement)) {
      this._focus();
    }
  }

  componentWillUnmount() {
    this.outerContainer.removeEventListener(
      'keydown', this._handleEscKey, false,
    );
  }

  _focus() {
    this.outerContainer.focus();
  }

  _handleEscKey(e) {
    if (e.keyCode === ESC_KEY_CODE) {
      // Is escape key
      this._confirmClose(e);
    }
  }

  _guardRefresh() {
    if (!Helper.isNullOrUndefined(Router.getParams().id)
      && Helper.isNullOrUndefined(this.props.data)) {
      Router.transitionFromSubpath(this.basePath, Router.getParams().id);
    }
  }

  _confirmClose(e) {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    if (this.state.isChanged) {
      this.setState({ showCloseModal: true });
    } else {
      this._closeForm();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _closeForm() {
    Router.pop();
  }

  _edit() {
    if (!Helper.isNullOrUndefined(this.props.data)
    && !Helper.isNullOrUndefined(this.props.data.id)) {
      Router.transitionFromSubpath(this.basePath, `${Router.getParams().id}/edit`);
    } else {
      Router.transitionFromSubpath(this.basePath, 'edit');
    }
    this._focus();
  }

  _closeFormOutsideFormClick = (e) => {
    if (!this.innerContainer.contains(e.target)) {
      // Clicking outside form
      this._confirmClose(e);
    }
  }

  _onFormChange(fieldName, value, shouldChange = true) {
    this.setState((prevState) => {
      const form = { ...prevState.form };
      let obj = form;
      let lastKey;
      if (!Helper.isNullOrUndefined(fieldName)) {
        const keys = fieldName.split('.');
        lastKey = keys[keys.length - 1];
        for (let i = 0; i < keys.length; i += 1) {
          if (Helper.isNullOrUndefined(obj[keys[i]])) {
            obj[keys[i]] = {};
          }
          if (i < keys.length - 1) {
            obj = obj[keys[i]];
          }
        }
      }

      if (Helper.isNullOrUndefined(value) || value.constructor !== Object) {
        obj[lastKey] = value;
      } else {
        if (!Helper.isNullOrUndefined(lastKey)) {
          obj = obj[lastKey];
        }
        Object.keys(value).forEach((key) => { obj[key] = value[key]; });
      }

      return { form, isChanged: shouldChange ? true : prevState.isChanged };
    });
  }

  // =======================================================
  // Actions taken from form.jsx of pages
  // eslint-disable-next-line class-methods-use-this
  _getDefaultForm(data) {
    return data || {};
  }

  _resetForm() {
    this.setState({ form: this._getDefaultForm() });
  }

  _getAttributes() {
    return this.state.form;
  }

  // eslint-disable-next-line class-methods-use-this
  _getFormError() {
    return {};
  }

  _archive() {
    this.props.dispatch(this.mainActions.archive(this.props.data.id));
  }

  _unarchive() {
    this.props.dispatch(this.mainActions.unarchive(this.props.data.id));
  }

  async _create() {
    try {
      this.setState({ isUpdating: true });
      await this.props.dispatch(this.mainActions.create(this._getAttributes()));
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        error: {},
        isUpdating: false,
      });
    } catch (error) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        error,
        isUpdating: false,
      });
    }
  }

  async _update() {
    try {
      this.setState({ isUpdating: true });
      await this.props.dispatch(this.mainActions.update(this.props.data.id, this._getAttributes()));
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        error: {},
        isUpdating: false,
      });
    } catch (error) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        error,
        isUpdating: false,
      });
    }
  }

  _delete() {
    this.props.dispatch(this.mainActions.erase(this.props.data.id));
  }

  // =======================================================

  _renderCloseButton() {
    return (
      <div
        className="vf-form__inner-container__close-button"
        id="vf-form__close-button"
        onClick={this._confirmClose.bind(this)}
      >
        <i className="versa-close" />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _renderHeader() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderMenu() {
    return (
      <Title />
    );
  }

  // Replace with your own button render method if any
  _renderButtonForNew() {
    return (
      <div>
        <Button.Done
          iconColor={Color.$green}
          // this.props.isUpdating is left for backward compatibility
          loading={this.state.isUpdating || this.props.isUpdating}
          onClick={() => this._create()}
          withDefaultText
        />
        <Button.Refresh
          onClick={() => this._resetForm()}
          withDefaultText
        />
      </div>
    );
  }

  // Replace with your own button render method if any
  _renderButtonForExisting() {
    return (
      <div>
        <Button.Delete
          onClick={() => this._delete()}
          withDefaultText
        />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _renderLeftPanel() {
    return (
      <LeftPanel />
    );
  }

  _renderRightPanel() {
    return (
      <RightPanel>
        {this._renderButton()}
      </RightPanel>
    );
  }

  // Replace with your own button render method if any
  _renderButton() {
    if (Helper.isNullOrUndefined(this.props.data)
    || Helper.isNullOrUndefined(this.props.data.id)) {
      return this._renderButtonForNew();
    }
    return this._renderButtonForExisting();
  }

  _renderContent() {
    return (
      <div>
        {this._renderLeftPanel()}
        {this._renderRightPanel()}
      </div>
    );
  }

  _renderInnerContainerContent() {
    return (
      <div className="vf-form__inner-container__content">
        {this._renderMenu()}
        {this._renderContent()}
      </div>
    );
  }

  _renderCloseWarningDialog = () => (
    <CloseWarningModal
      leftButtonOnClick={this._closeForm}
      rightButtonOnClick={() => this.setState({ showCloseModal: false })}
      visible={this.state.showCloseModal}
    />
  )

  render() {
    // Tab Index used to allow focusing on div
    return (
      <div
        className="vf-form__outer-container"
        id="vf-form__outside-form"
        onMouseDown={this._closeFormOutsideFormClick}
        ref={(node) => { this.outerContainer = node; }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      >
        <div
          className="vf-form__inner-container"
          ref={(node) => { this.innerContainer = node; }}
        >
          {this._renderInnerContainerContent()}
          {this._renderCloseButton()}
          {this._renderCloseWarningDialog()}
        </div>
      </div>
    );
  }
}

Form.displayName = 'Form';

Form.propTypes = {
  data: PropTypes.shape(),
  dispatch: PropTypes.func.isRequired,
  // TODO: to remove isUpdating once all forms use local state for isUpdating
  isUpdating: PropTypes.bool,
};

Form.defaultProps = {
  data: undefined,
  isUpdating: false,
};

Form.Block = Block;
Form.CloseWarningModal = CloseWarningModal;
Form.Divider = Divider;
Form.LeftPanel = LeftPanel;
Form.MainHeader = MainHeader;
Form.RightPanel = RightPanel;
Form.Title = Title;
Form.Dropdown = Dropdown;
Form.GroupItem = GroupItem;
Form.MultipleGroupButton = MultipleGroupButton;
Form.MultipleGroupHeader = MultipleGroupHeader;
Form.InputGroupList = InputGroupList;

export default Form;
