import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { translate } from 'versafleet-core';
import Component from 'components/component';
import Helper from 'utils/helper';
import ReduxActions from 'reduxActions';
import addOnsSettings from 'pages/addOns/addOnsConstants';
import styles from 'stylesheets/modules/task.styl';

class AssignWindowForm extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.vehicle.fetchOptionList({ status: 'available' }));

    if (!Helper.isNullOrUndefined(this.props.versadriveSetting)
        && this.props.versadriveSetting.allow_assignment_to_available_drivers_only) {
      this.props.dispatch(ReduxActions.driver.fetchOptionList({ status: 'available' }));
    } else {
      this.props.dispatch(ReduxActions.driver.fetchOptionList());
    }

    if (!Helper.isNullOrUndefined(this.props.addonsSetting)
        && this.props.addonsSetting.parts_and_config === addOnsSettings.subscribed) {
      this.props.dispatch(ReduxActions.vehiclePart.fetchOptionList({ status: 'available' }));
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.addonsSetting, this.props.addonsSetting)
      || !isEqual(nextProps.data, this.props.data)
      || !isEqual(nextProps.options, this.props.options)
      || !isEqual(nextProps.strings, this.props.strings)
      || !isEqual(nextProps.taskIds, this.props.taskIds)
      || nextProps.isAssigningTask !== this.props.isAssigningTask;
  }

  _onClick = () => {
    const unassignedIds = !Helper.isNullOrUndefined(this.props.taskIds)
      ? this._getUnassignedIds() : null;

    if (this.props.isTaskTransfer
      && !Helper.isNullOrUndefined(this.props.taskIds)
      && this.props.taskIds.length > 0) {
      this.props.showModal();
    } else if (!this.props.isTaskTransfer
      && !Helper.isNullOrUndefined(unassignedIds)
      && unassignedIds.length < this.props.taskIds.length) {
      this.props.showModal();
    } else {
      this.props.assign();
    }
  }

  _getUnassignedIds() {
    const { tasks, taskIds } = this.props;
    const unassignedIds = taskIds.filter(id => tasks[id].state === 'unassigned');

    return unassignedIds;
  }

  _renderItemWithSkills = item => (
    <React.Fragment>
      <Component.Window.Content>
        {item.name || item.plate_number}
      </Component.Window.Content>
      {this._renderSkills(item)}
    </React.Fragment>
  );

  _renderSkills = item => (
    <React.Fragment>
      <Component.Window.Description>
        {this.props.strings.specification}
      </Component.Window.Description>
      {
        Array.isArray((item || {}).skills) && item.skills.length > 0 ? (
          <Component.Tags
            className={styles['vf-task__assign__form__tags']}
            tags={item.skills.map(skill => skill.name)}
          />
        ) : (
          <Component.Window.Content>-</Component.Window.Content>
        )
      }
    </React.Fragment>
  );

  render() {
    const {
      addonsSetting, strings, options, data, isAssigningTask,
      isTaskTransfer, onFormChange, onMouseDown, onMouseUp,
    } = this.props;
    const drivers = options.driver.filter(driver => !driver.is_attendant);
    const attendants = options.driver.filter(driver => driver.is_attendant);
    const isSkillSubscribed = !Helper.isNullOrUndefined(addonsSetting)
      && addonsSetting.skills === addOnsSettings.subscribed;

    let confirmButtonText;
    if (isTaskTransfer) {
      confirmButtonText = isAssigningTask ? strings.transferring : strings.transfer;
    } else {
      confirmButtonText = isAssigningTask ? strings.assigning : strings.assign;
    }

    return (
      <div
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <Component.Window.Section>
          <Component.Window.FieldEditGroup>
            <Component.Window.FieldEdit.Searchbox
              allowCustom
              dropdownFocusedClassName={isSkillSubscribed ? styles['vf-task__assign__form__dropdown--focused'] : null}
              fieldName={strings.driver}
              isRequired
              onChange={value => onFormChange('driver_id', value)}
              options={drivers.map(driver => ({
                row: isSkillSubscribed ? this._renderItemWithSkills(driver) : null,
                text: driver.name,
                value: driver.id,
              }))}
              value={data.driver_id}
            />
            {
              !Helper.isNullOrUndefined(data.driver_id) && isSkillSubscribed
                ? this._renderSkills(drivers.find(driver => driver.id === data.driver_id)) : false
            }
            {
              !Helper.isNullOrUndefined(addonsSetting)
                && addonsSetting.attendants_and_config === addOnsSettings.subscribed
                ? (
                  <Component.Window.FieldEdit.Searchbox
                    dropdownFocusedClassName={isSkillSubscribed ? styles['vf-task__assign__form__dropdown--focused'] : null}
                    fieldName={strings.attendant}
                    onChange={value => onFormChange('attendant_id', value)}
                    options={attendants.map(driver => ({
                      row: isSkillSubscribed ? this._renderItemWithSkills(driver) : null,
                      text: driver.name,
                      value: driver.id,
                    }))}
                    value={data.attendant_id}
                  />
                )
                : false
            }
            {
              !Helper.isNullOrUndefined(data.attendant_id) && isSkillSubscribed
                && addonsSetting.attendants_and_config === addOnsSettings.subscribed
                ? this._renderSkills(attendants.find(
                  attendant => attendant.id === data.attendant_id,
                )) : false
            }
            <Component.Window.FieldEdit.Searchbox
              allowCustom
              dropdownFocusedClassName={isSkillSubscribed ? styles['vf-task__assign__form__dropdown--focused'] : null}
              fieldName={strings.vehicle}
              isRequired
              onChange={value => onFormChange('vehicle_id', value)}
              options={this.props.options.vehicle.map(vehicle => ({
                row: isSkillSubscribed ? this._renderItemWithSkills(vehicle) : null,
                text: vehicle.plate_number,
                value: vehicle.id,
              }))}
              value={data.vehicle_id}
            />
            {
              !Helper.isNullOrUndefined(data.vehicle_id) && isSkillSubscribed
                ? this._renderSkills(this.props.options.vehicle.find(
                  vehicle => vehicle.id === data.vehicle_id,
                )) : false
            }
            {
              !Helper.isNullOrUndefined(addonsSetting)
                && addonsSetting.parts_and_config === addOnsSettings.subscribed
                ? (
                  <Component.Window.FieldEdit.Searchbox
                    dropdownFocusedClassName={isSkillSubscribed ? styles['vf-task__assign__form__dropdown--focused'] : null}
                    fieldName={strings.part}
                    onChange={value => onFormChange('vehicle_part_id', value)}
                    options={this.props.options.vehiclePart.map(part => ({
                      row: isSkillSubscribed ? this._renderItemWithSkills(part) : null,
                      text: part.plate_number,
                      value: part.id,
                    }))}
                    value={data.vehicle_part_id}
                  />
                )
                : false
            }
            {
              !Helper.isNullOrUndefined(data.vehicle_part_id) && isSkillSubscribed
                && addonsSetting.parts_and_config === addOnsSettings.subscribed
                ? this._renderSkills(this.props.options.vehiclePart.find(
                  part => part.id === data.vehicle_part_id,
                )) : false
            }
          </Component.Window.FieldEditGroup>
          <Component.Window.FieldEdit.TimePicker
            fieldName={strings.scheduleTime}
            onChange={value => onFormChange('estimated_start_time', value)}
            placeholder={strings.scheduleTimePlaceHolder}
            value={data.estimated_start_time}
          />
          <Component.Window.FieldEdit.Textbox
            fieldName={strings.remark}
            onChange={value => onFormChange('remarks', value)}
            value={data.remarks}
          />
          <Component.Window.FieldEditGroup>
            {!Helper.isNullOrUndefined(data.driver_id) && !Helper.isNullOrUndefined(data.vehicle_id)
              ? (
                <Component.Button
                  className={styles['vf-task__assign__form__button']}
                  iconClassName={isTaskTransfer ? 'versa-swap-horiz' : 'versa-completed'}
                  loading={isAssigningTask}
                  onClick={this._onClick}
                >
                  {confirmButtonText}
                </Component.Button>
              ) : false}
          </Component.Window.FieldEditGroup>
        </Component.Window.Section>
      </div>
    );
  }
}

AssignWindowForm.displayName = 'AssignWindowForm';

AssignWindowForm.propTypes = {
  addonsSetting: PropTypes.shape({
    attendants_and_config: PropTypes.number,
    parts_and_config: PropTypes.number,
    skills: PropTypes.number,
  }),
  assign: PropTypes.func.isRequired,
  data: PropTypes.shape({
    attendant_id: PropTypes.number,
    driver_id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string, // Temporarily store unsaved new driver
    ]),
    estimated_start_time: PropTypes.number,
    remarks: PropTypes.string,
    vehicle_id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string, // Temporarily store unsaved new vehicle
    ]),
    vehicle_part_id: PropTypes.number,
  }),
  dispatch: PropTypes.func.isRequired,
  isAssigningTask: PropTypes.bool,
  isTaskTransfer: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  options: PropTypes.shape({
    driver: PropTypes.arrayOf(PropTypes.shape()),
    vehicle: PropTypes.arrayOf(PropTypes.shape()),
    vehiclePart: PropTypes.arrayOf(PropTypes.shape()),
  }),
  showModal: PropTypes.func.isRequired,
  strings: PropTypes.shape({
    assign: PropTypes.string,
    assigning: PropTypes.string,
    attendant: PropTypes.string,
    driver: PropTypes.string,
    part: PropTypes.string,
    remark: PropTypes.string,
    scheduleTime: PropTypes.string,
    scheduleTimePlaceHolder: PropTypes.string,
    specification: PropTypes.string,
    transfer: PropTypes.string,
    transferring: PropTypes.string,
    vehicle: PropTypes.string,
  }).isRequired,
  taskIds: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.shape({}),
  versadriveSetting: PropTypes.shape({
    allow_assignment_to_available_drivers_only: PropTypes.bool,
  }),
};

AssignWindowForm.defaultProps = {
  addonsSetting: null,
  data: {},
  isAssigningTask: false,
  onMouseDown: null,
  onMouseUp: null,
  options: null,
  taskIds: [],
  tasks: null,
  versadriveSetting: null,
};

function mapStateToProps(state) {
  const {
    account, task, driver, vehicle, vehiclePart, versadriveSettings,
  } = state;
  return {
    addonsSetting: account.account.addons_setting,
    tasks: task.tasks,
    options: {
      driver: driver.optionDrivers || [],
      vehicle: vehicle.optionVehicles || [],
      vehiclePart: vehiclePart.optionVehicleParts || [],
    },
    drivers: driver.drivers || {},
    versadriveSetting: versadriveSettings.versadriveSettings,
  };
}

export default connect(mapStateToProps)(translate('Task.AssignForm')(AssignWindowForm));
