/* eslint-disable no-await-in-loop */
import { store } from 'reduxActions';
import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';
import Router from 'utils/router';
import Formatter from 'utils/formatter';
import featuresSettings from 'pages/featuresConstants';
import RunsheetActions from './runsheet';
// commenting out until eslint is all fixed - reverted eslint fix due to issue in job import
// eslint-disable-next-line
import JobActions from './job';

const { RequestHelper: { openInNewWindow } } = Utils;

const baseFilter = {
  sort_by: 'id',
  order_by: 'desc',
  per_page: 20,
};

function receiveTask(task) {
  return {
    type: 'RECEIVE_TASK',
    task,
  };
}

function receiveTasks(result, timestamp) {
  return {
    type: 'RECEIVE_TASKS',
    tasks: result.tasks,
    meta: result.meta,
    timestamp,
  };
}

function receiveTasksDetails(result) {
  return {
    type: 'RECEIVE_TASKS_DETAILS',
    tasks: result.tasks,
  };
}

function receiveTaskUpdateSuccessful(task) {
  return {
    type: 'RECEIVE_TASK_UPDATE_SUCCESSFUL',
    task,
  };
}

function receiveTaskGeolocationUpdateSuccessful(id, location) {
  return {
    type: 'RECEIVE_TASK_GEOLOCATION_UPDATE_SUCCESSFUL',
    id,
    location,
  };
}

function receiveVisualTasks(result, timestamp) {
  return {
    type: 'RECEIVE_VISUAL_TASKS',
    tasks: result,
    timestamp,
  };
}

function receiveArchivedTasks(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_TASKS',
    tasks: result.tasks,
    meta: result.meta,
    timestamp,
  };
}

function receiveAutoAllocateTasks(tasks) {
  return {
    type: 'RECEIVE_AUTOALLOCATE_TASKS',
    tasks,
  };
}

function receiveTotalFailedTasksCount(count) {
  return {
    type: 'RECEIVE_TOTAL_FAILED_TASKS_COUNT',
    count,
  };
}

function receiveFailedTasks(failedTasks) {
  return {
    type: 'RECEIVE_FAILED_TASKS',
    failedTasks,
  };
}

function receiveIncompletionReasonsOptionList(incompletionReasons) {
  return {
    type: 'RECEIVE_INCOMPLETION_REASONS_OPTION_LIST',
    incompletionReasons,
  };
}

function receiveTaskHistory(taskHistory, id) {
  return {
    type: 'RECEIVE_TASK_HISTORY',
    taskHistory: {
      task_history: {
        ...taskHistory,
      },
      // [VF-1772] ID data type is string but integer is required
      id: parseInt(id, 10),
    },
  };
}

function receiveTaskHistoryUpdate(taskHistory, id) {
  return {
    type: 'RECEIVE_TASK_HISTORY_UPDATE',
    taskHistory,
    id: parseInt(id, 10),
  };
}

function receiveRecipientVerificationHistory(verificationHistory, id) {
  return {
    type: 'RECEIVE_TASK_VERIFICATION_HISTORY',
    verificationHistory: {
      verificationHistory: {
        ...verificationHistory,
      },
      id,
    },
  };
}

function receiveExportTags(exportTags) {
  return {
    type: 'RECEIVE_EXPORT_TAGS',
    exportTags,
  };
}

function receiveSystemTags(systemTags) {
  return {
    type: 'RECEIVE_SYSTEM_TAGS',
    systemTags,
  };
}

function receivePresetReportTypes(presetReportTypes) {
  return {
    type: 'RECEIVE_PRESET_REPORT_TYPES',
    presetReportTypes,
  };
}

function receiveExportTaskCountSuccessful(taskCount) {
  return {
    type: 'RECEIVE_EXPORT_TASK_COUNT_SUCCESSFUL',
    taskCount,
  };
}

function receiveResetExportTaskCount() {
  return {
    type: 'RECEIVE_RESET_EXPORT_TASK_COUNT',
  };
}

function setFilter(filter) {
  return {
    type: 'SET_TASK_FILTER',
    filter,
  };
}

function requestUpdatingMultipleVersadrive() {
  return {
    type: 'REQUEST_UPDATING_MULTIPLE_VERSADRIVE',
  };
}

function receiveUpdatingMultipleVersaDrive() {
  return {
    type: 'RECEIVE_UPDATING_MULTIPLE_VERSADRIVE',
  };
}

function receiveOptionAddresses(optionAddresses) {
  return {
    type: 'RECEIVE_OPTION_ADDRESSES',
    optionAddresses,
  };
}

function setIsFetchingAdditionalInfo(isFetchingAdditionalInfo) {
  return {
    type: 'SET_IS_FETCHING_ADDITIONAL_INFO',
    isFetchingAdditionalInfo,
  };
}

function setIsFetchingOptionAddresses(isFetchingOptionAddresses) {
  return {
    type: 'SET_IS_FETCHING_OPTION_ADDRESSES',
    isFetchingOptionAddresses,
  };
}

function setIsFetchingIncompletionReasons(isFetchingIncompletionReasons) {
  return {
    type: 'SET_IS_FETCHING_INCOMPLETION_REASONS',
    isFetchingIncompletionReasons,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}`, { method: 'GET' });
    dispatch(receiveTask(result.task));
    return result.task;
  };
}

async function updateGeolocation(id, location) {
  const data = { location };
  await Utils.RequestHelper.request(`/tasks/${id}/location`, { method: 'PUT' }, data);
  store.dispatch(receiveTaskGeolocationUpdateSuccessful(id, location));
}

// Not called from outside this file
async function geocodeItem(item, useCrs, accountId = null) {
  // to differentiate between task and job (role property)
  const address = !Object.prototype.hasOwnProperty.call(item, 'role')
    ? item.base_task && item.base_task.address
    : item.address;
  if (Helper.isNullOrUndefined(address)) {
    return;
  }

  let location;
  try {
    location = await Utils.Geocoder.geocodeAddress(address, useCrs, accountId);
  } catch (error) {
    // ignore error
  }

  const result = {
    latitude: Helper.isNullOrUndefined(location) ? null : location.lat,
    longitude: Helper.isNullOrUndefined(location) ? null : location.lng,
  };

  if (!Object.prototype.hasOwnProperty.call(item, 'role')) {
    if (item.base_task.address.latitude !== result.latitude
      || item.base_task.address.longitude !== result.longitude) {
      JobActions.updateGeolocation(item.id, result);
    }
  } else if (item.address.latitude !== result.latitude
    || item.address.longitude !== result.longitude) {
    updateGeolocation(item.id, result);
  }
}

// Not called from outside this file
async function checkLatLngTask(tasks, useCrs, accountId = null) {
  // tasks fetched with additional information -> base task geocoding information
  const addressNeedsGeocoding = task => !Helper.isNullOrUndefined(task)
    && !Helper.isNullOrUndefined(task.address)
    && (Helper.isNullOrUndefined(task.address.latitude)
      || Helper.isNullOrUndefined(task.address.longitude))
    && !Helper.isNullOrUndefined(task.address.line_1);

  const filteredTasks = tasks
    .filter(task => addressNeedsGeocoding(task))
    .map(task => geocodeItem(task, useCrs, accountId));

  const filteredJobs = tasks
    .filter(task => task && task.job)
    .map(task => task.job)
    .filter((job, pos, arr) => (arr.findIndex(j => j.id === job.id) === pos)
      && addressNeedsGeocoding(job.base_task))
    .map(job => geocodeItem(job, useCrs, accountId));

  const geocoderPromises = filteredJobs.concat(filteredTasks);
  await Promise.all(geocoderPromises);
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const queryString = Utils.RequestHelper.convertDataToParams({
      page: state.task.taskMeta.page,
      archived: false,
      ...baseFilter,
      ...state.task.filter,
    });

    // Encode reserved characters in url query
    let encodedQueryString = queryString;
    [':', '/', '#', '@'].forEach((reservedWord) => {
      encodedQueryString = encodedQueryString.replace(
        reservedWord,
        encodeURIComponent(reservedWord),
      );
    });

    const result = await Utils.RequestHelper.request(`/tasks${encodedQueryString}`, { method: 'GET' });
    dispatch(receiveTasks(result, timestamp));

    const { features_setting: featuresSetting, id: accountId } = state.account.account;
    const useCrs = featuresSetting?.coordinate_retrieval_service === featuresSettings.enabled
                || featuresSetting?.coordinate_retrieval_service === undefined;
    checkLatLngTask(result.tasks, useCrs, accountId);

    return result;
  };
}

// if ids are specified, fetch and update the task details of the ids,
// otherwise filter and only load tasks without additional information
function fetchAdditionalInfo(ids, batch = 50, geocode = true) {
  return async (dispatch, getState) => {
    const state = getState();

    const idsToLoad = !Helper.isNullOrUndefined(ids)
      ? ids
      : state.task.tasksIds.concat(state.task.archivedTaskIds).concat(state.task.visualTaskIds)
        .map(taskId => state.task.tasks[taskId])
        .filter(task => Helper.isNullOrUndefined(task.job)
          || Helper.isNullOrUndefined(task.task_assignment)
          || Helper.isNullOrUndefined(task.tags)
          || Helper.isNullOrUndefined(task.measurements)
          || ((task.state === 'assigned'
              || task.state === 'waiting_for_acknowledgement'
              || task.state === 'acknowledged')
            && !Helper.isNullOrUndefined(task.task_assignment)
            && Helper.isNullOrUndefined(task.task_assignment.driver)))
        .map(task => task.id);

    for (let i = 0; i < idsToLoad.length; i += batch) {
      const queryString = Utils.RequestHelper.convertDataToParams(
        { ids: idsToLoad.slice(i, i + batch) },
      );
      const result = await Utils.RequestHelper.request(
        `/tasks/additional_info${queryString}`, { method: 'GET' },
      );
      dispatch(receiveTasksDetails(result));
      if (geocode) {
        const { features_setting: featuresSetting, id: accountId } = state.account.account;
        const useCrs = featuresSetting?.coordinate_retrieval_service === featuresSettings.enabled
                    || featuresSetting?.coordinate_retrieval_service === undefined;
        checkLatLngTask(result.tasks, useCrs, accountId);
      }
    }
  };
}

function fetchCompletionHistories(ids, batch = 50) {
  return async (dispatch, getState) => {
    const state = getState();
    const idsToLoad = !Helper.isNullOrUndefined(ids)
      ? ids
      : state.task.tasksIds.concat(state.task.archivedTaskIds).concat(state.task.visualTaskIds)
        .map(taskId => state.task.tasks[taskId])
        .filter(task => Helper.isNullOrUndefined(task.task_completion_histories))
        .map(task => task.id);

    for (let i = 0; i < idsToLoad.length; i += batch) {
      const queryString = Utils.RequestHelper.convertDataToParams(
        { ids: idsToLoad.slice(i, i + batch) },
      );
      const result = await Utils.RequestHelper.request(
        `/tasks/completion_histories${queryString}`, { method: 'GET' },
      );
      dispatch(receiveTasksDetails(result));
    }
  };
}

// if ids are specified, fetch and update the custom fields of the ids,
// otherwise filter and only load tasks without custom fields
function fetchCustomFields(ids, batch = 50) {
  return async (dispatch, getState) => {
    const state = getState();
    const idsToLoad = !Helper.isNullOrUndefined(ids)
      ? ids
      : state.task.tasksIds.concat(state.task.archivedTaskIds).concat(state.task.visualTaskIds)
        .map(taskId => state.task.tasks[taskId])
        .filter(task => Helper.isNullOrUndefined(task.custom_fields))
        .map(task => task.id);

    for (let i = 0; i < idsToLoad.length; i += batch) {
      const queryString = Utils.RequestHelper.convertDataToParams(
        { ids: idsToLoad.slice(i, i + batch) },
      );
      const result = await Utils.RequestHelper.request(
        `/tasks/custom_fields${queryString}`, { method: 'GET' },
      );
      dispatch(receiveTasksDetails(result));
    }
  };
}

function fetchPendingList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const queryString = Utils.RequestHelper.convertDataToParams({
      page: state.task.taskMeta.page,
      ...baseFilter,
      ...state.task.filter,
      archived: false,
    });
    const result = await Utils.RequestHelper.request(
      `/tasks/pending${queryString}`, { method: 'GET' },
    );
    dispatch(receiveTasks(result, timestamp));
    return result;
  };
}

function fetchVisualList(filter, geocode = true) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const state = getState();
    let page = 1;
    let totalResults = 0;
    let results = [];

    // If per_page is not sent, it defaults to 500 in the backend.
    do {
      const queryString = Utils.RequestHelper.convertDataToParams({
        ...state.task.filter,
        archived: false,
        page,
      });
      const response = await Utils.RequestHelper.request(`/tasks${queryString}`, { method: 'GET' });

      if (geocode) {
        const useCrs = state.account.account.features_setting.coordinate_retrieval_service
          === featuresSettings.enabled;
        const accountId = state.account.account.id;
        checkLatLngTask(response.tasks, useCrs, accountId);
      }

      results = results.concat(response.tasks);
      totalResults = response.meta.total;
      page += 1;
      // Limit the number of iterations as there is an issue where the original condition
      // does not seem to work properly, causing it to run infinitely.
    } while (results.length < totalResults && page < 21);

    dispatch(receiveVisualTasks(results, new Date()));

    return results;
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const state = getState();
    const timestamp = new Date();
    const queryString = Utils.RequestHelper.convertDataToParams({
      page: state.task.archivedTaskMeta.page,
      ...baseFilter,
      ...state.task.filter,
      archived: true,
    });
    const result = await Utils.RequestHelper.request(`/tasks${queryString}`, { method: 'GET' });
    dispatch(receiveArchivedTasks(result, timestamp));
    return result;
  };
}

async function createWithGeocodedAddress(query, location) {
  let newQuery = query;
  if (!Helper.isNullOrUndefined(location)) {
    const newAddressAttributes = {
      ...query.address_attributes,
      latitude: location.lat,
      longitude: location.lng,
    };
    newQuery = {
      ...query,
      address_attributes: newAddressAttributes,
    };
  }

  const data = { task: newQuery };
  const result = await Utils.RequestHelper.request('/tasks', { method: 'POST' }, data);
  const path = Router.getPath();
  const state = store.getState();
  store.dispatch(receiveTaskUpdateSuccessful(result.task));
  if (path.indexOf('visual') !== -1) {
    store.dispatch(fetchVisualList(state.task.filter));
  } else {
    store.dispatch(fetchList());
  }
}

function create(query) {
  return async (dispatch, getState) => {
    let location = {
      lat: query.address_attributes.latitude,
      lng: query.address_attributes.longitude,
    };
    if (Helper.isNullOrUndefined(location.lat) || Helper.isNullOrUndefined(location.lng)) {
      try {
        const state = getState();
        const useCrs = state.account.account.features_setting.coordinate_retrieval_service
          === featuresSettings.enabled;
        const accountId = state.account.account.id;
        location = await Utils.Geocoder.geocodeAddress(query.address_attributes, useCrs, accountId);

        if (Helper.isNullOrUndefined(location.lat) || Helper.isNullOrUndefined(location.lng)) {
          location = Helper.parseAddressToLatLng(query.address_attributes);
        }
      } catch (error) {
        location = null;
      }
    }
    return createWithGeocodedAddress(query, location);
  };
}

function updateCompleteLocation(id, location) {
  return async (dispatch) => {
    const data = { location };
    const result = await Utils.RequestHelper.request(`/tasks/${id}/update_complete_location`, { method: 'PUT' }, data);

    dispatch(receiveTaskHistoryUpdate(result.task_update_complete_location_history, id));
  };
}

function updateInvoice(id, query) {
  return async (dispatch) => {
    const data = { task: query };
    const result = await Utils.RequestHelper.request(`/tasks/${id}`, { method: 'PUT' }, data);
    dispatch(receiveTaskUpdateSuccessful(result.task));
    return result;
  };
}

function updateTask(id, query, location) {
  return async (dispatch, getState) => {
    let newQuery = query;
    if (location !== undefined) {
      const newAddressAttributes = {
        ...query.address_attributes,
        latitude: Helper.isNullOrUndefined(location) ? null : location.lat,
        longitude: Helper.isNullOrUndefined(location) ? null : location.lng,
      };
      newQuery = {
        ...query,
        address_attributes: newAddressAttributes,
      };
    }

    const data = { task: newQuery };
    const result = await Utils.RequestHelper.request(`/tasks/${id}`, { method: 'PUT' }, data);
    if (!Helper.isNullOrUndefined(result.task)
      && Helper.isNullOrUndefined((result.task.address || {}).longitude)
      && Helper.isNullOrUndefined((result.task.address || {}).latitude)) {
      const state = getState();
      const useCrs = state.account.account.features_setting.coordinate_retrieval_service
        === featuresSettings.enabled;
      const accountId = state.account.account.id;
      geocodeItem(result.task, useCrs, accountId);
    }
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function update(id, query) {
  return async (dispatch, getState) => {
    const state = getState();
    let location = {
      lat: query.address_attributes.latitude,
      lng: query.address_attributes.longitude,
    };
    if (
      !Helper.isNullOrUndefined(state.task.tasks[id])
        && !Helper.isNullOrUndefined(query.address_attributes)
        && Formatter.getInlineAddress(query.address_attributes)
          !== Formatter.getInlineAddress(state.task.tasks[id].address)
        && (Helper.isNullOrUndefined(location.lat)
          || Helper.isNullOrUndefined(location.lng))
    ) {
      try {
        const useCrs = state.account.account.features_setting.coordinate_retrieval_service
          === featuresSettings.enabled;
        const accountId = state.account.account.id;
        location = await Utils.Geocoder.geocodeAddress(query.address_attributes, useCrs, accountId);

        if (Helper.isNullOrUndefined(location.lat)
          || Helper.isNullOrUndefined(location.lng)
        ) {
          location = Helper.parseAddressToLatLng(query.address_attributes);
        }
      } catch (error) {
        // ignore error
      }
    }

    await dispatch(updateTask(id, query, location));
  };
}

function tagMultiple(ids, query) {
  return async () => {
    const data = {
      ids,
      action_type: 'update',
      task: {
        tag_list: query,
      },
    };
    await Utils.RequestHelper.request('/tasks/update_multiple', { method: 'PUT' }, data);
  };
}

function editMultiple(ids, query) {
  return async () => {
    const data = {
      ids,
      action_type: 'update',
      task: query,
    };
    const result = await Utils.RequestHelper.request(
      '/tasks/update_multiple', { method: 'PUT' }, data,
    );
    return result;
  };
}

function duplicate(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}/duplicate`, { method: 'POST' });
    dispatch(receiveTask(result.task));
    dispatch(fetchList());
    return result;
  };
}

function archive(id) {
  return async () => {
    await Utils.RequestHelper.request(`/tasks/${id}/archive`, { method: 'PUT' });
  };
}

function unarchive(id) {
  return async (dispatch) => {
    await Utils.RequestHelper.request(`/tasks/${id}/unarchive`, { method: 'PUT' });
    dispatch(fetchArchivedList());
  };
}

function archiveMultiple(ids) {
  return async () => {
    const data = {
      ids,
      action_type: 'archive',
    };
    await Utils.RequestHelper.request('/tasks/update_multiple', { method: 'PUT' }, data);
  };
}

function unarchiveMultiple(ids) {
  return async () => {
    const data = {
      ids,
      action_type: 'unarchive',
    };
    await Utils.RequestHelper.request('/tasks/update_multiple', { method: 'PUT' }, data);
  };
}

function unassign(id, query = {}) {
  return async (dispatch) => {
    const data = {
      task: {
        remarks: '',
        driver_id: '',
        vehicle_id: '',
        ...query,
      },
    };
    const result = await Utils.RequestHelper.request(
      `/tasks/${id}/assign`, { method: 'PUT' }, data,
    );
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function unassignMultiple(ids) {
  return async () => {
    const data = { task: { ids } };
    await Utils.RequestHelper.request('/tasks/assign', { method: 'PUT' }, data);
  };
}

function print(id) {
  return async () => {
    await openInNewWindow(`/tasks/${id}/print`);
  };
}

async function printMultiple(query) {
  const result = await Utils.RequestHelper.request('/tasks/multiple_print', {
    method: 'POST',
  }, {
    task: query,
  });
  return result;
}

function printTaskLabel(id) {
  openInNewWindow(`/tasks/${id}/task_label`);
}

async function printTaskLabelMultiple(query) {
  const result = await Utils.RequestHelper.request('/tasks/task_label_multiple', {
    method: 'POST',
  }, {
    task: query,
  });
  return result;
}

function submitAll(date) {
  return async (dispatch) => {
    dispatch(requestUpdatingMultipleVersadrive());
    const data = { date };
    await Utils.RequestHelper.request('/tasks/submit_all', { method: 'PUT' }, data);
  };
}

function submitMultiple(ids) {
  return async (dispatch) => {
    dispatch(requestUpdatingMultipleVersadrive());
    const data = {
      ids,
      action_type: 'submit',
    };
    await Utils.RequestHelper.request('/tasks/update_multiple', { method: 'PUT' }, data);
  };
}

function submit(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}/submit`, { method: 'PUT' });
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function complete(id, query) {
  return async (dispatch) => {
    const data = {
      to_state: 'successful',
      note: query.note,
      is_partial_success: query.is_partial_success,
    };
    const result = await Utils.RequestHelper.request(
      `/tasks/${id}/complete`, { method: 'PUT' }, data,
    );
    dispatch(receiveTaskUpdateSuccessful(result.task));
    return result;
  };
}

function restart(id) {
  return async (dispatch) => {
    const data = { to_state: 'unassigned' };
    const result = await Utils.RequestHelper.request(
      `/tasks/${id}/update_state`, { method: 'PUT' }, data,
    );
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function assign(id, query) {
  return async (dispatch) => {
    const data = { task: query };
    const result = await Utils.RequestHelper.request(`/tasks/${id}/assign`, { method: 'PUT' }, data);
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function assignMultiple(query) {
  return async () => {
    const data = { task: query };
    await Utils.RequestHelper.request('/tasks/assign', { method: 'PUT' }, data);
  };
}

function transfer(id, query) {
  return async (dispatch) => {
    const data = { task: query };
    const result = await Utils.RequestHelper.request(`/tasks/${id}/transfer`, { method: 'PUT' }, data);
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function transferMultiple(query) {
  return async () => {
    const data = { task: query };
    await Utils.RequestHelper.request('/tasks/transfer', { method: 'PUT' }, data);
  };
}

function allocate(id, query) {
  return async () => {
    const data = { task: query };
    await Utils.RequestHelper.request(`/tasks/${id}/allocate`, { method: 'PUT' }, data);
  };
}

function allocateMultiple(query, options = {}) {
  return async () => {
    const data = { task: query };
    await Utils.RequestHelper.request('/tasks/allocate', { method: 'PUT', ...options }, data);
  };
}

function unallocate(id) {
  return async () => {
    await Utils.RequestHelper.request(`/tasks/${id}/unallocate`, { method: 'PUT' });
  };
}

function unallocateMultiple(ids, options = {}) {
  return async () => {
    const data = { task: ids };
    await Utils.RequestHelper.request('/tasks/unallocate', { method: 'PUT', ...options }, data);
  };
}

// Can also take in single id as a parameter
function acceptAllocationMultiple(ids) {
  return async () => {
    const data = {
      task: {
        ids: Array.isArray(ids) ? ids : [ids],
      },
    };
    await Utils.RequestHelper.request('/tasks/accept_allocation', { method: 'PUT' }, data);
  };
}

function rejectAllocationMultiple(query) {
  return async () => {
    const data = { task: query };
    await Utils.RequestHelper.request('/tasks/reject_allocation', { method: 'PUT' }, data);
  };
}

function updateMeasurement(id, query) {
  return async (dispatch) => {
    const data = { task: query };
    const result = await Utils.RequestHelper.request(`/tasks/${id}`, { method: 'PUT' }, data);
    dispatch(receiveTaskUpdateSuccessful(result.task));
  };
}

function validate(query) {
  return async () => {
    const data = { task: query };
    await Utils.RequestHelper.request('/tasks/validate', { method: 'POST' }, data);
  };
}

function fetchTotalFailedTasksCount(query) {
  return async (dispatch) => {
    const queryString = Utils.RequestHelper.convertDataToParams(query);
    const result = await Utils.RequestHelper.request(
      `/tasks/total_failed_count${queryString}`, { method: 'GET' },
    );
    dispatch(receiveTotalFailedTasksCount(result.count));
  };
}

function fetchFailedTasks(query) {
  return async (dispatch) => {
    const queryString = Utils.RequestHelper.convertDataToParams(query);
    const result = await Utils.RequestHelper.request(
      `/tasks/failed_count${queryString}`, { method: 'GET' },
    );
    dispatch(receiveFailedTasks(result.tasks));
  };
}

function fetchIncompletionReasonsOptionList(date) {
  return async (dispatch, getState) => {
    const { isFetchingIncompletionReasons } = getState().task;

    if (isFetchingIncompletionReasons) {
      return null;
    }

    let result = {};
    try {
      dispatch(setIsFetchingIncompletionReasons(true));

      const queryString = Utils.RequestHelper.convertDataToParams({ date });
      result = await Utils.RequestHelper.request(`/tasks/incompletion_reasons_list${queryString}`, { method: 'GET' });
      dispatch(receiveIncompletionReasonsOptionList(result.incompletion_reasons));
    } finally {
      dispatch(setIsFetchingIncompletionReasons(false));
    }

    return result.incompletion_reasons;
  };
}

function fetchTaskHistory(id, query) {
  return async (dispatch) => {
    const queryString = Utils.RequestHelper.convertDataToParams(query);
    const result = await Utils.RequestHelper.request(
      `/tasks/${id}/history${queryString}`, { method: 'GET' },
    );
    dispatch(receiveTaskHistory(result.task_history, id));
  };
}

function fetchRecipientVerificationHistory(id, query) {
  return async (dispatch) => {
    const queryString = Utils.RequestHelper.convertDataToParams(query);
    const result = await Utils.RequestHelper.request(
      `/tasks/${id}/recipient_verification_history${queryString}`, { method: 'GET' },
    );
    dispatch(receiveRecipientVerificationHistory(result.task_recipient_verification_history, id));
  };
}

// For fetching task and runsheet data together
function fetchVisualData(filter, ids) {
  return async (dispatch, getState) => {
    dispatch(RunsheetActions.fetchVisualList({ date: filter.date }));

    const tasks = await dispatch(fetchVisualList(filter, false));

    // The loading state is to prevent [Fix Location] button from being clicked
    // before required data is fetched. The dispatch is put here because in other
    // cases, it's not necessary to wait for the additional info to be fetched.
    dispatch(setIsFetchingAdditionalInfo(true));
    await dispatch(fetchAdditionalInfo(ids, 75, false));
    dispatch(setIsFetchingAdditionalInfo(false));

    // Result from fetchVisualList does not contain job details,
    // so the job / base task will not be geocoded after fetching in the map view.
    // Solution: fetchAdditionalInfo will fetch the job details,
    // await fetchAdditionalInfo and get tasks from redux state instead.
    const state = getState();
    const taskIds = tasks.map(task => task.id);
    const { features_setting: featuresSetting, id: accountId } = state.account.account;
    const useCrs = featuresSetting?.coordinate_retrieval_service === featuresSettings.enabled
                || featuresSetting?.coordinate_retrieval_service === undefined;
    const tasksToCheck = taskIds.map(id => state.task.tasks[id]);
    await checkLatLngTask(tasksToCheck, useCrs, accountId);
  };
}

function fetchPending(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}/pending`, { method: 'GET' });
    dispatch(receiveTask(result.task));
  };
}

function fetchExportTags() {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/tasks/export/tags', { method: 'GET' });
    dispatch(receiveExportTags(result.export_tags));
  };
}

function fetchSystemTags() {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/tasks/system_tags', { method: 'GET' });
    dispatch(receiveSystemTags(result.tags));
  };
}

function fetchAutoAllocateList(filter, options = {}) {
  return async (dispatch) => {
    const queryString = Utils.RequestHelper.convertDataToParams({
      archived: false,
      ...filter,
    });
    const result = await Utils.RequestHelper.request(`/tasks${queryString}`, { method: 'GET', ...options });
    dispatch(receiveAutoAllocateTasks(result.tasks));
    return result;
  };
}

function fetchPresetReportTypes() {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/tasks/preset_reports/list', { method: 'GET' });
    dispatch(receivePresetReportTypes(result.preset_report_types));
  };
}

async function generatePresetReport(query) {
  const result = await Utils.RequestHelper.request('/tasks/preset_reports', {
    method: 'POST',
  }, {
    task: query,
  });

  return result;
}

async function exportTasks(query) {
  const result = await Utils.RequestHelper.request('/tasks/export', {
    method: 'POST',
  }, {
    task: query,
  });
  return result;
}

function checkExportTaskCount(data) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(
      '/tasks/export/task_count', { method: 'POST' }, data,
    );
    dispatch(receiveExportTaskCountSuccessful(result.task_count));
  };
}

function resetExportTaskCount() {
  return (dispatch) => {
    dispatch(receiveResetExportTaskCount());
  };
}

function deleteTask(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}`, { method: 'DELETE' });
    dispatch(fetchArchivedList());
    return result;
  };
}

function multipleDelete(ids) {
  return async (dispatch) => {
    const data = { ids };
    const result = await Utils.RequestHelper.request(
      '/tasks/multiple_delete', { method: 'DELETE' }, data,
    );
    dispatch(fetchArchivedList());
    return result;
  };
}

const receiveCancelSuccessful = () => ({ type: 'RECEIVE_TASK_CANCEL_SUCCESSFUL' });

function cancel(id, note) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}/cancel`, { method: 'PUT' }, { note });
    dispatch(receiveCancelSuccessful(id));
    dispatch(fetch(id));
    return result;
  };
}

function requestPin(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/tasks/${id}/request_new_pin`, { method: 'GET' });
    dispatch(receiveTask(result.task));
    dispatch(fetchRecipientVerificationHistory(id));
    return result;
  };
}

function fetchMembershipDetails(id) {
  return async () => {
    const result = await Utils.RequestHelper.request(`/tasks/get_membership_details/${id}`, {
      method: 'GET',
    });
    return result;
  };
}

// Date can be a string or array. YYYY-MM-DD format
// Will fetch all the addresses of the tasks within the date range.
function fetchAddressesOptionList(date) {
  return async (dispatch, getState) => {
    const { isFetchingOptionAddresses } = getState().task;

    if (isFetchingOptionAddresses) {
      return;
    }

    try {
      dispatch(setIsFetchingOptionAddresses(true));
      const queryString = Utils.RequestHelper.convertDataToParams({ date });

      // `result` can have duplicate results
      let result = await Utils.RequestHelper.request(
        `/tasks/task_contact_person/list${queryString}`, { method: 'GET' },
      );
      result = Helper.uniqBy(result.tasks, 'contact_person');

      dispatch(receiveOptionAddresses(result));
    } finally {
      dispatch(setIsFetchingOptionAddresses(false));
    }
  };
}

export default {
  setFilter,
  fetch,
  fetchList,
  fetchAdditionalInfo,
  fetchCompletionHistories,
  fetchCustomFields,
  fetchPendingList,
  fetchVisualList,
  fetchArchivedList,
  fetchVisualData,
  create,
  updateTask,
  update,
  updateInvoice,
  updateCompleteLocation,
  tagMultiple,
  editMultiple,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  unassign,
  unassignMultiple,
  print,
  printMultiple,
  printTaskLabel,
  printTaskLabelMultiple,
  duplicate,
  submitAll,
  submitMultiple,
  submit,
  complete,
  restart,
  assign,
  assignMultiple,
  transfer,
  transferMultiple,
  allocate,
  allocateMultiple,
  unallocate,
  unallocateMultiple,
  acceptAllocationMultiple,
  rejectAllocationMultiple,
  updateMeasurement,
  validate,
  fetchTotalFailedTasksCount,
  fetchFailedTasks,
  fetchIncompletionReasonsOptionList,
  fetchTaskHistory,
  fetchRecipientVerificationHistory,
  fetchExportTags,
  fetchSystemTags,
  fetchPending,
  exportTasks,
  fetchPresetReportTypes,
  generatePresetReport,
  checkExportTaskCount,
  receiveUpdatingMultipleVersaDrive,
  resetExportTaskCount,
  delete: deleteTask,
  multipleDelete,
  cancel,
  requestPin,
  fetchAutoAllocateList,
  fetchMembershipDetails,
  fetchAddressesOptionList,
};
