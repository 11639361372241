import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

const Block = (props) => {
  let levelClassName = 'vf-form__block--level-';
  let iconLevelClassName = 'vf-form__block__icon--level-';
  let contentLevelClassName = 'vf-form__block__content--level-';
  switch (props.level) {
    case 1:
      levelClassName += 'one';
      iconLevelClassName += 'one';
      contentLevelClassName += 'one';
      break;
    case 2:
      levelClassName += 'two';
      iconLevelClassName += 'two';
      contentLevelClassName += 'two';
      break;
    case 3:
      levelClassName += 'three';
      iconLevelClassName += 'three';
      contentLevelClassName += 'three';
      break;
    case 0:
    default:
      levelClassName = '';
      iconLevelClassName = '';
      contentLevelClassName = '';
      break;
  }

  let fontColorClassName = 'vf-form__block__content--';
  switch (props.fontColor) {
    case 'GREY':
      fontColorClassName += 'grey';
      break;
    case 'BLUE':
      fontColorClassName += 'blue';
      break;
    case 'TEXT':
    default:
      fontColorClassName += 'text';
      break;
  }

  let fontWeightClassName = 'vf-form__block__content--';
  switch (props.fontWeight) {
    case 'LIGHT':
      fontWeightClassName += 'light';
      break;
    case 'REGULAR':
      fontWeightClassName += 'regular';
      break;
    case 'BOLD':
      fontWeightClassName += 'bold';
      break;
    default:
      fontWeightClassName = '';
  }
  const className = classNames('vf-form__block', levelClassName,
    props.className);

  const iconContainerClassName = classNames('vf-form__block__icon', iconLevelClassName,
    props.iconContainerClassName);

  const contentClassName = classNames('vf-form__block__content', contentLevelClassName,
    fontColorClassName,
    fontWeightClassName,
    props.contentClassName);

  let icon = false;
  if (!Helper.isNullOrUndefined(props.icon)) {
    icon = props.icon;
  } else if (props.iconClassName) {
    icon = (
      <div className={iconContainerClassName}>
        <i className={props.iconClassName}>{props.iconText}</i>
      </div>
    );
  }

  return (
    <div className={className}>
      {icon}
      <div className={contentClassName}>
        {props.children}
      </div>
      <div className="vf-form__block__required">
        {props.isRequired ? '*' : false}
      </div>
    </div>
  );
};

Block.displayName = 'Form Block';

Block.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  fontColor: PropTypes.oneOf(['GREY', 'BLUE', 'TEXT']),
  fontWeight: PropTypes.oneOf(['LIGHT', 'REGULAR', 'BOLD']),
  icon: PropTypes.node,
  iconClassName: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  iconText: PropTypes.string,
  isRequired: PropTypes.bool,
  level: PropTypes.oneOf([0, 1, 2, 3]),
};

Block.defaultProps = {
  children: null,
  className: null,
  contentClassName: null,
  fontColor: 'TEXT',
  fontWeight: null,
  icon: null,
  iconClassName: null,
  iconContainerClassName: null,
  iconText: null,
  isRequired: false,
  level: 0,
};

export default Block;
