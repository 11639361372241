import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  selectedId: null,
  users: {},
  userIds: [],
  archivedUserIds: [],
  userMeta: {},
  archivedUserMeta: {},
  filter: {},
};

function user(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_USER':
      return {
        ...state,
        users: ReduxHelper.mergeDataWithObject(state.users, action.user),
      };
    case 'RECEIVE_USERS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          userIds: action.users.map(_user => _user.id),
          users: ReduxHelper.mergeDataWithList(state.users, action.users),
          userMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_USERS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedUserIds: action.users.map(_user => _user.id),
          users: ReduxHelper.mergeDataWithList(state.users, action.users),
          archivedUserMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_USER_UPDATE_SUCCESSFUL':
      return {
        ...state,
        users: ReduxHelper.mergeDataWithObject(state.users, action.user),
        selectedId: action.user.id,
      };
    case 'SET_USER_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default user;
