import React from 'react';
import PropTypes from 'prop-types';
import Item from './item';

const Title = props => (
  <div className="vf-form__title">
    {props.children}
  </div>
);

Title.displayName = 'Form Title';

Title.propTypes = {
  children: PropTypes.node,
};

Title.defaultProps = {
  children: null,
};
Title.Item = Item;

export default Title;
