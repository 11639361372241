import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Color from 'utils/color';
import NewDialog from 'utils/newDialog';
import TranslationHelper from 'utils/translationHelper';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';
import { connect } from 'react-redux';
import { Utils, translate } from 'versafleet-core';
import DeleteSuccessMessage from './deleteSuccessMessage';

const SECONDS_LIMIT = 4;

class TaskDeleteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: SECONDS_LIMIT,
      isDeleting: false,
    };
  }

  componentDidMount() {
    this._decrementTimer();
  }

  componentWillUnmount() {
    if (!Helper.isNullOrUndefined(this.timerId)) {
      clearTimeout(this.timerId);
    }
  }

  _decrementTimer() {
    this.timerId = setTimeout(() => {
      this.setState(prevState => ({ seconds: prevState.seconds - 1 }));
      if (this.state.seconds > 0) {
        this._decrementTimer();
      }
      this.timerId = null;
    }, 1000);
  }

  _cancel = () => {
    NewDialog.DialogManager.close();
  }

  _delete = async () => {
    const dataIds = this.props.dataIds;
    this.setState({ isDeleting: true });
    const action = this.props.dataIds.length > 1
      ? ReduxActions.task.multipleDelete(this.props.dataIds)
      : ReduxActions.task.delete(this.props.dataIds[0]);
    try {
      await this.props.dispatch(action);
      this.setState({ isDeleting: false });
      NewDialog.DialogManager.close();
      Utils.SuccessMessage.SuccessMessageManager.flash((
        <DeleteSuccessMessage
          count={dataIds.length}
        />
      ));
    } catch (error) {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const { dataIds } = this.props;
    const strings = this.props.strings.deleteDialog;
    return (
      <div className="vf-task__delete-dialog">
        <div className="vf-task__delete-dialog__header">
          <Component.CircleIcon
            className="vf-task__delete-dialog__header__icon"
            iconClassName="versa-completed"
            innerIconColor={Color.$red}
            outerIconColor={Color.$redlighter}
          />
          <span className="vf-task__delete-dialog__header__text">
            {
              TranslationHelper.replaceTag(strings.header, {
                count: dataIds.length,
              })
            }
          </span>
        </div>

        <div className="vf-task__delete-dialog__description">
          {strings.line1}
          <br />
          <br />
          {strings.line2}
        </div>

        <div className="vf-task__delete-dialog__buttons">
          <Component.Button
            color={Component.Button.Color.RED}
            disabled={this.state.seconds > 0}
            iconClassName="versa-delete"
            loading={this.state.isDeleting}
            onClick={this._delete}
          >
            {
              this.state.isDeleting ? strings.isDeleting
                : TranslationHelper.replaceTag(strings.confirmButton, {
                  countdown: this.state.seconds > 0 ? ` (${this.state.seconds})` : '',
                })
            }
          </Component.Button>
          <Component.Button
            className="vf-task__delete-dialog__buttons__cancel"
            onClick={this._cancel}
          >
            {strings.cancelButton}
          </Component.Button>
        </div>
      </div>
    );
  }
}

TaskDeleteDialog.displayName = 'Task Delete Dialog';

TaskDeleteDialog.propTypes = {
  dataIds: PropTypes.arrayOf(PropTypes.number),
  dispatch: PropTypes.func.isRequired,
  strings: PropTypes.shape({
    deleteDialog: PropTypes.string,
  }).isRequired,
};

TaskDeleteDialog.defaultProps = {
  dataIds: [],
};

export default connect()(translate('Task')(TaskDeleteDialog));
