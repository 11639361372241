import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  customers: {},
  isFetchingOptionCustomers: false,
  optionCustomers: [],
  customerIds: [],
  archivedCustomerIds: [],
  customerMeta: {},
  archivedCustomerMeta: {},
  filter: {},
};

function customer(state = initialState, action) {
  switch (action.type) {
    case 'SET_IS_FETCHING_OPTION_CUSTOMERS':
      return {
        ...state,
        isFetchingOptionCustomers: action.isFetchingOptionCustomers,
      };
    case 'SET_CUSTOMER_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    case 'RECEIVE_CUSTOMER':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithObject(
          state.customers, {
            ...action.customer,
            billingAccountIds: action.customer.billing_accounts.map(
              billingAccount => billingAccount.id,
            ),
          },
        ),
      };
    case 'RECEIVE_CUSTOMER_EPOD_SETTING':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithObject(state.customers, action.customer),
      };
    case 'RECEIVE_CUSTOMER_TRACKING_SETTING':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithObject(state.customers, action.customer),
      };
    case 'RECEIVE_CUSTOMER_MYJOBS_SETTING':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithObject(state.customers, action.customer),
      };
    case 'RECEIVE_CUSTOMERS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          customerIds: action.customers.map(_customer => _customer.id),
          customers: ReduxHelper.mergeDataWithList(state.customers, action.customers),
          customerMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_CUSTOMERS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedCustomerIds: action.customers.map(_customer => _customer.id),
          customers: ReduxHelper.mergeDataWithList(state.customers, action.customers),
          archivedCustomerMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_OPTION_CUSTOMERS':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithList(
          state.customers, action.customers,
        ),
        optionCustomers: action.customers,
      };
    case 'RECEIVE_CUSTOMER_UPDATE_SUCCESSFUL':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithObject(
          state.customers, action.customer,
        ),
      };
    case 'RECEIVE_ARCHIVED_BILLING_ACCOUNTS': {
      // for every customer (if previously fetched) of billing accounts that have been archived,
      // remove billing_accounts array in customer state (remove possibly outdated information)
      const currentState = { ...state };
      const customerIds = [...new Set(action.billingAccounts
        .map(billingAccount => billingAccount.customer && billingAccount.customer.id)),
      ];

      customerIds.forEach((customerId) => {
        if (!Helper.isNullOrUndefined(state.customers[customerId])) {
          currentState.customers[customerId].billing_accounts = undefined;
        }
      });

      return {
        ...currentState,
      };
    }
    case 'RECEIVE_CUSTOMER_BILLING_ACCOUNTS':
      return {
        ...state,
        customers: ReduxHelper.mergeDataWithObject(
          state.customers, {
            id: action.customerId,
            billing_accounts: action.billingAccounts,
            billingAccountIds: action.billingAccounts.map(billingAccount => billingAccount.id),
          },
        ),
      };
    default:
      return state;
  }
}

export default customer;
