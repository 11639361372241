import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';
import authorize from 'authorize';

const Item = (props) => {
  const className = classNames('vf-form__title__item', {
    'vf-form__title__item--highlighted': props.isHighlighted,
    'vf-form__title__item--clickable': !Helper.isNullOrUndefined(props.onClick),
  });
  return (
    <div className={className} id={props.id} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

Item.displayName = 'Form Title Item';

Item.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  children: null,
  id: null,
  isHighlighted: false,
  onClick: null,
};

export default authorize('')(Item);
