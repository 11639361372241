import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Formatter from 'utils/formatter';
import NewDialog from 'utils/newDialog';
import Color from 'utils/color';
import { connect } from 'react-redux';
import Router from 'utils/router';
import ReduxActions from 'reduxActions';
import { Components, Utils, translate } from 'versafleet-core';
import TranslationHelper from 'utils/translationHelper';
import AccessRightHelper from 'utils/accessRightHelper';
import CancelDialog from './cancel/cancelDialog';
import TaskGroupItem from '../task/groupItem';
import { withRouterHooks } from '../../utils/router';

const { Helper } = Utils;

class JobViewForm extends Component.Form {
  constructor(props) {
    super(props);
    this.mainActions = ReduxActions.job;
    this.basePath = this.props.basePath || 'jobs';
    this.state = {
      ...this.state,
      disableButton: { print: false },
      isDuplicating: false,
      isDuplicatingSuccess: false,
      duplicatedJobId: undefined,
    };
  }

  _closeForm = () => {
    this.props.navigate('..');
  }

  // eslint-disable-next-line class-methods-use-this
  _isPointToPoint(data) {
    return data.job_type === 'delivery' && data.tags
    && data.tags.filter(tag => tag.name === 'Point-to-Point').length > 0;
  }

  _approve = () => {
    if (!Helper.isNullOrUndefined(this.props.setIsApproving)) {
      this.props.setIsApproving(true, async () => {
        await this.props.dispatch(ReduxActions.job.approve(this.props.data.id));
        this.props.setIsApproving(false);
      });
    } else {
      this.props.dispatch(ReduxActions.job.approve(this.props.data.id));
    }
  }

  _disapprove = () => {
    this.props.dispatch(ReduxActions.job.disapprove(this.props.data.id));
  }

  async _archiveTask(id) {
    await this.props.dispatch(ReduxActions.task.archive(id));
  }

  _duplicate() {
    const strings = this.props.strings.Job.form.buttons;
    NewDialog.DialogManager.confirm(
      strings.duplicateConfirm.confirmation,
      () => {
        this.setState({
          isDuplicating: true,
        }, async () => {
          try {
            const returnedValue = await this.props.dispatch(
              ReduxActions.job.duplicate(this.props.data.id),
            );

            this.setState({
              duplicatedJobId: returnedValue.job.id,
              isDuplicatingSuccess: true,
              isDuplicating: false,
            }, () => {
              this._showDuplicateSuccessMessage();
            });

            setTimeout(() => {
              this.setState({
                isDuplicatingSuccess: false,
              });
            }, 2000);
          } catch (e) {
            this.setState({
              isDuplicatingSuccess: false,
              isDuplicating: false,
            });

            // TODO: show error
          }
        });
      },
    );
  }

  _showDuplicateSuccessMessage() {
    Utils.SuccessMessage.SuccessMessageManager.flash((
      <div className="vf-job__duplicate-success-message">
        <div className="vf-job__duplicate-success-message__icon">
          <i className="versa-completed" />
        </div>
        <div>
          {
            this.props.strings.Job.page.duplicateSuccess
          }
        </div>
        <div
          className="vf-job__duplicate-success-message__duplicate-go-to-job"
          onClick={() => {
            Router.transitionTo(`/planning/tasks/jobs/${this.state.duplicatedJobId}`);
          }}
        >
          {
            this.props.strings.Job.page.duplicateGoToJob
          }
        </div>
      </div>
    ), 6000);
  }

  _cancel = () => {
    NewDialog.DialogManager.open(<CancelDialog dataIds={[this.props.data.id]} shouldPopRoute />);
  }

  // eslint-disable-next-line class-methods-use-this
  _viewJobEditForm() {
    Router.transitionFromSubpath(null, 'edit');
  }

  // eslint-disable-next-line class-methods-use-this
  _viewTaskViewForm(task) {
    Router.transitionTo(`planning/tasks/${task.id}`);
  }

  _archive = () => {
    this.props.dispatch(ReduxActions.job.archive(this.props.data.id));
  }

  _disableButton(buttonName) {
    this.setState(prevState => ({
      disableButton: { ...prevState.disableButton, [buttonName]: true },
    }), async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.setState(prevState => ({
        disableButton: { ...prevState.disableButton, [buttonName]: false },
      }));
    });
  }

  _renderMenu() {
    if (!Helper.isNullOrUndefined(this.props.data)
        && !Helper.isNullOrUndefined(this.props.data.customer)) {
      return (
        <Component.Form.Title>
          <Component.Form.Title.Item isHighlighted>
            {this.props.data.customer.name}
          </Component.Form.Title.Item>
        </Component.Form.Title>
      );
    }

    return false;
  }

  _renderPickUpDeliveryInfo() {
    const strings = this.props.strings.Job.constants;
    const stringsPickupInfo = this.props.strings.Job.form.pickupInfo;
    const jobTypeStrings = this.props.strings.Job.form.jobType;

    const address = this.props.data.base_task.address;
    const time = Formatter.getInlineRangeDateTime(this.props.data.base_task.time_from,
      this.props.data.base_task.time_to).split(', ');
    const isToday = (moment().format('DD MMMM YYYY') === time[1] ? ` (${strings.today})` : '');

    const replaceMap = {
      one: <b>{strings.one}</b>,
      many: <b>{strings.many}</b>,
    };

    let description;

    if (this._isPointToPoint(this.props.data)) {
      description = TranslationHelper.replaceTag(jobTypeStrings.p2pDescription, replaceMap);
    } else if (this.props.data.job_type === 'delivery') {
      description = TranslationHelper.replaceTag(jobTypeStrings.deliveryDescription, replaceMap);
    } else {
      description = TranslationHelper.replaceTag(jobTypeStrings.pickupDescription, replaceMap);
    }

    /* eslint-disable max-len */
    return (
      <div>
        <Component.Form.Block level={1}>{jobTypeStrings.title}</Component.Form.Block>
        <Component.Form.Block level={2}>
          <div className="vf-job__view-form__radio-details">
            <span>
              {
                this._isPointToPoint(this.props.data) ? 'Point-to-Point'
                  : Formatter.capitalizeFirstLetter(this.props.data.job_type)
              }
            </span>
            <span className="vf-job__view-form__radio-details__description">
              {description}
            </span>
          </div>
        </Component.Form.Block>

        <Component.Form.Divider size="LARGE" />

        <Component.Form.Block level={1}>
          {this.props.data.job_type === 'delivery' ? stringsPickupInfo.pickupFrom
            : stringsPickupInfo.deliveryTo}
        </Component.Form.Block>

        <Component.Form.Block iconClassName="versa-marker-plain" level={2}>
          <div>{address.name}</div>
          <div>{address.line_1}</div>
          <div>{address.line_2}</div>
          <div>{address.city}</div>
          <div>{address.zip}</div>
          <div>{address.country}</div>
          <div>{[address.latitude, address.longitude].filter(n => !Helper.isNullOrUndefined(n)).join(', ')}</div>
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block iconClassName="versa-calendar-day" level={2}>
          {time[1] + isToday}
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block iconClassName="versa-time" level={2}>
          {time[0]}
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block iconClassName="versa-person" level={2}>
          {address.contact_person || stringsPickupInfo.noContactPerson}
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block iconClassName="versa-call" level={2}>
          {Helper.isNonEmptyString(address.country_code)
            ? `+${address.country_code}${address.contact_number || ''}`
            : address.contact_number || stringsPickupInfo.noContactNumber}
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block iconClassName="versa-email" level={2}>
          {address.email || stringsPickupInfo.noEmail}
        </Component.Form.Block>
      </div>
    );
    /* eslint-enable */
  }

  _renderAttachmentPhoto(photo, i) {
    const strings = this.props.strings.Job.form.attachment;

    const createdAt = moment(photo.created_at).format('DD MMM YYYY [at] hh:mm A');
    const updatedAt = moment(photo.updated_at).format('DD MMM YYYY [at] hh:mm A');

    return (
      <div className="vf-job__view-form__attachment__photo" key={i}>
        {Utils.Helper.isImageFileName(photo.name) ? (
          <img
            alt=""
            className="vf-job__view-form__attachment__photo__image"
            src={photo.url}
          />
        ) : (
          <Components.Svg>
            <Components.Svg.Use
              fill={Color.$blue}
              height={30}
              href="versa-file-text-o"
            />
          </Components.Svg>
        )}
        <div className="vf-job__view-form__attachment__photo__details">
          <div className="vf-job__view-form__attachment__photo__details__name">
            <a
              href={photo.url}
              target="blank"
            >
              {photo.name}
            </a>
          </div>
          <div>{`${strings.added} ${createdAt}`}</div>
          <div>{`${strings.lastUpdated} ${updatedAt}`}</div>
        </div>
      </div>
    );
  }

  _renderServiceTime() {
    const { account, data } = this.props;
    const strings = this.props.strings.Job.form.additionalInfo.serviceTime;
    const addOns = account.addons_setting || {};

    if (!addOns.vroom || !addOns.scheduler || this.basePath !== 'jobs'
      || Helper.isNullOrUndefined(data.base_task.service_time)) {
      return false;
    }

    return (
      <Component.Form.Block fontColor="TEXT" level={2}>
        <Component.Form.Divider />
        {`${strings.title}: ${Helper.roundToOneDecimalPlace(data.base_task.service_time / 60)} ${strings.mins}`}
      </Component.Form.Block>
    );
  }

  _renderAdditionalInfo() {
    const strings = this.props.strings.Job;
    const attachments = (this.props.data.attachments || []).map((photo, i) => (
      this._renderAttachmentPhoto(photo, i)
    ));

    return (
      <Component.Form.Dropdown
        id={!Helper.isNullOrUndefined(this.props.id)
          ? `${this.props.id}__left-panel__additional-info` : undefined}
        parent={(
          <Component.Form.Block level={1}>Additional Info</Component.Form.Block>
        )}
        showByDefault={false}
      >

        <Component.Form.Block iconClassName="versa-tags" level={2}>
          <Component.Tags tags={(this.props.data.tags || []).map(tag => tag.name).filter(tag => tag !== 'Point-to-Point')} />
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block
          className="vf-job__view-form__additional-info__remarks"
          iconClassName="versa-note"
          level={2}
        >
          {this.props.data.remarks || strings.form.additionalInfo.noRemarks}
        </Component.Form.Block>
        <Component.Form.Divider />
        <Component.Form.Block iconClassName="versa-attachment" level={2}>
          <div>
            {(this.props.data.attachments || []).length
            || strings.constants.none}
            {' '}
            {strings.form.attachment.attachmentPlural}

          </div>
          {attachments}
        </Component.Form.Block>
        {this._renderServiceTime()}

        <Component.Form.Divider size="MEDIUM" />

      </Component.Form.Dropdown>
    );
  }

  _renderJobInfo() {
    const { strings } = this.props;
    const subtitle = this.props.jobInfoSubtitle || (
      <div>
        <div>{this.props.data.guid}</div>
        <div>
          {strings.Job.status[this.props.data.state]}
        </div>
      </div>
    );
    const baseTaskIcon = this.props.data.job_type === 'delivery' ? 'versa-base-delivery' : 'versa-base-pickup';
    const icon = this.props.jobInfoIcon || baseTaskIcon;
    const iconColor = this.props.jobInfoIconColor || Color.$help;
    const iconContainerClassName = this.props.jobInfoIconContainerClassName
      || undefined;
    const iconText = this.props.jobInfoIconText || undefined;
    const title = this.props.jobInfoTitle || (
      (this.props.data && this.props.data.customer)
      && this.props.data.customer.name
    );

    return (
      <Component.Form.Dropdown
        id={!Helper.isNullOrUndefined(this.props.id)
          ? `${this.props.id}__left-panel__main-info` : undefined}
        parent={(
          <Component.Form.MainHeader
            iconClassName={icon}
            iconContainerClassName={iconContainerClassName}
            iconText={iconText}
            innerIconColor={iconColor}
            subtitle={subtitle}
            title={title}
          />
        )}
      >
        <Component.Form.Divider size="MEDIUM" />
        {this._renderPickUpDeliveryInfo()}
        <Component.Form.Divider size="LARGE" />
        {this._renderAdditionalInfo()}
        <Component.Form.Divider />
      </Component.Form.Dropdown>
    );
  }

  _renderTaskList() {
    const strings = this.props.strings.Job.constants;
    let count = 0;
    const tasks = (this.props.data.tasks || []).map((task) => {
      if (!task.archived) {
        count += 1;
        return this._renderTask(task, count);
      }
      return undefined;
    });

    return (
      <div>
        <Component.Form.MultipleGroupHeader fontWeight="BOLD" level={0}>
          {`${Formatter.capitalizeFirstLetter(strings.taskPlural)} (${count})`}
        </Component.Form.MultipleGroupHeader>
        {tasks}
      </div>
    );
  }

  _renderTask(task, index) {
    const { accessRights } = this.props;
    const isTaskEditAllowed = AccessRightHelper.hasAccess(accessRights, 'task.edit');
    const isTaskViewAllowed = AccessRightHelper.hasAccess(accessRights, 'task.view');
    return (
      <TaskGroupItem
        account={this.props.account}
        afterArchive={() => { this.props.dispatch(ReduxActions.job.fetch(this.props.data.id)); }}
        id={!Helper.isNullOrUndefined(this.props.id)
          ? `${this.props.id}__left-panel__task-info__item` : undefined}
        index={index}
        job={this.props.data}
        key={`taskList-${index}`}
        modelName={this.props.strings.Common.models.task.singular}
        onArchive={(this.props.isTaskEditable && !this.props.data.archived && isTaskEditAllowed)
          ? () => this._archiveTask(task.id)
          : null}
        onDelete={async () => {
          await this.props.dispatch(ReduxActions.task.delete(task.id));
        }}
        onEdit={(this.props.isTaskEditable && !this.props.data.archived
          && (isTaskEditAllowed || isTaskViewAllowed))
          ? () => this._viewTaskViewForm(task)
          : null}
        task={task}
      />
    );
  }

  _print = () => {
    this.props.dispatch(ReduxActions.job.print(this.props.data.id));
    this._disableButton('print');
  }

  _renderLeftPanel() {
    if (!Helper.isNullOrUndefined(this.props.data)) {
      return (
        <Component.Form.LeftPanel>
          {this._renderJobInfo()}
          <Component.Form.Divider size="LARGE" />
          {this._renderTaskList()}
        </Component.Form.LeftPanel>
      );
    }

    return (
      <Component.Form.LeftPanel />
    );
  }

  _renderButton() {
    const strings = this.props.strings.Job.form.buttons;
    if (this.props.renderButton) {
      return this.props.renderButton();
    }

    if (!Helper.isNullOrUndefined(this.props.data)) {
      if (this.props.data.state === 'submitted'
          || this.props.data.state === 'draft') {
        return (
          <div>
            <Component.Button.Done
              hint={null}
              id={!Helper.isNullOrUndefined(this.props.id)
                ? `${this.props.id}__right-panel__approve-button` : null}
              onClick={this._approve}
            >
              {strings.approve}
            </Component.Button.Done>
            <Component.Button.Cancel
              hint={null}
              id={!Helper.isNullOrUndefined(this.props.id)
                ? `${this.props.id}__right-panel__disapprove-button` : null}
              onClick={this._disapprove}
            >
              {strings.disapprove}
            </Component.Button.Cancel>
          </div>
        );
      }

      let archiveButton = false;

      const isArchiveAllowed = ((this.props.data.state === 'completed'
          && AccessRightHelper.hasAccess(this.props.accessRights, 'job.edit_after_completed'))
        || (this.props.data.state !== 'completed'
          && AccessRightHelper.hasAccess(this.props.accessRights, 'job.edit')));

      if (isArchiveAllowed && this.props.data.archived) {
        archiveButton = (
          <Component.Unarchive
            accessRightKey="job.edit"
            id={!Helper.isNullOrUndefined(this.props.id)
              ? `${this.props.id}__right-panel__unarchive-button` : null}
            modelName={this.props.strings.Common.models.job.singular}
            onClick={() => this._unarchive()}
            withDefaultText
          />
        );
      } else if (isArchiveAllowed) {
        archiveButton = (
          <Component.Archive
            archive={this._archive}
            archivePath={() => Router.transitionTo('/archives/tasks/jobs')}
            ids={[this.props.data.id]}
            modelName={this.props.strings.Job.constants.job}
            updateContent={() => {
              const path = Router.getPath();
              if (path.indexOf('visual') !== -1) {
                this.props.dispatch(ReduxActions.task.fetchVisualList());
                this.props.dispatch(ReduxActions.runsheet.fetchVisualList());
              } else {
                this.props.dispatch(ReduxActions.job.fetchList());
              }
              this._closeForm();
            }}
          >
            <Component.Button
              hint={null}
              iconClassName="versa-archive"
              id={!Helper.isNullOrUndefined(this.props.id)
                ? `${this.props.id}__right-panel__archive-button` : null}
            >
              {strings.archive}
            </Component.Button>
          </Component.Archive>
        );
      }

      let duplicateText;
      if (this.state.isDuplicating) {
        duplicateText = strings.duplicating;
      } else if (this.state.isDuplicatingSuccess) {
        duplicateText = strings.duplicated;
      } else {
        duplicateText = strings.duplicate;
      }

      return (
        <div>
          {
            !this.props.data.archived
            && !this.props.data.has_any_allocated_task
            && (AccessRightHelper.hasAccess(this.props.accessRights, 'job.edit_after_completed')
              || this.props.data.state !== 'completed')
              ? (
                <Component.Button.Edit
                  accessRightKey="job.edit"
                  hint={null}
                  id={!Helper.isNullOrUndefined(this.props.id)
                    ? `${this.props.id}__right-panel__edit-button` : null}
                  onClick={this._viewJobEditForm}
                >
                  {strings.edit}
                </Component.Button.Edit>
              ) : false
          }
          {!this.props.data.archived ? (
            <Component.Button
              accessRightKey="job.edit"
              color={this.state.isDuplicatingSuccess
                ? Component.Button.Color.GREEN
                : Component.Button.Color.BLUE}
              hint={null}
              iconClassName={this.state.isDuplicatingSuccess ? 'versa-completed' : 'versa-copy'}
              id="vf-job-form__right-panel__versa-copy"
              loading={this.state.isDuplicating}
              onClick={() => this._duplicate()}
            >
              {duplicateText}
            </Component.Button>
          ) : false}
          {
            !this.props.data.archived
              && this.props.data.state !== 'cancelled'
              && !this._isPointToPoint(this.props.data)
              ? (
                <Component.Button.Add
                  accessRightKey="task.edit"
                  hint={null}
                  id={!Helper.isNullOrUndefined(this.props.id)
                    ? `${this.props.id}__right-panel__add-task-button` : null}
                  onClick={() => Router.transitionFromSubpath(null, 'tasks/new')}
                >
                  {strings.addTask}
                </Component.Button.Add>
              ) : false
          }
          <Component.Button.Print
            disabled={this.state.disableButton.print}
            id={!Helper.isNullOrUndefined(this.props.id)
              ? `${this.props.id}__right-panel__print-button` : null}
            onClick={this._print}
            withDefaultText
          />
          {archiveButton}
          {
            !this.props.data.archived && this.props.data.cancellable && this.props.data.state !== 'cancelled'
             && AccessRightHelper.hasAccess(this.props.accessRights, 'job.edit') ? (
               <Component.Button
                 iconClassName="versa-danger"
                 id={!Helper.isNullOrUndefined(this.props.id)
                   ? `${this.props.id}__right-panel__cancel-button` : null}
                 onClick={this._cancel}
               >
                 {strings.cancel}
               </Component.Button>
              ) : false
          }
        </div>
      );
    }

    return false;
  }
}

JobViewForm.displayName = 'Job View Form';

JobViewForm.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.shape()),
  account: PropTypes.shape({}),
  basePath: PropTypes.string,
  data: PropTypes.shape({}),
  id: PropTypes.string,
  isTaskEditable: PropTypes.bool,
  jobInfoIcon: PropTypes.string,
  jobInfoIconColor: PropTypes.string,
  jobInfoIconContainerClassName: PropTypes.string,
  jobInfoIconText: PropTypes.string,
  jobInfoSubtitle: PropTypes.node,
  jobInfoTitle: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  renderButton: PropTypes.func,
  setIsApproving: PropTypes.func,
  strings: PropTypes.shape({}).isRequired,
};

JobViewForm.defaultProps = {
  isTaskEditable: true,
  setIsApproving: null,
};

function mapStateToProps(state) {
  const { account, job, profile } = state;
  return {
    accessRights: profile.profile.access_rights || [],
    account: account.account,
    data: job.jobs[Router.getParams().id],
  };
}

export default withRouterHooks(connect(mapStateToProps)(translate('')(JobViewForm)));
