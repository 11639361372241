import { store } from 'reduxActions';
import NotificationActions from 'reduxActions/notification';
import JobActions from 'reduxActions/job';
import TaskActions from 'reduxActions/task';
import PusherHelper from 'utils/pusherHelper';

export function initialisePusherEvents() {
  PusherHelper.channel.account.bind('new-notification', () => {
    store.dispatch(NotificationActions.updateNewNotifications(true));
  });

  PusherHelper.channel.user.bind('reset-notification-count', () => {
    store.dispatch(NotificationActions.loadNewCount());
  });

  PusherHelper.channel.account.bind('pending-job-update', () => {
    store.dispatch(JobActions.checkPendingJobCount());
  });

  PusherHelper.channel.user.bind('task-submitted', (result) => {
    console.log({
      message: 'Received pusher event: task-submitted',
      level: 'info',
      extra: {
        request_id: result.message.request_id,
      },
      timestamp: new Date().toString(),
    });
    store.dispatch(TaskActions.receiveUpdatingMultipleVersaDrive());
  });
}

export default initialisePusherEvents;
