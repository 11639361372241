import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonCancel = props => (
  <Button
    hint={props.withDefaultText ? null : props.strings.text}
    iconClassName="versa-close"
    {...props}
  >
    {props.withDefaultText ? props.strings.text : props.children}
  </Button>
);

ButtonCancel.displayName = 'Button Cancel';

ButtonCancel.propTypes = {
  children: PropTypes.node,
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonCancel.defaultProps = {
  children: null,
  withDefaultText: false,
};

export default translate('Component.Button.Cancel')(ButtonCancel);
