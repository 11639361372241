import React from 'react';
import PropTypes from 'prop-types';
import authorize from 'authorize';

const Divider = (props) => {
  let sizeClassName = 'vf-form__divider--';
  switch (props.size) {
    case 'SMALL':
      sizeClassName += 'small';
      break;
    case 'MEDIUM':
      sizeClassName += 'medium';
      break;
    case 'LARGE':
      sizeClassName += 'large';
      break;
    case 'REGULAR':
    default:
      sizeClassName = '';
      break;
  }
  const className = classNames('vf-form__divider', sizeClassName,
    props.className);

  return (
    <div className={className} />
  );
};

Divider.displayName = 'Form Divider';

Divider.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['SMALL', 'REGULAR', 'MEDIUM', 'LARGE']),
};

Divider.defaultProps = {
  className: null,
  size: 'REGULAR',
};

export default authorize('')(Divider);
