import Helper from 'utils/helper';
import Router from 'utils/router';
import { Utils } from 'versafleet-core';

const { RequestHelper: { convertDataToParams, request } } = Utils;

// Notification Setting
function receiveNotificationSettings(result) {
  return {
    type: 'RECEIVE_NOTIFICATION_SETTING',
    notificators: result,
  };
}

function receiveNotificationSettingCreateSuccessful(result) {
  return {
    type: 'RECEIVE_NOTIFICATION_SETTING_CREATE_SUCCESSFUL',
    notificator: result,
  };
}

function receiveNotificationSettingUpdateSuccessful(result) {
  return {
    type: 'RECEIVE_NOTIFICATION_SETTING_UPDATE_SUCCESSFUL',
    notificator: result,
  };
}

// Job Notification Setting
function receiveJobNotificationSettings(result) {
  return {
    type: 'RECEIVE_JOB_NOTIFICATION_SETTING',
    jobNotificators: result,
  };
}

function receiveJobNotificatorsUpdateSuccessful(result) {
  return {
    type: 'RECEIVE_JOB_NOTIFICATION_SETTING_UPDATE_SUCCESSFUL',
    jobNotificator: result,
  };
}

// Task Notification Setting
function receiveTaskNotificationSettings(result) {
  return {
    type: 'RECEIVE_TASK_NOTIFICATION_SETTING',
    taskNotificators: result,
  };
}

function receiveSystemTags(result) {
  return {
    type: 'RECEIVE_SYSTEM_TAGS',
    systemTags: result,
  };
}

function receiveTaskNotificatorsUpdateSuccessful(result) {
  return {
    type: 'RECEIVE_TASK_NOTIFICATION_SETTING_UPDATE_SUCCESSFUL',
    taskNotificator: result,
  };
}

function resetError() {
  return {
    type: 'RESET_NOTIFICATOR_ERROR',
  };
}

// Notification Setting
function fetchNotificationSetting() {
  return async (dispatch) => {
    const result = await request('/notificators', {
      method: 'GET',
    });
    dispatch(receiveNotificationSettings(result.notificators));
    return result.notificators;
  };
}

function createNotificationSetting(query) {
  return async (dispatch) => {
    const result = await request('/notificators', {
      method: 'POST',
    }, query);
    dispatch(receiveNotificationSettingCreateSuccessful(result.notificator));
  };
}

function updateNotificationSetting(id, query) {
  return async (dispatch) => {
    const result = await request(`/notificators/${id}`, {
      method: 'PUT',
    }, query);
    dispatch(receiveNotificationSettingUpdateSuccessful(result.notificator));
  };
}

function deleteNotificationSetting(id) {
  return async (dispatch) => {
    await request(`/notificators/${id}`, {
      method: 'DELETE',
    });
    dispatch(fetchNotificationSetting());
  };
}

function fetchJobNotificationSetting() {
  return async (dispatch) => {
    const result = await request('/job_notification_settings', {
      method: 'GET',
    });
    dispatch(receiveJobNotificationSettings(result.job_notification_settings));
    return result.job_notification_setting;
  };
}

function updateJobNotificationSetting(query, path) {
  return async (dispatch) => {
    const result = await request('/job_notification_settings', {
      method: 'PUT',
    }, query);
    dispatch(receiveJobNotificatorsUpdateSuccessful(result.job_notification_setting));
    if (!Helper.isNullOrUndefined(path)) {
      Router.transitionTo(`/settings/notifications/${path}`);
    }
  };
}

function fetchJobSystemTags() {
  return async (dispatch) => {
    const result = await request('/job_notification_settings/system_tags', {
      method: 'GET',
    });
    dispatch(receiveSystemTags(result.tags));
    return result.tags;
  };
}
// Task Notification Setting
function fetchTaskNotificationSetting() {
  return async (dispatch) => {
    const result = await request('/task_notification_settings', {
      method: 'GET',
    });
    dispatch(receiveTaskNotificationSettings(result.task_notification_settings));
    return result.task_notification_settings;
  };
}

function updateTaskNotificationSetting(query, path) {
  return async (dispatch) => {
    const result = await request('/task_notification_settings', {
      method: 'PUT',
    }, query);
    dispatch(receiveTaskNotificatorsUpdateSuccessful(result.task_notification_setting));
    if (!Helper.isNullOrUndefined(path)) {
      Router.transitionTo(`/settings/notifications/${path}`);
    }
  };
}

function fetchTaskSystemTags(query = {}) {
  return async (dispatch) => {
    const result = await request(
      `/task_notification_settings/system_tags${convertDataToParams(query)}`,
      {
        method: 'GET',
      },
    );
    dispatch(receiveSystemTags(result.tags));
    return result.tags;
  };
}

function sendSMSPreview(query) {
  return async () => {
    await request('/utils/preview/sms', {
      method: 'POST',
    }, query);
  };
}

function sendEmailPreview(query) {
  return async () => {
    await request('/utils/preview/email', {
      method: 'POST',
    }, query);
  };
}

export default {
  fetchNotificationSetting,
  createNotificationSetting,
  updateNotificationSetting,
  deleteNotificationSetting,
  fetchJobNotificationSetting,
  updateJobNotificationSetting,
  fetchJobSystemTags,
  fetchTaskNotificationSetting,
  updateTaskNotificationSetting,
  fetchTaskSystemTags,
  sendSMSPreview,
  sendEmailPreview,
  resetError,
};
