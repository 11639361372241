import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

const GroupItemEditButton = (props) => {
  const { onEdit } = props;
  const id = Helper.isNullOrUndefined(props.id)
    ? undefined
    : `${props.id}__edit-button`;

  return (
    <div className="vf-form__group-item__buttons__edit" id={id} onClick={onEdit}>
      <i className="versa-edit" />
    </div>
  );
};

GroupItemEditButton.displayName = 'Group Item Edit Button';

GroupItemEditButton.propTypes = {
  id: PropTypes.string,
  onEdit: PropTypes.func,
};

GroupItemEditButton.defaultProps = {
  id: null,
  onEdit: null,
};

export default GroupItemEditButton;
