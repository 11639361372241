import { Utils } from 'versafleet-core';

const { RequestHelper: { convertDataToParams, request } } = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function setFilter(filter) {
  return {
    type: 'SET_BILLING_ACCOUNT_FILTER',
    filter,
  };
}

function setIsFetchingOptionBillingAccounts(isFetchingOptionBillingAccounts) {
  return {
    type: 'SET_IS_FETCHING_OPTION_BILLING_ACCOUNTS',
    isFetchingOptionBillingAccounts,
  };
}

function receiveAccountSuccessful(billingAccount) {
  return {
    type: 'RECEIVE_BILLING_ACCOUNT',
    billingAccount,
  };
}

function receiveAccountsSuccessful(billingAccounts, meta) {
  return {
    type: 'RECEIVE_BILLING_ACCOUNTS',
    billingAccounts,
    meta,
  };
}

function receiveOptionAccountsSuccessful(optionBillingAccounts) {
  return {
    type: 'RECEIVE_OPTION_BILLING_ACCOUNTS',
    optionBillingAccounts,
  };
}

function receiveArchivedBillingAccounts(result) {
  return {
    type: 'RECEIVE_ARCHIVED_BILLING_ACCOUNTS',
    billingAccounts: result.billing_accounts,
    meta: result.meta,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/billing_accounts/${id}`, {
      method: 'GET',
    });
    dispatch(receiveAccountSuccessful(result.billing_account));
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const state = getState();
    const data = {
      page: state.billingAccount.billingAccountMeta.page,
      ...baseFilter,
      ...state.billingAccount.filter,
      archived: false,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/billing_accounts${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveAccountsSuccessful(result.billing_accounts, result.meta));
  };
}

function fetchOptionList() {
  return async (dispatch, getState) => {
    const { isFetchingOptionBillingAccounts } = getState().billingAccount;

    if (isFetchingOptionBillingAccounts) {
      return;
    }

    try {
      dispatch(setIsFetchingOptionBillingAccounts(true));

      const result = await request('/billing_accounts/list', {
        method: 'GET',
      });
      dispatch(receiveOptionAccountsSuccessful(result.billing_accounts));
    } finally {
      dispatch(setIsFetchingOptionBillingAccounts(false));
    }
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/billing_accounts/${id}`, {
      method: 'PUT',
    }, {
      billing_account: query,
    });
    dispatch(receiveAccountSuccessful(result.billing_account));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/billing_accounts', {
      method: 'POST',
    }, {
      billing_account: query,
    });
    dispatch(receiveAccountSuccessful(result.billing_account));
  };
}

function archive(id) {
  return async () => {
    await request(`/billing_accounts/${id}/archive`, {
      method: 'PUT',
    });
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const state = getState();
    const data = {
      page: state.billingAccount.archivedBillingAccountMeta.page,
      ...baseFilter,
      ...state.billingAccount.filter,
      archived: true,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/billing_accounts${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedBillingAccounts(result));
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/billing_accounts/${id}/unarchive`, {
      method: 'PUT',
    });
    dispatch(receiveAccountSuccessful(result.billing_account));
    dispatch(fetchArchivedList());
  };
}

export default {
  setFilter,
  fetch,
  fetchList,
  fetchOptionList,
  update,
  create,
  archive,
  unarchive,
  fetchArchivedList,
};
