import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

class InputMultipleGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingIndex: -1,
      isAddingNewGroup: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.isChanged || this.props.isChanged !== nextProps.isChanged;
  }

  addNewItem() {
    this._setIsAddingNewGroup(true);
    this.setState({
      editingIndex: -1,
    });
  }

  _setIsAddingNewGroup = (isAddingNewGroup) => {
    this.setState({
      isAddingNewGroup,
    });
  }

  _onSubmitNewGroup(data) {
    this.props.onChange(data);
    this._setIsAddingNewGroup(false);
  }

  _onCancelNewGroup() {
    this._setIsAddingNewGroup(false);
  }

  _onEdit(index) {
    this.setState({
      editingIndex: index,
    });
  }

  _onDelete(value, index) {
    this.props.onDelete(value, index);
  }

  _onSubmit(data) {
    const { editingIndex } = this.state;

    this.props.onChange(data, editingIndex);
    this.setState({
      editingIndex: -1,
    });
  }

  _onCancel() {
    this.setState({
      editingIndex: -1,
    });
  }

  _renderAddNewGroupButton() {
    const { isAddingNewGroup, editingIndex } = this.state;
    const { addButtonHintText, addButtonText, shouldRenderAddButton } = this.props;

    if (!shouldRenderAddButton) {
      return false;
    }

    if (!isAddingNewGroup && editingIndex === -1) {
      return (
        <Button.Add
          className="form__left-panel__header__button
                  form__left-panel__header__button--large-header"
          hint={addButtonHintText}
          id="vf-form__left-panel__add-new-group-button"
          onClick={this._setIsAddingNewGroup(true)}
        >
          {addButtonText}
        </Button.Add>
      );
    }

    return false;
  }

  _renderAddNewGroup() {
    const { isAddingNewGroup } = this.state;

    if (isAddingNewGroup) {
      return (
        React.cloneElement(this.props.editForm, {
          data: {},
          onSubmit: this._onSubmitNewGroup.bind(this),
          onCancel: this._onCancelNewGroup.bind(this),
        })
      );
    }
    return false;
  }

  render() {
    const { editingIndex, isAddingNewGroup } = this.state;

    const formGroups = this.props.values.map((value, index) => {
      if (index !== this.state.editingIndex) {
        // Render viewing form
        return (
          React.cloneElement(this.props.viewForm, {
            data: value,
            index,
            key: `view-${value.id}`,
            onEdit: this._onEdit.bind(this, index),
            onDelete: this._onDelete.bind(this, value, index),
            renderButtons: (editingIndex === -1 && !isAddingNewGroup),
          })
        );
      }
      // Render editing form
      return (
        React.cloneElement(this.props.editForm, {
          data: value,
          index,
          key: `edit-${value.id}`,
          onSubmit: this._onSubmit.bind(this),
          onCancel: this._onCancel.bind(this),
          renderButtons: true,
        })
      );
    });
    return (
      <div>
        {this._renderAddNewGroupButton()}
        {this._renderAddNewGroup()}
        {formGroups}
      </div>
    );
  }
}

InputMultipleGroups.displayName = 'Input Multiple Groups';

InputMultipleGroups.propTypes = {
  addButtonHintText: PropTypes.string,
  addButtonText: PropTypes.string,
  editForm: PropTypes.node,
  isChanged: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  shouldRenderAddButton: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  viewForm: PropTypes.node,
};

InputMultipleGroups.defaultProps = {
  addButtonHintText: '',
  addButtonText: 'Add',
  editForm: null,
  isChanged: null,
  onChange: null,
  onDelete: null,
  shouldRenderAddButton: true,
  viewForm: null,
};

export default InputMultipleGroups;
