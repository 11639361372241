import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Logo from './authentication/logo';

const LoginLoading = ({ text = null, children = null, withLoading = true }) => (
  <div className="vf-authentication__login-loading">
    <Logo className="vf-authentication__login-loading__logo" />
    {
      (children || text)
        ? (
          <div className="vf-authentication__login-loading__text">
            {children || text }
          </div>
        ) : false
    }
    { withLoading && <Component.EllipsisLoading className="vf-authentication__login-loading__ellipsis" />}
  </div>
);

LoginLoading.displayName = 'Login Loading';

LoginLoading.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  withLoading: PropTypes.bool,
};

export default (LoginLoading);
