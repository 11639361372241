import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonExport = props => (
  <Button
    hint={props.withDefaultText ? null : props.strings.text}
    iconClassName="versa-download"
    {...props}
  >
    {props.withDefaultText ? props.strings.text : props.children}
  </Button>
);

ButtonExport.displayName = 'Button Export';

ButtonExport.propTypes = {
  children: PropTypes.node,
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonExport.defaultProps = {
  children: null,
  withDefaultText: false,
};

export default translate('Component.Button.Export')(ButtonExport);
