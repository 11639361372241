import React from 'react';
import PropTypes from 'prop-types';
import NewDialog from 'utils/newDialog';
import Component from 'components/component';
import Color from 'utils/color';
import TranslationHelper from 'utils/translationHelper';
import ReduxActions from 'reduxActions';
import { connect } from 'react-redux';
import { Utils, translate } from 'versafleet-core';
import CancelSuccessMessage from './cancelSuccessMessage';
import { withRouterHooks } from '../../../utils/router';

class JobCancelDialog extends React.Component {
  _cancel = async () => {
    if (this.props.dataIds.length === 1) {
      try {
        await this.props.dispatch(ReduxActions.job.cancel(this.props.dataIds[0]));
        NewDialog.DialogManager.close();
        Utils.SuccessMessage.SuccessMessageManager.flash(<CancelSuccessMessage count={1} />);
        if (this.props.shouldPopRoute) {
          this.props.navigate('..');
        }
      } catch (error) {
        NewDialog.DialogManager.close();
        Utils.SuccessMessage.SuccessMessageManager.flash(
          <div className="vf-job__cancel-dialog__error-message">
            {TranslationHelper.replaceTag(this.props.strings.cancelDialog.errorMessage, {
              count: 1,
            })}
          </div>,
        );
      }
    }
  }

  render() {
    const strings = this.props.strings.cancelDialog;
    return (
      <div className="vf-job__cancel-dialog">
        <div className="vf-job__cancel-dialog__header">
          <Component.CircleIcon
            className="vf-job__cancel-dialog__header__icon"
            iconClassName="versa-warning"
            innerIconColor={Color.$red}
            outerIconColor={Color.$redlighter}
          />
          <span className="vf-job__cancel-dialog__header__text">
            {strings.header}
          </span>
        </div>

        <div className="vf-job__cancel-dialog__description">
          {strings.line1}
          <br />
          <br />
          {strings.line2}
        </div>

        <div className="vf-job__cancel-dialog__buttons">
          <Component.Button
            className="vf-job__cancel-dialog__buttons__cancel"
            onClick={() => NewDialog.DialogManager.close()}
          >
            {strings.cancelButton}
          </Component.Button>
          <Component.Button
            className="vf-job__cancel-dialog__buttons__confirm"
            color={Component.Button.Color.GOLD}
            onClick={this._cancel}
          >
            {strings.confirmButton}
          </Component.Button>
        </div>
      </div>
    );
  }
}

JobCancelDialog.displayName = 'Task Delete Dialog';

JobCancelDialog.propTypes = {
  dataIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  dispatch: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldPopRoute: PropTypes.bool,
  strings: PropTypes.shape({
    cancelDialog: PropTypes.shape(),
    page: PropTypes.shape(),
  }).isRequired,
};

JobCancelDialog.defaultProps = {
  shouldPopRoute: false,
};

export default withRouterHooks(connect()(translate('Job')(JobCancelDialog)));
