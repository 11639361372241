import React from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import Helper from 'utils/helper';
import Button from './authorizedButton';

class ButtonUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this._onFinish = this._onFinish.bind(this);
    this.uploaderInput = null;
  }

  _onFinish = (url, file) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({
        url: url.baseUrl,
        name: file.name,
      });
    }

    this._setLoading(false);
  }

  _setLoading(loading) {
    if (loading !== this.state.loading) {
      this.setState({ loading });
    }
  }

  _uploadFile = () => {
    this.uploaderInput.click();
  }

  render() {
    const nonLoadingButtonText = Helper.isNonEmptyString(this.props.url) ? this.props.changeText
      : this.props.uploadText;
    const buttonText = this.state.loading ? this.props.loadingText : nonLoadingButtonText;
    const hintText = this.state.loading ? null : nonLoadingButtonText;
    const nonLoadingIconClassName = Helper.isNonEmptyString(this.props.url) ? 'versa-edit' : 'versa-upload';
    const iconClassName = this.state.loading ? null : nonLoadingIconClassName;

    return (
      <div className="vf-button-upload">
        {this.props.editable && !this.state.loading
          ? (
            <ReactS3Uploader
              accept="image/*"
              className="vf-button-upload__uploader"
              inputRef={(node) => { this.uploaderInput = node; }}
              onFinish={this._onFinish}
              onProgress={() => this._setLoading(true)}
              signingUrl={`${process.env.apiUrl}/files/new_upload`}
              uploadRequestHeaders={{}}
            />
          ) : false}
        <Button
          disabled={this.state.loading}
          hint={hintText}
          iconClassName={iconClassName}
          loading={this.state.loading}
          onClick={this._uploadFile}
          showShadow={!this.state.loading}
        >
          {buttonText}
        </Button>
      </div>
    );
  }
}

ButtonUpload.propTypes = {
  changeText: PropTypes.string,
  editable: PropTypes.bool,
  loadingText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  uploadText: PropTypes.string,
  url: PropTypes.string.isRequired,
};

ButtonUpload.defaultProps = {
  changeText: 'Change',
  editable: true,
  loadingText: 'Updating Logo',
  uploadText: 'Upload',
};

export default ButtonUpload;
