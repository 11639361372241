import * as Sentry from '@sentry/browser';
import PusherHelper from 'utils/pusherHelper';

const initialState = {
  profile: null,
  sso_enabled: false,
};

function SetSentryPerson(person) {
  Sentry.configureScope((scope) => {
    scope.setUser(person);
  });
}

// this below commented for https://versafleet.atlassian.net/browse/CP-283
// function SetSegmentData(data) {
//   analytics.identify(data.id, data);
// }
function SetAppcuesData(data) {
  window.Appcues.identify(data.id, data);
}

function profile(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CHECK_SSO_ENABLED_SUCCESSFUL':
      return {
        ...state,
        sso_enabled: action.sso_enabled,
        myjobs_sso_enabled: action.myjobs_sso_enabled,
        ssoDirectLogin: action.allow_direct_login,
      };
    case 'RECEIVE_CHECK_SSO_ENABLED_FAILED':
      return {
        ...state,
        sso_enabled: false,
        myjobs_sso_enabled: false,
      };
    case 'RECEIVE_LOGOUT_SUCCESSFUL':
      PusherHelper.channel.user.unsubscribe();
      PusherHelper.channel.account.unsubscribe();
      return {
        ...initialState,
        logout_url: action.url,
      };
    case 'RECEIVE_CHECK_SUCCESSFUL':
      SetSentryPerson({
        id: `vf_user_${action.profile.id}`,
        account_id: action.profile.account_id,
        username: action.profile.first_name,
        email: action.profile.email,
      });
      SetAppcuesData({
        id: action.profile.id,
        name: action.profile.first_name,
        email: action.profile.email,
        account_id: action.profile.account_id,
        version: global.versionNumber,
      });
      // this below commented for https://versafleet.atlassian.net/browse/CP-283
      // SetSegmentData({
      //   id: action.profile.id,
      //   name: action.profile.first_name,
      //   email: action.profile.email,
      //   account_id: action.profile.account_id,
      //   version: global.versionNumber,
      // });
      return {
        ...state,
        profile: action.profile,
      };
    case 'RECEIVE_CHECK_FAILED':
      return {
        ...state,
        profile: null,
      };
    case 'RECEIVE_PROFILE_UPDATE_SUCCESSFUL':
      return {
        ...state,
        profile: action.profile,
      };
    case 'RECEIVE_PROFILE':
      return {
        ...state,
        profile: action.profile,
      };
    default:
      return state;
  }
}

export default profile;
