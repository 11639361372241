const initialState = {
  notifications: [],
  panelNotifications: null,
  notificationMeta: {},
  newNotifications: false,
  filter: {},
};

function notification(state = initialState, action) {
  switch (action.type) {
    case 'SET_NOTIFICATION_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    case 'RECEIVE_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.notifications,
        notificationMeta: action.meta || state.notificationMeta,
      };
    case 'RECEIVE_PANEL_NOTIFICATIONS':
      return {
        ...state,
        panelNotifications: action.panelNotifications,
      };
    case 'RECEIVE_COUNT':
      return {
        ...state,
        newNotifications: action.count > 0,
      };
    case 'UPDATE_NEW_NOTIFICATIONS':
      return {
        ...state,
        newNotifications: action.newNotifications,
      };
    default:
      return state;
  }
}

export default notification;
