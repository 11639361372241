import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';

class TimeWindowButton extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.account.fetchTimeWindows());
  }

  _getSubTextTimeRange() {
    const { timeWindows, values } = this.props;
    const {
      time_window_id: timeWindowId,
      time_from: timeFrom,
      time_to: timeTo,
    } = values;

    const timeWindow = timeWindowId.length === 1
      ? timeWindows.find(tw => tw.id === timeWindowId[0])
      : null;
    const startTime = !Helper.isNullOrUndefined(timeWindow) ? timeWindow.time_from : timeFrom * 60;
    const endTime = !Helper.isNullOrUndefined(timeWindow) ? timeWindow.time_to : timeTo * 60;

    return `${Helper.getTimeFromSecond(startTime)} - ${Helper.getTimeFromSecond(endTime)}`;
  }

  render() {
    const {
      id, values, onChange, timeWindows, strings, className, filterClassName,
    } = this.props;
    const text = values.time_window_id.length < 1 || (values.time_window_id.length === 1)
      !== (!Helper.isNullOrUndefined(values.time_from) && !Helper.isNullOrUndefined(values.time_to))
      ? strings.timeWindow.item
      : `${values.time_window_id.length === timeWindows.length
        ? strings.filter.all
        : strings.filter.multiple} ${strings.timeWindow.itemPlural}`;

    const subText = (values.time_window_id.length === 1)
      !== (!Helper.isNullOrUndefined(values.time_from) && !Helper.isNullOrUndefined(values.time_to))
      ? this._getSubTextTimeRange()
      : null;

    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? null : `${id}__dropdown-button`}
        showArrow
        subText={subText}
        text={text}
      >
        <Component.ActionPanel.FilterTimeCheckbox
          className={filterClassName}
          id={Helper.isNullOrUndefined(id) ? null : `${id}__filter-time-checkbox`}
          onChange={(fieldName, v) => onChange(fieldName, v)}
          options={timeWindows}
          values={values}
        />
      </Component.ActionPanel.DropdownButton>
    );
  }
}

TimeWindowButton.displayName = 'Job Filter Time Window Button';

TimeWindowButton.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  strings: PropTypes.shape({
    filter: PropTypes.shape({
      all: PropTypes.string,
      multiple: PropTypes.string,
    }),
    timeWindow: PropTypes.shape({
      itemPlural: PropTypes.string,
      item: PropTypes.string,
    }),
  }).isRequired,
  timeWindows: PropTypes.arrayOf(PropTypes.shape()),
  values: PropTypes.shape({
    time_from: PropTypes.string,
    time_to: PropTypes.string,
    time_window_id: PropTypes.arrayOf(),
  }),
};

TimeWindowButton.defaultProps = {
  className: null,
  filterClassName: null,
  id: null,
  onChange: null,
  values: {},
  timeWindows: [],
};

function mapStateToProps(state) {
  const { account } = state;
  return {
    timeWindows: account.timeWindows,
  };
}

export default connect(mapStateToProps)(translate('Job')(TimeWindowButton));
