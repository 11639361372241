import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveScanToIdentifyTagsSuccessful(data) {
  return {
    type: 'RECEIVE_SCAN_TO_IDENTIFY_TAGS_SUCCESSFUL',
    data,
  };
}

function receiveScanToIdentifyConfigurationSuccessful(data) {
  return {
    type: 'RECEIVE_SCAN_TO_IDENTIFY_CONFIGURATION_SUCCESSFUL',
    data,
  };
}

function receiveScanToIdentifyConfigurationUpdateSuccessful(data) {
  return {
    type: 'RECEIVE_SCAN_TO_IDENTIFY_CONFIGURATION_UPDATE_SUCCESSFUL',
    data,
  };
}

function fetch() {
  return async (dispatch) => {
    const result = await request('/scan_to_identify_configurations', {
      method: 'GET',
    });

    dispatch(receiveScanToIdentifyConfigurationSuccessful(result));
  };
}

function update(query) {
  return async (dispatch) => {
    const result = await request('/scan_to_identify_configurations', {
      method: 'PUT',
    }, {
      scan_to_identify_configuration: query,
    });

    dispatch(receiveScanToIdentifyConfigurationUpdateSuccessful(result));
    return result;
  };
}

function fetchTags() {
  return async (dispatch) => {
    const result = await request('/scan_to_identify_configurations/tags', {
      method: 'GET',
    });

    dispatch(receiveScanToIdentifyTagsSuccessful(result));
  };
}

export default {
  fetch,
  fetchTags,
  update,
};
