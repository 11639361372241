import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveVersadriveSettings(versadriveSettings) {
  return {
    type: 'RECEIVE_VERSADRIVE_SETTINGS',
    versadriveSettings,
  };
}

function receiveVersadriveSettingsUpdateSuccessful(versadriveSettings) {
  return {
    type: 'RECEIVE_VERSADRIVE_SETTINGS_UPDATE_SUCCESSFUL',
    versadriveSettings,
  };
}

function receiveTaskIncompletionSettings(taskIncompletionSettings) {
  return {
    type: 'RECEIVE_TASK_INCOMPLETION_SETTINGS',
    taskIncompletionSettings,
  };
}

function receiveLineItemValidationSettings(lineItemValidationSettings) {
  return {
    type: 'RECEIVE_LINE_ITEM_VALIDATION_SETTINGS',
    lineItemValidationSettings,
  };
}

function receiveSystemTags(systemTags) {
  return {
    type: 'RECEIVE_SYSTEM_TAGS',
    systemTags,
  };
}

function fetchVersadriveSettings() {
  return async (dispatch) => {
    const result = await request('/versadrive_settings/versadrive_settings', {
      method: 'GET',
    });
    dispatch(receiveVersadriveSettings(result.versadrive_setting));
  };
}

function updateVersadriveSettings(query) {
  return async (dispatch) => {
    const result = await request('/versadrive_settings/versadrive_settings/update_settings', {
      method: 'PUT',
    }, query);
    dispatch(receiveVersadriveSettingsUpdateSuccessful(result.versadrive_setting));
  };
}

// Fetches the task incompletion toggle settings
function fetchTaskIncompletionReasonSettings() {
  return async (dispatch) => {
    const result = await request('/versadrive_settings/task_incompletion_reasons', {
      method: 'GET',
    });
    dispatch(receiveTaskIncompletionSettings(result.account));
  };
}

function updateTaskIncompletionSettings(query) {
  return async (dispatch) => {
    const result = await request('/versadrive_settings/task_incompletion_reasons/update_settings', {
      method: 'PUT',
    }, query);
    dispatch(receiveTaskIncompletionSettings(result.account));
  };
}

function fetchLineItemValidationSettings() {
  return async (dispatch) => {
    const result = await request('/line_item_validation_setting', { method: 'GET' });
    dispatch(receiveLineItemValidationSettings(result.line_item_validation_setting));
  };
}

function updateLineItemValidationSettings(query) {
  return async (dispatch) => {
    const result = await request(
      '/line_item_validation_setting',
      { method: 'PUT' },
      { line_item_validation_setting: query },
    );
    dispatch(receiveLineItemValidationSettings(result.line_item_validation_setting));
  };
}

function fetchSystemTags() {
  return async (dispatch) => {
    const result = await request('/versadrive_settings/versadrive_settings/system_tags', {
      method: 'GET',
    });

    dispatch(receiveSystemTags(result.tags));
  };
}

export default {
  fetchVersadriveSettings,
  updateVersadriveSettings,
  fetchTaskIncompletionReasonSettings,
  updateTaskIncompletionSettings,
  fetchLineItemValidationSettings,
  updateLineItemValidationSettings,
  fetchSystemTags,
};
