import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

const GroupItemDeleteButton = (props) => {
  const { onDelete } = props;
  const id = Helper.isNullOrUndefined(props.id)
    ? undefined
    : `${props.id}__delete-button`;

  return (
    <div className="vf-form__group-item__buttons__delete" id={id} onClick={onDelete}>
      <i className="versa-delete" />
    </div>
  );
};

GroupItemDeleteButton.displayName = 'Group Item Delete Button';

GroupItemDeleteButton.propTypes = {
  id: PropTypes.string,
  onDelete: PropTypes.func,
};

GroupItemDeleteButton.defaultProps = {
  id: null,
  onDelete: null,
};

export default GroupItemDeleteButton;
