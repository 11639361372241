import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { translate, Utils } from 'versafleet-core';
import LoginLoading from './loginLoading';

import '../../stylesheets/stringNameStylesheets/pages/errorBoundaryFallback.styl';

const ErrorBoundaryFallback = ({ error, strings }) => {
  // eslint-disable-next-line react/hook-use-state
  const [errorId] = useState(crypto.randomUUID());

  useEffect(() => {
    Sentry.setTag('referenceNumber', errorId);
    Sentry.captureException(new Error(error));
  }, []);

  return (
    <LoginLoading withLoading={false}>
      <div className="vf-error-boundary__container">
        <p className="vf-error-boundary__error-message">
          <span>{strings.description}</span>
          <span>{strings.description2}</span>
          {Utils.TranslationHelper.replaceTag(strings.description3, {
            errorId: (
              <b>{errorId}</b>
            ),
          })}
        </p>
        <button className="vf-error-boundary__button" onClick={() => window.location.replace('/home')}>
          {Utils.TranslationHelper.replaceTag(strings.backTo, {
            page: 'Home Page',
          })}
        </button>
      </div>
    </LoginLoading>
  );
};
export default translate('ErrorBoundary')(ErrorBoundaryFallback);

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.shape({}).isRequired,
  strings: PropTypes.shape({
    description: PropTypes.string.isRequired,
    description2: PropTypes.string.isRequired,
    description3: PropTypes.string.isRequired,
    backTo: PropTypes.string.isRequired,
  }).isRequired,
};
