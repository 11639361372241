const initialState = {
  vehicleSettings: null,
};

function vehicleSettings(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VEHICLE_SETTINGS':
      return {
        ...state,
        vehicleSettings: action.vehicleSettings,
      };
    case 'RECEIVE_VEHICLE_SETTINGS_UPDATE':
      return {
        ...state,
        vehicleSettings: action.vehicleSettings,
      };
    default:
      return state;
  }
}

export default vehicleSettings;
