import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  exportTemplates: [],
};

function exportTemplate(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_EXPORT_TEMPLATES':
      return {
        ...state,
        // Data is always overwritten to refresh list of templates
        exportTemplates: ReduxHelper.mergeDataWithList(
          [],
          action.exportTemplates,
        ),
      };
    case 'RECEIVE_EXPORT_TEMPLATE':
      return {
        ...state,
        exportTemplates:
          ReduxHelper.mergeDataWithObject(state.exportTemplates, action.exportTemplate),
      };
    case 'RECEIVE_EXPORT_TEMPLATE_UPDATE_SUCCESSFUL':
      return {
        ...state,
        exportTemplates:
          ReduxHelper.mergeDataWithObject(state.exportTemplates, action.exportTemplate),
      };
    default:
      return state;
  }
}

export default exportTemplate;
