import React from 'react';
import Button from './authorizedButton';

const ButtonMenu = props => (
  <Button
    iconClassName="versa-menu"
    {...props}
  />
);

ButtonMenu.displayName = 'Button Menu';

export default ButtonMenu;
