import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import Helper from 'utils/helper';

const ActionPanelFilterAddressButton = (props) => {
  const {
    id, value, onChange, strings, className, filterClassName, isDeliveryFilter,
  } = props;
  const addressAttribute = isDeliveryFilter ? 'delivery_address' : 'pickup_address';
  const text = strings[addressAttribute].text;
  const subText = !Helper.isNullOrUndefined(value) ? value.join(', ') : [];

  return (
    <Component.ActionPanel.DropdownButton
      className={className}
      id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
      showArrow
      subText={subText}
      text={text}
    >
      <Component.ActionPanel.FilterTagSearch
        className={filterClassName}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-tag-dropdown`}
        onChange={v => onChange(v)}
        placeholder={strings[addressAttribute].placeholder}
        value={value}
      />
    </Component.ActionPanel.DropdownButton>
  );
};

ActionPanelFilterAddressButton.displayName = 'Action Panel Filter Address Button';

ActionPanelFilterAddressButton.propTypes = {
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  isDeliveryFilter: PropTypes.bool,
  onChange: PropTypes.func,
  strings: PropTypes.shape({
    pickup_address: PropTypes.shape({
      text: PropTypes.string,
      placeholder: PropTypes.string,
    }).isRequired,
    delivery_address: PropTypes.shape({
      text: PropTypes.string,
      placeholder: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterAddressButton.defaultProps = {
  className: '',
  filterClassName: '',
  id: '',
  isDeliveryFilter: false,
  onChange: null,
  value: [],
};

export default translate('Task')(ActionPanelFilterAddressButton);
