import React from 'react';
import PropTypes from 'prop-types';
import NewDialog from 'utils/newDialog';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import authorize from 'authorize';
import DeleteDialog from './deleteDialog';

class TaskDeleteButton extends React.Component {
  _openDialog = () => {
    NewDialog.DialogManager.open((
      <DeleteDialog
        dataIds={[this.props.dataId]}
      />
    ));
  }

  render() {
    return (
      <Component.Button
        className="vf-task__delete-button"
        hint={this.props.strings.page.buttons.delete}
        iconClassName="versa-delete"
        id={this.props.id}
        onClick={this._openDialog}
      />
    );
  }
}

TaskDeleteButton.displayName = 'Task Delete Button';

TaskDeleteButton.propTypes = {
  dataId: PropTypes.number.isRequired,
  id: PropTypes.string,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

TaskDeleteButton.defaultProps = {
  id: '',
};

export default translate('Task')(authorize('')(TaskDeleteButton));
