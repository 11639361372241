import { Utils } from 'versafleet-core';

const {
  RequestHelper: {
    request,
  },
} = Utils;

function receiveExportTemplate(exportTemplate) {
  return {
    type: 'RECEIVE_EXPORT_TEMPLATE',
    exportTemplate,
  };
}

function receiveExportTemplates(exportTemplates) {
  return {
    type: 'RECEIVE_EXPORT_TEMPLATES',
    exportTemplates,
  };
}

function receiveExportTemplateUpdateSuccessful(exportTemplate) {
  return {
    type: 'RECEIVE_EXPORT_TEMPLATE_UPDATE_SUCCESSFUL',
    exportTemplate,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/export_templates/${id}`, {
      method: 'GET',
    });
    dispatch(receiveExportTemplate(result.export_template));
    return result.export_template;
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/export_templates', {
      method: 'GET',
    });
    dispatch(receiveExportTemplates(result.export_templates));
    return result.export_templates;
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/export_templates', {
      method: 'POST',
    }, {
      export_templates: query,
    });
    dispatch(receiveExportTemplateUpdateSuccessful(result.export_template));
    return result.export_template;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/export_templates/${id}`, {
      method: 'PUT',
    }, {
      export_templates: query,
    });
    dispatch(receiveExportTemplateUpdateSuccessful(result.export_template));
    dispatch(fetchList());
    return result.export_template;
  };
}

function deleteExportTemplate(id) {
  return async (dispatch) => {
    const result = await request(`/export_templates/${id}`, { method: 'DELETE' });
    dispatch(fetchList());
    return result;
  };
}

export default {
  fetch,
  receiveExportTemplate,
  fetchList,
  receiveExportTemplates,
  create,
  update,
  receiveExportTemplateUpdateSuccessful,
  deleteExportTemplate,
};
