import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';

class ActionPanelFilterCustomerButton extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.customer.fetchOptionCustomerList());
  }

  render() {
    const {
      id, value, onChange, optionCustomers, strings, className, filterClassName,
    } = this.props;
    const options = (optionCustomers || [])
      .map(option => ({ value: option.id, text: option.name }));

    let text;
    if (value.length <= 1) {
      text = strings.customer.item;
    } else {
      text = `${value.length > 1 && value.length < options.length
        ? strings.filter.multiple : strings.filter.all} ${strings.customer.itemPlural}`;
    }

    const subText = value.length === 1 && options.length > 0 ? options.find(option => value[0]
      === option.value).text : undefined;

    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
        showArrow
        subText={subText}
        text={text}
      >
        <Component.ActionPanel.FilterTagDropdown
          className={filterClassName}
          error={strings.customer.error}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-tag-dropdown`}
          onChange={v => onChange(v)}
          options={options}
          placeholder={strings.customer.placeholder}
          value={value}
        />
      </Component.ActionPanel.DropdownButton>
    );
  }
}

ActionPanelFilterCustomerButton.displayName = 'Action Panel Filter Customer Button';

ActionPanelFilterCustomerButton.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  optionCustomers: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    customer: PropTypes.shape({
      error: PropTypes.string,
      placeholder: PropTypes.string,
      item: PropTypes.string,
      itemPlural: PropTypes.string,
    }).isRequired,
    filter: PropTypes.shape({
      multiple: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
};

ActionPanelFilterCustomerButton.defaultProps = {
  className: '',
  filterClassName: '',
  id: '',
  onChange: null,
  optionCustomers: [],
  value: [],
};

function mapStateToProps(state) {
  const { customer } = state;
  return {
    optionCustomers: customer.optionCustomers,
  };
}

export default connect(mapStateToProps)(translate('Task')(ActionPanelFilterCustomerButton));
