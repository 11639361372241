import React from 'react';
import Component from 'components/component';
import PropTypes from 'prop-types';
import authorize from 'authorize';
import NewDialog from 'utils/newDialog';
import CancelWarningModal from '../cancelWarningModal';

class UnassignButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelWarningModal: false,
    };
  }

  _openConfirmDialog = () => {
    NewDialog.DialogManager.confirm('Are you sure you want to unassign the driver(s)?', this.props.onClick);
  }

  render() {
    const { onClick, ...restProps } = this.props;
    return (
      <React.Fragment>
        <Component.Button
          onClick={() => {
            if (this.props.haveCancelledTask) {
              this.setState({ showCancelWarningModal: true });
            } else {
              this._openConfirmDialog();
            }
          }}
          {...restProps}
        />
        <CancelWarningModal
          onClick={() => {
            this.setState({ showCancelWarningModal: false });
            this._openConfirmDialog();
          }}
          visible={this.state.showCancelWarningModal}
        />
      </React.Fragment>
    );
  }
}

UnassignButton.displayName = 'Task Unassign Button';

UnassignButton.propTypes = {
  haveCancelledTask: PropTypes.bool,
  onClick: PropTypes.func,
};

UnassignButton.defaultProps = {
  haveCancelledTask: false,
  onClick: null,
};

export default authorize('task.edit')(UnassignButton);
