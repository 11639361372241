import React from 'react';
import PropTypes from 'prop-types';
import { Components } from 'versafleet-core';
import Helper from 'utils/helper';
import EditButton from './editButton';
import DeleteButton from './deleteButton';
import SubmitButton from './submitButton';
import CancelButton from './cancelButton';

const GroupItem = (props) => {
  const {
    children, index, id, onDelete, onEdit, onSubmit, onCancel,
    onArchive, archivePath, afterArchive, modelName,
  } = props;

  let buttons = false;
  if (!Helper.isNullOrUndefined(onDelete) || !Helper.isNullOrUndefined(onEdit)
    || !Helper.isNullOrUndefined(onArchive)) {
    let deleteButton = false;
    if (!Helper.isNullOrUndefined(onArchive)) {
      deleteButton = (
        <Components.Archive
          archive={onArchive}
          archivePath={archivePath}
          delete={onDelete}
          ids={[index]}
          modelName={modelName}
          updateContent={afterArchive}
        >
          <div>
            <DeleteButton id={id} />
          </div>
        </Components.Archive>
      );
    } else if (!Helper.isNullOrUndefined(onDelete)) {
      deleteButton = <DeleteButton id={id} onDelete={onDelete} />;
    }

    buttons = (
      <div className="vf-form__group-item__buttons">
        {deleteButton}
        {!Helper.isNullOrUndefined(onEdit) ? <EditButton id={id} onEdit={onEdit} /> : false}
      </div>
    );
  } else if (!Helper.isNullOrUndefined(onSubmit) || (!Helper.isNullOrUndefined(onCancel))) {
    buttons = (
      <div className="vf-form__group-item__buttons">
        {!Helper.isNullOrUndefined(onSubmit) ? <SubmitButton id={id} onSubmit={onSubmit} /> : false}
        {!Helper.isNullOrUndefined(onCancel) ? <CancelButton id={id} onCancel={onCancel} /> : false}
      </div>
    );
  }

  const containerClassName = classNames({
    'vf-form__group-item': true,
  }, props.className);

  let groupItemIndex = false;
  if (!Helper.isNullOrUndefined(index) && typeof index === 'object') {
    groupItemIndex = index;
  } else if (!Helper.isNullOrUndefined(index) && typeof index === 'number') {
    groupItemIndex = <div className="vf-form__group-item__index">{`${index}.`}</div>;
  }

  return (
    <div className={containerClassName}>
      {groupItemIndex}
      <div className="vf-form__group-item__content">{children}</div>
      {buttons}
    </div>
  );
};

GroupItem.propTypes = {
  afterArchive: PropTypes.func,
  archivePath: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  modelName: PropTypes.string,
  onArchive: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
};

GroupItem.defaultProps = {
  afterArchive: null,
  archivePath: null,
  children: null,
  className: null,
  id: null,
  index: null,
  modelName: '',
  onArchive: null,
  onCancel: null,
  onDelete: null,
  onEdit: null,
  onSubmit: null,
};

export default GroupItem;
