import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;

const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  selectedId: null,
  drivers: {},
  driverIds: [],
  archivedDriverIds: [],
  isFetchingOptionDrivers: false,
  optionDrivers: [],
  driverHistory: [],
  driverMeta: {},
  archivedDriverMeta: {},
  filter: {},
  skills: [],
  systemTags: {},
};

function driver(state = initialState, action) {
  switch (action.type) {
    case 'REQUEST_DRIVER_LOCATION_HISTORY':
      return {
        ...state,
        driverHistory: [],
      };
    case 'REQUEST_VEHICLE_LOCATOR_HISTORY':
      return {
        ...state,
        driverHistory: [],
      };
    case 'RECEIVE_DRIVER':
      return {
        ...state,
        drivers: ReduxHelper.mergeDataWithObject(state.drivers, action.driver),
      };
    case 'RECEIVE_DRIVERS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          driverIds: action.drivers.map(_driver => _driver.id),
          drivers: ReduxHelper.mergeDataWithList(state.drivers, action.drivers),
          driverMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_OPTION_DRIVERS':
      return {
        ...state,
        optionDrivers: action.drivers,
      };
    case 'RECEIVE_DRIVER_SKILLS':
      return {
        ...state,
        skills: action.skills,
      };
    case 'RECEIVE_DRIVER_LOCATION_HISTORY':
      return {
        ...state,
        driverHistory: action.driverHistory,
      };
    case 'RECEIVE_ARCHIVED_DRIVERS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedDriverIds: action.drivers.map(_driver => _driver.id),
          drivers: ReduxHelper.mergeDataWithList(state.drivers, action.drivers),
          archivedDriverMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_DRIVER_UPDATE_SUCCESSFUL':
      return {
        ...state,
        drivers: ReduxHelper.mergeDataWithObject(state.drivers, action.driver),
        selectedId: action.driver.id,
      };
    case 'RECEIVE_DRIVER_SYSTEM_TAGS': {
      const customTags = (action.systemTags.custom_field_groups || []).map((item) => {
        const newTitle = item.title.split('-')[1];
        return { ...item, title: newTitle };
      });
      return {
        ...state,
        systemTags: { ...action.systemTags, custom_field_groups: customTags },
      };
    }
    case 'SET_IS_FETCHING_OPTION_DRIVERS':
      return {
        ...state,
        isFetchingOptionDrivers: action.isFetchingOptionDrivers,
      };
    case 'SET_DRIVER_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default driver;
