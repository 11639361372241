import { Utils } from 'versafleet-core';

const { RequestHelper: { request, requestCustomBody } } = Utils;

function receiveLayers(layers) {
  return {
    type: 'RECEIVE_LAYERS',
    layers,
  };
}

function receiveLayer(layer) {
  return {
    type: 'RECEIVE_LAYER',
    layer,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/layers/${id}`, {
      method: 'GET',
    });
    dispatch(receiveLayer(result.layers));
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/layers', {
      method: 'GET',
    });
    dispatch(receiveLayers(result.layers));
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/layers/${id}`, {
      method: 'PUT',
    }, query);
    dispatch(receiveLayer(result.layer));
  };
}

function deleteLayers(ids) {
  return async () => {
    await request('/layers/multiple_delete', {
      method: 'DELETE',
    }, {
      ids,
    });
  };
}

function importData(data) {
  return async () => {
    const result = await requestCustomBody('/layers/import', {
      method: 'POST',
    }, data);
    return result;
  };
}

export default {
  fetch,
  fetchList,
  update,
  import: importData,
  deleteList: deleteLayers,
};
