import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import ReduxActions from 'reduxActions';
import { connect } from 'react-redux';
import Helper from 'utils/helper';

class ActionPanelFilterIncompletionReasons extends React.Component {
  componentDidMount() {
    this.props.dispatch(
      ReduxActions.task.fetchIncompletionReasonsOptionList(this.props.date),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.props.dispatch(
        ReduxActions.task.fetchIncompletionReasonsOptionList(this.props.date),
      );
    }
  }

  render() {
    const {
      id, value, onChange, optionIncompletionReasons, strings, className, filterClassName,
    } = this.props;
    const options = optionIncompletionReasons.map(option => ({ value: option, text: option }));

    let text;
    if (value.length <= 1) {
      text = strings.incompletionReasons.item;
    } else {
      text = `${value.length > 1 && value.length < options.length
        ? strings.filter.multiple : strings.filter.all} ${strings.incompletionReasons.itemPlural}`;
    }

    const subText = value.length === 1 ? Helper.decodeURIComponentTagValue(value[0]) : undefined;
    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
        showArrow
        subText={subText}
        text={text}
      >
        <Component.ActionPanel.FilterTagDropdown
          className={filterClassName}
          error={strings.incompletionReasons.error}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-tag-dropdown`}
          onChange={v => onChange(v)}
          options={options}
          placeholder={strings.incompletionReasons.placeholder}
          value={value}
        />
      </Component.ActionPanel.DropdownButton>
    );
  }
}

ActionPanelFilterIncompletionReasons.displayName = 'Action Panel Filter Incompletion Reasons Button';

ActionPanelFilterIncompletionReasons.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  dispatch: PropTypes.func.isRequired,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  optionIncompletionReasons: PropTypes.arrayOf(PropTypes.string),
  strings: PropTypes.shape({
    filter: PropTypes.shape({
      multiple: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
    incompletionReasons: PropTypes.shape({
      error: PropTypes.string,
      placeholder: PropTypes.string,
      item: PropTypes.string,
      itemPlural: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterIncompletionReasons.defaultProps = {
  className: '',
  filterClassName: '',
  date: moment().format('YYYY-MM-DD'),
  id: '',
  onChange: null,
  optionIncompletionReasons: [],
  value: [],
};

function mapStateToProps(state) {
  const { task } = state;
  return {
    optionIncompletionReasons: task.incompletionReasons || [],
  };
}

export default connect(mapStateToProps)(translate('Task')(ActionPanelFilterIncompletionReasons));
