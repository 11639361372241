import Color from 'utils/color';
import { Utils } from 'versafleet-core';

const { RequestHelper: { convertDataToParams, request } } = Utils;

function receiveJobsProgressData(jobsProgressData) {
  return {
    type: 'RECEIVE_JOBS_PROGRESS_DATA',
    jobsProgressData,
  };
}

function receiveJobsCreationData(jobsCreationData) {
  return {
    type: 'RECEIVE_JOBS_CREATION_DATA',
    jobsCreationData,
  };
}

function receiveDriverAssignmentsData(driverAssignmentsData) {
  return {
    type: 'RECEIVE_DRIVER_ASSIGNMENTS_DATA',
    driverAssignmentsData,
  };
}

function receiveVehicleAssignmentsData(vehicleAssignmentsData) {
  return {
    type: 'RECEIVE_VEHICLE_ASSIGNMENTS_DATA',
    vehicleAssignmentsData,
  };
}

function receiveVehicleActivityData(vehicleActivityData) {
  return {
    type: 'RECEIVE_VEHICLE_ACTIVITY_DATA',
    vehicleActivityData,
  };
}

function receiveActivityFeedList(activityFeedData) {
  return {
    type: 'RECEIVE_ACTIVITY_FEED_DATA',
    activityFeedData,
  };
}

function processJobsProgressData(data) {
  return [{
    name: 'Unassigned',
    color: Color.getRgba(Color.$yellow, 0.5),
    y: data.unassigned,
  }, {
    name: 'In Progress',
    color: Color.$yellow,
    y: data.in_progress,
  }, {
    name: 'Completed',
    color: Color.$green,
    y: data.completed,
  }];
}

function fetchJobsProgressList(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/dashboards/job_progress${queryString}`, {
      method: 'GET',
    });
    const data = processJobsProgressData(result.job_progress);
    dispatch(receiveJobsProgressData(data));
  };
}

function processDefaultJobsCreationDataWeekly(query) {
  const categories = [];
  const data = [];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const fromDay = (new Date(query.from_date)).getDay();
  const toDay = (new Date(query.to_date)).getDay();

  for (let i = fromDay; i <= toDay; i += 1) {
    categories.push(days[i]);
    data.push(0);
  }

  return {
    categories,
    data,
  };
}

function processJobsCreationDataWeekly(jobsCreationObject, query) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const categories = [];
  const data = [];

  if (Object.keys(jobsCreationObject).length === 0) {
    // No data
    const result = processDefaultJobsCreationDataWeekly(query);
    return result;
  }

  Object.keys(jobsCreationObject).forEach((key) => {
    categories.push(days[(new Date(key)).getDay()]);
    data.push(jobsCreationObject[key]);
  });
  return {
    categories,
    data,
  };
}

function processJobsCreationDataMonthly(jobsCreationObject) {
  const categories = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];
  const data = [];
  for (let i = 0; i < 12; i += 1) {
    data.push(0);
  }

  Object.keys(jobsCreationObject).forEach((key) => {
    const d = new Date(key);
    const month = d.getMonth();
    data[month] += jobsCreationObject[key];
  });
  return {
    categories,
    data,
  };
}

function processJobsCreationData(jobsCreationObject, category, query) {
  const dataState = (category === 'Weekly')
    ? processJobsCreationDataWeekly(jobsCreationObject, query)
    : processJobsCreationDataMonthly(jobsCreationObject, query);

  return dataState;
}

function processJobsCreationPeriod(jobsCreationObject, category, query) {
  const dateKeys = Object.keys(jobsCreationObject);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const temp = (dateKeys.length === 0) ? {
    firstDate: new Date(query.from_date),
    lastDate: new Date(query.to_date),
  } : {
    firstDate: new Date(dateKeys[0]),
    lastDate: new Date(dateKeys[dateKeys.length - 1]),
  };
  const firstDate = temp.firstDate;
  const lastDate = temp.lastDate;

  const result = (firstDate.getMonth() === lastDate.getMonth()) // Same month
    ? (`${firstDate.getDate()} - ${lastDate.getDate()} ${months[firstDate.getMonth()]}`)
    : (`${firstDate.getDate()} ${months[firstDate.getMonth()]} - ${lastDate.getDate()} ${months[lastDate.getMonth()]}`);
  return result;
}

function fetchJobsCreationList(query, category) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/dashboards/job_creation${queryString}`, {
      method: 'GET',
    });
    const data = processJobsCreationData(result.job_creations, category, query);
    const period = processJobsCreationPeriod(result.job_creations, category, query);
    dispatch(receiveJobsCreationData({ data, period }));
  };
}

function processColumnChartData(resourceAssignmentData, type, period) {
  const name = (type === 'vehicle' ? 'plate_number' : 'name');
  const categories = [];
  const data = [];

  for (let i = 0; i < resourceAssignmentData.length; i += 1) {
    categories.push(resourceAssignmentData[i][name]);
    data.push(resourceAssignmentData[i].count);
  }

  const firstDate = moment(period.from_date, 'YYYY-MM-DD').toDate();
  const lastDate = moment(period.to_date, 'YYYY-MM-DD').toDate();
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const formattedPeriod = (firstDate.getMonth() === lastDate.getMonth()) // Same month
    ? (`${firstDate.getDate()} - ${lastDate.getDate()} ${months[firstDate.getMonth()]}`)
    : (`${firstDate.getDate()} ${months[firstDate.getMonth()]} - ${
      lastDate.getDate()} ${months[lastDate.getMonth()]}`);

  return {
    categories,
    data,
    period: formattedPeriod,
  };
}

function fetchAssignmentsList(query, type) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    if (type === 'driver') {
      const result = await request(`/dashboards/driver_assignment${queryString}`, {
        method: 'GET',
      });
      const data = processColumnChartData(result.driver_assignments, type, query);
      dispatch(receiveDriverAssignmentsData(data));
    } else if (type === 'vehicle') {
      const result = await request(`/dashboards/vehicle_assignment${queryString}`, {
        method: 'GET',
      });
      const data = processColumnChartData(result.vehicle_assignments, type, query);
      dispatch(receiveVehicleAssignmentsData(data));
    }
  };
}

function processVehicleActivityData(data) {
  return ([{
    name: 'Moving',
    color: Color.getRgba(Color.$yellow, 0.5),
    y: data.moving,
  }, {
    name: 'Idling',
    color: Color.$yellow,
    y: data.idling,
  }, {
    name: 'Offline',
    color: Color.$inactive,
    y: data.offline,
  }]);
}

function fetchVehicleActivityList() {
  return async (dispatch) => {
    const result = await request('/dashboards/vehicle_activity', {
      method: 'GET',
    });
    const data = processVehicleActivityData(result.vehicle_activity);
    dispatch(receiveVehicleActivityData(data));
  };
}

function fetchActivityFeedList() {
  return async (dispatch) => {
    const query = {
      date: new Date(),
      page: 1,
      per_page: 3,
    };
    const queryString = convertDataToParams(query);
    const result = await request(`/activity_feeds${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveActivityFeedList(result.activity_feeds));
  };
}

export default {
  fetchJobsProgressList,
  fetchJobsCreationList,
  fetchAssignmentsList,
  fetchVehicleActivityList,
  fetchActivityFeedList,
};
