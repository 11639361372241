import Router from 'utils/router';
import { Utils } from 'versafleet-core';

const { RequestHelper: { request, convertDataToParams } } = Utils;

function receiveCustomFieldGroupUpdateSuccessful(customFieldGroup) {
  return {
    type: 'RECEIVE_CUSTOM_FIELD_GROUP_UPDATE_SUCCESSFUL',
    customFieldGroup,
  };
}

function receiveCustomFieldGroup(customFieldGroup) {
  return {
    type: 'RECEIVE_CUSTOM_FIELD_GROUP',
    customFieldGroup,
  };
}

function receiveCustomFieldGroups(customFieldGroups) {
  return {
    type: 'RECEIVE_CUSTOM_FIELD_GROUPS',
    customFieldGroups,
  };
}

function receiveOptionCustomFieldGroups(customFieldGroups) {
  return {
    type: 'RECEIVE_OPTION_CUSTOM_FIELD_GROUPS',
    customFieldGroups,
  };
}

function receiveCustomFieldDescription(customFieldDescription) {
  return {
    type: 'RECEIVE_CUSTOM_FIELD_DESCRIPTION',
    customFieldDescription,
  };
}

function setIsFetchingOptionCustomFieldGroups(isFetchingOptionCustomFieldGroups) {
  return {
    type: 'SET_IS_FETCHING_OPTION_CUSTOM_FIELD_GROUPS',
    isFetchingOptionCustomFieldGroups,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/custom_field_groups/${id}`, {
      method: 'GET',
    });
    dispatch(receiveCustomFieldGroup(result.custom_field_group));
  };
}

function fetchList(query) {
  return async (dispatch) => {
    const param = convertDataToParams(query);
    const result = await request(`/custom_field_groups${param}`, {
      method: 'GET',
    });
    dispatch(receiveCustomFieldGroups(result.custom_field_groups));
  };
}

function fetchOptionCustomFieldGroupList(query) {
  return async (dispatch, getState) => {
    const { isFetchingOptionCustomFieldGroups } = getState().customFieldGroup;

    if (isFetchingOptionCustomFieldGroups) {
      return;
    }

    try {
      dispatch(setIsFetchingOptionCustomFieldGroups(true));

      const param = convertDataToParams(query);
      const result = await request(`/custom_field_groups/list${param}`, {
        method: 'GET',
      });
      dispatch(receiveOptionCustomFieldGroups(result.custom_field_groups));
    } finally {
      dispatch(setIsFetchingOptionCustomFieldGroups(false));
    }
  };
}

function archive(id) {
  return async (dispatch) => {
    const result = await request(`/custom_field_groups/${id}/archive`, {
      method: 'PUT',
    });
    dispatch(receiveCustomFieldGroup(result.custom_field_group));
    dispatch(fetchList());
    Router.transitionFromSubpath('custom_fields');
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/custom_field_groups/${id}`, {
      method: 'PUT',
    }, {
      custom_field_group: query,
    });
    Router.transitionFromSubpath('custom_fields');
    dispatch(receiveCustomFieldGroupUpdateSuccessful(result.custom_field_group));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/custom_field_groups', {
      method: 'POST',
    }, {
      custom_field_group: query,
    });
    dispatch(receiveCustomFieldGroupUpdateSuccessful(result));
    dispatch(fetchList());
    Router.transitionFromSubpath('custom_fields');
    return result;
  };
}

function fetchCustomFieldDescription(id, filter = {}) {
  return async (dispatch) => {
    const queryString = convertDataToParams({ ...filter });
    const result = await request(`/custom_field_descriptions/${id}${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveCustomFieldDescription(result.custom_field_description));
  };
}

export default {
  fetch,
  fetchList,
  fetchOptionCustomFieldGroupList,
  archive,
  update,
  create,
  fetchCustomFieldDescription,
};
