import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';
import { Components } from 'versafleet-core';

const MainHeader = (props) => {
  const {
    iconClassName, iconContainerClassName, iconText, subtitle, title,
    innerIconColor, outerIconColor, strokeColor, iconSource,
  } = props;

  return (
    <div className="vf-form__main-header">
      {
        Helper.isNullOrUndefined(iconSource)
        && Helper.isNullOrUndefined(iconClassName)
        && Helper.isNullOrUndefined(iconText)
          ? false
          : (
            <div className="vf-form__main-header__icon-container">
              {iconSource ? (
                <img
                  alt="Logo"
                  className="vf-form__main-header__icon-container__image"
                  src={iconSource}
                />
              ) : (
                <Components.CircleIcon
                  className={classNames(
                    'vf-form__main-header__icon-container__circle-icon',
                    iconContainerClassName,
                  )}
                  iconClassName={iconClassName}
                  iconText={iconText}
                  innerIconColor={innerIconColor}
                  outerIconColor={outerIconColor}
                  strokeColor={strokeColor}
                />
              )}
            </div>
          )
      }
      <div className="vf-form__main-header__text">
        <div className="vf-form__main-header__text--title">
          {title}
        </div>
        <div className="vf-form__main-header__text--subtitle">
          {subtitle}
        </div>
      </div>
    </div>
  );
};

MainHeader.displayName = 'Form Main Header';

MainHeader.propTypes = {
  iconClassName: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  iconSource: PropTypes.string,
  iconText: PropTypes.string,
  innerIconColor: PropTypes.string,
  outerIconColor: PropTypes.string,
  strokeColor: PropTypes.string,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

MainHeader.defaultProps = {
  iconClassName: null,
  iconContainerClassName: null,
  iconSource: null,
  iconText: null,
  innerIconColor: null,
  outerIconColor: null,
  strokeColor: null,
  subtitle: null,
  title: null,
};

export default MainHeader;
