import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  vehicleParts: {},
  vehiclePartIds: [],
  vehiclePartMeta: {},
  archivedVehiclePartMeta: {},
  optionVehicleParts: [],
  archivedVehiclePartIds: [],
  filter: {},
  skills: [],
  systemTags: {},
};

function vehiclePart(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VEHICLE_PART':
      return {
        ...state,
        vehicleParts: ReduxHelper.mergeDataWithObject(state.vehicleParts, action.vehiclePart),
      };
    case 'RECEIVE_VEHICLE_PARTS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          vehiclePartIds: action.vehicleParts.map(part => part.id),
          vehicleParts: ReduxHelper.mergeDataWithList(state.vehicleParts, action.vehicleParts),
          vehiclePartMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_VEHICLE_PARTS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedVehiclePartIds: action.vehicleParts.map(part => part.id),
          vehicleParts: ReduxHelper.mergeDataWithList(state.vehicleParts, action.vehicleParts),
          archivedVehiclePartMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_OPTION_VEHICLE_PARTS':
      return {
        ...state,
        optionVehicleParts: action.optionVehicleParts,
      };
    case 'RECEIVE_VEHICLE_PART_SKILLS':
      return {
        ...state,
        skills: action.skills,
      };
    case 'RECEIVE_VEHICLE_PART_SYSTEM_TAGS': {
      const customTags = action.systemTags.custom_field_groups.map((item) => {
        const newTitle = item.title.split('-')[1];
        return { ...item, title: newTitle };
      });
      return {
        ...state,
        systemTags: { ...action.systemTags, custom_field_groups: customTags },
      };
    }
    case 'SET_VEHICLE_PART_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default vehiclePart;
