import React from 'react';
import ListItem from './listItem';

const EditForm = React.lazy(() => import('./editForm'));
const ViewForm = React.lazy(() => import('./viewForm'));
const ServiceRequirementsForm = React.lazy(() => import('./serviceRequirementsForm'));
const LocationForm = React.lazy(() => import('./locationForm'));
const NewForm = React.lazy(() => import('./newForm'));

export default {
  ListItem,
  EditForm,
  ViewForm,
  NewForm,
  LocationForm,
  ServiceRequirementsForm,
};
