import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';

class ActionPanelFilterCustomFieldButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentInfo: null,
      parentOptions: [],
      childOptions: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(ReduxActions.customFieldGroup.fetchOptionCustomFieldGroupList());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.optionCustomFieldGroups !== this.props.optionCustomFieldGroups) {
      this._getParentOptions(this.props.optionCustomFieldGroups);
    }

    if (prevProps.customFieldDescription !== this.props.customFieldDescription) {
      this._getChildOptions(this.props.customFieldDescription);
    }
  }

  _getParentOptions(optionCustomFieldGroups) {
    const parentOptions = [];
    optionCustomFieldGroups.forEach((fieldGroup) => {
      fieldGroup.custom_field_descriptions
        .filter(field => ['text', 'number', 'dropdown', 'choice'].includes(field.field_type))
        .forEach((field) => {
          parentOptions.push({
            groupName: fieldGroup.name,
            value: field.id,
            text: field.name,
            row: (
              <div>
                <div>{field.name}</div>
                <div className="vf-action-panel__filter-nested-search__second-label">{fieldGroup.name}</div>
              </div>
            ),
          });
        });
    });

    this.setState({ parentOptions });
  }

  _getChildOptions(customFieldDescription) {
    // eslint-disable-next-line camelcase
    const customFields = customFieldDescription?.custom_fields ?? [];
    let childOptions = customFields.map(field => ({
      text: field.value,
      value: field.value,
    }));
    childOptions = Helper.uniqBy(childOptions, 'value');

    this.setState(prevState => ({
      ...prevState,
      childOptions,
    }));
  }

  onParentChange(value) {
    const { date } = this.props;
    const filter = { date };
    this.props.childOnChange(null, false);

    if (!value) {
      this.setState(prevState => ({
        ...prevState,
        parentInfo: null,
      }));
    }

    const cfd = this.state.parentOptions.find(field => field.value === value);
    if (!Helper.isNullOrUndefined(cfd)) {
      this.setState(prevState => ({
        ...prevState,
        parentInfo: cfd.groupName,
      }));
      this.props.dispatch(ReduxActions.customFieldGroup.fetchCustomFieldDescription(
        cfd.value, filter,
      ));
    }
    this.props.parentOnChange(value, true);
  }

  render() {
    const {
      id, strings, className, parentValue, childValue,
    } = this.props;
    const { parentInfo, parentOptions, childOptions } = this.state;

    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
        showArrow
        text={strings.text}
      >
        <Component.ActionPanel.FilterNestedSearch
          childEmptyMessage={strings.childEmptyMessage}
          childLabel={strings.childLabel}
          childOnChange={value => this.props.childOnChange(value, true)}
          childOptions={childOptions}
          childPlaceholder={strings.childPlaceholder}
          childValue={childValue}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-custom-field`}
          parentInfo={!Helper.isNullOrUndefined(parentValue) ? parentInfo : null}
          parentLabel={strings.parentLabel}
          parentOnChange={value => this.onParentChange(value)}
          parentOptions={parentOptions}
          parentPlaceholder={strings.parentPlaceholder}
          parentValue={parentValue}
          showChild={!Helper.isNullOrUndefined(parentValue)}
        />
      </Component.ActionPanel.DropdownButton>
    );
  }
}

ActionPanelFilterCustomFieldButton.displayName = 'Action Panel Custom Field Button';

ActionPanelFilterCustomFieldButton.propTypes = {
  childOnChange: PropTypes.func.isRequired,
  childValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  customFieldDescription: PropTypes.shape({}),
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  optionCustomFieldGroups: PropTypes.arrayOf(PropTypes.shape()),
  parentOnChange: PropTypes.func.isRequired,
  parentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strings: PropTypes.shape({
    parentPlaceholder: PropTypes.string,
    childPlaceholder: PropTypes.string,
    childEmptyMessage: PropTypes.string,
    text: PropTypes.string,
    childLabel: PropTypes.string,
    parentLabel: PropTypes.string,
  }).isRequired,
};

ActionPanelFilterCustomFieldButton.defaultProps = {
  childValue: null,
  className: '',
  customFieldDescription: null,
  id: '',
  optionCustomFieldGroups: [],
  parentValue: null,
};

function mapStateToProps(state, ownProps) {
  const { customFieldGroup } = state;

  let customFieldDescription = null;
  if (!Helper.isNullOrUndefined(ownProps.parentValue)) {
    customFieldDescription = customFieldGroup.customFieldDescriptions[ownProps.parentValue];
  }

  return {
    optionCustomFieldGroups: customFieldGroup.optionCustomFieldGroups || [],
    customFieldDescription,
  };
}

export default connect(mapStateToProps)(translate('Task.customField')(ActionPanelFilterCustomFieldButton));
