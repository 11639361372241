import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  archivedTimestamp: null,
  customFieldGroups: {},
  customFieldGroupIds: [],
  isFetchingOptionCustomFieldGroups: false,
  optionCustomFieldGroups: [],
  customFieldDescriptions: {},
};

function customFieldGroup(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CUSTOM_FIELD_GROUP':
      return {
        ...state,
        customFieldGroups: ReduxHelper.mergeDataWithObject(
          state.customFieldGroups, action.customFieldGroup,
        ),
      };
    case 'RECEIVE_CUSTOM_FIELD_GROUPS':
      return {
        ...state,
        customFieldGroupIds: action.customFieldGroups.map(
          _customFieldGroup => _customFieldGroup.id,
        ),
        customFieldGroups: ReduxHelper.mergeDataWithList(
          state.customFieldGroups, action.customFieldGroups,
        ),
      };
    case 'RECEIVE_CUSTOM_FIELD_GROUP_UPDATE_SUCCESSFUL':
      return {
        ...state,
        customFieldGroups: ReduxHelper.mergeDataWithObject(
          state.customFieldGroups, action.customFieldGroup,
        ),
      };
    case 'RECEIVE_OPTION_CUSTOM_FIELD_GROUPS':
      return {
        ...state,
        optionCustomFieldGroups: action.customFieldGroups,
      };
    case 'RECEIVE_CUSTOM_FIELD_DESCRIPTION':
      return {
        ...state,
        customFieldDescriptions: ReduxHelper.mergeDataWithObject(
          state.customFieldDescriptions, action.customFieldDescription,
        ),
      };
    case 'SET_IS_FETCHING_OPTION_CUSTOM_FIELD_GROUPS':
      return {
        ...state,
        isFetchingOptionCustomFieldGroups: action.isFetchingOptionCustomFieldGroups,
      };
    default:
      return state;
  }
}

export default customFieldGroup;
