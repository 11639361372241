import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import App from './pages/app';
import lazyWithRetry from './utils/lazyWithRetry';

import FleetManagementDrivers from './pages/fleet/driver';
import FleetManagementVehicles from './pages/fleet/vehicle';
import { ProtectedRoute } from './utils/router';

import TaskModule from './modules/task';
import JobModule from './modules/job';

import CustomerModule from './modules/customer';
import BillingAccountModule from './modules/billingAccount';

import TaskEmailTrigger from './pages/settings/notifications/statusTrigger/taskEmailTrigger';
import TaskSmsTrigger from './pages/settings/notifications/statusTrigger/taskSmsTrigger';
import JobEmailTrigger from './pages/settings/notifications/statusTrigger/jobEmailTrigger';
import JobSmsTrigger from './pages/settings/notifications/statusTrigger/jobSmsTrigger';

import UserModule from './modules/user';
import Profile from './pages/profile';

import SettingsAddonsLocationBasedFunction from './pages/settings/addons/locationBasedFunction';
import SettingsAddonsTrackingSetting from './pages/settings/addons/tracking';
import SettingsAddonsRunsheetManager from './pages/settings/addons/runsheetManager';
import ViewTasksRescheduling from './pages/settings/addons/tasksRescheduling/viewTasksRescheduling';
import DetailTasksRescheduling from './pages/settings/addons/tasksRescheduling/detailTasksRescheduling';

const Authentication = lazyWithRetry(() => import('./pages/authentication/authentication'));
const AuthenticationHome = lazyWithRetry(() => import('./pages/authentication/authenticationHome'));
const Login = lazyWithRetry(() => import('./pages/authentication/login'));
const ForgetPassword = lazyWithRetry(() => import('./pages/authentication/forgetPassword'));
const ResetPassword = lazyWithRetry(() => import('./pages/authentication/resetPassword'));
const ResendActivation = lazyWithRetry(() => import('./pages/authentication/resendActivation'));
const ActivateEmail = lazyWithRetry(() => import('./pages/authentication/activateEmail'));
const LoginTotp = lazyWithRetry(() => import('./pages/authentication/loginTotp'));
const AuthenticationNew = lazyWithRetry(() => import('./pages/authentication/authenticationNew'));
const SsoLogin = lazyWithRetry(() => import('./pages/authentication/ssoLogin'));
const SsoError = lazyWithRetry(() => import('./pages/authentication/ssoError'));
const ResetExpiredPassword = lazyWithRetry(() => import('./pages/authentication/resetExpiredPassword'));

const MainContainer = lazyWithRetry(() => import('./pages/mainContainer'));
const Dashboard = lazyWithRetry(() => import('./pages/dashboard/dashboard'));
const PageNotFound = lazyWithRetry(() => import('./pages/pageNotFound'));

const FleetManagement = lazyWithRetry(() => import('./pages/fleet/fleetManagement'));
const FleetManagementMap = lazyWithRetry(() => import('./pages/fleet/map/fleetMapIndex'));

const Planning = lazyWithRetry(() => import('./pages/planning/planning'));
const PlanningVisual = lazyWithRetry(() => import('./pages/planning/visual/visual'));
const PlanningAddressCorrectionForm = lazyWithRetry(() => import('./pages/planning/visual/addressCorrection/addressCorrectionForm'));
const PlanningOptimizeForm = lazyWithRetry(() => import('./pages/planning/visual/optimizer/form'));
const PlanningGroupOptimizeForm = lazyWithRetry(() => import('./pages/planning/visual/optimizer/groupOptimizeForm'));
const PlanningPending = lazyWithRetry(() => import('./pages/planning/pending/mainPageContent'));
const PlanningPendingJobViewForm = lazyWithRetry(() => import('./pages/planning/pending/pendingJobViewForm'));
const PlanningPendingTaskViewForm = lazyWithRetry(() => import('./pages/planning/pending/pendingTaskViewForm'));
const PlanningTask = lazyWithRetry(() => import('./pages/planning/tasks/mainPageContent'));
const PlanningTaskSearch = lazyWithRetry(() => import('./pages/planning/tasks/taskSearchPageContent'));
const PlanningTaskSearchViewForm = lazyWithRetry(() => import('./pages/planning/tasks/taskSearchViewForm'));
const PlanningTaskEditForm = lazyWithRetry(() => import('./pages/planning/tasks/taskEditForm'));
const PlanningJobViewForm = lazyWithRetry(() => import('./pages/planning/tasks/jobViewForm'));
const PlanningJobEditForm = lazyWithRetry(() => import('./pages/planning/tasks/jobEditForm'));
const PlanningJobNew = lazyWithRetry(() => import('./pages/planning/tasks/jobNew'));
const PlanningTaskNew = lazyWithRetry(() => import('./pages/planning/tasks/taskNew'));
const PlanningPresetReportsForm = lazyWithRetry(() => import('./pages/planning/tasks/presetReportsForm'));
const PlanningAllocateViewForm = lazyWithRetry(() => import('./pages/planning/tasks/allocateViewForm'));
const PlanningAllocateEditForm = lazyWithRetry(() => import('./pages/planning/tasks/allocateEditForm'));
const PlanningAllocateRunForm = lazyWithRetry(() => import('./pages/planning/tasks/runAutoAllocateForm'));
const PlanningRunsheetList = lazyWithRetry(() => import('./pages/planning/runsheets/list'));
const PlanningRunsheetBoard = lazyWithRetry(() => import('./pages/planning/runsheets/board'));
const PlanningRunsheetBoardViewTask = lazyWithRetry(() => import('./pages/planning/runsheets/board/viewTask'));
const PlanningRunsheetBoardCustomLayout = lazyWithRetry(() => import('./pages/planning/runsheets/board/customLayout'));
const RunsheetConfigForm = lazyWithRetry(() => import('./pages/planning/runsheets/list/runsheetConfigForm'));
const RunsheetInfo = lazyWithRetry(() => import('./pages/planning/runsheets/list/runsheetInfo'));
const RunsheetPrint = lazyWithRetry(() => import('./pages/planning/runsheets/list/runsheetPrint'));

const Customers = lazyWithRetry(() => import('./pages/customer/customer'));
const CustomersCustomer = lazyWithRetry(() => import('./pages/customer/customers/customer'));
const CustomerEpodSettingForm = lazyWithRetry(() => import('./pages/customer/customers/epodSettingForm'));
const CustomersAccount = lazyWithRetry(() => import('./pages/customer/accounts/account'));

const Settings = lazyWithRetry(() => import('./pages/settings/settings'));

const SettingsGeneral = lazyWithRetry(() => import('./pages/settings/general/general'));
const SettingsGeneralCompanyProfileViewForm = lazyWithRetry(() => import('./pages/settings/general/companyProfile/viewForm'));
const SettingsGeneralCompanyProfileEditForm = lazyWithRetry(() => import('./pages/settings/general/companyProfile/editForm'));
const SettingsGeneralAccountLanguageAndRegionViewForm = lazyWithRetry(() => import('./pages/settings/general/accountLanguageAndRegion/viewForm'));
const SettingsGeneralAccountLanguageAndRegionEditForm = lazyWithRetry(() => import('./pages/settings/general/accountLanguageAndRegion/editForm'));
const SettingsGeneralUnitAndShippingFactorForm = lazyWithRetry(() => import('./pages/settings/general/accountUnitAndShippingFactorForm'));
const SettingsGeneralTimeWindowViewForm = lazyWithRetry(() => import('./pages/settings/general/timeWindow/viewForm'));
const SettingsGeneralTimeWindowEditForm = lazyWithRetry(() => import('./pages/settings/general/timeWindow/editForm'));

const SettingsOurLocations = lazyWithRetry(() => import('./pages/settings/ourLocation/ourLocations'));
const SettingsOurLocationsEditForm = lazyWithRetry(() => import('./pages/settings/ourLocation/editForm'));
const SettingsOurLocationsViewForm = lazyWithRetry(() => import('./pages/settings/ourLocation/viewForm'));

const SettingsNotifications = lazyWithRetry(() => import('./pages/settings/notifications/notifications'));
const AlertRules = lazyWithRetry(() => import('./pages/settings/notifications/alertRules/index'));
const AlertRulesView = lazyWithRetry(() => import('./pages/settings/notifications/alertRules/view'));
const AlertRulesEdit = lazyWithRetry(() => import('./pages/settings/notifications/alertRules/edit'));

const SettingsVersadrive = lazyWithRetry(() => import('./pages/settings/versadrive/versadrive'));
const SettingsVersadriveJobCompletionForm = lazyWithRetry(() => import('./pages/settings/versadrive/jobCompletionForm'));
const SettingsVersadriveTaskCompletionForm = lazyWithRetry(() => import('./pages/settings/versadrive/taskCompletionForm'));
const SettingsVersadriveRecipientVerificationForm = lazyWithRetry(() => import('./pages/settings/versadrive/recipientVerificationForm'));
const SettingsVersadriveDriverAssignmentForm = lazyWithRetry(() => import('./pages/settings/versadrive/driverAssignmentForm'));
const SettingsVersadriveDriverAcknowledgementForm = lazyWithRetry(() => import('./pages/settings/versadrive/driverAcknowledgementForm'));
const SettingsVersadriveDriverStartedForm = lazyWithRetry(() => import('./pages/settings/versadrive/driverStartedForm'));
const SettingsVersadriveJobTaskSummaryLayoutForm = lazyWithRetry(() => import('./pages/settings/versadrive/jobTaskSummaryLayout/form'));
const SettingsVersadriveTaskIncompletionForm = lazyWithRetry(() => import('./pages/settings/versadrive/taskIncompletionForm'));
const SettingsVersadriveLineItemValidationForm = lazyWithRetry(() => import('./pages/settings/versadrive/lineItemValidationForm'));
const SettingsVersadriveTaskSequenceLockForm = lazyWithRetry(() => import('./pages/settings/versadrive/taskSequenceLockForm'));
const SettingsVersadriveScanToIdentifyForm = lazyWithRetry(() => import('./pages/settings/versadrive/scanToIdentifyForm'));
const SettingsVersadriveClickToChatForm = lazyWithRetry(() => import('./pages/settings/versadrive/clickToChatForm'));
const SettingsVersadrivePhotoTakingForm = lazyWithRetry(() => import('./pages/settings/versadrive/photoTakingForm'));

const SettingsUsers = lazyWithRetry(() => import('./pages/settings/user'));

const SettingsAddons = lazyWithRetry(() => import('./pages/settings/addons'));
const SettingsAddonsGeneralEpodSettingForm = lazyWithRetry(() => import('./pages/settings/addons/epod/form'));
const SettingsAddonsGeneralTaskLabelViewForm = lazyWithRetry(() => import('./pages/settings/addons/taskLabel/viewForm'));
const SettingsAddonsGeneralTaskLabelEditForm = lazyWithRetry(() => import('./pages/settings/addons/taskLabel/editForm'));
const SettingsAddonsCustomFieldGroupViewForm = lazyWithRetry(() => import('./pages/settings/addons/customFieldGroup/viewForm'));
const SettingsAddonsCustomFieldGroupEditForm = lazyWithRetry(() => import('./pages/settings/addons/customFieldGroup/editForm'));
const SettingsAddonsCustomTaskLayoutViewForm = lazyWithRetry(() => import('./pages/settings/addons/customTaskLayout/viewForm'));
const SettingsAddonsCustomTaskLayoutEditForm = lazyWithRetry(() => import('./pages/settings/addons/customTaskLayout/editForm'));
const SettingsAddonsMyJobsForm = lazyWithRetry(() => import('./pages/settings/addons/myJobs/form'));
const SettingsAddonsMyJobsTaskLayoutEditForm = lazyWithRetry(() => import('./pages/settings/addons/myJobs/editForm'));
const SettingsAddonsScheduledOptimisationsListForm = lazyWithRetry(() => import('./pages/settings/addons/scheduledOptimisations/listForm'));
const SettingsAddonsScheduledOptimisationGroupEditForm = lazyWithRetry(() => import('./pages/settings/addons/scheduledOptimisations/groupEdit'));
const SettingsAddonsScheduledOptimisationsRosterViewForm = lazyWithRetry(() => import('./pages/settings/addons/scheduledOptimisations/rosterViewForm'));
const SettingsAddonsScheduledOptimisationsRosterEditForm = lazyWithRetry(() => import('./pages/settings/addons/scheduledOptimisations/rosterEditForm'));

const AddOns = lazyWithRetry(() => import('./pages/addOns/addOns'));
const AddOnsViewForm = lazyWithRetry(() => import('./pages/addOns/viewForm'));

const Archive = lazyWithRetry(() => import('./pages/archive/archive'));
const ArchiveTask = lazyWithRetry(() => import('./pages/archive/task'));
const ArchiveJob = lazyWithRetry(() => import('./pages/archive/job'));
const ArchiveDriver = lazyWithRetry(() => import('./pages/archive/driver'));
const ArchiveDriverRecord = lazyWithRetry(() => import('./pages/archive/driver/record/index'));
const ArchiveDriverRecordView = lazyWithRetry(() => import('./pages/archive/driver/record/view'));
const ArchiveVehicle = lazyWithRetry(() => import('./pages/archive/vehicle'));
const ArchiveVehiclePart = lazyWithRetry(() => import('./pages/archive/vehicle/part/index'));
const ArchiveVehiclePartView = lazyWithRetry(() => import('./pages/archive/vehicle/part/view'));
const ArchiveVehicleRecord = lazyWithRetry(() => import('./pages/archive/vehicle/record/index'));
const ArchiveVehicleRecordView = lazyWithRetry(() => import('./pages/archive/vehicle/record/view'));
const ArchiveCustomer = lazyWithRetry(() => import('./pages/archive/customer'));
const ArchiveUser = lazyWithRetry(() => import('./pages/archive/user'));
const ArchiveAccount = lazyWithRetry(() => import('./pages/archive/billingAccount'));

const Notification = lazyWithRetry(() => import('./pages/notification/notification'));

const Payment = lazyWithRetry(() => import('./pages/payment/payment'));
const PaymentTrigger = lazyWithRetry(() => import('./pages/payment/trigger'));
const ExportForm = lazyWithRetry(() => import('./pages/payment/exportForm'));
const SMSTransactionDetailsForm = lazyWithRetry(() => import('./pages/payment/sms/transactionDetailsForm'));
const SMSRecordsForm = lazyWithRetry(() => import('./pages/payment/sms/recordsForm'));
const EmailRecordsForm = lazyWithRetry(() => import('./pages/payment/email/recordsForm'));

const Invoices = lazyWithRetry(() => import('./pages/invoices/invoices'));
const InvoicesViewForm = lazyWithRetry(() => import('./pages/invoices/invoicesTaskViewForm'));

const Integrations = lazyWithRetry(() => import('./pages/integrations/integrations'));
const IntegrationsXero = lazyWithRetry(() => import('./pages/integrations/xeroForm'));

const Help = lazyWithRetry(() => import('./pages/help/index'));

const routes = (
  <Suspense fallback={false}>
    <Routes>
      <Route element={<App />}>
        {/* Redirects */}
        <Route element={<Navigate to="/auth/login" />} path="/" />
        <Route element={<Navigate to="/auth/login" />} path="auth" />

        {/* Main Authentication Routes */}
        <Route element={<Authentication />} path="auth">
          <Route element={<AuthenticationHome />} path="home" />
          <Route element={<Login />} path="login" />
          <Route element={<LoginTotp />} path="login_totp" />
          <Route element={<ForgetPassword />} path="forget_password" />
          <Route element={<ResetPassword />} path="reset_password" />
          <Route element={<ResendActivation />} path="resend_activation" />
          <Route element={<ActivateEmail />} path="activate" />
          <Route element={<ResetExpiredPassword />} path="reset_expired_password" />
        </Route>

        <Route element={<AuthenticationNew />} path="auth/login/sso">
          <Route element={<SsoLogin />} path=":domain" />
          <Route element={<SsoError />} path=":domain/error" />
        </Route>

        <Route element={<MainContainer showLeftNavigationPanel />} path="/">
          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={['account.view', 'custom_field_group.view', 'my_location.view',
                  'notificator.view', 'task_notification_setting.view', 'versadrive_setting.view',
                  'task_incompletion_reason.view', 'dashboard.view', 'user.view', 'vehicle_locator.view',
                  'vehicle.view', 'driver.view', 'runsheet.visual_view', 'job.pending_view', 'task.view',
                  'job.view', 'runsheet.view', 'customer.view', 'xero.invoice_view', 'payment.view',
                  'addons.view']}
                redirectKeys={[{
                  keys: ['dashboard.view'],
                  path: '/dashboard',
                }, {
                  keys: ['runsheet.visual_view', 'job.pending_view', 'task.view',
                    'job.view', 'runsheet.view'],
                  path: '/planning',
                }, {
                  keys: ['vehicle_locator.view', 'vehicle.view', 'driver.view'],
                  path: '/fleet',
                }, {
                  keys: ['customer.view', 'account.view'],
                  path: '/customer',
                }, {
                  keys: ['account.view', 'custom_field_group.view', 'my_location.view',
                    'notificator.view', 'task_notification_setting.view', 'versadrive_setting.view',
                    'task_incompletion_reason.view'],
                  path: '/settings',
                }, {
                  keys: ['addons.view'],
                  path: '/addons',
                }, {
                  keys: ['task.view', 'job.view', 'driver.view', 'vehicle.view',
                    'customer.view', 'user.view'],
                  path: '/archives',
                }, {
                  keys: ['xero.invoice_view'],
                  path: '/invoices',
                }, {
                  keys: ['payment.view'],
                  path: '/billing',
                }]}
              />
            )}
            path="home"
          />

          <Route
            element={(
              <ProtectedRoute authorizeKeys={['dashboard.view']}>
                <Dashboard titleKey="dashboard" />
              </ProtectedRoute>
            )}
            path="dashboard"
          />

          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={['vehicle_locator.view', 'vehicle.view', 'driver.view']}
                redirectKeys={[{
                  keys: ['vehicle_locator.view'],
                  path: '/fleet/map',
                }, {
                  keys: ['vehicle.view'],
                  path: '/fleet/vehicles',
                }, {
                  keys: ['driver.view'],
                  path: '/fleet/drivers',
                }]}
              >
                <FleetManagement titleKey="fleetManagement" />
              </ProtectedRoute>
            )}
            path="fleet"
          >
            <Route
              element={(
                <ProtectedRoute
                  authorizeKeys={['vehicle_locator.view']}
                >
                  <FleetManagementMap />
                </ProtectedRoute>
              )}
              path="map"
            />

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['vehicle.view']}>
                  <FleetManagementVehicles.Vehicle />
                </ProtectedRoute>
              )}
              path="vehicles"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehicleCustomLayout />
                  </ProtectedRoute>
                )}
                path="custom_layout"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehicleSettings />
                  </ProtectedRoute>
                )}
                path="settings"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehicleImport />
                  </ProtectedRoute>
                )}
                path="import"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehicleEdit />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementVehicles.VehicleRecordList />
                  </ProtectedRoute>
                )}
                path=":id/records"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordNew />
                  </ProtectedRoute>
                )}
                path=":vehicleId/records/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordEdit />
                  </ProtectedRoute>
                )}
                path=":vehicleId/records/:recordId/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementVehicles.RecordView />
                  </ProtectedRoute>
                )}
                path=":vehicleId/records/:recordId"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehicleEdit />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.view']}>
                    <FleetManagementVehicles.VehicleView />
                  </ProtectedRoute>
                )}
                path=":id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['vehicle.view']}>
                  <FleetManagementVehicles.Part />
                </ProtectedRoute>
              )}
              path="vehicles/parts"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehiclePartCustomLayout />
                  </ProtectedRoute>
                )}
                path="custom_layout"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehiclePartSettings />
                  </ProtectedRoute>
                )}
                path="settings"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehiclePartImport />
                  </ProtectedRoute>
                )}
                path="import"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehiclePartEdit />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementVehicles.VehiclePartRecordList />
                  </ProtectedRoute>
                )}
                path=":id/records"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordNew />
                  </ProtectedRoute>
                )}
                path=":partId/records/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordEdit />
                  </ProtectedRoute>
                )}
                path=":partId/records/:recordId/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementVehicles.RecordView />
                  </ProtectedRoute>
                )}
                path=":partId/records/:recordId"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.edit']}>
                    <FleetManagementVehicles.VehiclePartEdit />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['vehicle.view']}>
                    <FleetManagementVehicles.VehiclePartView />
                  </ProtectedRoute>
                )}
                path=":id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                  <FleetManagementVehicles.Record />
                </ProtectedRoute>
              )}
              path="vehicles/records"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordSetting />
                  </ProtectedRoute>
                )}
                path="settings/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordSetting />
                  </ProtectedRoute>
                )}
                path="settings/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordTemplate />
                  </ProtectedRoute>
                )}
                path="settings"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordNew />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementVehicles.RecordExport />
                  </ProtectedRoute>
                )}
                path="export"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementVehicles.RecordEdit />
                  </ProtectedRoute>
                )}
                path=":recordId/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementVehicles.RecordView />
                  </ProtectedRoute>
                )}
                path=":recordId"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['driver.view']}>
                  <FleetManagementDrivers.Driver />
                </ProtectedRoute>
              )}
              path="drivers"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.DriverCustomLayout />
                  </ProtectedRoute>
                )}
                path="custom_layout"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.DriverSettings />
                  </ProtectedRoute>
                )}
                path="settings"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.ImportForm />
                  </ProtectedRoute>
                )}
                path="import"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.DriverEdit />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementDrivers.DriverRecordList />
                  </ProtectedRoute>
                )}
                path=":id/records"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordNew />
                  </ProtectedRoute>
                )}
                path=":driverId/records/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordEdit />
                  </ProtectedRoute>
                )}
                path=":driverId/records/:recordId/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementDrivers.RecordView />
                  </ProtectedRoute>
                )}
                path=":driverId/records/:recordId"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.DriverEdit />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.WorkingHourEdit />
                  </ProtectedRoute>
                )}
                path=":id/configurations"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.edit']}>
                    <FleetManagementDrivers.VersaDriverDetailEdit />
                  </ProtectedRoute>
                )}
                path=":id/versa_driver"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['driver.view']}>
                    <FleetManagementDrivers.DriverView />
                  </ProtectedRoute>
                )}
                path=":id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                  <FleetManagementDrivers.Record />
                </ProtectedRoute>
              )}
              path="drivers/records"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordSetting />
                  </ProtectedRoute>
                )}
                path="settings/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordSetting />
                  </ProtectedRoute>
                )}
                path="settings/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordTemplate />
                  </ProtectedRoute>
                )}
                path="settings"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordNew />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementDrivers.RecordExport />
                  </ProtectedRoute>
                )}
                path="export"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <FleetManagementDrivers.RecordEdit />
                  </ProtectedRoute>
                )}
                path=":recordId/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <FleetManagementDrivers.RecordView />
                  </ProtectedRoute>
                )}
                path=":recordId"
              />
            </Route>
          </Route>

          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={['runsheet.visual_view', 'job.pending_view', 'task.view',
                  'task.pending_view', 'job.view', 'runsheet.view']}
                redirectKeys={[{
                  keys: ['runsheet.visual_view'],
                  path: '/planning/visual',
                }, {
                  keys: ['task.pending_view', 'job.pending_view'],
                  path: '/planning/pending',
                }, {
                  keys: ['task.view', 'job.view'],
                  path: '/planning/tasks',
                }, {
                  keys: ['runsheet.view'],
                  path: '/planning/runsheets',
                }]}
              >
                <Planning titleKey="planning" />
              </ProtectedRoute>
            )}
            path="planning"
          >
            <Route
              element={(
                <ProtectedRoute authorizeKeys={['runsheet.visual_view']}>
                  <PlanningVisual />
                </ProtectedRoute>
              )}
              path="visual"
            >
              {/* Redirect to avoid 404 */}
              <Route element={<Navigate to="/planning/visual" />} path="/planning/visual/tasks" />

              <Route element={<ProtectedRoute authorizeKeys={['task.view']} />} path="tasks">
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['task.edit']}>
                      <PlanningTaskEditForm />
                    </ProtectedRoute>
                  )}
                  path=":id/edit"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['task.view']}>
                      <TaskModule.ViewForm />
                    </ProtectedRoute>
                  )}
                  path=":id"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['task.view']}>
                      <TaskModule.HistoryForm />
                    </ProtectedRoute>
                  )}
                  path=":id/history"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['task.view']}>
                      <TaskModule.RecipientVerificationForm />
                    </ProtectedRoute>
                  )}
                  path=":id/recipient_verification"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.edit']}>
                      <PlanningJobNew />
                    </ProtectedRoute>
                  )}
                  path="jobs/new"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.edit']}>
                      <JobModule.ImportForm />
                    </ProtectedRoute>
                  )}
                  path="jobs/import"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.view']}>
                      <TaskModule.ExportTemplate.ListItem />
                    </ProtectedRoute>
                  )}
                  path="jobs/export"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.view']}>
                      <TaskModule.ExportTemplate.New />
                    </ProtectedRoute>
                  )}
                  path="jobs/export/new"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.view']}>
                      <TaskModule.ExportTemplate.View />
                    </ProtectedRoute>
                  )}
                  path="jobs/export/:id"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.view']}>
                      <TaskModule.ExportTemplate.Edit />
                    </ProtectedRoute>
                  )}
                  path="jobs/export/:id/edit"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.edit']}>
                      <PlanningJobEditForm />
                    </ProtectedRoute>
                  )}
                  path="jobs/:id/edit"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['task.edit']}>
                      <PlanningTaskNew />
                    </ProtectedRoute>
                  )}
                  path="jobs/:id/tasks/new"
                />
                <Route
                  element={(
                    <ProtectedRoute authorizeKeys={['job.view']}>
                      <PlanningJobViewForm />
                    </ProtectedRoute>
                  )}
                  path="jobs/:id"
                />
              </Route>

              <Route element={<PlanningOptimizeForm />} path="optimize" />
              <Route element={<PlanningGroupOptimizeForm />} path="group_optimization" />
              <Route element={<PlanningAddressCorrectionForm />} path="address_correction" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['job.pending_view']}>
                  <PlanningPending />
                </ProtectedRoute>
              )}
              path="pending"
            >
              <Route element={<PlanningPendingJobViewForm />} path="jobs/:id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['task.pending_view']}>
                  <PlanningPending />
                </ProtectedRoute>
              )}
              path="pending"
            >
              <Route element={<PlanningPendingTaskViewForm />} path="tasks/:id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['task.view']}>
                  <PlanningTaskSearch />
                </ProtectedRoute>
              )}
              path="tasks/search"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.view']}>
                    <PlanningTaskSearchViewForm />
                  </ProtectedRoute>
                )}
                path=":id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['task.view', 'job.view']}>
                  <PlanningTask />
                </ProtectedRoute>
              )}
              path="tasks"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.view']}>
                    <TaskModule.HistoryForm />
                  </ProtectedRoute>
                )}
                path=":id/history"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.view']}>
                    <TaskModule.RecipientVerificationForm />
                  </ProtectedRoute>
                )}
                path=":id/recipient_verification"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.edit']}>
                    <PlanningTaskEditForm />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.view']}>
                    <PlanningPresetReportsForm />
                  </ProtectedRoute>
                )}
                path="preset_reports"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.view']}>
                    <TaskModule.ViewForm />
                  </ProtectedRoute>
                )}
                path=":id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.edit']}>
                    <PlanningJobNew />
                  </ProtectedRoute>
                )}
                path="jobs/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.edit']}>
                    <JobModule.ImportForm />
                  </ProtectedRoute>
                )}
                path="jobs/import"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.view']}>
                    <TaskModule.ExportTemplate.ListItem />
                  </ProtectedRoute>
                )}
                path="jobs/export"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.view']}>
                    <TaskModule.ExportTemplate.New />
                  </ProtectedRoute>
                )}
                path="jobs/export/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.view']}>
                    <TaskModule.ExportTemplate.View />
                  </ProtectedRoute>
                )}
                path="jobs/export/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.view']}>
                    <TaskModule.ExportTemplate.Edit />
                  </ProtectedRoute>
                )}
                path="jobs/export/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.edit']}>
                    <PlanningJobEditForm />
                  </ProtectedRoute>
                )}
                path="jobs/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.edit']}>
                    <PlanningTaskNew />
                  </ProtectedRoute>
                )}
                path="jobs/:id/tasks/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.view']}>
                    <PlanningJobViewForm />
                  </ProtectedRoute>
                )}
                path="jobs/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.edit']}>
                    <PlanningAllocateViewForm />
                  </ProtectedRoute>
                )}
                path="auto_allocate/rules"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.edit']}>
                    <PlanningAllocateEditForm />
                  </ProtectedRoute>
                )}
                path="auto_allocate/rules/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['job.edit']}>
                    <PlanningAllocateRunForm />
                  </ProtectedRoute>
                )}
                path="auto_allocate/run"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['runsheet.view']}>
                  <PlanningRunsheetList />
                </ProtectedRoute>
              )}
              path="runsheets"
            >
              <Route element={<RunsheetPrint />} path="drivers/print_multiple" />
              <Route element={<RunsheetInfo />} path="drivers/:id" />
              <Route element={<RunsheetPrint />} path="drivers/:id/print" />
              <Route element={<RunsheetConfigForm />} path="configure" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['runsheet.view']}>
                  <PlanningRunsheetBoard />
                </ProtectedRoute>
              )}
              path="runsheets/board"
            >
              <Route element={<PlanningRunsheetBoardCustomLayout />} path="custom_layout" />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.view']}>
                    <PlanningRunsheetBoardViewTask />
                  </ProtectedRoute>
                )}
                path=":id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task.edit']}>
                    <PlanningTaskEditForm />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
            </Route>
          </Route>

          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={['customer.view', 'account.view']}
                redirectKeys={[{
                  keys: ['customer.view'],
                  path: '/customer/customers',
                }, {
                  keys: ['billing_account.view'],
                  path: '/customer/accounts',
                }]}
              >
                <Customers titleKey="customers" />
              </ProtectedRoute>
            )}
            path="customer"
          >
            <Route
              element={(
                <ProtectedRoute authorizeKeys={['customer.view']}>
                  <CustomersCustomer />
                </ProtectedRoute>
              )}
              path="customers"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.edit']}>
                    <CustomerModule.ImportForm />
                  </ProtectedRoute>
                )}
                path="import"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.edit']}>
                    <CustomerModule.EditForm />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.edit']}>
                    <CustomerModule.EditForm />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.edit']}>
                    <CustomerModule.AvailableHourForm />
                  </ProtectedRoute>
                )}
                path=":id/configurations"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.view']}>
                    <CustomerModule.ViewForm />
                  </ProtectedRoute>
                )}
                path=":id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.edit']}>
                    <BillingAccountModule.NewForm />
                  </ProtectedRoute>
                )}
                path=":customer_id/accounts/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.edit']}>
                    <BillingAccountModule.EditForm />
                  </ProtectedRoute>
                )}
                path=":customer_id/accounts/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.edit']}>
                    <CustomerEpodSettingForm />
                  </ProtectedRoute>
                )}
                path=":id/epod_setting"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['customer.edit']}>
                    <CustomerEpodSettingForm />
                  </ProtectedRoute>
                )}
                path=":id/epod_setting/edit"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['billing_account.view']}>
                  <CustomersAccount />
                </ProtectedRoute>
              )}
              path="accounts"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.view']}>
                    <BillingAccountModule.ViewForm />
                  </ProtectedRoute>
                )}
                path=":id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.edit']}>
                    <BillingAccountModule.EditForm />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.edit']}>
                    <BillingAccountModule.ServiceRequirementsForm />
                  </ProtectedRoute>
                )}
                path=":id/service_requirements"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.edit']}>
                    <BillingAccountModule.LocationForm />
                  </ProtectedRoute>
                )}
                path=":account_id/location/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['billing_account.edit']}>
                    <BillingAccountModule.LocationForm />
                  </ProtectedRoute>
                )}
                path=":account_id/location/:id"
              />
            </Route>
          </Route>

          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={[
                  'account.view', 'custom_field_group.view', 'my_location.view',
                  'notificator.view', 'task_notification_setting.view', 'versadrive_setting.view',
                  'task_incompletion_reason.view', 'user.view',
                ]}
                redirectKeys={[
                  {
                    keys: ['account.view'],
                    path: '/settings/general',
                  },
                  {
                    keys: ['my_location.view'],
                    path: '/settings/our_locations',
                  },
                  {
                    keys: ['notificator.view', 'task_notification_setting.view'],
                    path: '/settings/notifications',
                  },
                  {
                    keys: ['versadrive_setting.view', 'task_incompletion_reason.view'],
                    path: '/settings/versadrive',
                  },
                  {
                    keys: ['user.view'],
                    path: '/settings/users',
                  },
                  {
                    keys: ['addons_setting.view'],
                    path: '/settings/add_ons',
                  },
                ]}
              >
                <Settings titleKey="settings" />
              </ProtectedRoute>
            )}
            path="settings"
          >
            <Route
              element={(
                <ProtectedRoute authorizeKeys={['account.view']}>
                  <SettingsGeneral />
                </ProtectedRoute>
              )}
              path="general"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <SettingsGeneralCompanyProfileViewForm />
                  </ProtectedRoute>
                )}
                path="company_profile"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.edit']}>
                    <SettingsGeneralCompanyProfileEditForm />
                  </ProtectedRoute>
                )}
                path="company_profile/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <SettingsGeneralAccountLanguageAndRegionViewForm />
                  </ProtectedRoute>
                )}
                path="account_language_region"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.edit']}>
                    <SettingsGeneralAccountLanguageAndRegionEditForm />
                  </ProtectedRoute>
                )}
                path="account_language_region/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <SettingsGeneralUnitAndShippingFactorForm />
                  </ProtectedRoute>
                )}
                path="unit_shipping_factor"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <SettingsGeneralTimeWindowViewForm />
                  </ProtectedRoute>
                )}
                path="time_windows"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.edit']}>
                    <SettingsGeneralTimeWindowEditForm />
                  </ProtectedRoute>
                )}
                path="time_windows/edit"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['my_location.view']}>
                  <SettingsOurLocations />
                </ProtectedRoute>
              )}
              path="our_locations"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['my_location.edit']}>
                    <SettingsOurLocationsEditForm />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['my_location.edit']}>
                    <SettingsOurLocationsEditForm />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['my_location.view']}>
                    <SettingsOurLocationsViewForm />
                  </ProtectedRoute>
                )}
                path=":id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['account.view', 'notificator.view', 'task_notification_setting.view', 'job_notification_setting.view']}>
                  <SettingsNotifications />
                </ProtectedRoute>
              )}
              path="notifications"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['notificator.view']}>
                    <AlertRules />
                  </ProtectedRoute>
                )}
                path="alert_rules"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['notificator.edit']}>
                    <AlertRulesEdit />
                  </ProtectedRoute>
                )}
                path="alert_rules/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['notificator.edit']}>
                    <AlertRulesEdit />
                  </ProtectedRoute>
                )}
                path="alert_rules/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['notificator.view']}>
                    <AlertRulesView />
                  </ProtectedRoute>
                )}
                path="alert_rules/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskEmailTrigger />
                  </ProtectedRoute>
                )}
                path="task_email_trigger"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskEmailTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="task_email_trigger/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskEmailTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="task_email_trigger/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskEmailTrigger.View />
                  </ProtectedRoute>
                )}
                path="task_email_trigger/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskSmsTrigger />
                  </ProtectedRoute>
                )}
                path="task_sms_trigger"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskSmsTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="task_sms_trigger/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskSmsTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="task_sms_trigger/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <TaskSmsTrigger.View />
                  </ProtectedRoute>
                )}
                path="task_sms_trigger/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobEmailTrigger />
                  </ProtectedRoute>
                )}
                path="job_email_trigger"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobEmailTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="job_email_trigger/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobEmailTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="job_email_trigger/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobEmailTrigger.View />
                  </ProtectedRoute>
                )}
                path="job_email_trigger/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobSmsTrigger />
                  </ProtectedRoute>
                )}
                path="job_sms_trigger"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobSmsTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="job_sms_trigger/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobSmsTrigger.Edit />
                  </ProtectedRoute>
                )}
                path="job_sms_trigger/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['account.view']}>
                    <JobSmsTrigger.View />
                  </ProtectedRoute>
                )}
                path="job_sms_trigger/:id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['versadrive_setting.view', 'task_incompletion_reason.view']}>
                  <SettingsVersadrive />
                </ProtectedRoute>
              )}
              path="versadrive"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveJobCompletionForm />
                  </ProtectedRoute>
                )}
                path="job_completion"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveTaskCompletionForm />
                  </ProtectedRoute>
                )}
                path="task_completion"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveRecipientVerificationForm />
                  </ProtectedRoute>
                )}
                path="recipient_verification"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveDriverAssignmentForm />
                  </ProtectedRoute>
                )}
                path="driver_assignment"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveDriverAcknowledgementForm />
                  </ProtectedRoute>
                )}
                path="driver_acknowledgement"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveDriverStartedForm />
                  </ProtectedRoute>
                )}
                path="driver_started"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveJobTaskSummaryLayoutForm />
                  </ProtectedRoute>
                )}
                path="job_summary_layout"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveJobTaskSummaryLayoutForm />
                  </ProtectedRoute>
                )}
                path="task_summary_layout"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['task_incompletion_reason.view']}>
                    <SettingsVersadriveTaskIncompletionForm />
                  </ProtectedRoute>
                )}
                path="task_incompletion"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveLineItemValidationForm />
                  </ProtectedRoute>
                )}
                path="line_item_validation"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveTaskSequenceLockForm />
                  </ProtectedRoute>
                )}
                path="task_sequence_lock"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveScanToIdentifyForm />
                  </ProtectedRoute>
                )}
                path="scan_to_identify"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadriveClickToChatForm />
                  </ProtectedRoute>
                )}
                path="click_to_chat"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['versadrive_setting.view']}>
                    <SettingsVersadrivePhotoTakingForm />
                  </ProtectedRoute>
                )}
                path="photo_taking"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['user.view']}>
                  <SettingsUsers />
                </ProtectedRoute>
              )}
              path="users"
            >
              <Route element={<Profile.View titleKey="profile" />} path="profile" />
              <Route element={<Profile.Edit titleKey="profile_edit" />} path="profile/edit" />
              <Route element={<Profile.ChangePassword titleKey="profile_change_password" />} path="profile/change_password" />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['user.create']}>
                    <UserModule.EditForm />
                  </ProtectedRoute>
                )}
                path="new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['user.edit']}>
                    <UserModule.EditForm />
                  </ProtectedRoute>
                )}
                path=":id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['user.view']}>
                    <UserModule.ViewForm />
                  </ProtectedRoute>
                )}
                path=":id"
              />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                  <SettingsAddons />
                </ProtectedRoute>
              )}
              path="add_ons"
            >
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsMyJobsForm />
                  </ProtectedRoute>
                )}
                path="myjobs"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsMyJobsTaskLayoutEditForm />
                  </ProtectedRoute>
                )}
                path="myjobs/task_view_edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <CustomerModule.MyJobsViewForm />
                  </ProtectedRoute>
                )}
                path="myjobs/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <CustomerModule.MyJobsEditForm />
                  </ProtectedRoute>
                )}
                path="myjobs/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsGeneralEpodSettingForm />
                  </ProtectedRoute>
                )}
                path="epod"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsGeneralEpodSettingForm />
                  </ProtectedRoute>
                )}
                path="epod/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsGeneralTaskLabelViewForm />
                  </ProtectedRoute>
                )}
                path="task_label"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsGeneralTaskLabelEditForm />
                  </ProtectedRoute>
                )}
                path="task_label/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsCustomFieldGroupViewForm />
                  </ProtectedRoute>
                )}
                path="custom_fields"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsCustomFieldGroupEditForm />
                  </ProtectedRoute>
                )}
                path="custom_fields/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsCustomFieldGroupEditForm />
                  </ProtectedRoute>
                )}
                path="custom_fields/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsLocationBasedFunction.ViewForm />
                  </ProtectedRoute>
                )}
                path="location_based_function"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsLocationBasedFunction.EditForm />
                  </ProtectedRoute>
                )}
                path="location_based_function/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsCustomTaskLayoutViewForm />
                  </ProtectedRoute>
                )}
                path="task_view"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsCustomTaskLayoutEditForm />
                  </ProtectedRoute>
                )}
                path="task_view/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsScheduledOptimisationsListForm />
                  </ProtectedRoute>
                )}
                path="scheduled_optimisation_group"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsScheduledOptimisationGroupEditForm />
                  </ProtectedRoute>
                )}
                path="scheduled_optimisation_group/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsScheduledOptimisationsRosterEditForm />
                  </ProtectedRoute>
                )}
                path="scheduled_optimisation_group/:group_id/roster/:id/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsScheduledOptimisationsRosterViewForm />
                  </ProtectedRoute>
                )}
                path="scheduled_optimisation_group/:group_id/roster/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsTrackingSetting.View />
                  </ProtectedRoute>
                )}
                path="tracking_setting"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsTrackingSetting.Edit />
                  </ProtectedRoute>
                )}
                path="tracking_setting/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <SettingsAddonsRunsheetManager.View />
                  </ProtectedRoute>
                )}
                path="runsheet_manager"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <SettingsAddonsRunsheetManager.Edit />
                  </ProtectedRoute>
                )}
                path="runsheet_manager/edit"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <ViewTasksRescheduling />
                  </ProtectedRoute>
                )}
                path="tasks_rescheduling"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.view']}>
                    <DetailTasksRescheduling />
                  </ProtectedRoute>
                )}
                path="tasks_rescheduling/:id"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <DetailTasksRescheduling />
                  </ProtectedRoute>
                )}
                path="tasks_rescheduling/new"
              />
              <Route
                element={(
                  <ProtectedRoute authorizeKeys={['addons_setting.edit']}>
                    <DetailTasksRescheduling />
                  </ProtectedRoute>
                )}
                path="tasks_rescheduling/:id/edit"
              />
            </Route>
          </Route>

          <Route
            element={(
              <ProtectedRoute authorizeKeys={['addons.view']}>
                <AddOns titleKey="addOns" />
              </ProtectedRoute>
            )}
            path="addons"
          >
            <Route element={<AddOnsViewForm />} path="all_you_can_plan_combo" />
            <Route element={<AddOnsViewForm />} path="custom_epod" />
            <Route element={<AddOnsViewForm />} path="custom_task_label" />
            <Route element={<AddOnsViewForm />} path="custom_field" />
            <Route element={<AddOnsViewForm />} path="task_view" />
            <Route element={<AddOnsViewForm />} path="scheduler" />
            <Route element={<AddOnsViewForm />} path="vroom" />
            <Route element={<AddOnsViewForm />} path="location_based_function" />
          </Route>

          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={['task.view', 'job.view', 'driver.view', 'vehicle.view', 'customer.view', 'user.view']}
                redirectKeys={[
                  { keys: ['task.view'], path: '/archives/tasks' },
                  { keys: ['job.view'], path: '/archives/tasks/jobs' },
                  { keys: ['driver.view'], path: '/archives/drivers' },
                  { keys: ['driver.view'], path: '/archives/drivers/records' },
                  { keys: ['vehicle.view'], path: '/archives/vehicles' },
                  { keys: ['vehicle.view'], path: '/archives/vehicles/parts' },
                  { keys: ['vehicle.view'], path: '/archives/vehicles/records' },
                  { keys: ['customer.view'], path: '/archives/customers' },
                  { keys: ['user.view'], path: '/archives/users' },
                  { keys: ['user.view'], path: '/archives/accounts' },
                ]}
              >
                <Archive titleKey="archives" />
              </ProtectedRoute>
            )}
            path="archives"
          >
            <Route
              element={(
                <ProtectedRoute authorizeKeys={['job.view']}>
                  <ArchiveJob />
                </ProtectedRoute>
              )}
              path="tasks/jobs"
            >
              <Route element={<PlanningJobViewForm />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['task.view']}>
                  <ArchiveTask />
                </ProtectedRoute>
              )}
              path="tasks"
            >
              <Route element={<TaskModule.ViewForm />} path=":id" />
              <Route element={<TaskModule.HistoryForm />} path=":id/history" />
              <Route element={<PlanningJobEditForm />} path="jobs/:id/edit" />
              <Route element={<PlanningTaskNew />} path="jobs/:id/tasks/new" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['driver.view']}>
                  <ArchiveDriverRecord />
                </ProtectedRoute>
              )}
              path="drivers/records"
            >
              <Route element={<ArchiveDriverRecordView />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['driver.view']}>
                  <ArchiveDriver />
                </ProtectedRoute>
              )}
              path="drivers"
            >
              <Route element={<FleetManagementDrivers.DriverView />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['vehicle.view']}>
                  <ArchiveVehiclePart />
                </ProtectedRoute>
              )}
              path="vehicles/parts"
            >
              <Route element={<ArchiveVehiclePartView />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['vehicle.view']}>
                  <ArchiveVehicleRecord />
                </ProtectedRoute>
              )}
              path="vehicles/records"
            >
              <Route element={<ArchiveVehicleRecordView />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['vehicle.view']}>
                  <ArchiveVehicle />
                </ProtectedRoute>
              )}
              path="vehicles"
            >
              <Route element={<FleetManagementVehicles.VehicleView />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['customer.view']}>
                  <ArchiveCustomer />
                </ProtectedRoute>
              )}
              path="customers"
            >
              <Route element={<CustomerModule.ViewForm />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['user.view']}>
                  <ArchiveUser />
                </ProtectedRoute>
              )}
              path="users"
            >
              <Route element={<UserModule.ViewForm />} path=":id" />
            </Route>

            <Route
              element={(
                <ProtectedRoute authorizeKeys={['user.view']}>
                  <ArchiveAccount />
                </ProtectedRoute>
              )}
              path="accounts"
            >
              <Route element={<BillingAccountModule.ViewForm />} path=":id" />
            </Route>
          </Route>

          <Route
            element={(
              <ProtectedRoute authorizeKeys={['xero.invoice_view']}>
                <Invoices titleKey="invoices" />
              </ProtectedRoute>
            )}
            path="invoices"
          >
            <Route element={<InvoicesViewForm />} path=":id" />
          </Route>

          <Route
            element={(
              <ProtectedRoute
                authorizeKeys={['payment.view']}
                redirectKeys={[{
                  keys: ['payment.view'],
                  path: '/billing/triggers',
                }]}
              >
                <Payment titleKey="billing" />
              </ProtectedRoute>
            )}
            path="billing"
          >
            {/* legacy commented route */}
            {/* <Route component={PaymentSubscription} path="subscriptions">
                  <Route component={SubscriptionPlan} path="plan" />
                  <Route component={SubscriptionVersadriveTopup} path="versadrive_topup" />
                  <Route component={SubscriptionVersadriveDownload} path="versadrive" />
                </Route> */}
            <Route
              element={(
                <ProtectedRoute authorizeKeys={['payment.view']}>
                  <PaymentTrigger />
                </ProtectedRoute>
              )}
              path="triggers"
            >
              <Route element={<EmailRecordsForm />} path="email_records" />
              <Route element={<SMSRecordsForm />} path="sms_records" />
              <Route element={<SMSTransactionDetailsForm />} path="transactions" />
              <Route element={<ExportForm />} path="export" />
            </Route>
          </Route>

          <Route element={<Integrations titleKey="integrations" />} path="integrations">
            <Route
              element={(
                <ProtectedRoute authorizeKeys={['xero.session_view']}>
                  <IntegrationsXero />
                </ProtectedRoute>
              )}
              path="xero"
            />
          </Route>

          <Route element={<Help titleKey="help" />} path="help" />

          <Route element={<Notification titleKey="notifications" />} path="notifications" />

        </Route>

        <Route element={<PageNotFound />} path="*" />
      </Route>
    </Routes>
  </Suspense>
);

export default routes;
