import Helper from 'utils/helper';

class AccessRightHelper {
  static hasAccess(accessRights, accessRightKey) {
    for (let i = 0; i < accessRights.length; i += 1) {
      const rights = accessRights[i];
      const splitKeys = accessRightKey.split('.');
      if (!Helper.isNullOrUndefined(rights[splitKeys[0]])
        && rights[splitKeys[0]].includes(splitKeys[1])) {
        return true;
      }
    }

    return false;
  }
}

export default AccessRightHelper;
