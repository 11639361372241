import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  layers: {},
  layerIds: [],
};

function layer(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_LAYERS':
      return {
        ...state,
        layers: ReduxHelper.mergeDataWithList(state.layers, action.layers),
        layerIds: action.layers.map(l => l.id),
      };
    case 'RECEIVE_LAYER':
      return {
        ...state,
        layers: ReduxHelper.mergeDataWithObject(state.layers, action.layer),
        layerIds: [action.layer.id],
      };
    default:
      return state;
  }
}

export default layer;
