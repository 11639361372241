import Color from 'utils/color';

// successful is used for base task
const labelColors = {
  in_progress: Color.$greenlighter,
  complete: Color.$green,
  successful: Color.$green,
  cancelled: Color.$redlighter,
};

const whiteTextLabel = {
  in_progress: false,
  complete: true,
  successful: true,
  cancelled: false,
};

export default {
  labelColors,
  whiteTextLabel,
};
