import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Appcues({ children }) {
  const location = useLocation();

  // We use appcues to show users a changelog when there’s a release to production
  // It should load no matter where the user is
  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.page();
    }
  }, [location]);

  return children;
}

export default Appcues;
