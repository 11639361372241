import React from 'react';
import PropTypes from 'prop-types';
import TranslationHelper from 'utils/translationHelper';
import { translate } from 'versafleet-core';

const DeleteSuccessMessage = props => ((
  <div className="vf-task__delete-success-message">
    <div className="vf-task__delete-success-message__icon">
      <i className="versa-delete" />
    </div>
    <div>
      {
        TranslationHelper.replaceTag(props.strings.page.deleteSuccess, {
          count: props.count,
        })
      }
    </div>
  </div>
));

DeleteSuccessMessage.displayName = 'Delete Success Message';

DeleteSuccessMessage.propTypes = {
  count: PropTypes.number,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

DeleteSuccessMessage.defaultProps = {
  count: 0,
};

export default translate('Task')(DeleteSuccessMessage);
