import React from 'react';

const DriverView = React.lazy(() => import('modules/driver/view'));
const DriverEdit = React.lazy(() => import('modules/driver/edit'));
const WorkingHourEdit = React.lazy(() => import('modules/driver/workingHourEdit'));
const VersaDriverDetailEdit = React.lazy(() => import('modules/driver/versaDriverDetailEdit'));
const Driver = React.lazy(() => import('./driver'));
const DriverCustomLayout = React.lazy(() => import('./driver/customLayout'));
const DriverSettings = React.lazy(() => import('./driver/settings'));
const DriverRecordList = React.lazy(() => import('./driver/records'));
const Record = React.lazy(() => import('./record'));
const RecordSetting = React.lazy(() => import('./record/template/setting'));
const RecordTemplate = React.lazy(() => import('./record/template'));
const RecordNew = React.lazy(() => import('./record/new'));
const RecordEdit = React.lazy(() => import('./record/edit'));
const RecordView = React.lazy(() => import('./record/view'));
const RecordExport = React.lazy(() => import('./record/export'));
const ImportForm = React.lazy(() => import('modules/driver/importForm'));

export default {
  Driver,
  DriverView,
  DriverEdit,
  WorkingHourEdit,
  VersaDriverDetailEdit,
  DriverCustomLayout,
  DriverSettings,
  DriverRecordList,
  Record,
  RecordSetting,
  RecordTemplate,
  RecordNew,
  RecordEdit,
  RecordView,
  RecordExport,
  ImportForm,
};
