import { Components } from 'versafleet-core';
import authorize from 'authorize';

import InputAttachment from './inputAttachment';
import Button from './button/button';
import Form from './form';

import InputMultipleGroups from './input/inputMultipleGroups';
import InputRadioButton from './input/inputRadioButton';
import InputSingleAttachment from './input/inputSingleAttachment';
import InputSlider from './input/inputSlider';
import InputToggle from './input/inputToggle';
import TiptapInput from './input/tiptapInput';

Components.InputAttachment = InputAttachment;
Components.Button = Button;
Components.Form = Form;

Components.Input.Tiptap = TiptapInput;
Components.Input.MultipleGroups = InputMultipleGroups;
Components.Input.RadioButton = InputRadioButton;
Components.Input.Select = authorize('')(Components.Input.Select);
Components.Input.SingleAttachment = InputSingleAttachment;
Components.Input.Slider = InputSlider;
Components.Input.Toggle = InputToggle;

export default Components;
