import { Utils } from 'versafleet-core';

function receiveVehicleSettings(vehicleSettings) {
  return {
    type: 'RECEIVE_VEHICLE_SETTINGS',
    vehicleSettings,
  };
}

function receiveVehicleSettingsUpdate(vehicleSettings) {
  return {
    type: 'RECEIVE_VEHICLE_SETTINGS_UPDATE',
    vehicleSettings,
  };
}

function fetch() {
  return async (dispatch) => {
    try {
      const result = await Utils.RequestHelper.request('/vehicle_setting', {
        method: 'GET',
      });
      dispatch(receiveVehicleSettings(result.vehicle_setting));
      return result.vehicle_setting;
    } catch (error) {
      if (Array.isArray(error) && error.length > 0 && error[0] === 'Not subscribed to vehicle config') {
        // Ignore this error to reduce Sentry usage
        return error;
      }
      throw error;
    }
  };
}

function update(query) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/vehicle_setting', {
      method: 'PUT',
    }, {
      vehicle_setting: query,
    });
    dispatch(receiveVehicleSettingsUpdate(result.vehicle_setting));
    return result.vehicle_setting;
  };
}
export default {
  fetch,
  update,
};
