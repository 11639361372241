import React from 'react';

const PageNotFound = () => {
  const deadEnd = "Oops! You've hit a dead end.";
  const checkURL = "If you're sure that you have the correct URL, then you may not have access to this page.";

  return (
    <div className="vf-page-not-found">
      <div className="vf-page-not-found__title">
        { deadEnd }
      </div>
      <img alt="404 Not Found" className="vf-page-not-found__image" src="/assets/images/404.svg" />
      <div className="vf-page-not-found__subtitle">
        { checkURL }
      </div>
      <img alt="Logo" className="vf-page-not-found__logo" src="/assets/images/versafleet.png" />
    </div>
  );
};

export default PageNotFound;
