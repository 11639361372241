import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import authorize from 'authorize';
import Button from './authorizedButton';

const ButtonAddMaintenance = props => (
  <Button
    hint={props.withDefaultText ? null : props.strings.text}
    iconClassName="versa-vehicle-maintenance-add"
    {...props}
  />
);

ButtonAddMaintenance.displayName = 'Button Add Maintenance';

ButtonAddMaintenance.propTypes = {
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonAddMaintenance.defaultProps = {
  withDefaultText: false,
};

export default translate('Component.Button.AddMaintenance')(authorize('vehicle.edit')(ButtonAddMaintenance));
