import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Color from 'utils/color';
import NewDialog from 'utils/newDialog';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';
import { connect } from 'react-redux';
import { Utils, translate } from 'versafleet-core';
import CompleteSuccessMessage from './completeSuccessMessage';

class CompleteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        is_partial_success: false,
      },
    };
  }

  _cancel = () => {
    NewDialog.DialogManager.close();
  }

  async _complete() {
    // There's only one id for completion right now. Refactor later if we want multiple complete.
    // Refer to deleteDialog for similar behaviour.
    if (this.props.dataIds.length === 1) {
      NewDialog.DialogManager.close();
      try {
        await this.props.dispatch(
          ReduxActions.task.complete(this.props.dataIds[0], this.state.form),
        );
        if (!Helper.isNullOrUndefined(this.props.taskFilter)) {
          this.props.dispatch(ReduxActions.task.fetchList(this.props.taskFilter));
        }
        Utils.SuccessMessage.SuccessMessageManager.flash(<CompleteSuccessMessage />);
      } catch (error) {
        // continue regardless of error
      }
    }
  }

  _onFormChange = (fieldName, value) => {
    this.setState(prevState => ({
      form: Helper.setFilter(prevState.form, fieldName, value),
    }));
  }

  render() {
    const strings = this.props.strings.completeDialog;
    return (
      <div className="vf-task__complete-dialog">
        <div className="vf-task__complete-dialog__header">
          <Component.CircleIcon
            className="vf-task__complete-dialog__header__icon"
            iconClassName="versa-completed"
            innerIconColor={Color.$green}
            outerIconColor={Color.$greenlighter}
          />
          <span className="vf-task__complete-dialog__header__text">
            {strings.header}
          </span>
        </div>

        <div className="vf-task__complete-dialog__content">
          <span className="vf-task__complete-dialog__content__description">
            {strings.description}
          </span>
          <Component.Input.Textbox
            multiLine
            onChange={value => this._onFormChange('note', value)}
            placeholder={strings.noteHint}
            value={this.state.form.note}
          />
        </div>

        <div className="vf-task__complete-dialog__buttons">
          <Component.Button
            onClick={this._cancel}
          >
            {strings.cancelButton}
          </Component.Button>
          <Component.Button
            className="vf-task__complete-dialog__buttons__confirm"
            onClick={() => this._complete()}
          >
            {strings.confirmButton}
          </Component.Button>
        </div>
      </div>
    );
  }
}

CompleteDialog.propTypes = {
  dataIds: PropTypes.arrayOf(PropTypes.number),
  dispatch: PropTypes.func.isRequired,
  strings: PropTypes.shape({
    completeDialog: PropTypes.shape({
      cancelButton: PropTypes.string,
      confirmButton: PropTypes.string,
      description: PropTypes.string,
      header: PropTypes.string,
      noteHint: PropTypes.string,
    }),
  }).isRequired,
  taskFilter: PropTypes.shape({}),
};

CompleteDialog.defaultProps = {
  dataIds: [],
  taskFilter: null,
};

export default connect()(translate('Task')(CompleteDialog));
