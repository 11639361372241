import Router from 'utils/router';
import { Utils } from 'versafleet-core';

const { RequestHelper: { request, convertDataToParams, requestCustomBody } } = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveVehiclePart(vehiclePart) {
  return {
    type: 'RECEIVE_VEHICLE_PART',
    vehiclePart,
  };
}

function receiveVehicleParts(result, timestamp) {
  return {
    type: 'RECEIVE_VEHICLE_PARTS',
    vehicleParts: result.vehicle_parts,
    meta: result.meta,
    timestamp,
  };
}

function receiveArchivedVehicleParts(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_VEHICLE_PARTS',
    vehicleParts: result.vehicle_parts,
    meta: result.meta,
    timestamp,
  };
}

function receiveOptionVehicleParts(optionVehicleParts) {
  return {
    type: 'RECEIVE_OPTION_VEHICLE_PARTS',
    optionVehicleParts,
  };
}

function receiveVehiclePartSkills(skills) {
  return {
    type: 'RECEIVE_VEHICLE_PART_SKILLS',
    skills,
  };
}

function receiveVehiclePartSystemTags(systemTags) {
  return {
    type: 'RECEIVE_VEHICLE_PART_SYSTEM_TAGS',
    systemTags,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_VEHICLE_PART_FILTER',
    filter,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/vehicle_parts/${id}`, {
      method: 'GET',
    });
    dispatch(receiveVehiclePart(result.vehicle_part));
    return result.vehicle_part;
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.vehiclePart.vehiclePartMeta.page,
      ...baseFilter,
      ...state.vehiclePart.filter,
      archived: false,
    };
    const param = convertDataToParams(data);
    const result = await request(`/vehicle_parts${param}`, {
      method: 'GET',
    });
    dispatch(receiveVehicleParts(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.vehiclePart.archivedVehiclePartMeta.page,
      ...baseFilter,
      ...state.vehiclePart.filter,
      archived: true,
    };
    const param = convertDataToParams(data);
    const result = await request(`/vehicle_parts${param}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedVehicleParts(result, timestamp));
  };
}

function fetchOptionList(filter) {
  const param = convertDataToParams(filter);
  return async (dispatch) => {
    const result = await request(`/vehicle_parts/list${param}`, {
      method: 'GET',
    });
    dispatch(receiveOptionVehicleParts(result.vehicle_parts));
    return result.vehicle_parts;
  };
}

function fetchSkills() {
  return async (dispatch) => {
    try {
      const result = await request('/vehicle_parts/skills', {
        method: 'GET',
      });
      dispatch(receiveVehiclePartSkills(result.skills));
      return result.skills;
    } catch (error) {
      if (Array.isArray(error) && error.length > 0 && error[0].includes('Not subscribed')) {
        // Ignore this error to reduce Sentry usage
        return error;
      }
      throw error;
    }
  };
}

function fetchSystemTags() {
  return async (dispatch) => {
    const result = await request('/vehicle_parts/system_tags', {
      method: 'GET',
    });
    dispatch(receiveVehiclePartSystemTags(result.tags));
    return result.tags;
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/vehicle_parts', {
      method: 'POST',
    }, {
      vehicle_part: query,
    });
    dispatch(receiveVehiclePart(result.vehicle_part));
    dispatch(fetchList());
    return result.vehicle_part;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/vehicle_parts/${id}`, {
      method: 'PUT',
    }, {
      vehicle_part: query,
    });
    dispatch(receiveVehiclePart(result.vehicle_part));
    return result.vehicle_part;
  };
}

function archive(id) {
  return async () => {
    const result = await request(`/vehicle_parts/${id}/archive`, {
      method: 'PUT',
    });
    return result.vehicle_part;
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/vehicle_parts/${id}/unarchive`, {
      method: 'PUT',
    });
    dispatch(fetchArchivedList());
    return result.vehicle_part;
  };
}

function archiveMultiple(ids) {
  return async () => {
    const result = await request('/vehicle_parts/archive', {
      method: 'PUT',
    }, {
      ids,
    });
    return result;
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    const result = await request('/vehicle_parts/unarchive', {
      method: 'PUT',
    }, {
      ids,
    });
    dispatch(fetchArchivedList());
    return result;
  };
}

function importData(query) {
  return async (dispatch) => {
    await requestCustomBody('/vehicle_parts/import', {
      method: 'POST',
    }, query);
    Router.transitionTo('/fleet/vehicles/parts/import?success=true');
    dispatch(fetchList());
  };
}

export default {
  setFilter,
  fetch,
  fetchList,
  fetchArchivedList,
  fetchOptionList,
  fetchSkills,
  fetchSystemTags,
  create,
  update,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  importData,
};
