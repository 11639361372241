import Color from 'utils/color';

const initialState = {
  jobsProgress: [{
    name: 'Unassigned',
    color: Color.getRgba(Color.$yellow, 0.5),
    y: 0,
  }, {
    name: 'In Progress',
    color: Color.$yellow,
    y: 0,
  }, {
    name: 'Completed',
    color: Color.$green,
    y: 0,
  }],
  jobsCreation: {
    data: { categories: [], data: [] },
    period: '',
  },
  activityFeed: [],
  driverAssignments: { categories: [], data: [], period: '' },
  vehicleAssignments: { categories: [], data: [], period: '' },
};

function dashboard(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_JOBS_PROGRESS_DATA':
      return {
        ...state,
        jobsProgress: [...action.jobsProgressData],
      };
    case 'RECEIVE_JOBS_CREATION_DATA':
      return {
        ...state,
        jobsCreation: {
          data: action.jobsCreationData.data,
          period: action.jobsCreationData.period,
        },
      };
    case 'RECEIVE_ACTIVITY_FEED_DATA':
      return {
        ...state,
        activityFeed: action.activityFeedData,
      };
    case 'RECEIVE_DRIVER_ASSIGNMENTS_DATA':
      return {
        ...state,
        driverAssignments: { ...action.driverAssignmentsData },
      };
    case 'RECEIVE_VEHICLE_ASSIGNMENTS_DATA':
      return {
        ...state,
        vehicleAssignments: { ...action.vehicleAssignmentsData },
      };
    case 'RECEIVE_VEHICLE_ACTIVITY_DATA':
      return {
        ...state,
        vehicleActivity: [...action.vehicleActivityData],
      };
    default:
      return state;
  }
}

export default dashboard;
