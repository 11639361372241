const initialState = {
  customLayout: {},
  customTaskLayoutTags: {},
};

function customLayout(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CUSTOM_LAYOUT':
      return {
        ...state,
        customLayout: action.customLayout,
      };
    case 'RECEIVE_CUSTOM_TASK_LAYOUT_TAGS':
      return {
        ...state,
        customTaskLayoutTags: action.customTaskLayoutTags,
      };
    default:
      return state;
  }
}

export default customLayout;
