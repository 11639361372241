import React from 'react';
import PropTypes from 'prop-types';
import authorize from 'authorize';
import { Utils } from 'versafleet-core';
import Component from 'components/component';
import Helper from 'utils/helper';
import TagForm from './tagForm';

class TagButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
    };
  }

  componentDidMount() {
    Utils.OutsideClickHandler.registerNode(this.button,
      this._setDropdown.bind(this, false));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAddingTag && this.props.isAddingTag) {
      this._setDropdown(false);
    }
  }

  componentWillUnmount() {
    Utils.OutsideClickHandler.removeNode(this.button);
  }

  _setDropdown(dropdown) {
    this.setState({
      dropdown,
    });
    if (!Helper.isNullOrUndefined(this.props.setButtonPersistent)) {
      this.props.setButtonPersistent(dropdown);
    }
  }

  _toggleDropdown() {
    this.setState(prevState => ({
      dropdown: !prevState.dropdown,
    }));
    if (!Helper.isNullOrUndefined(this.props.setButtonPersistent)) {
      this.props.setButtonPersistent(!this.state.dropdown);
    }
  }

  _onClick = () => {
    this._toggleDropdown();
    const id = this.props.taskId;
    if (!Helper.isNullOrUndefined(this.props.onClick)) {
      this.props.onClick(id);
    }
  }

  _renderButton() {
    const className = classNames('vf-task__tag__button', this.props.buttonClassName);
    return (
      <Component.Button
        {...this.props}
        className={className}
        onClick={this._onClick}
      />
    );
  }

  _renderDropdown() {
    if (this.state.dropdown) {
      const containerClassName = classNames({
        'vf-task__tag__form-container': true,
        'vf-task__tag__form-container--right': this.props.alignRight,
      });

      return (
        <Component.AutoPositionContainer className={containerClassName}>
          <TagForm
            id={this.props.id}
            loading={this.props.isAddingTag}
            tagMultiple={(ids, tagList) => this.props.tagMultiple(ids, tagList)}
            tags={this.props.tags}
            taskIds={this.props.taskIds}
          />
        </Component.AutoPositionContainer>
      );
    }

    return false;
  }

  render() {
    const buttonClassName = classNames('vf-task__tag', this.props.className);

    return (
      <div className={buttonClassName} ref={(node) => { this.button = node; }}>
        {this._renderButton()}
        {this._renderDropdown()}
      </div>
    );
  }
}

TagButton.propTypes = {
  alignRight: PropTypes.bool,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  isAddingTag: PropTypes.bool,
  onClick: PropTypes.func,
  setButtonPersistent: PropTypes.func,
  tagMultiple: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.string),
  taskId: PropTypes.number,
  taskIds: PropTypes.arrayOf(PropTypes.number),
};

TagButton.defaultProps = {
  alignRight: false,
  buttonClassName: null,
  className: null,
  id: null,
  isAddingTag: false,
  onClick: null,
  setButtonPersistent: null,
  tagMultiple: null,
  tags: null,
  taskId: null,
  taskIds: null,
};

TagButton.displayName = 'Task Tag Button';

export default authorize('task.edit')(TagButton);
