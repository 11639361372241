import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from 'reducers';

export default function configureStore(initialState) {
  const middlewares = [thunkMiddleware];
  if (__DEV__) {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
  }

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares),
  );
}
