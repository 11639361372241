const initialState = {
  accountCodes: [],
  xeroData: null,
  taskIds: [],
  meta: {},
};

function xero(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_XERO_DATA':
      return {
        ...state,
        xeroData: action.xeroData,
      };
    case 'RECEIVE_ACCOUNT_CODES':
      return {
        ...state,
        accountCodes: action.accountCodes,
      };
    case 'RECEIVE_XERO_LOGOUT_SUCCESSFUL':
      return {
        ...state,
        xeroData: null,
      };
    case 'RECEIVE_INVOICE_TASKS':
      return {
        ...state,
        taskIds: action.tasks.map(task => task.id),
        meta: action.meta,
      };
    default:
      return state;
  }
}

export default xero;
