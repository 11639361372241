import React from 'react';
import PropTypes from 'prop-types';
import authorize from 'authorize';
import { Components, translate } from 'versafleet-core';

const CloseWarningModal = (props) => {
  const strings = props.strings;

  return (
    <Components.Modal.Warning
      headerText={strings.header}
      leftButtonOnClick={props.leftButtonOnClick}
      leftButtonText={strings.dontSave}
      rightButtonOnClick={props.rightButtonOnClick}
      rightButtonText={strings.back}
      visible={props.visible}
    >
      {strings.description}
    </Components.Modal.Warning>
  );
};

CloseWarningModal.displayName = 'Form Close Warning Modal';

CloseWarningModal.propTypes = {
  leftButtonOnClick: PropTypes.func,
  rightButtonOnClick: PropTypes.func,
  strings: PropTypes.shape().isRequired,
  visible: PropTypes.bool,
};

CloseWarningModal.defaultProps = {
  leftButtonOnClick: null,
  rightButtonOnClick: null,
  visible: false,
};

export default authorize('')(translate('Component.Form.CloseWarningModal')((CloseWarningModal)));
