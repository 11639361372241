import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Component from 'components/component';
import { Utils, translate } from 'versafleet-core';
import Color from 'utils/color';
import Router from 'utils/router';

// eslint-disable-next-line react/prefer-stateless-function
class TaskGroupItem extends React.Component {
  _showCustomId() {
    const { versadriveSettings } = this.props;
    return versadriveSettings.delivery_job_scan_enabled
      || versadriveSettings.delivery_task_scan_enabled
      || versadriveSettings.pickup_job_scan_enabled
      || versadriveSettings.pickup_task_scan_enabled;
  }

  render() {
    const {
      task, index, job, modelName, afterArchive,
    } = this.props;

    const stateDividerClassName = 'vf-task__group-item__state-divider';
    let outerIconColor = Color.$bluelighter3;
    switch (task.state) {
      case 'unassigned':
        outerIconColor = Color.$gold;
        break;
      case 'successful':
        outerIconColor = Color.$green;
        break;
      case 'started':
      case 'acknowledged':
      case 'collected':
        outerIconColor = Color.getRgba(Color.$green, 0.5);
        break;
      case 'assigned':
      case 'waiting_for_acknowledgement':
        outerIconColor = Color.getRgba(Color.$gold, 0.5);
        break;
      case 'failed':
        outerIconColor = Color.$red;
        break;
      case 'cancelled':
        outerIconColor = Color.getRgba(Color.$red, 0.5);
        break;
      default:
        break;
    }

    let iconClassName = 'vf-task__group-item__icon';
    if (['unassigned', 'successful', 'started', 'assigned', 'failed',
      'acknowledged', 'collected', 'waiting_for_acknowledgement', 'cancelled'].includes(task.state)) {
      iconClassName += ' vf-task__group-item__icon--inverted';
    }

    const id = task.guid;
    const address = Utils.Formatter.getInlineAddress(Utils.Helper.isNullOrUndefined(task.address)
      ? task.address_attributes : task.address);
    let jobType = '';
    if (!Utils.Helper.isNullOrUndefined(job)) {
      jobType = job.job_type;
    } else {
      jobType = task.job.job_type;
    }
    const icon = classNames(iconClassName, {
      'versa-warehouse': task.role === 'base',
      'versa-arrow-up': task.role !== 'base' && jobType === 'pickup',
      'versa-arrow-down': task.role !== 'base' && jobType !== 'pickup',
    });

    const taskMeasurements = (Utils.Helper.isNullOrUndefined(task.measurements)
      ? task.measurements_attributes : task.measurements) || [];
    const measurementList = taskMeasurements.map((measurement, i) => {
      const { quantity, description, weight } = measurement;
      const quantityUnit = measurement.quantity_unit;
      const volumeHeight = measurement.volume_height;
      const volumeLength = measurement.volume_length;
      const volumeWidth = measurement.volume_width;
      const customItemId = !Utils.Helper.isNullOrUndefined(measurement.custom_item_id)
        && this._showCustomId() ? measurement.custom_item_id : '';

      const { account } = this.props;

      const hasWeight = (!Utils.Helper.isNullOrUndefined(weight));
      const hasDimensions = (
        !Utils.Helper.isNullOrUndefined(volumeLength)
          && !Utils.Helper.isNullOrUndefined(volumeHeight)
          && !Utils.Helper.isNullOrUndefined(volumeWidth)
      );
      const hasVolume = !Utils.Helper.isNullOrUndefined(measurement.volume);

      // Calculate the volume
      const volume = (
        Utils.Helper.isNullOrUndefined(measurement.volume)
          ? volumeLength * volumeWidth * volumeHeight : measurement.volume
      );

      const measurementUnits = Utils.Helper.getMeasurementUnits();
      const volumeUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
        ? measurementUnits[account.unit_system].volumeUnit : 'in³';
      const weightUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
        ? measurementUnits[account.unit_system].weightUnit : 'lb';

      return (
        <div className="vf-task__group-item__measurements" key={measurement.id}>
          <div>
            <span className="vf-task__group-item__measurements__index">{`${i + 1}.`}</span>
            <span>{`${quantity} x ${quantityUnit} ${customItemId}`}</span>
          </div>
          <div className="vf-task__group-item__measurements__description">
            {(
              Utils.Helper.isNullOrUndefined(measurement.description)
                || measurement.description === ''
            ) ? false : (
              <div>
                {`${description}`}
              </div>
              )}
            <div>
              {!hasWeight ? '' : `${weight} ${weightUnit}, `}
              {!hasVolume && !hasDimensions ? ''
                : `${Utils.Helper.roundUpToTwoDecimalPlaces(volume)} ${volumeUnit}, `}
              {(!hasVolume && !hasDimensions)
                || Utils.Helper.isNullOrUndefined(account.shipping_factor)
                ? '' : `${Utils.Helper.calculateVolumetricWeight(
                  volume,
                  account.shipping_factor,
                  account.unit_system,
                )}${weightUnit}`}
            </div>
          </div>
        </div>
      );
    });

    let contactDetails = '';
    const taskAddress = Utils.Helper.isNullOrUndefined(task.address)
      ? task.address_attributes : task.address;

    if (Utils.Helper.isNullOrUndefined(taskAddress.contact_person)) {
      if (!Utils.Helper.isNullOrUndefined(taskAddress.contact_number)) {
        contactDetails = taskAddress.contact_number;
      }
    } else {
      contactDetails = taskAddress.contact_person;
      if (!Utils.Helper.isNullOrUndefined(taskAddress.contact_number)) {
        contactDetails = `${contactDetails}, ${taskAddress.contact_number}`;
      }
    }

    let title = '';

    if (!Utils.Helper.isNullOrUndefined(task.billing_account)) {
      title = task.billing_account.name;
    } else if (taskAddress) {
      title = taskAddress.contact_person;
    } else {
      title = task.guid;
    }

    let status = false;
    if (task.role !== 'base') {
      status = this.props.strings[task.state];
      if (!status) {
        status = Utils.Formatter.removeUnderscore(
          Utils.Formatter.capitalizeFirstLetter(task.state),
        );
      }
    }

    return (
      <Component.Form.GroupItem
        afterArchive={afterArchive}
        archivePath={() => Router.transitionTo('/archives/tasks')}
        className="vf-task__group-item"
        id={this.props.id}
        index={index}
        key={`taskitem-${index}`}
        modelName={modelName}
        onArchive={this.props.onArchive}
        onDelete={this.props.onDelete}
        onEdit={this.props.onEdit}
      >
        <Component.Form.Dropdown
          id={this.props.id}
          parent={(
            <Component.Form.MainHeader
              iconClassName={icon}
              iconText=""
              outerIconColor={outerIconColor}
              subtitle={(
                <div>
                  <span>{id}</span>
                  {task.role === 'base' ? false : (
                    <span
                      className={stateDividerClassName}
                      style={{ backgroundColor: outerIconColor }}
                    />
                  )}
                  {status}
                </div>
              )}
              title={title}
            />
          )}
          showByDefault={false}
        >
          <div>
            <Component.Form.Divider />
            <Component.Form.Block iconClassName="versa-person" level={2}>
              {contactDetails}
            </Component.Form.Block>

            <Component.Form.Divider />
            <Component.Form.Block iconClassName="versa-marker-plain" level={2}>
              {address}
            </Component.Form.Block>

            {task.role === 'base' ? false : (
              <div>
                <Component.Form.Divider />
                <Component.Form.Block iconClassName="versa-subject" level={2}>
                  {measurementList}
                </Component.Form.Block>
              </div>
            )}

            <Component.Form.Divider />
          </div>
        </Component.Form.Dropdown>
      </Component.Form.GroupItem>
    );
  }
}

TaskGroupItem.displayName = 'Task Group Item';

TaskGroupItem.propTypes = {
  account: PropTypes.shape({
    shipping_factor: PropTypes.number,
    unit_system: PropTypes.string,
  }).isRequired,
  afterArchive: PropTypes.func,
  id: PropTypes.string,
  index: PropTypes.number,
  job: PropTypes.shape({
    job_type: PropTypes.string,
  }),
  modelName: PropTypes.string,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  strings: PropTypes.shape().isRequired,
  task: PropTypes.shape({
    address: PropTypes.shape({}),
    address_attributes: PropTypes.shape({}),
    billing_account: PropTypes.shape({
      name: PropTypes.string,
    }),
    guid: PropTypes.string,
    job: PropTypes.shape({
      job_type: PropTypes.string,
    }),
    measurements: PropTypes.arrayOf(PropTypes.shape({})),
    measurements_attributes: PropTypes.arrayOf(PropTypes.shape()),
    role: PropTypes.string,
    state: PropTypes.string,
  }),
  versadriveSettings: PropTypes.shape({
    delivery_job_scan_enabled: PropTypes.bool,
    delivery_task_scan_enabled: PropTypes.bool,
    pickup_job_scan_enabled: PropTypes.bool,
    pickup_task_scan_enabled: PropTypes.bool,

  }).isRequired,
};

TaskGroupItem.defaultProps = {
  afterArchive: null,
  index: null,
  id: null,
  job: null,
  modelName: '',
  onArchive: null,
  onDelete: null,
  onEdit: null,
  task: null,
};

function mapStateToProps(state) {
  const { versadriveSettings } = state;
  return {
    versadriveSettings: versadriveSettings.versadriveSettings,
  };
}

export default connect(mapStateToProps)(translate('Task.status')(TaskGroupItem));
