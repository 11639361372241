import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import ReduxActions from 'reduxActions';
import { connect } from 'react-redux';
import Helper from 'utils/helper';
import Router from 'utils/router';
import authorize from 'authorize';

class TaskBulkArchiveButton extends React.Component {
  _archive = async () => {
    if (!Helper.isNullOrUndefined(this.props.dataIds) && this.props.dataIds.length > 0) {
      await this.props.dispatch(ReduxActions.task.archiveMultiple(this.props.dataIds));
    }
  }

  render() {
    return (
      <Component.Archive
        archive={this._archive}
        archivePath={() => Router.transitionTo('/archives/tasks')}
        delete={this.props.delete}
        ids={this.props.dataIds}
        modelName={this.props.strings.Common.models.task.singular}
        updateContent={this.props.onSuccessfulUpdate}
      >
        <Component.Button
          className="vf-task__bulk-archive-button"
          hint={this.props.strings.page.buttons.archive}
          iconClassName="versa-archive"
          id={this.props.id}
        />
      </Component.Archive>
    );
  }
}

TaskBulkArchiveButton.displayName = 'Task Bulk Archive Button';

TaskBulkArchiveButton.propTypes = {
  dataIds: PropTypes.arrayOf(PropTypes.number),
  delete: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  onSuccessfulUpdate: PropTypes.func,
  strings: PropTypes.shape({
    Common: PropTypes.shape({
      models: PropTypes.shape({
        task: PropTypes.shape({
          singular: PropTypes.string,
        }),
      }),
    }),
    page: PropTypes.shape({
      buttons: PropTypes.shape({
        archive: PropTypes.string,
      }),
    }),
  }).isRequired,
};

TaskBulkArchiveButton.defaultProps = {
  dataIds: [],
  delete: null,
  id: '',
  onSuccessfulUpdate: () => {},
};

export default connect()(translate('Task')(authorize('')(TaskBulkArchiveButton)));
