import React from 'react';

const VehicleView = React.lazy(() => import('modules/vehicle/view'));
const VehicleEdit = React.lazy(() => import('modules/vehicle/edit'));
const VehicleImport = React.lazy(() => import('modules/vehicle/importForm'));
const VehiclePartView = React.lazy(() => import('modules/vehiclePart/view'));
const VehiclePartEdit = React.lazy(() => import('modules/vehiclePart/edit'));
const Vehicle = React.lazy(() => import('./vehicle'));
const VehicleCustomLayout = React.lazy(() => import('./vehicle/customLayout'));
const VehicleSettings = React.lazy(() => import('./vehicle/settings'));
const VehicleRecordList = React.lazy(() => import('./vehicle/records'));
const Part = React.lazy(() => import('./part'));
const VehiclePartImport = React.lazy(() => import('modules/vehiclePart/importForm'));
const VehiclePartCustomLayout = React.lazy(() => import('./part/customLayout'));
const VehiclePartSettings = React.lazy(() => import('./part/settings'));
const VehiclePartRecordList = React.lazy(() => import('./part/records'));
const Record = React.lazy(() => import('./record'));
const RecordSetting = React.lazy(() => import('./record/template/setting'));
const RecordTemplate = React.lazy(() => import('./record/template'));
const RecordNew = React.lazy(() => import('./record/new'));
const RecordEdit = React.lazy(() => import('./record/edit'));
const RecordView = React.lazy(() => import('./record/view'));
const RecordExport = React.lazy(() => import('./record/export'));

export default {
  Vehicle,
  VehicleView,
  VehicleEdit,
  VehicleImport,
  VehicleCustomLayout,
  VehicleSettings,
  VehicleRecordList,
  Part,
  VehiclePartImport,
  VehiclePartView,
  VehiclePartEdit,
  VehiclePartCustomLayout,
  VehiclePartSettings,
  VehiclePartRecordList,
  Record,
  RecordSetting,
  RecordTemplate,
  RecordNew,
  RecordEdit,
  RecordView,
  RecordExport,
};
