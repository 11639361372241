const initialState = {
  constant: {
    countries: [],
    drivers: {
      statuses: [],
    },
    jobs: {
      quantity_units: [],
      states: [],
      tags: [],
    },
    notificator: {
      actions: [],
      categories: [],
      minutes: [],
      triggers: [],
    },
    tasks: {
      quantity_units: [],
      tags: [],
      states: [],
      time_types: [],
    },
    users: {
      roles: [],
    },
    vehicle_locators: {
      vehicle_locator_provider_types: [],
    },
    vehicles: {
      statutes: [],
    },
    xero: {
      invoices: {
        statuses: [],
      },
    },
  },
  timeZoneList: [],
  countryList: [],
};

function constant(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CONSTANTS':
      return {
        ...state,
        constant: {
          ...action.constants,
        },
      };
    case 'RECEIVE_TIME_ZONES':
      return {
        ...state,
        timeZoneList: action.time_zones,
      };
    case 'RECEIVE_COUNTRIES':
      return {
        ...state,
        countryList: action.countries,
      };
    default:
      return state;
  }
}

export default constant;
