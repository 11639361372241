import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import { connect } from 'react-redux';
import Helper from 'utils/helper';

const ActionPanelFilterJobTagButton = (props) => {
  const {
    id, value, onChange, optionTags, strings, className, filterClassName,
  } = props;
  const optionsData = optionTags.map(option => ({ value: option, text: option }));
  const options = Helper.encodeURIComponentTagValue(optionsData);

  const text = value.length <= 1
    ? strings.tag.item
    : `${value.length > 1 && value.length < options.length
      ? strings.filter.multiple
      : strings.filter.all} ${strings.tag.itemPlural}`;

  const subText = value.length === 1 ? Helper.decodeURIComponentTagValue(value[0]) : undefined;

  return (
    <Component.ActionPanel.DropdownButton
      className={className}
      id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
      showArrow
      subText={subText}
      text={text}
    >
      <Component.ActionPanel.FilterTagDropdown
        className={filterClassName}
        error={strings.tag.error}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-tag-dropdown`}
        onChange={v => onChange(v)}
        options={options}
        placeholder={strings.tag.placeholder}
        value={value}
      />
    </Component.ActionPanel.DropdownButton>
  );
};

ActionPanelFilterJobTagButton.displayName = 'Action Panel Filter Job Tag Button';

ActionPanelFilterJobTagButton.propTypes = {
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  optionTags: PropTypes.arrayOf(PropTypes.string),
  strings: PropTypes.shape({
    filter: PropTypes.shape({
      all: PropTypes.string,
      multiple: PropTypes.string,
    }),
    tag: PropTypes.shape({
      error: PropTypes.string,
      item: PropTypes.string,
      itemPlural: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  }),
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterJobTagButton.defaultProps = {
  className: null,
  filterClassName: null,
  id: null,
  onChange: null,
  strings: {},
  value: [],
  optionTags: [],
};

function mapStateToProps(state) {
  const { constant } = state;
  return {
    optionTags: constant.constant.jobs.tags,
  };
}

export default connect(mapStateToProps)(translate('Job')(ActionPanelFilterJobTagButton));
