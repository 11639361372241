import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  jobs: {},
  jobsIds: [],
  archivedJobIds: [],
  jobMeta: {},
  archivedJobMeta: {},
  filter: {},
  taskCount: null,
  pendingJobCount: 0,
  importTimestamp: null,
};

function job(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_JOBS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          jobsIds: action.jobs.map(_job => _job.id),
          jobs: ReduxHelper.mergeDataWithList(state.jobs, action.jobs),
          jobMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_JOBS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedJobIds: action.jobs.map(_job => _job.id),
          jobs: ReduxHelper.mergeDataWithList(state.jobs, action.jobs),
          archivedJobMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_JOB_UPDATE_SUCCESSFUL':
      return {
        ...state,
        jobs: ReduxHelper.mergeDataWithObject(state.jobs, action.job),
      };
    case 'RECEIVE_JOB_GEOLOCATION_UPDATE_SUCCESSFUL': {
      const newState = { ...state };
      if (!Helper.isNullOrUndefined(state.jobs[action.id])) {
        newState.jobs = ReduxHelper.mergeDataWithObject(state.jobs, {
          ...state.jobs[action.id],
          base_task: {
            ...state.jobs[action.id].base_task,
            address: {
              ...state.jobs[action.id].base_task.address,
              latitude: action.location.latitude,
              longitude: action.location.longitude,
            },
          },
        });
      }
      return newState;
    }
    case 'RECEIVE_EXPORT_TASK_COUNT_SUCCESSFUL':
      return {
        ...state,
        taskCount: action.taskCount,
      };
    case 'RECEIVE_RESET_JOB_EXPORT_TASK_COUNT':
      return {
        ...state,
        taskCount: null,
      };
    case 'SET_JOB_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    case 'RECEIVE_PENDING_JOB_NOTIFICATION_COUNT':
      return {
        ...state,
        pendingJobCount: action.count,
      };
    case 'RECEIVE_IMPORT_TIMESTAMP':
      return {
        ...state,
        importTimestamp: action.timestamp,
      };
    default:
      return state;
  }
}

export default job;
