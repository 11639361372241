import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import Formatter from 'utils/formatter';
import Helper from 'utils/helper';
import TranslationHelper from 'utils/translationHelper';

const ActionPanelFilterTypeButton = (props) => {
  const {
    id, value, onChange, options, strings, className, filterClassName, model,
  } = props;
  const replaceMap = { model: Formatter.capitalizeFirstLetter(model || strings.constants.task) };
  let text;
  if (value.length === 1) {
    text = Formatter.capitalizeFirstLetter(strings.type[value[0]]);
  } else if (value.length === options.length) {
    text = `${strings.filter.all} ${TranslationHelper.replaceTag(strings.type.itemPlural, replaceMap).join('')}`;
  } else if (value.length === 0) {
    text = `${strings.filter.no} ${TranslationHelper.replaceTag(strings.type.item, replaceMap).join('')}`;
  } else {
    text = `${value.length} ${TranslationHelper.replaceTag(strings.type.itemPlural, replaceMap).join('')}...`;
  }

  return (
    <Component.ActionPanel.DropdownButton
      className={className}
      id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
      showArrow
      text={text}
    >
      <Component.ActionPanel.FilterCheckbox
        className={filterClassName}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-checkbox`}
        onChange={v => onChange(v)}
        options={options}
        values={value}
      />
    </Component.ActionPanel.DropdownButton>
  );
};

ActionPanelFilterTypeButton.displayName = 'Action Panel Filter Type Button';

ActionPanelFilterTypeButton.propTypes = {
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  model: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    constants: PropTypes.shape({
      task: PropTypes.string,
    }).isRequired,
    filter: PropTypes.shape({
      all: PropTypes.string,
      no: PropTypes.string,
    }).isRequired,
    type: PropTypes.shape({
      itemPlural: PropTypes.string,
      item: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterTypeButton.defaultProps = {
  className: '',
  filterClassName: '',
  id: '',
  model: '',
  onChange: null,
  options: [],
  value: [],
};

export default translate('Task')(ActionPanelFilterTypeButton);
