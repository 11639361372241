import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'versafleet-core';
import Component from 'components/component';
import ReduxActions from 'reduxActions';
import Helper from 'utils/helper';
import addOnsSettings from 'pages/addOns/addOnsConstants';
import styles from 'stylesheets/modules/task.styl';
import AssignWindowForm from './assignWindowForm';
import CancelWarningModal from '../cancelWarningModal';

class AssignButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      showCancelWarningModal: false,
      showModal: false,
      isAssigningTask: false,
      form: this._getDefaultForm(),
    };
    this.div = null;
    this.filteredTaskIds = !Helper.isNullOrUndefined(props.taskIds)
      ? props.taskIds.filter(taskId => props.tasks[taskId].state !== 'cancelled') : [];
  }

  componentDidUpdate(prevProps) {
    const { taskIds } = this.props;

    if (this.props.data !== prevProps.data) {
      this._updateForm();
    }
    if (!Helper.isNullOrUndefined(taskIds) && taskIds !== prevProps.taskIds) {
      this.filteredTaskIds = taskIds.filter(taskId => this.props.tasks[taskId].state !== 'cancelled');
    }
  }

  _updateForm = () => {
    this.setState({ form: this._getDefaultForm() });
  }

  _onClick = () => {
    const id = this.props.taskId;

    if (!Helper.isNullOrUndefined(this.props.onClick)) {
      this.props.onClick(id);
    }

    if (!Helper.isNullOrUndefined(this.props.taskIds) && !this.state.dropdown) {
      if (this.filteredTaskIds.length < this.props.taskIds.length) {
        this.setState({ showCancelWarningModal: true });
      } else {
        this._toggleDropdown();
      }
    } else {
      this._toggleDropdown();
    }
  }

  _createDriver = async (driverName) => {
    const newDriver = await this.props.dispatch(
      ReduxActions.driver.create({ name: driverName }),
    );
    return newDriver;
  }

  _createVehicle = async (vehiclePlateNumber) => {
    const newVehicle = await this.props.dispatch(
      ReduxActions.vehicle.create({ plate_number: vehiclePlateNumber }),
    );
    return newVehicle;
  }

  _onFormChange = async (fieldName, value) => {
    try {
      if (fieldName === 'driver_id' && !Helper.isNullOrUndefined(value) && this._findDriver(value)) {
        const vehicleId = !Helper.isNullOrUndefined(this._findDriver(value).default_vehicle)
          ? this._findDriver(value).default_vehicle.id : null;
        let attendantId = null;

        if (!Helper.isNullOrUndefined(this.props.addonsSetting)
            && this.props.addonsSetting.attendants_and_config === addOnsSettings.subscribed) {
          const driver = await this.props.dispatch(ReduxActions.driver.fetch(value));
          attendantId = !Helper.isNullOrUndefined(driver.attendant)
            ? driver.attendant.id : null;
        }

        this.setState(prevState => ({
          form: {
            ...prevState.form,
            driver_id: value,
            attendant_id: attendantId,
            vehicle_id: vehicleId,
            vehicle_part_id: null,
          },
        }));
      } else {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            [fieldName]: value,
          },
        }));
      }
    } catch (e) {
      //
    }
  }

  _onBlur = () => {
    if (!this.mouseDown) {
      this.setState({ dropdown: false });
    }
  }

  _assign = async () => {
    this.setState({ isAssigningTask: true });

    try {
      const form = { ...this.state.form };

      // Create a new driver if they're not in the list of options
      if (form.driver_id && !this._findDriver(form.driver_id)) {
        const newDriver = await this._createDriver(form.driver_id);
        form.driver_id = newDriver.id;
      }

      // Create a new vehicle if it's not in the list of options
      if (form.vehicle_id && !this._findVehicle(form.vehicle_id)) {
        const newVehicle = await this._createVehicle(form.vehicle_id);
        form.vehicle_id = newVehicle.id;
      }

      if (!Helper.isNullOrUndefined(this.props.taskId)) {
        const params = {
          estimated_start_time: form.estimated_start_time,
          remarks: form.remarks,
          attendant_id: form.attendant_id,
          driver_id: form.driver_id,
          vehicle_id: form.vehicle_id,
          vehicle_part_id: form.vehicle_part_id,
        };

        await this.props.dispatch(
          this.props.isTaskTransfer
            ? ReduxActions.task.transfer(this.props.taskId, params)
            : ReduxActions.task.assign(this.props.taskId, params),
        );
        this.props.onSuccessfulUpdate();
      } else {
        const params = {
          ids: this.filteredTaskIds,
          attendant_id: form.attendant_id,
          driver_id: form.driver_id,
          vehicle_id: form.vehicle_id,
          vehicle_part_id: form.vehicle_part_id,
          estimated_start_time: form.estimated_start_time,
          remarks: form.remarks,
        };

        await this.props.dispatch(
          this.props.isTaskTransfer
            ? ReduxActions.task.transferMultiple(params)
            : ReduxActions.task.assignMultiple(params),
        );
        this.props.onSuccessfulUpdate();
      }
      this._closeModal();
    } catch {
      //
    }
    this.setState({ isAssigningTask: false });
  }

  _showModal = () => {
    this.setState({
      showModal: true,
    });
  }

  _closeModal = () => {
    this.setState({ showModal: false });
    this._setDropdown(false);
  }

  _findDriver(id) {
    return this.props.options.driver.find(driver => driver.id === id) || null;
  }

  _findVehicle(id) {
    return this.props.options.vehicle.find(vehicle => vehicle.id === id) || null;
  }

  _getDefaultForm() {
    const data = this.props.data || {};
    const est = new Date(data.estimated_start_time);

    return {
      driver_id: data.driver_id,
      attendant_id: data.attendant_id,
      vehicle_id: data.vehicle_id,
      vehicle_part_id: data.vehicle_part_id,
      estimated_start_time: data.estimated_start_time && ((est.getHours() * 60) + est.getMinutes()),
      remarks: data.remarks,
    };
  }

  _setDropdown(isDropdown) {
    this.setState({
      dropdown: isDropdown,
    });
    if (!Helper.isNullOrUndefined(this.props.setButtonPersistent)) {
      this.props.setButtonPersistent(isDropdown);
    }
  }

  _toggleDropdown() {
    this.setState(prevState => ({
      dropdown: !prevState.dropdown,
    }));

    if (!Helper.isNullOrUndefined(this.props.setButtonPersistent)) {
      this.props.setButtonPersistent(this.state.dropdown);
    }
  }

  _renderButton() {
    const className = classNames(styles['vf-task__assign__button'], this.props.buttonClassName);

    return (
      <Component.Button
        {...this.props}
        className={className}
        onClick={this._onClick}
      />
    );
  }

  _renderDropdown() {
    if (this.state.dropdown) {
      const containerClassName = classNames({
        [styles['vf-task__assign__form-container']]: true,
        [styles['vf-task__assign__form-container--right']]: this.props.alignRight,
      });

      return (
        <Component.AutoPositionContainer className={containerClassName}>
          {
            Helper.isNullOrUndefined(this.props.taskId)
              ? (
                <AssignWindowForm
                  assign={this._assign}
                  data={this.state.form}
                  id={this.props.id}
                  isAssigningTask={this.state.isAssigningTask}
                  isTaskTransfer={this.props.isTaskTransfer}
                  onFormChange={this._onFormChange}
                  onMouseDown={() => { this.mouseDown = true; }}
                  onMouseUp={() => { this.mouseDown = false; }}
                  showModal={this._showModal}
                  taskIds={this.filteredTaskIds}
                />
              ) : (
                <AssignWindowForm
                  assign={this._assign}
                  data={this.state.form}
                  id={this.props.id}
                  isAssigningTask={this.state.isAssigningTask}
                  isTaskTransfer={this.props.isTaskTransfer}
                  onFormChange={this._onFormChange}
                  onMouseDown={() => { this.mouseDown = true; }}
                  onMouseUp={() => { this.mouseDown = false; }}
                  showModal={this._showModal}
                  taskId={this.props.taskId}
                />
              )
          }
        </Component.AutoPositionContainer>
      );
    }
    return false;
  }

  _renderModal() {
    const { strings } = this.props;
    return (
      <Component.Modal.Confirmation
        leftButtonOnClick={this._closeModal}
        leftButtonText={this.props.isTaskTransfer
          ? strings.leftButtonTransferText
          : strings.leftButtonAssignText}
        rightButtonLoading={this.state.isAssigningTask}
        rightButtonOnClick={this._assign}
        rightButtonText={this.props.isTaskTransfer
          ? strings.rightButtonTransferText
          : strings.rightButtonAssignText}
        visible={this.state.showModal}
      >
        <Component.Window.Content>
          {this.props.isTaskTransfer
            ? strings.confirmTransferText
            : strings.confirmAssignText}
        </Component.Window.Content>
      </Component.Modal.Confirmation>
    );
  }

  _renderCancelWarningModal = () => (
    <CancelWarningModal
      onClick={() => {
        this.setState({ showCancelWarningModal: false });
        // Focus on the assign button instead of modal
        // to enable the onBlur function in assign button
        this.div.focus();
        this._toggleDropdown();
      }}
      visible={this.state.showCancelWarningModal}
    />
  );

  render() {
    const className = classNames(styles['vf-task__assign'], this.props.className);

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div className={className} onBlur={this._onBlur} ref={(div) => { this.div = div; }} tabIndex="0">
        {this._renderButton()}
        {this._renderDropdown()}
        {this._renderModal()}
        {this._renderCancelWarningModal()}
      </div>
    );
  }
}

AssignButton.displayName = 'Assign Button';

AssignButton.propTypes = {
  addonsSetting: PropTypes.shape({
    attendants_and_config: PropTypes.number,
  }),
  alignRight: PropTypes.bool,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  // https://versafleet.atlassian.net/browse/VF-2462
  // Driver transfer is just a driver reassignment without the changing of states
  isTaskTransfer: PropTypes.bool,
  onClick: PropTypes.func,
  onSuccessfulUpdate: PropTypes.func,
  options: PropTypes.shape({
    driver: PropTypes.arrayOf(PropTypes.shape()),
    vehicle: PropTypes.arrayOf(PropTypes.shape()),
  }),
  setButtonPersistent: PropTypes.func,
  strings: PropTypes.shape({
    confirmAssignText: PropTypes.string,
    confirmTransferText: PropTypes.string,
    leftButtonAssignText: PropTypes.string,
    leftButtonTransferText: PropTypes.string,
    rightButtonAssignText: PropTypes.string,
    rightButtonTransferText: PropTypes.string,
  }).isRequired,
  taskId: PropTypes.number,
  taskIds: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      state: PropTypes.string,
    })),
    PropTypes.shape({
      state: PropTypes.string,
    }),
  ]),
};

AssignButton.defaultProps = {
  addonsSetting: null,
  alignRight: false,
  buttonClassName: null,
  className: null,
  data: null,
  id: null,
  isTaskTransfer: false,
  onClick: null,
  onSuccessfulUpdate: () => {},
  options: null,
  setButtonPersistent: null,
  taskId: null,
  taskIds: null,
  tasks: [],
};

function mapStateToProps(state) {
  const {
    account, driver, vehicle, task,
  } = state;

  return {
    addonsSetting: account.account.addons_setting,
    options: {
      driver: driver.optionDrivers || [],
      vehicle: vehicle.optionVehicles || [],
    },
    tasks: task.tasks,
  };
}

export default connect(mapStateToProps)(translate('Task.AssignForm')(AssignButton));
