import React from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import Helper from 'utils/helper';
import TranslationHelper from 'utils/translationHelper';
import { Components, translate, Utils } from 'versafleet-core';
import Color from 'utils/color';
import Button from './button/button';

class InputAttachment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: [],
    };
    this.uploader = null;
    this.uploaderInput = null;
  }

  // The `preprocess` prop aborts the upload if `next` is not called.
  _onUploadStart = async (file, next) => {
    let isValidFileType = false;
    let errors = [];

    if ([
      'image/png', 'image/jpeg', 'application/pdf', 'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ].includes(file.type) || ['docx', 'doc', 'csv', 'xlsx', 'xls'].includes(file.name.split('.').pop())) {
      this.setState({ errors: [] });
      isValidFileType = true;
    } else {
      this.setState({ errors: [this.props.strings.invalidType] });
    }

    // Do a virus scan on the file
    errors = await Utils.RequestHelper.requestVirusScan(file);
    this.setState({ errors });

    if (isValidFileType && errors.length === 0) {
      next(file);
    }
  }

  _onFinish = (url, file) => {
    if (typeof this.props.onChange === 'function') {
      const newAttachment = {
        url: url.baseUrl,
        name: file.name,
      };
      const attachmentList = newAttachment in this.props.value ? this.props.value
        : this.props.value.concat(newAttachment);
      this.props.onChange(attachmentList);
      if (!Helper.isNullOrUndefined(this.uploader)) {
        this.uploader.clear();
      }
    }
    this._setLoading(false);
  }

  _setLoading(loading) {
    if (loading !== this.state.loading) {
      this.setState({ loading });
    }
  }

  _delete(attachment) {
    let attachments = this.props.value;
    const attachmentIndex = attachments.indexOf(attachment);
    const destroyedAttachment = { ...attachment, _destroy: true };

    if (!Helper.isNullOrUndefined(attachment.id)) {
      attachments = [
        ...attachments.slice(0, attachmentIndex),
        destroyedAttachment,
        ...attachments.slice(attachmentIndex + 1),
      ];
    } else {
      attachments = [
        ...attachments.slice(0, attachmentIndex),
        ...attachments.slice(attachmentIndex + 1),
      ];
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(attachments);
    }
  }

  _uploadFile = () => {
    this.uploaderInput.click();
  }

  _renderLoading() {
    return (
      <div className="loading">
        <Components.EllipsisLoading />
        <div className="loading__title">{this.props.strings.loadingImage}</div>
      </div>
    );
  }

  _renderAttachment(item, i) {
    return (
      <div className="attachment-item" key={i}>
        {Utils.Helper.isImageFileName(item.name) ? (
          <div className="thumbnail">
            <img alt="" src={item.url} />
          </div>
        ) : (
          <div id="thumbnail-doc">
            <Components.Svg>
              <Components.Svg.Use
                fill={Color.$blue}
                height={30}
                href="versa-file-text-o"
              />
            </Components.Svg>
          </div>
        )}
        <div className="filename">
          {item.name}
        </div>
        {
          this.props.removable
            ? (
              <div
                className="button-remove-attachment"
                onClick={() => this._delete(item)}
              >
                <i className="versa-close" />
              </div>
            )
            : false
        }
      </div>
    );
  }

  render() {
    const files = this.props.value.filter(
      // eslint-disable-next-line no-underscore-dangle
      file => Helper.isNullOrUndefined(file._destroy) || !file._destroy,
    );

    const replaceMap = {
      count: files.length,
      file: (files.length > 1 ? this.props.strings.filePlural : this.props.strings.file),
    };

    return (
      <div className="v-attachment">
        <div className="attachment-icon">
          <i className="versa-attachment" />
        </div>
        <div className="attachment-content">
          <div className="file-input-wrapper">
            <Button
              className="button-upload"
              id={Helper.isNullOrUndefined(this.props.id) ? undefined : `${this.props.id}__button`}
              onClick={this._uploadFile}
            >
              {this.props.strings.attachHint}
            </Button>
            {
              files.length === 0
                ? false
                : TranslationHelper.replaceTag(this.props.strings.attached, replaceMap)
            }
            <ReactS3Uploader
              accept={[
                'image/png', 'image/jpeg', 'application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
              ]}
              className="file-input"
              inputRef={(node) => { this.uploaderInput = node; }}
              onFinish={this._onFinish}
              onProgress={() => this._setLoading(true)}
              preprocess={this._onUploadStart}
              ref={(uploader) => { this.uploader = uploader; }}
              signingUrl={`${process.env.apiUrl}/files/new_upload`}
              uploadRequestHeaders={{}}
            />
          </div>
          <div className="attachment-list">
            {
              // eslint-disable-next-line no-underscore-dangle
              files.map((item, i) => (!item._destroy ? this._renderAttachment(item, i) : false))
            }
          </div>
          {this.state.loading ? this._renderLoading() : false}
        </div>
        <Components.Error errors={this.state.errors} />
      </div>
    );
  }
}

InputAttachment.displayName = 'Input Attachment';

InputAttachment.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  removable: PropTypes.bool,
  strings: PropTypes.shape({
    attached: PropTypes.string,
    attachHint: PropTypes.string,
    file: PropTypes.string,
    fileContainsVirus: PropTypes.string,
    filePlural: PropTypes.string,
    fileTooLarge: PropTypes.string,
    invalidType: PropTypes.string,
    loadingImage: PropTypes.string,
    unableToScan: PropTypes.string,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})),
};

InputAttachment.defaultProps = {
  id: null,
  onChange: null,
  removable: null,
  value: null,
};

export default translate('Component.Attachment')(InputAttachment);
