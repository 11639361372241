import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import { connect } from 'react-redux';
import Helper from 'utils/helper';

const ActionPanelFilterTagButton = (props) => {
  const {
    id, value, onChange, optionTags, strings, className, filterClassName,
  } = props;
  const optionsData = optionTags.map(option => ({ value: option, text: option }));
  const options = Helper.encodeURIComponentTagValue(optionsData);

  let text;
  if (value.length <= 1) {
    text = strings.tag.item;
  } else {
    text = `${value.length > 1 && value.length < options.length
      ? strings.filter.multiple : strings.filter.all} ${strings.tag.itemPlural}`;
  }

  const subText = value.length === 1 ? Helper.decodeURIComponentTagValue(value[0]) : undefined;
  return (
    <Component.ActionPanel.DropdownButton
      className={className}
      id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
      showArrow
      subText={subText}
      text={text}
    >
      <Component.ActionPanel.FilterTagDropdown
        className={filterClassName}
        error={strings.tag.error}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-tag-dropdown`}
        onChange={v => onChange(v)}
        options={options}
        placeholder={strings.tag.placeholder}
        value={value}
      />
    </Component.ActionPanel.DropdownButton>
  );
};

ActionPanelFilterTagButton.displayName = 'Action Panel Filter Tag Button';

ActionPanelFilterTagButton.propTypes = {
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  optionTags: PropTypes.arrayOf(PropTypes.string),
  strings: PropTypes.shape({
    filter: PropTypes.shape({
      multiple: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
    tag: PropTypes.shape({
      error: PropTypes.string,
      placeholder: PropTypes.string,
      item: PropTypes.string,
      itemPlural: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterTagButton.defaultProps = {
  className: '',
  filterClassName: '',
  id: '',
  onChange: null,
  optionTags: [],
  value: [],
};

function mapStateToProps(state) {
  const { constant } = state;
  return {
    optionTags: constant.constant.tasks.tags,
  };
}

export default connect(mapStateToProps)(translate('Task')(ActionPanelFilterTagButton));
