const initialState = {
  vehiclePartSettings: null,
};

function vehiclePartSettings(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VEHICLE_PART_SETTINGS':
      return {
        ...state,
        vehiclePartSettings: action.vehiclePartSettings,
      };
    case 'RECEIVE_VEHICLE_PART_SETTINGS_UPDATE':
      return {
        ...state,
        vehiclePartSettings: action.vehiclePartSettings,
      };
    default:
      return state;
  }
}

export default vehiclePartSettings;
