const initialState = {
  isAddressValidated: false,
  addressError: {},
  isMeasurementValidated: false,
  measurementError: {},
};

function validator(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VALIDATE_ADDRESS_SUCCESSFUL':
      return {
        ...state,
        addressError: [],
        isAddressValidated: true,
      };
    case 'RECEIVE_VALIDATE_ADDRESS_FAILED':
      return {
        ...state,
        addressError: action.error,
        isAddressValidated: false,
      };
    case 'RESET_VALIDATE_ADDRESS':
      return {
        ...state,
        addressError: [],
        isAddressValidated: false,
      };
    case 'RECEIVE_VALIDATE_MEASUREMENT_SUCCESSFUL':
      return {
        ...state,
        measurementError: [],
        isMeasurementValidated: true,
      };
    case 'RECEIVE_VALIDATE_MEASUREMENT_FAILED':
      return {
        ...state,
        measurementError: action.error,
        isMeasurementValidated: false,
      };
    case 'RESET_VALIDATE_MEASUREMENT':
      return {
        ...state,
        measurementError: [],
        isMeasurementValidated: false,
      };
    default:
      return state;
  }
}

export default validator;
