import Router from 'utils/router';
import { Utils } from 'versafleet-core';

const {
  RequestHelper: {
    convertDataToParams, request, requestCustomBody, requestBlob,
  },
} = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function setIsFetchingOptionCustomers(isFetchingOptionCustomers) {
  return {
    type: 'SET_IS_FETCHING_OPTION_CUSTOMERS',
    isFetchingOptionCustomers,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_CUSTOMER_FILTER',
    filter,
  };
}

function receiveCustomerUpdateSuccessful(customer) {
  return {
    type: 'RECEIVE_CUSTOMER_UPDATE_SUCCESSFUL',
    customer,
  };
}

function receiveCustomer(customer) {
  return {
    type: 'RECEIVE_CUSTOMER',
    customer,
  };
}

function receiveCustomerTrackingSetting(customer) {
  return {
    type: 'RECEIVE_CUSTOMER_TRACKING_SETTING',
    customer,
  };
}

function receiveCustomerEpodSetting(customer) {
  return {
    type: 'RECEIVE_CUSTOMER_EPOD_SETTING',
    customer,
  };
}

function receiveCustomers(result, timestamp) {
  return {
    type: 'RECEIVE_CUSTOMERS',
    customers: result.customers,
    meta: result.meta,
    timestamp,
  };
}

function receiveArchivedCustomers(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_CUSTOMERS',
    customers: result.customers,
    meta: result.meta,
    timestamp,
  };
}

function receiveOptionCustomers(customers) {
  return {
    type: 'RECEIVE_OPTION_CUSTOMERS',
    customers,
  };
}

function receiveBillingAccounts(billingAccounts, customerId) {
  return {
    type: 'RECEIVE_CUSTOMER_BILLING_ACCOUNTS',
    billingAccounts,
    customerId,
  };
}

function receiveCustomerMyjobsSetting(customer) {
  return {
    type: 'RECEIVE_CUSTOMER_MYJOBS_SETTING',
    customer,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}`, {
      method: 'GET',
    });
    dispatch(receiveCustomer(result.customer));
  };
}

function fetchBillingAccounts(customerId) {
  return async (dispatch) => {
    const result = await request(`/customers/${customerId}/billing_accounts`, {
      method: 'GET',
    });
    dispatch(receiveBillingAccounts(result.billing_accounts, customerId));
  };
}

function fetchEpodSetting(id) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/epod_setting`, {
      method: 'GET',
    });
    dispatch(receiveCustomerEpodSetting({
      id,
      epod_setting: result.epod_setting,
    }));
  };
}

function fetchTrackingSetting(id) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/tracking_setting`, {
      method: 'GET',
    });
    dispatch(receiveCustomerTrackingSetting({
      id,
      tracking_setting: result.tracking_setting,
    }));
  };
}

function fetchMyjobsSetting(id) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/myjobs_setting`, {
      method: 'GET',
    });
    dispatch(receiveCustomerMyjobsSetting({
      id,
      myjobs_setting: result.myjobs_setting,
    }));
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.customer.customerMeta.page,
      ...baseFilter,
      ...state.customer.filter,
      archived: false,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/customers${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveCustomers(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.customer.archivedCustomerMeta.page,
      ...baseFilter,
      ...state.customer.filter,
      archived: true,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/customers${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedCustomers(result, timestamp));
  };
}

function fetchOptionCustomerList(query) {
  return async (dispatch, getState) => {
    const { isFetchingOptionCustomers } = getState().customer;

    if (isFetchingOptionCustomers) {
      return;
    }

    try {
      dispatch(setIsFetchingOptionCustomers(true));

      const queryString = convertDataToParams(query);
      const result = await request(`/customers/list${queryString}`, {
        method: 'GET',
      });
      dispatch(receiveOptionCustomers(result.customers));
    } finally {
      dispatch(setIsFetchingOptionCustomers(false));
    }
  };
}

function archiveMultiple(ids) {
  return async () => {
    await request('/customers/update_multiple', {
      method: 'PUT',
    }, {
      ids,
      action_type: 'archive',
    });
  };
}

function archive(id) {
  return async () => {
    await request(`/customers/${id}/archive`, {
      method: 'PUT',
    });
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/unarchive`, {
      method: 'PUT',
    });
    Router.transitionFromSubpath('customers');
    dispatch(receiveCustomer(result.customer));
    dispatch(fetchArchivedList());
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    await request('/customers/update_multiple', {
      method: 'PUT',
    }, {
      ids,
      action_type: 'unarchive',
    });
    dispatch(fetchArchivedList());
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}`, {
      method: 'PUT',
    }, {
      customer: query,
    });
    dispatch(receiveCustomerUpdateSuccessful(result.customer));
    return result;
  };
}

function updateEpodSetting(id, query) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/epod_setting`, {
      method: 'PUT',
    }, {
      epod_setting: query,
    });
    Router.transitionFromSubpath('customers', Router.getParams().id);
    dispatch(receiveCustomerEpodSetting({
      id,
      epod_setting: result.epod_setting,
    }));
  };
}

function updateTrackingSetting(id, query) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/tracking_setting`, {
      method: 'PUT',
    }, {
      tracking_setting: query,
    });
    Router.transitionFromSubpath('customers', Router.getParams().id);
    dispatch(receiveCustomerTrackingSetting({
      id,
      tracking_setting: result.tracking_setting,
    }));
  };
}

function updateMyjobsSetting(id, query) {
  return async (dispatch) => {
    const result = await request(`/customers/${id}/myjobs_setting`, {
      method: 'PUT',
    }, {
      myjobs_setting: query,
    });
    dispatch(receiveCustomerMyjobsSetting({
      id,
      myjobs_setting: result.myjobs_setting,
    }));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/customers', {
      method: 'POST',
    }, {
      customer: query,
    });
    dispatch(receiveCustomerUpdateSuccessful(result.customer));
    dispatch(fetchList());
    return result;
  };
}

function importData(query) {
  return async (dispatch) => {
    await requestCustomBody('/customers/import', {
      method: 'POST',
    }, query);
    dispatch(fetchList());
    Router.transitionTo('/customer/customers/import?success=true');
  };
}

function getImportTemplate() {
  return async () => {
    const data = await requestBlob('/customers/import/template', {
      method: 'GET',
    });

    Utils.Helper.saveBlob(data, 'Import_Customer.xlsx');
  };
}

export default {
  setFilter,
  fetch,
  fetchBillingAccounts,
  fetchEpodSetting,
  fetchTrackingSetting,
  fetchMyjobsSetting,
  fetchList,
  fetchArchivedList,
  fetchOptionCustomerList,
  archiveMultiple,
  archive,
  unarchive,
  unarchiveMultiple,
  update,
  updateEpodSetting,
  updateTrackingSetting,
  updateMyjobsSetting,
  create,
  importData,
  getImportTemplate,
};
