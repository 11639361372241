import { Components } from 'versafleet-core';

import Button from './authorizedButton';
import ButtonAdd from './buttonAdd';
import ButtonView from './buttonView';
import ButtonPrint from './buttonPrint';
import ButtonArchive from './buttonArchive';
import ButtonDelete from './buttonDelete';
import ButtonImport from './buttonImport';
import ButtonMoreAction from './buttonMoreAction';
import ButtonRefresh from './buttonRefresh';
import ButtonDone from './buttonDone';
import ButtonCopyToClipboard from './buttonCopyToClipboard';
import ButtonUpload from './buttonUpload';
import ButtonEdit from './buttonEdit';
import ButtonCancel from './buttonCancel';
import ButtonUpdateVersaDrive from './buttonUpdateVersaDrive';
import ButtonBack from './buttonBack';
import ButtonExport from './buttonExport';
import ButtonMenu from './buttonMenu';
import ButtonTopUp from './buttonTopUp';
import ButtonAddMaintenance from './buttonAddMaintenance';

Button.Color = Components.Button.Color;
Button.Add = ButtonAdd;
Button.View = ButtonView;
Button.Print = ButtonPrint;
Button.Archive = ButtonArchive;
Button.Delete = ButtonDelete;
Button.Import = ButtonImport;
Button.MoreAction = ButtonMoreAction;
Button.Refresh = ButtonRefresh;
Button.Done = ButtonDone;
Button.CopyToClipboard = ButtonCopyToClipboard;
Button.Upload = ButtonUpload;
Button.Edit = ButtonEdit;
Button.Cancel = ButtonCancel;
Button.Back = ButtonBack;
Button.UpdateVersaDrive = ButtonUpdateVersaDrive;
Button.Export = ButtonExport;
Button.Menu = ButtonMenu;
Button.TopUp = ButtonTopUp;
Button.AddMaintenance = ButtonAddMaintenance;

export default Button;
