import Router from 'utils/router';
import { Utils } from 'versafleet-core';
import PusherHelper from 'utils/pusherHelper';
import { initialisePusherEvents } from '../reducers/pusherInitialiser';

const { RequestHelper: { convertDataToParams, request } } = Utils;

function receiveCheckSsoEnabledSuccessful(result) {
  return {
    type: 'RECEIVE_CHECK_SSO_ENABLED_SUCCESSFUL',
    myjobs_sso_enabled: result.myjobs_sso_enabled,
    sso_enabled: result.sso_enabled,
    allow_direct_login: result.allow_direct_login,
  };
}

function receiveCheckSsoEnabledFailed() {
  return {
    type: 'RECEIVE_CHECK_SSO_ENABLED_FAILED',
  };
}

function receiveLogoutSuccessful(url) {
  return {
    type: 'RECEIVE_LOGOUT_SUCCESSFUL',
    url,
  };
}

function receiveCheckSuccessful(profile) {
  PusherHelper.channel.user = PusherHelper.pusher.subscribe(`user-${profile.id}`);
  PusherHelper.channel.account = PusherHelper.pusher.subscribe(`account-${profile.account_id}`);

  PusherHelper.channel.user.bind('pusher:subscription_succeeded', () => {
    console.log({
      message: 'Successfully subscribe to user channel',
      level: 'info',
      extra: {
        'channel-name': `user-${profile.id}`,
      },
      timestamp: new Date().toString(),
    });
  });

  PusherHelper.channel.account.bind('pusher:subscription_succeeded', () => {
    console.log({
      message: 'Successfully subscribe to account channel',
      level: 'info',
      extra: {
        'channel-name': `account-${profile.account_id}`,
      },
      timestamp: new Date().toString(),
    });
  });

  initialisePusherEvents();

  return {
    type: 'RECEIVE_CHECK_SUCCESSFUL',
    profile,
  };
}

function receiveCheckFailed() {
  return {
    type: 'RECEIVE_CHECK_FAILED',
  };
}

function receiveProfileUpdateSuccessful(profile) {
  return {
    type: 'RECEIVE_PROFILE_UPDATE_SUCCESSFUL',
    profile,
  };
}

function receiveProfile(profile) {
  return {
    type: 'RECEIVE_PROFILE',
    profile,
  };
}

function login(query) {
  return async () => {
    const result = await request('/session/login', {
      method: 'POST',
    }, query);

    if (result.user && result.user.is_totp_enabled) {
      localStorage.setItem('totp-email', result.user.email);
      if (Router.getQuery().destination) {
        Router.transitionTo(`/auth/login_totp?destination=${Router.getQuery().destination}`);
      } else {
        Router.transitionTo('/auth/login_totp');
      }
    } else if (Router.getQuery().destination) {
      Router.transitionTo(`/auth/login?destination=${Router.getQuery().destination}&success=true`);
    } else if (result.redirect_to) {
      Router.transitionTo(result.redirect_to);
    } else {
      Router.transitionTo('/auth/login?success=true');
    }
  };
}

function validateTotp(query) {
  return async () => {
    const result = await request('/session/totp_login', {
      method: 'POST',
    }, query);

    return result;
  };
}

function validateTotpBackupCode(query) {
  return async () => {
    const result = await request('/session/totp_backup_login', {
      method: 'POST',
    }, query);

    return result;
  };
}

function getSsoSetting(query) {
  return async (dispatch) => {
    try {
      const queryString = convertDataToParams(query);
      const result = await request(`/saml/get_sso_setting${queryString}`, {
        method: 'GET',
      });
      dispatch(receiveCheckSsoEnabledSuccessful(result));
    } catch (errors) {
      dispatch(receiveCheckSsoEnabledFailed());
    }
  };
}

function ssoLogin(query) {
  return async () => {
    const queryString = convertDataToParams(query);
    const result = await request(`/saml/init${queryString}`, {
      method: 'GET',
    });
    return result.url;
  };
}

function logout(query) {
  return async (dispatch) => {
    const result = await request('/session/logout', {
      method: 'DELETE',
    }, query);
    localStorage.removeItem('bearerToken');
    if (result.url) {
      dispatch(receiveLogoutSuccessful(result.url));
      window.location = result.url;
    } else {
      dispatch(receiveLogoutSuccessful('/auth/login'));
      Router.transitionTo('/auth/login');
    }
  };
}

function check(query) {
  return async (dispatch) => {
    try {
      const queryString = convertDataToParams(query);
      const result = await request(`/profile${queryString}`, {
        method: 'GET',
      });
      dispatch(receiveCheckSuccessful(result.profile));
    } catch (errors) {
      dispatch(receiveCheckFailed());
    }
  };
}

function getProfile() {
  return async (dispatch) => {
    const result = await request('/profile', {
      method: 'GET',
    });
    dispatch(receiveProfile(result.profile));
  };
}

function updateProfile(query) {
  return async (dispatch) => {
    const result = await request('/profile', {
      method: 'PUT',
    }, {
      profile: query,
    });
    dispatch(receiveProfileUpdateSuccessful(result.profile));
  };
}

function updatePassword(query) {
  return async (dispatch) => {
    const result = await request('/profile/update_password', {
      method: 'PUT',
    }, query);
    dispatch(receiveProfileUpdateSuccessful(result.profile));
  };
}

function sendSetupTotpBackupEmail() {
  return async () => {
    const result = await request('/profile/send_setup_totp_backup_email', {
      method: 'GET',
    });

    return result;
  };
}

function sendLoginTotpBackupEmail(query) {
  return async () => {
    const queryString = Utils.RequestHelper.convertDataToParams({
      email: encodeURIComponent(localStorage.getItem('totp-email')),
      ...query,
    });

    const result = await request(`/profile/send_totp_backup_email${queryString}`, {
      method: 'GET',
    });

    return result;
  };
}

function verifyTotpBackupCode(query) {
  return async (dispatch) => {
    const result = await request('/profile/verify_totp_backup_code', {
      method: 'PUT',
    }, {
      profile: query,
    });

    dispatch(receiveProfileUpdateSuccessful(result.profile));
  };
}

function verifyTotpCode(query) {
  return async (dispatch) => {
    const result = await request('/profile/verify_totp_code', {
      method: 'PUT',
    }, {
      profile: query,
    });

    dispatch(receiveProfileUpdateSuccessful(result.profile));
    return result.profile;
  };
}

export default {
  getSsoSetting,
  ssoLogin,
  logout,
  check,
  getProfile,
  updateProfile,
  updatePassword,
  login,
  sendSetupTotpBackupEmail,
  sendLoginTotpBackupEmail,
  verifyTotpCode,
  verifyTotpBackupCode,
  validateTotp,
  validateTotpBackupCode,
};
