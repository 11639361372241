import React from 'react';
import { translate } from 'versafleet-core';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { useGetTasksReschedulingList } from 'queryActions/useTasksRescheduling';

import 'stylesheets/stringNameStylesheets/pages/tasksRescheduling.styl';

function ListTasksRescheduling({ strings }) {
  const { tasksRescheduling = [], isLoadingTasksRescheduling } = useGetTasksReschedulingList();

  return (
    <Component.Window.Section>
      <Component.Window.Section.Heading>
        {strings.title2}
      </Component.Window.Section.Heading>

      <Content
        isLoadingTasksRescheduling={isLoadingTasksRescheduling}
        strings={strings}
        tasksRescheduling={tasksRescheduling}
      />
    </Component.Window.Section>
  );
}

function Content({
  isLoadingTasksRescheduling,
  tasksRescheduling,
  strings,
}) {
  if (isLoadingTasksRescheduling) {
    return <Component.EllipsisLoading className="vf-tasks-rescheduling__loading" />;
  }

  if (!tasksRescheduling?.length) {
    return (
      <Component.Window.Description>
        {strings.noRules}
      </Component.Window.Description>
    );
  }

  return (
    <TasksReschedulingRules
      rules={tasksRescheduling}
      strings={strings}
    />
  );
}

function TasksReschedulingRules({ strings, rules = [] }) {
  const daysOfWeek = Object.entries(strings.workingDays.days)
    .map(([key]) => key);
  const navigate = useNavigate();

  return (
    <Component.Window.Content className="vf-tasks-rescheduling--no-margin">
      <Component.Window.List>
        {rules.map(rule => (
          <Component.Window.List.Item key={rule.id}>
            <Component.Window.List.Item.Content>
              <Component.Window.Content>
                <Component.Link onClick={() => navigate(`${rule.id}`)}>
                  {rule.name}
                </Component.Link>
                <Component.Window.Content className="vf-tasks-rescheduling__container">
                  <Component.Window.Description>
                    {rule.working_days.every(isEnable => isEnable)
                      ? strings.workingDays.allDays
                      : rule.working_days.map((isEnable, index) => (isEnable
                        ? strings.workingDays.days[daysOfWeek[index]] : null))
                        .filter(Boolean)
                        .join(', ')}
                  </Component.Window.Description>
                </Component.Window.Content>
              </Component.Window.Content>
            </Component.Window.List.Item.Content>

            <Component.Status.Enabled active={rule.is_enabled} />
          </Component.Window.List.Item>
        ))}
      </Component.Window.List>
    </Component.Window.Content>
  );
}

ListTasksRescheduling.propTypes = {
  strings: PropTypes.shape().isRequired,
};
Content.propTypes = {
  isLoadingTasksRescheduling: PropTypes.bool.isRequired,
  strings: PropTypes.shape().isRequired,
  tasksRescheduling: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
TasksReschedulingRules.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  strings: PropTypes.shape().isRequired,
};

export default translate('Settings.Addons.tasksRescheduling')(ListTasksRescheduling);
