import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReduxActions from 'reduxActions';
import ListWindow from '../list';

const TaskEmailTriggerEdit = React.lazy(() => import('./edit'));
const TaskEmailTriggerView = React.lazy(() => import('./view'));

class TaskEmailTrigger extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.statusTrigger.fetchByModelType('task', 'email'));
  }

  render() {
    return <ListWindow data={this.props.data} model="task" type="email" />;
  }
}

TaskEmailTrigger.displayName = 'Task Email Trigger';

TaskEmailTrigger.propTypes = {
  data: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

TaskEmailTrigger.Edit = TaskEmailTriggerEdit;

TaskEmailTrigger.View = TaskEmailTriggerView;

const mapStateToProps = state => ({
  data: state.statusTrigger.taskEmailTriggers,
});

export default connect(mapStateToProps)(TaskEmailTrigger);
