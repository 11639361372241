import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Helper from 'utils/helper';
import NewDialog from 'utils/newDialog';
import authorize from 'authorize';
import CompleteDialog from './completeDialog';

class CompleteButton extends React.Component {
  _onClick = () => {
    NewDialog.DialogManager.open(
      <CompleteDialog
        dataIds={this.props.dataIds}
        taskFilter={this.props.taskFilter}
      />,
    );
    if (!Helper.isNullOrUndefined(this.props.onClick)) {
      this.props.onClick(this.props.dataIds);
    }
  }

  render() {
    return (
      <Component.Button
        className={this.props.buttonClassName}
        hint={this.props.hint}
        iconClassName="versa-completed"
        onClick={this._onClick}
      >
        {this.props.buttonText}
      </Component.Button>
    );
  }
}

CompleteButton.propTypes = {
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  dataIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  hint: PropTypes.string,
  onClick: PropTypes.func,
  taskFilter: PropTypes.shape({}),
};

CompleteButton.defaultProps = {
  buttonClassName: '',
  buttonText: '',
  dataIds: [],
  hint: null,
  onClick: null,
  taskFilter: null,
};

export default authorize('')(CompleteButton);
