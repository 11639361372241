const initialState = {
  account: {
    addons_setting: {},
  },
  epodSetting: {},
  trackingSetting: {},
  trackingSettingTags: {},
  customEpodSettingMergeTagsBlocks: {},
  customTaskLabelMergeTagsBlocks: {},
  isFetchingTimeWindows: false,
  timeWindows: [],
  taskLabel: {},
  subaccountTags: [],
  runsheetManagerSetting: {},
  isFetchingOptionChildAccounts: false,
  optionChildAccounts: [],
};

function account(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_ACCOUNT':
      return {
        ...state,
        account: action.account,
      };
    case 'RECEIVE_EPOD_SETTING':
      return {
        ...state,
        epodSetting: action.epodSetting,
      };
    case 'RECEIVE_TASK_LABEL':
      return {
        ...state,
        taskLabel: action.taskLabel,
      };
    case 'RECEIVE_TRACKING_SETTING':
      return {
        ...state,
        trackingSetting: action.trackingSetting,
      };
    case 'RECEIVE_TIME_WINDOWS':
      return {
        ...state,
        timeWindows: action.timeWindows,
      };
    case 'RECEIVE_TRACKING_SETTING_TAGS':
      return {
        ...state,
        trackingSettingTags: action.trackingSettingTags,
      };
    case 'RECEIVE_CUSTOM_EPOD_SETTING_TAGS':
      return {
        ...state,
        customEpodSettingMergeTagsBlocks: action.customEpodSettingTagsBlocks,
      };
    case 'RECEIVE_CUSTOM_TASK_LABEL_TAGS':
      return {
        ...state,
        customTaskLabelMergeTagsBlocks: action.customTaskLabelTags,
      };
    case 'RECEIVE_ACCOUNT_UPDATE_SUCCESSFUL':
      return {
        ...state,
        ...action.account,
      };
    case 'RECEIVE_SUBACCOUNT_TAGS':
      return {
        ...state,
        subaccountTags: action.subaccountTags,
      };
    case 'RECEIVE_RUNSHEET_MANAGER_SETTING':
      return {
        ...state,
        runsheetManagerSetting: action.runsheetManagerSetting,
      };
    case 'RECEIVE_OPTION_CHILD_ACCOUNTS':
      return {
        ...state,
        optionChildAccounts: action.optionChildAccounts,
      };
    case 'SET_IS_FETCHING_TIME_WINDOWS':
      return {
        ...state,
        isFetchingTimeWindows: action.isFetchingTimeWindows,
      };
    case 'SET_IS_FETCHING_OPTION_CHILD_ACCOUNTS':
      return {
        ...state,
        isFetchingOptionChildAccounts: action.isFetchingOptionChildAccounts,
      };
    default:
      return state;
  }
}

export default account;
