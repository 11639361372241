/* eslint-disable max-classes-per-file */
import React from 'react';
import Helper from 'utils/helper';
import Button from '../components/button/authorizedButton';

let registeredDialog = null;

class DialogManager {
  static register(dialog) {
    registeredDialog = dialog;
  }

  static deregister() {
    registeredDialog = null;
  }

  static open(childrenNode) {
    if (!Helper.isNullOrUndefined(registeredDialog)) {
      registeredDialog.open(childrenNode);
    }
  }

  static confirm(confirmText, confirmCallback) {
    DialogManager.open(
      <div className="vf-dialog__confirm">
        <div className="vf-dialog__confirm__text">
          {confirmText}
        </div>
        <div className="vf-dialog__confirm__button-container">
          <Button onClick={DialogManager.close}>
            Cancel
          </Button>
          <Button
            color={Button.Color.GOLD}
            fillColor
            onClick={(e) => {
              confirmCallback(e);
              DialogManager.close();
            }}
          >
            OK
          </Button>
        </div>
      </div>,
    );
  }

  static close() {
    if (!Helper.isNullOrUndefined(registeredDialog)) {
      registeredDialog.close();
    }
  }
}

class DialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childrenNode: false,
    };
  }

  componentDidMount() {
    DialogManager.register(this);
  }

  componentWillUnmount() {
    DialogManager.deregister();
  }

  open(childrenNode) {
    this.setState({ childrenNode });
  }

  close() {
    this.setState({ childrenNode: false });
  }

  render() {
    return this.state.childrenNode ? (
      <div className="vf-dialog__overlay">
        <div className="vf-dialog__overlay__box">
          {this.state.childrenNode}
        </div>
      </div>
    ) : false;
  }
}

export default {
  DialogComponent,
  DialogManager,
};
