import { Utils } from 'versafleet-core';

const { RequestHelper: { convertDataToParams, request } } = Utils;

function resetFilterStatus() {
  return {
    type: 'RESET_FILTER_STATUS',
  };
}

function receivePlanningFilterUpdate(planningFilter) {
  return {
    type: 'RECEIVE_PLANNING_FILTER_UPDATE',
    planningFilter,
  };
}

function receivePlanningTaskFilterUpdate(planningTaskFilter) {
  return {
    type: 'RECEIVE_PLANNING_TASK_FILTER_UPDATE',
    planningTaskFilter,
  };
}

function receivePlanningJobFilterUpdate(planningJobFilter) {
  return {
    type: 'RECEIVE_PLANNING_JOB_FILTER_UPDATE',
    planningJobFilter,
  };
}

function receiveArchivesFilterUpdate(archiveFilter) {
  return {
    type: 'RECEIVE_ARCHIVES_FILTER_UPDATE',
    archiveFilter,
  };
}

function receiveFilterSettings(filterSettings) {
  return {
    type: 'RECEIVE_FILTER_SETTINGS',
    filterSettings,
  };
}

function receiveFilterSettingsUpdate(filterSettings) {
  return {
    type: 'RECEIVE_FILTER_SETTINGS_UPDATE',
    filterSettings,
  };
}

function receiveFilterSettingsDelete(filterId) {
  return {
    type: 'RECEIVE_FILTER_SETTINGS_DELETE',
    filterId,
  };
}

function updatePlanningFilter(query) {
  return dispatch => dispatch(receivePlanningFilterUpdate(query));
}

function updatePlanningTaskFilter(query) {
  return dispatch => dispatch(receivePlanningTaskFilterUpdate(query));
}

function updatePlanningJobFilter(query) {
  return dispatch => dispatch(receivePlanningJobFilterUpdate(query));
}

function updateArchivesFilter(query) {
  return dispatch => dispatch(receiveArchivesFilterUpdate(query));
}

function fetchList(filterType) {
  return async (dispatch) => {
    const data = {
      filter_type: filterType,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/filter_settings${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveFilterSettings(result));
  };
}

function create(query) {
  return async () => {
    const result = await request('/filter_settings', {
      method: 'POST',
    }, {
      filter_settings: query,
    });
    return result;
  };
}

function deleteFilter(id) {
  return async (dispatch) => {
    await request(`/filter_settings/${id}`, {
      method: 'DELETE',
    });
    dispatch(receiveFilterSettingsDelete(id));
  };
}

export default {
  fetchList,
  create,
  deleteFilter,
  resetFilterStatus,
  updatePlanningFilter,
  updatePlanningTaskFilter,
  updatePlanningJobFilter,
  updateArchivesFilter,
  receiveFilterSettingsUpdate,
};
