import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonTopUp = props => (
  <Button
    hint={props.strings.text}
    {...props}
  />
);

ButtonTopUp.displayName = 'Button Top Up';

ButtonTopUp.propTypes = {
  strings: PropTypes.shape().isRequired,
};

export default translate('Component.Button.TopUp')(ButtonTopUp);
