import { Utils } from 'versafleet-core';
import AccountReduxActions from './account';
import TaskReduxActions from './task';

const { RequestHelper: { convertDataToParams, request } } = Utils;

const baseFilter = {
  sort_by: 'id',
  order_by: 'desc',
  per_page: 20,
};

function receiveXeroData(xeroData) {
  return {
    type: 'RECEIVE_XERO_DATA',
    xeroData,
  };
}

function receiveAccountCodes(accountCodes) {
  return {
    type: 'RECEIVE_ACCOUNT_CODES',
    accountCodes,
  };
}

function receiveXeroLogoutSuccessful() {
  return {
    type: 'RECEIVE_XERO_LOGOUT_SUCCESSFUL',
  };
}

function receiveTask(task) {
  return {
    type: 'RECEIVE_TASK',
    task,
  };
}

function receiveInvoiceTasks(result, timestamp) {
  return {
    type: 'RECEIVE_INVOICE_TASKS',
    tasks: result.tasks,
    meta: result.meta,
    timestamp,
  };
}

function logout() {
  return async (dispatch) => {
    await request('/xero/session/logout', {
      method: 'DELETE',
    });
    dispatch(receiveXeroLogoutSuccessful());
    dispatch(AccountReduxActions.fetch());
  };
}

function check() {
  return async (dispatch) => {
    const result = await request('/xero/session/check', {
      method: 'GET',
    });
    dispatch(receiveXeroData(result));
  };
}

function fetchAccountCodes() {
  return async (dispatch) => {
    const result = await request('/xero/accounts', {
      method: 'GET',
    });
    dispatch(receiveAccountCodes(result.accounts));
  };
}

function invoiceMultiple(array, id) {
  return async () => {
    const data = {
      ids: array,
      customer_id: id,
    };
    await request('/xero/invoices/tasks', {
      method: 'POST',
    }, data);
    return array.length;
  };
}

function invoiceAll(query) {
  return async () => {
    const data = {
      from_date: query.from_date,
      to_date: query.to_date,
      customer_id: query.customer_id,
      keyword: query.keyword,
      state: query.state,
    };
    const result = await request('/xero/invoices/all_tasks', {
      method: 'POST',
    }, data);
    return result.task_count;
  };
}

function fetchTaskList(filter) {
  return async (dispatch, getState) => {
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.xero.meta.page,
      ...baseFilter,
      ...filter,
      archived: false,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/xero/invoices/tasks${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveInvoiceTasks(result, timestamp));
    dispatch(TaskReduxActions.fetchAdditionalInfo(result.tasks.map(item => item.id)));
  };
}

function updateTask(id, query) {
  return async (dispatch) => {
    const result = await request(`/xero/invoices/tasks/${id}`, {
      method: 'PUT',
    }, {
      task: query,
    });
    dispatch(receiveTask(result.task));
  };
}

function fetchTask(id) {
  return async (dispatch) => {
    const result = await request(`/xero/invoices/tasks/${id}`, {
      method: 'GET',
    });
    dispatch(receiveTask(result.task));
  };
}

export default {
  logout,
  check,
  fetchAccountCodes,
  invoiceMultiple,
  invoiceAll,
  fetchTaskList,
  fetchTask,
  updateTask,
};
