import configureStore from 'configureStore';

// New redux files should be imported and defined in main.js
class ReduxActions {
  static setActions(actions) {
    Object.keys(actions).forEach((key) => {
      this[key] = actions[key];
    });
  }
}

export default ReduxActions;

export const store = configureStore();
