import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReduxActions from 'reduxActions';
import ListWindow from '../list';

const JobEmailTriggerEdit = React.lazy(() => import('./edit'));
const JobEmailTriggerView = React.lazy(() => import('./view'));

class JobEmailTrigger extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.statusTrigger.fetchByModelType('job', 'email'));
  }

  render() {
    return <ListWindow data={this.props.data} model="job" type="email" />;
  }
}

JobEmailTrigger.displayName = 'Job Email Trigger';

JobEmailTrigger.propTypes = {
  data: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

JobEmailTrigger.Edit = JobEmailTriggerEdit;

JobEmailTrigger.View = JobEmailTriggerView;

const mapStateToProps = state => ({
  data: state.statusTrigger.jobEmailTriggers,
});

export default connect(mapStateToProps)(JobEmailTrigger);
