import Helper from 'utils/helper';
import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveVroomTimerGroupsSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMER_GROUPS_SUCCESSFUL',
    data,
  };
}

function receiveVroomTimerGroupSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMER_GROUP_SUCCESSFUL',
    data,
  };
}

function receiveVroomTimerGroupCreateSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMER_GROUPS_CREATE_SUCCESSFUL',
    data,
  };
}

function receiveVroomTimerGroupUpdateSuccessful(data) {
  return {
    type: 'RECEIVE_VROOM_TIMER_GROUPS_UPDATE_SUCCESSFUL',
    data,
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/vroom_timer_groups', {
      method: 'GET',
    });
    // Sort vroom timer groups by name, then scheduled time
    result.vroom_timer_groups.sort((vtg1, vtg2) => {
      if (vtg1.name.localeCompare(vtg2.name) === 0) {
        // Groups without scheduled time will be sorted to the front
        const vtg1Time = Helper.isNullOrUndefined(vtg1.scheduled_time) ? -1 : vtg1.scheduled_time;
        const vtg2Time = Helper.isNullOrUndefined(vtg2.scheduled_time) ? -1 : vtg2.scheduled_time;

        return vtg1Time - vtg2Time;
      }
      return vtg1.name.localeCompare(vtg2.name);
    });
    dispatch(receiveVroomTimerGroupsSuccessful(result));
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/vroom_timer_groups/${id}`, {
      method: 'GET',
    });
    dispatch(receiveVroomTimerGroupSuccessful(result));
  };
}

function create(data) {
  return async (dispatch) => {
    const result = await request('/vroom_timer_groups', {
      method: 'POST',
    }, data);
    dispatch(receiveVroomTimerGroupCreateSuccessful(result));
    return result;
  };
}

function update(data, id) {
  return async (dispatch) => {
    const result = await request(`/vroom_timer_groups/${id}`, {
      method: 'PUT',
    }, data);
    dispatch(receiveVroomTimerGroupUpdateSuccessful(result));
    return result;
  };
}

function deleteVroomTimerGroup(id) {
  return async () => {
    await request(`/vroom_timer_groups/${id}`, {
      method: 'DELETE',
    });
  };
}

function triggerOptimize(data) {
  return async () => {
    const result = await Utils.RequestHelper.request(
      '/vroom_timer_groups/trigger_optimize',
      { method: 'POST' },
      data,
    );

    return result;
  };
}

export default {
  create,
  fetch,
  fetchList,
  update,
  delete: deleteVroomTimerGroup,
  triggerOptimize,
};
