import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import Formatter from 'utils/formatter';
import Helper from 'utils/helper';

class ActionPanelFilterAllocationStatusButton extends React.Component {
  _getValueText() {
    const { options, value } = this.props;
    return options.find(option => option.value === value[0]).text;
  }

  render() {
    const {
      id, value, onChange, options, strings, className, filterClassName,
    } = this.props;
    let text;
    if (value.length === 0) {
      text = `${strings.filter.no} ${strings.allocationStatus.item}`;
    } else if (value.length === 1) {
      text = Formatter.capitalizeFirstLetter(this._getValueText());
    } else if (value.length === options.length) {
      text = `${strings.filter.all} ${strings.allocationStatus.itemPlural}`;
    } else {
      text = `${value.length} ${strings.allocationStatus.itemPlural}...`;
    }

    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
        showArrow
        text={text}
      >
        <Component.ActionPanel.FilterCheckbox
          className={filterClassName}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-checkbox`}
          onChange={v => onChange(v)}
          options={options}
          values={value}
        />
      </Component.ActionPanel.DropdownButton>
    );
  }
}

ActionPanelFilterAllocationStatusButton.displayName = 'Action Panel Filter Allocation Status Button';

ActionPanelFilterAllocationStatusButton.propTypes = {
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    filter: PropTypes.shape({
      no: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
    allocationStatus: PropTypes.shape({
      item: PropTypes.string,
      itemPlural: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterAllocationStatusButton.defaultProps = {
  className: '',
  filterClassName: '',
  id: '',
  onChange: null,
  options: [],
  value: [],
};

export default translate('Task')(ActionPanelFilterAllocationStatusButton);
