import { useQuery } from '@tanstack/react-query';
import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

// eslint-disable-next-line import/prefer-default-export
export function useConstants() {
  const {
    isLoading: isLoadingConstants,
    error: errorConstants,
    data: constants,
  } = useQuery({
    queryKey: ['constants'],
    queryFn: async () => {
      const result = await request('/constants', {
        method: 'GET',
      });
      return result;
    },
  });

  return {
    isLoadingConstants,
    errorConstants,
    constants,
  };
}
