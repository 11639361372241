import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Router from 'utils/router';
import Component from 'components/component';
import TranslationHelper from 'utils/translationHelper';
import Formatter from 'utils/formatter';
import { withRouterHooks } from '../../../../utils/router';

class ListWindow extends React.Component {
  _renderBreadcrumb() {
    const { strings, model, type } = this.props;
    const commonStrings = strings.Common;
    const triggerTitle = Formatter.capitalizeEachWord(
      TranslationHelper.replaceTag(
        strings.modelTypeTrigger.plural,
        { model: commonStrings.models[model].singular, type: strings.type[type] },
      ).join(''),
    );
    return (
      <Component.Window.Breadcrumb>
        <Component.Window.Breadcrumb.Item>
          {triggerTitle}
        </Component.Window.Breadcrumb.Item>
      </Component.Window.Breadcrumb>
    );
  }

  _renderStatusTriggerList() {
    const { strings } = this.props;
    return (
      <Component.Window.List>
        {
          Object.values(this.props.data).map(({
            id, name, enabled, updated_at: updatedAt, updated_by: updatedBy,
          }) => (
            <Component.Window.List.Item key={id}>
              <Component.Window.List.Item.Content>
                <Component.Link onClick={() => Router.transitionFromSubpath(null, `${id}`)}>
                  {name}
                </Component.Link>
                <Component.Window.Description>
                  {
                    TranslationHelper.replaceTag(
                      strings.list.itemUpdateInfo,
                      { user: updatedBy, time: Formatter.getDateAndTime(updatedAt) },
                    )
                  }
                </Component.Window.Description>
              </Component.Window.List.Item.Content>
              <div>
                <Component.Status.Enabled active={enabled} />
              </div>
            </Component.Window.List.Item>
          ))
        }
      </Component.Window.List>
    );
  }

  _renderBody() {
    const { strings, model, type } = this.props;
    const commonStrings = strings.Common;
    const triggerTitle = Formatter.capitalizeEachWord(
      TranslationHelper.replaceTag(
        strings.modelTypeTrigger.plural,
        { model: commonStrings.models[model].singular, type: strings.type[type] },
      ).join(''),
    );
    const triggerDescription = TranslationHelper.replaceTag(
      strings.list.description, { type: strings.type[type] },
    );

    return (
      <Component.Window.Body>
        <Component.Window.Header>
          <Component.Window.Header.Title>
            {triggerTitle}
          </Component.Window.Header.Title>
        </Component.Window.Header>
        <Component.Window.Description>
          {triggerDescription}
        </Component.Window.Description>
        {this._renderStatusTriggerList()}
      </Component.Window.Body>
    );
  }

  _renderActionPanel = () => (
    <Component.Window.ActionPanel>
      <Component.Window.ActionPanel.Button.Primary
        iconClassName="versa-add"
        onClick={() => Router.transitionFromSubpath(null, 'new')}
      >
        {this.props.strings.buttons.new}
      </Component.Window.ActionPanel.Button.Primary>
    </Component.Window.ActionPanel>
  )

  render() {
    return (
      <Component.Window onClose={() => this.props.navigate('..')}>
        {this._renderBreadcrumb()}
        {this._renderBody()}
        {this._renderActionPanel()}
      </Component.Window>
    );
  }
}

ListWindow.displayName = 'List Window';

ListWindow.propTypes = {
  data: PropTypes.shape().isRequired,
  model: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  strings: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
};

export default withRouterHooks(translate('Settings.Notifications.StatusTrigger')(ListWindow));
