import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  visualTimestamp: null,
  archivedTimestamp: null,
  invoiceTimestamp: null,
  tasks: {},
  tasksIds: [],
  visualTaskIds: [],
  archivedTaskIds: [],
  taskMeta: {},
  archivedTaskMeta: {},
  filter: {},
  isUpdatingMultipleVersadrive: false,
  invoiceTaskIds: [],
  invoiceTaskMeta: {},
  totalFailedTasksCount: 0,
  failedTasks: null,
  isFetchingIncompletionReasons: false,
  incompletionReasons: [],
  taskCount: null,
  exportTags: {},
  systemTags: {},
  presetReportTypes: [],
  isFetchingOptionAddresses: false,
  optionAddresses: [],
  isFetchingAdditionalInfo: false,
};

function task(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'RECEIVE_INVOICE_TASKS':
      if (Helper.isNullOrUndefined(state.invoiceTimestamp)
        || state.invoiceTimestamp < action.timestamp) {
        return {
          ...state,
          tasks: ReduxHelper.mergeDataWithList(state.tasks, action.tasks),
        };
      }
      return state;
    case 'RECEIVE_TASK':
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithObject(state.tasks, action.task),
      };
    case 'RECEIVE_TASKS':
      // Check time stamp
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          tasksIds: action.tasks.map(t => t.id),
          tasks: ReduxHelper.mergeDataWithList(state.tasks, action.tasks),
          taskMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_TASKS_DETAILS': {
      // Event can be triggered by fetching of either custom fields
      // or additional task information (job, measurements, task assignment)
      const uniqueBaseTasks = action.tasks.filter(t => t.job)
        .map(t => ({ ...t.job.base_task, job: t.job }))
        .filter((_task, pos, arr) => arr.findIndex(t => t.guid === _task.guid) === pos);
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithList(state.tasks, action.tasks.concat(uniqueBaseTasks)),
      };
    }
    case 'RECEIVE_VISUAL_TASKS':
      if (Helper.isNullOrUndefined(state.visualTimestamp)
        || state.visualTimestamp < action.timestamp) {
        return {
          ...state,
          visualTaskIds: action.tasks.map(t => t.id),
          tasks: ReduxHelper.mergeDataWithList(state.tasks, action.tasks),
          visualTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_TASKS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedTaskIds: action.tasks.map(t => t.id),
          tasks: ReduxHelper.mergeDataWithList(state.tasks, action.tasks),
          archivedTaskMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_AUTOALLOCATE_TASKS':
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithList(state.tasks, action.tasks),
      };
    case 'RECEIVE_TASK_UPDATE_SUCCESSFUL':
      return {
        ...state,
        tasks: action.task.role === 'base'
          ? ReduxHelper.mergeDataWithList(state.tasks, Object.keys(state.tasks)
            .filter(id => state.tasks[id].job.id === action.task.job.id)
            .map((id) => {
              const t = state.tasks[id];
              if (t.role === 'base') {
                return action.task;
              }
              return { ...t, job: action.task.job };
            }))
          : ReduxHelper.mergeDataWithObject(state.tasks, action.task),
      };
    case 'RECEIVE_TASK_GEOLOCATION_UPDATE_SUCCESSFUL':
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithObject(state.tasks, {
          ...state.tasks[action.id],
          address: {
            ...state.tasks[action.id].address,
            latitude: action.location.latitude,
            longitude: action.location.longitude,
          },
        }),
      };
    case 'RECEIVE_JOB_GEOLOCATION_UPDATE_SUCCESSFUL': {
      const updatedTasks = state.visualTaskIds
        .map(taskId => state.tasks[taskId])
        .filter(_task => _task.job && (_task.job.id === action.id))
        .map(_task => ({
          ..._task,
          job: {
            ..._task.job,
            base_task: {
              ..._task.job.base_task,
              address: {
                ..._task.job.base_task.address,
                latitude: action.location.latitude,
                longitude: action.location.longitude,
              },
            },
          },
        }));
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithList(state.tasks, updatedTasks),
      };
    }
    case 'SET_TASK_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    case 'SET_IS_FETCHING_ADDITIONAL_INFO':
      return {
        ...state,
        isFetchingAdditionalInfo: action.isFetchingAdditionalInfo,
      };
    case 'SET_IS_FETCHING_OPTION_ADDRESSES':
      return {
        ...state,
        isFetchingOptionAddresses: action.isFetchingOptionAddresses,
      };
    case 'SET_IS_FETCHING_INCOMPLETION_REASONS':
      return {
        ...state,
        isFetchingIncompletionReasons: action.isFetchingIncompletionReasons,
      };
    case 'RECEIVE_TOTAL_FAILED_TASKS_COUNT':
      return {
        ...state,
        totalFailedTasksCount: action.count,
      };
    case 'RECEIVE_FAILED_TASKS':
      return {
        ...state,
        failedTasks: action.failedTasks,
      };
    case 'RECEIVE_INCOMPLETION_REASONS_OPTION_LIST':
      return {
        ...state,
        incompletionReasons: action.incompletionReasons,
      };
    case 'RECEIVE_TASK_HISTORY':
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithObject(state.tasks, action.taskHistory),
      };
    case 'RECEIVE_TASK_HISTORY_UPDATE': {
      const taskHistory = {
        task_history: {
          ...state.tasks[action.id].task_history,
        },
        id: action.id,
      };
      taskHistory.task_history[Object.keys(taskHistory.task_history).length] = action.taskHistory;
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithObject(state.tasks, taskHistory),
      };
    }
    case 'RECEIVE_TASK_VERIFICATION_HISTORY':
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithObject(state.tasks, action.verificationHistory),
      };
    case 'REQUEST_UPDATING_MULTIPLE_VERSADRIVE':
      return {
        ...state,
        isUpdatingMultipleVersadrive: true,
      };
    case 'RECEIVE_UPDATING_MULTIPLE_VERSADRIVE':
      return {
        ...state,
        isUpdatingMultipleVersadrive: false,
      };
    case 'RECEIVE_EXPORT_TAGS':
      return {
        ...state,
        exportTags: action.exportTags,
      };
    case 'RECEIVE_SYSTEM_TAGS':
      return {
        ...state,
        systemTags: action.systemTags,
      };
    case 'RECEIVE_EXPORT_TASK_COUNT_SUCCESSFUL':
      return {
        ...state,
        taskCount: action.taskCount,
      };
    case 'RECEIVE_RESET_EXPORT_TASK_COUNT':
      return {
        ...state,
        taskCount: null,
      };
    case 'RECEIVE_RUNSHEET': {
      const newRunsheetTasks = action.runsheet.tasks.map((runsheetTask) => {
        const { ...newTask } = runsheetTask;
        return newTask;
      });
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithList(state.tasks, newRunsheetTasks),
      };
    }
    case 'RECEIVE_MULTIPLE_RUNSHEETS': {
      newState = { ...state };
      let newTasks = [];
      action.runsheets.forEach((runsheet) => {
        const runsheetTasks = runsheet.tasks.map((runsheetTask) => {
          const { ...newTask } = runsheetTask;
          return newTask;
        });
        newTasks = newTasks.concat(runsheetTasks);
      });
      newState.tasks = ReduxHelper.mergeDataWithList(newState.tasks, newTasks);
      return newState;
    }
    case 'RECEIVE_JOB_UPDATE_SUCCESSFUL':
      return {
        ...state,
        tasks: ReduxHelper.mergeDataWithList(state.tasks, Object.keys(state.tasks)
          .filter(id => (state.tasks[id].job || {}).id === action.job.id)
          .map((id) => {
            const t = state.tasks[id];
            if (t.role === 'base') {
              return { ...action.job.base_task, job: action.job };
            }
            return { ...t, job: action.job };
          })),
      };
    case 'RECEIVE_PRESET_REPORT_TYPES':
      return {
        ...state,
        presetReportTypes: action.presetReportTypes,
      };
    case 'RECEIVE_OPTION_ADDRESSES':
      return {
        ...state,
        optionAddresses: action.optionAddresses,
      };
    default:
      return state;
  }
}

export default task;
