import { combineReducers } from 'redux';
import user from './user';
import driver from './driver';
import vehicle from './vehicle';
import constant from './constant';
import customer from './customer';
import customLayout from './customLayout';
import dashboard from './dashboard';
import notification from './notification';
import validator from './validator';
import task from './task';
import job from './job';
import profile from './profile';
import account from './account';
import notificationSetting from './notificationSetting';
import ourLocation from './ourLocation';
import vehicleLocator from './vehicleLocator';
import runsheet from './runsheet';
import versadriveSettings from './versadriveSettings';
import xero from './xero';
import billing from './billing';
import filterSettings from './filterSettings';
import exportTemplate from './exportTemplate';
import globalSearch from './globalSearch';
import billingAccount from './billingAccount';
import customFieldGroup from './customFieldGroup';
import vfSetting from './vfSetting';
import vroom from './vroom';
import vroomTimer from './vroomTimer';
import vroomTimerGroup from './vroomTimerGroup';
import layer from './layer';
import vehicleSettings from './vehicleSettings';
import vehiclePartSettings from './vehiclePartSettings';
import vehiclePart from './vehiclePart';
import driverSettings from './driverSettings';
import vehicleRecordSetting from './vehicleRecordSetting';
import vehicleRecord from './vehicleRecord';
import driverRecordSetting from './driverRecordSetting';
import driverRecord from './driverRecord';
import statusTrigger from './statusTrigger';
import scanToIdentifyConfiguration from './scanToIdentifyConfiguration';

const rootReducer = combineReducers({
  user,
  driver,
  vehicle,
  constant,
  customer,
  customLayout,
  dashboard,
  notification,
  validator,
  task,
  job,
  profile,
  account,
  notificationSetting,
  ourLocation,
  vehicleLocator,
  runsheet,
  versadriveSettings,
  xero,
  billing,
  filterSettings,
  exportTemplate,
  globalSearch,
  billingAccount,
  customFieldGroup,
  vfSetting,
  vroom,
  vroomTimer,
  vroomTimerGroup,
  layer,
  vehicleSettings,
  vehiclePartSettings,
  vehiclePart,
  driverSettings,
  vehicleRecordSetting,
  vehicleRecord,
  driverRecordSetting,
  driverRecord,
  statusTrigger,
  scanToIdentifyConfiguration,
});

export default rootReducer;
