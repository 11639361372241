import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  selectedId: null,
  isFetchingOptionVehicles: false,
  optionVehicles: [],
  vehicles: {},
  vehicleIds: [],
  archivedVehicleIds: [],
  vehicleMeta: {},
  archivedVehicleMeta: {},
  filter: {},
  skills: [],
  systemTags: {},
  vehicleTypes: {
    diesel_vans: [],
    petrol_vans: [],
    electric_vans: [],
  },
};

function vehicle(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VEHICLE':
      return {
        ...state,
        selectedId: action.vehicle.id,
        vehicles: ReduxHelper.mergeDataWithObject(state.vehicles, action.vehicle),
      };
    case 'RECEIVE_VEHICLES':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          vehicleIds: action.vehicles.map(
            _vehicle => _vehicle.id,
          ),
          vehicles: ReduxHelper.mergeDataWithList(
            state.vehicles, action.vehicles,
          ),
          vehicleMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_VEHICLES':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedVehicleIds: action.vehicles.map(_vehicle => _vehicle.id),
          vehicles: ReduxHelper.mergeDataWithList(state.vehicles, action.vehicles),
          archivedVehicleMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_OPTION_VEHICLES':
      return {
        ...state,
        optionVehicles: action.optionVehicles,
      };
    case 'RECEIVE_VEHICLE_SKILLS':
      return {
        ...state,
        skills: action.skills,
      };
    case 'RECEIVE_VEHICLE_SYSTEM_TAGS': {
      const customTags = (action.systemTags.custom_field_groups || []).map((item) => {
        const newTitle = item.title.split('-')[1];
        return { ...item, title: newTitle };
      });
      return {
        ...state,
        systemTags: { ...action.systemTags, custom_field_groups: customTags },
      };
    }
    case 'RECEIVE_VEHICLE_TYPES': {
      return {
        ...state,
        vehicleTypes: action.vehicleTypes,
      };
    }
    case 'RECEIVE_VEHICLE_UPDATE_SUCCESSFUL':
      return {
        ...state,
        vehicles: ReduxHelper.mergeDataWithObject(state.vehicles, action.vehicle),
        selectedId: action.vehicle.id,
      };
    case 'SET_IS_FETCHING_OPTION_VEHICLES':
      return {
        ...state,
        isFetchingOptionVehicles: action.isFetchingOptionVehicles,
      };
    case 'SET_VEHICLE_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default vehicle;
