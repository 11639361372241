import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

const RadioButtonItem = (props) => {
  const { name, description } = props;

  return (
    <div>
      <div className="vf-radio-button__item__container" onClick={props.onClick}>
        <div className="vf-radio-button__item__container__name">
          {name}
        </div>
        {Helper.isNullOrUndefined(description) ? false : (
          <div className="vf-radio-button__item__container__description">
            {description}
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};

RadioButtonItem.displayName = 'Form Radio Button Item';

RadioButtonItem.propTypes = {
  children: PropTypes.node,
  description: PropTypes.node,
  name: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

RadioButtonItem.defaultProps = {
  children: null,
  description: null,
  onClick: null,
};

export default RadioButtonItem;
