import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utils, translate } from 'versafleet-core';
import Router from 'utils/router';

class MeasurementViewItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineItemCompletions: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.task !== this.props.task) {
      this._getLastTaskCompletion(this.props.task);
    }
  }

  _getLastTaskCompletion(task) {
    if (!Utils.Helper.isNullOrUndefined(task)) {
      const { state, task_completion_histories: taskCompletion } = task;
      if (state === 'successful' && Utils.Helper.isNonEmptyArray(taskCompletion)) {
        const lastTaskCompletion = taskCompletion[0];
        this.setState({ lineItemCompletions: lastTaskCompletion.line_item_completions });
      }
    }
  }

  _onEdit() {
    this.props.onEdit();
  }

  _onDelete() {
    this.props.onDelete();
  }

  _showCustomId() {
    const { data, versadriveSettings } = this.props;
    const hasCustomID = !Utils.Helper.isNullOrUndefined(data.custom_item_id);

    return hasCustomID && (
      versadriveSettings.delivery_job_scan_enabled
      || versadriveSettings.delivery_task_scan_enabled
      || versadriveSettings.pickup_job_scan_enabled
      || versadriveSettings.pickup_task_scan_enabled
    );
  }

  _renderMeasurementQuantity(data) {
    const { task, lineItemSettings } = this.props;
    const { lineItemCompletions } = this.state;

    const taskSuccessful = task.state === 'successful';
    const lineItemEnabled = lineItemSettings.enabled;
    let measurementCompletions = null;
    let showActualQty = false;
    if (lineItemEnabled && !Utils.Helper.isNullOrUndefined(lineItemCompletions)) {
      measurementCompletions = lineItemCompletions.find(line => line.measurement_id === data.id);
      showActualQty = taskSuccessful && !Utils.Helper.isNullOrUndefined(measurementCompletions);
    }

    return (
      <div className="vf-measurement-view-item__content__quantity">
        {showActualQty && (
          <span>{`${measurementCompletions.actual_quantity} of `}</span>
        )}
        <span>{data.quantity}</span>
        <span className="vf-measurement-view-item__content__multiplier">x</span>
        <span>{data.quantity_unit}</span>
        {this._showCustomId() && (
          <span className="vf-measurement-view-item__content__quantity_custom_id">{data.custom_item_id}</span>
        )}
      </div>
    );
  }

  render() {
    const {
      data, account, index, strings,
    } = this.props;

    // Should render altogether or not
    const hasWeight = (!Utils.Helper.isNullOrUndefined(data.weight));
    const hasDimensions = (
      !Utils.Helper.isNullOrUndefined(data.volume_length)
      && !Utils.Helper.isNullOrUndefined(data.volume_height)
      && !Utils.Helper.isNullOrUndefined(data.volume_width)
    );
    const hasVolume = !Utils.Helper.isNullOrUndefined(data.volume);

    // Calculate the volume
    const volume = (
      Utils.Helper.isNullOrUndefined(this.props.data.volume)
        ? data.volume_length * data.volume_width * data.volume_height : data.volume
    );

    const measurementUnits = Utils.Helper.getMeasurementUnits();
    const volumeUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].volumeUnit : 'in³';
    const dimensionUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].dimensionUnit : 'in';
    const weightUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].weightUnit : 'lb';
    const shippingFactorUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].shippingFactorUnit : 'in³/lb';

    const renderedLength = Utils.Helper.isNullOrUndefined(data.volume_length) ? '-' : data.volume_length;
    const renderedWidth = Utils.Helper.isNullOrUndefined(data.volume_width) ? '-' : data.volume_width;
    const renderedHeight = Utils.Helper.isNullOrUndefined(data.volume_height) ? '-' : data.volume_height;

    return (
      <div className="vf-measurement-view-item">

        <div className="vf-measurement-view-item__index">
          {`${index + 1}.`}
        </div>

        <div className="vf-measurement-view-item__content">
          {this._renderMeasurementQuantity(data)}
          <div className="vf-measurement-view-item__content__description">{data.description}</div>
          {!hasVolume && !hasDimensions && !hasWeight ? false : (
            <div className="vf-measurement-view-item__content__volume">

              <div className="vf-measurement-view-item__content__volume__block">
                <div className="vf-measurement-view-item__content__volume__block__header">
                  {strings.measurements.actualWeight}
                </div>
                <div className="vf-measurement-view-item__content__volume__block__text">
                  {hasWeight ? `${data.weight}${weightUnit}` : '-' }
                </div>
              </div>

              <div className="vf-measurement-view-item__content__volume__block">
                <div className="vf-measurement-view-item__content__volume__block__header">
                  {strings.measurements.volume}
                </div>
                <div className="vf-measurement-view-item__content__volume__block__text">
                  {!hasVolume && !hasDimensions ? '-'
                    : `${Utils.Helper.roundUpToTwoDecimalPlaces(volume)}${volumeUnit}`}
                  {hasDimensions ? (
                    <div className="vf-measurement-view-item__content__volume__block__text__subtext">
                      {`${renderedLength} x ${renderedWidth} x ${renderedHeight} ${dimensionUnit}`}
                    </div>
                  ) : false}
                </div>
              </div>

              <div className="vf-measurement-view-item__content__volume__block">
                <div className="vf-measurement-view-item__content__volume__block__header">
                  {strings.measurements.volumetricWeight}
                </div>
                <div className="vf-measurement-view-item__content__volume__block__text">
                  {(!hasVolume && !hasDimensions)
                    || Utils.Helper.isNullOrUndefined(account.shipping_factor)
                    ? '-' : `${Utils.Helper.calculateVolumetricWeight(
                      volume,
                      account.shipping_factor,
                      account.unit_system,
                    )}${weightUnit}`}
                  {(!hasVolume && !hasDimensions)
                    || Utils.Helper.isNullOrUndefined(account.shipping_factor) ? false
                    : (
                      <div className="vf-measurement-view-item__content__volume__block__text__subtext">
                        {`${strings.constants.by} ${account.shipping_factor} ${shippingFactorUnit}`}
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    );
  }
}

MeasurementViewItem.displayName = 'Measurement View Item';

MeasurementViewItem.propTypes = {
  account: PropTypes.shape({
    shipping_factor: PropTypes.string,
    unit_system: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    quantity: PropTypes.number,
    quantity_unit: PropTypes.string,
    description: PropTypes.string,
    weight: PropTypes.number,
    volume_height: PropTypes.number,
    volume_length: PropTypes.number,
    volume_width: PropTypes.number,
    volume: PropTypes.number,
    custom_item_id: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  lineItemSettings: PropTypes.shape({
    enabled: PropTypes.bool,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  strings: PropTypes.shape({
    constants: PropTypes.shape({
      by: PropTypes.string,
    }),
    measurements: PropTypes.shape({
      actualWeight: PropTypes.string,
      volume: PropTypes.string,
      volumetricWeight: PropTypes.string,
    }),
  }).isRequired,
  task: PropTypes.shape({
    state: PropTypes.string,
    task_completion_histories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  versadriveSettings: PropTypes.shape({
    delivery_job_scan_enabled: PropTypes.bool,
    delivery_task_scan_enabled: PropTypes.bool,
    pickup_job_scan_enabled: PropTypes.bool,
    pickup_task_scan_enabled: PropTypes.bool,
  }).isRequired,
};

MeasurementViewItem.defaultProps = {
  onDelete: null,
  onEdit: null,
};

function mapStateToProps(state) {
  const { account, task, versadriveSettings } = state;
  return {
    account: account.account,
    versadriveSettings: versadriveSettings.versadriveSettings,
    lineItemSettings: versadriveSettings.lineItemValidationSettings,
    task: task.tasks[Router.getParams().id],
  };
}

export default connect(mapStateToProps)(translate('Task')(MeasurementViewItem));
