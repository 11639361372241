import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import Formatter from 'utils/formatter';
import Helper from 'utils/helper';

class ActionPanelFilterStatusButton extends React.Component {
  _getValueText() {
    const { options, value } = this.props;
    return options.find(option => option.value === value.state[0]).text;
  }

  render() {
    const {
      id, value, onChange, options, strings, className, filterClassName,
    } = this.props;
    let text;
    if (value.state.length === 1) {
      text = Formatter.capitalizeFirstLetter(this._getValueText());
    } else if (value.state.length === options.length) {
      text = `${strings.filter.all} ${strings.status.itemPlural}`;
    } else if (value.state.length === 0) {
      text = `${strings.filter.no} ${strings.status.item}`;
    } else {
      text = `${value.state.length} ${strings.status.itemPlural}...`;
    }

    return (
      <Component.ActionPanel.DropdownButton
        className={className}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
        showArrow
        text={text}
      >
        <Component.Input.CheckboxGroup.WithArray
          className={filterClassName}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-checkbox`}
          onChange={v => onChange('state', v)}
          value={value.state}
        >
          <Component.Input.CheckboxGroup.Title>
            {`${strings.filter.all} ${strings.status.itemPlural}`}
          </Component.Input.CheckboxGroup.Title>
          {options.map(option => (
            <Component.Input.Checkbox.Item key={option.value} value={option.value}>
              {option.text}
              {!Helper.isNullOrUndefined(option.subOptions)
                && value.state.includes(option.value) && (
                  <Component.Input.Radio
                    onChange={nv => onChange(option.subOptions.name, nv)}
                    value={value[option.subOptions.name]}
                  >
                    {option.subOptions.options.map(radioItem => (
                      <Component.Input.Radio.Item key={radioItem.value} value={radioItem.value}>
                        {radioItem.text}
                      </Component.Input.Radio.Item>
                    ))}
                  </Component.Input.Radio>
              )}
            </Component.Input.Checkbox.Item>
          ))}
        </Component.Input.CheckboxGroup.WithArray>
      </Component.ActionPanel.DropdownButton>
    );
  }
}

ActionPanelFilterStatusButton.displayName = 'Action Panel Filter Status Button';

ActionPanelFilterStatusButton.propTypes = {
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    filter: PropTypes.shape({
      no: PropTypes.string,
      all: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
      item: PropTypes.string,
      itemPlural: PropTypes.string,
    }).isRequired,
  }).isRequired,
  value: PropTypes.shape({
    state: PropTypes.arrayOf(PropTypes.string),
    has_discrepancy: PropTypes.bool,
  }),
};

ActionPanelFilterStatusButton.defaultProps = {
  className: '',
  filterClassName: '',
  id: '',
  onChange: null,
  options: [],
  value: null,
};

export default translate('Task')(ActionPanelFilterStatusButton);
