import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Router from 'utils/router';
import { translate } from 'versafleet-core';

class BillingAccountListItem extends React.Component {
  _view = () => {
    Router.transitionFromSubpath(null, this.props.data.id);
  }

  _renderActionButtons = () => {
    const { renderActionButtons } = this.props;
    return renderActionButtons && renderActionButtons(this.props.data.id);
  }

  render() {
    return (
      <Component.LineItem
        id="vf-account__list-item"
        itemID={this.props.data.id}
        onClick={this._view}
        showCheckBox={false}
      >
        <Component.LineItem.IconBlock
          iconClassName="versa-business"
        />

        <Component.LineItem.Block size="MEDIUM">
          <Component.LineItem.BlockContent
            fontColor="BLACK"
            fontSize="LARGE"
          >
            {this.props.data.name}
          </Component.LineItem.BlockContent>
          <Component.LineItem.BlockContent fontColor="TRANSLUCENT">
            {this.props.data.customer.name}
          </Component.LineItem.BlockContent>
        </Component.LineItem.Block>

        <Component.LineItem.Divider />
        <Component.LineItem.Block size="MEDIUM">
          <Component.LineItem.BlockContent>
            {`${this.props.data.address_count} ${this.props.strings.form.locations.toLowerCase()}`}
          </Component.LineItem.BlockContent>
        </Component.LineItem.Block>
        {this._renderActionButtons()}
      </Component.LineItem>
    );
  }
}

BillingAccountListItem.propTypes = {
  data: PropTypes.shape(),
  renderActionButtons: PropTypes.func.isRequired,
  strings: PropTypes.shape().isRequired,
};

BillingAccountListItem.defaultProps = {
  data: null,
};

BillingAccountListItem.displayName = 'Billing Account Item';

export default translate('BillingAccount')(BillingAccountListItem);
