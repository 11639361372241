const formatOptions = [{
  value: 'xlsx',
  text: 'xlsx',
}, {
  value: 'csv',
  text: 'csv',
}];

const failed = 'failed';
const completed = 'completed';

const taskCountLimit = 10000;

export default {
  failed,
  completed,
  formatOptions,
  taskCountLimit,
};
