import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';

class InputGroupList extends React.Component {
  render() {
    const existingInputs = this.props.inputs.filter(i => i.id);
    const newInputs = this.props.inputs.filter(i => !i.id);
    const newErrors = this.props.error.filter(e => !e.id);
    return (
      <div className="vf-input-group-list">
        {existingInputs.map((input) => {
          // Existing Item,
          // Do not rely on order by backend, instead find and match the error with
          //   available error that has id in it
          const error = this.props.error.filter(
            e => (
              !Helper.isNullOrUndefined(e.id)
              && !Number.isNaN(Number(e.id))
              && e.id === input.id
            ),
          )[0];

          return React.cloneElement(input.component, { error });
        })}
        {newInputs.map((input, i) => {
          // New Item,
          // Rely on backend order
          const error = newErrors[i];

          return React.cloneElement(input.component, { error });
        })}
      </div>
    );
  }
}

InputGroupList.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    component: PropTypes.node,
  })).isRequired,
};

export default InputGroupList;
