import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Color from 'utils/color';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

class ButtonCopyToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
  }

  _copy = () => {
    this._copyTextToClipboard();
    this.setState({ copied: true });
    window.setTimeout(() => this.setState({ copied: false }), 1250);
  }

  // Taken from StackOverflow
  // http://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  _copyTextToClipboard() {
    const text = this.props.copyText;
    const textArea = document.createElement('textarea');

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      // Error from copying is ignored
    }

    document.body.removeChild(textArea);
  }

  render() {
    return (
      <Button
        hint={this.state.copied ? this.props.strings.text : null}
        iconClassName="versa-copy"
        onClick={this._copy}
        style={{
          backgroundColor: (this.state.copied) ? Color.$blue
            : Color.$white,
          color: (this.state.copied) ? Color.$white : Color.$blue,
        }}
        {...this.props}
        className={classNames({ 'inverse-color': this.state.copied }, this.props.className)}
      />
    );
  }
}

ButtonCopyToClipboard.displayName = 'Button Copy to Clipboard';

ButtonCopyToClipboard.propTypes = {
  className: PropTypes.string,
  copyText: PropTypes.string,
  strings: PropTypes.shape().isRequired,
};

ButtonCopyToClipboard.defaultProps = {
  className: null,
  copyText: '',
};

export default translate('Component.Button.CopyToClipboard')(ButtonCopyToClipboard);
