import React from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import Helper from 'utils/helper';
import { Components, translate } from 'versafleet-core';

class InputSingleAttachment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  _onFinish = (url, file) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({
        url: url.baseUrl,
        name: file.name,
      });
    }

    this._setLoading(false);

    this.inputFile.clear();
  }

  _setLoading(loading) {
    if (loading !== this.state.loading) {
      this.setState({ loading });
    }
  }

  _renderLoading() {
    return (
      <div className="loading">
        <Components.EllipsisLoading />
        <div className="loading__title">{this.props.strings.loadingImage}</div>
      </div>
    );
  }

  _renderAttachment() {
    return (
      <div className="single-attachment-item">
        <div className="thumbnail">
          <img alt="attachment" src={this.props.url} />
        </div>
      </div>
    );
  }

  /* TODO: CSS classes are still specific to left panel.
   * Change this in the future.
   */
  render() {
    const attachmentClassName = classNames({
      'v-single-attachment': true,
      'no-attachment': Helper.isNullOrUndefined(this.props.url),
    });
    return (
      <div className={attachmentClassName}>
        {
          this.props.url ? (
            <div className="form__left-panel--import-form__single-file-name">
              {this.props.editable ? this.props.strings.replaceFile
                : this.props.strings.attachedImage}
            </div>
          ) : (
            <div className="form__left-panel--import-form__single-file-placeholder">
              {this.props.placeholder || this.props.strings.attachHint}
            </div>
          )
        }
        {
          this.props.editable
            ? (
              <div className="form__left-panel--import-form__single-file-input">
                <ReactS3Uploader
                  accept="image/*"
                  className="button"
                  onFinish={this._onFinish}
                  onProgress={() => this._setLoading(true)}
                  ref={(inputFile) => { this.inputFile = inputFile; }}
                  signingUrl={`${process.env.apiUrl}/files/new_upload`}
                  uploadRequestHeaders={{}}
                />
              </div>
            ) : false
        }
        {this.state.loading ? this._renderLoading() : this._renderAttachment()}
      </div>
    );
  }
}

InputSingleAttachment.propTypes = {
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  strings: PropTypes.shape({
    loadingImage: PropTypes.string,
    attachHint: PropTypes.string,
    replaceFile: PropTypes.string,
    attachedImage: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

InputSingleAttachment.defaultProps = {
  editable: false,
  placeholder: '',
};

export default translate('Component.Attachment')(InputSingleAttachment);
