import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Helper from 'utils/helper';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import { translate, Utils } from 'versafleet-core';
import featuresSettings from 'pages/featuresConstants';

const TIMEOUT_DELAY = 1000;

class Address extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.account.fetch());
  }

  componentDidUpdate(prevProps) {
    if (this.props.account !== prevProps.account) {
      let countryName = null;
      const { countryList } = prevProps;

      for (let i = 0; i < countryList.length; i += 1) {
        if (countryList[i].code === this.props.account.default_country_code) {
          countryName = countryList[i].name;
          break;
        }
      }

      if (Helper.isNullOrUndefined(prevProps.value.country)) {
        this._onChange('country', countryName, false);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _getDefaultForm(address = {}) {
    return {
      line_1: address.line_1 || null,
      line_2: address.line_2 || null,
      city: address.city || null,
      zip: address.zip || null,
      country: address.country || null,
      latitude: address.latitude || null,
      longitude: address.longitude || null,
    };
  }

  _onChange(fieldName, value, shouldChange = true) {
    const address = this._getDefaultForm(this.props.value);
    address[fieldName] = value;
    this.props.onChange(address, shouldChange);

    // Auto fill address if empty
    if (!address.line_1
      && ((fieldName === 'zip' && value && value.length >= 3) || fieldName === 'country')
    ) {
      if (address.country && address.zip) {
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          const { account } = this.props;
          let useCrs = false;
          let accountId = null;

          if (!Helper.isNullOrUndefined(account)) {
            useCrs = account.features_setting.coordinate_retrieval_service
              === featuresSettings.enabled;
            accountId = account.id;
          }

          const geocodedAddress = await Utils.Geocoder.getAddress({
            line_1: address.zip,
            country: address.country,
          }, useCrs, accountId);

          if (!address.line_1 && geocodedAddress) {
            address.line_1 = geocodedAddress.address;
            address.latitude = geocodedAddress.lat;
            address.longitude = geocodedAddress.lng;
            this.props.onChange(address);
          }
        }, TIMEOUT_DELAY);
      }
    }
  }

  _getCountryOptions() {
    return this.props.countryList.map(
      country => ({
        value: country.name,
        text: country.name,
        code: country.code,
      }),
    );
  }

  _renderPostalCodeNote() {
    const { strings, value: addressValue } = this.props;
    const address = addressValue || {};

    if (address.country !== 'Singapore'
      || (address.line_1 && address.latitude && address.longitude)) {
      return null;
    }

    return (
      <Component.InfoBox>
        {strings.postalCodeNote}
      </Component.InfoBox>
    );
  }

  render() {
    const { strings, id, value: addressValue } = this.props;
    const address = addressValue || {};

    return (
      <div className="vf-address">
        <Component.Input.Textbox
          error={this.props.error.line_1}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__street-address-input`}
          onChange={value => this._onChange('line_1', value)}
          placeholder={strings.streetAddressHint}
          value={address.line_1}
        />
        <Component.Input.Textbox
          error={this.props.error.line_2}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__unit-no-floor-input`}
          onChange={value => this._onChange('line_2', value)}
          placeholder={strings.unitNoFloorHint}
          value={address.line_2}
        />
        <Component.Input.Textbox
          error={this.props.error.city}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__city-state-input`}
          onChange={value => this._onChange('city', value)}
          placeholder={strings.cityStateHint}
          value={address.city}
        />
        <Component.Input.Textbox
          error={this.props.error.zip}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__postal-code-input`}
          onChange={value => this._onChange('zip', value)}
          placeholder={strings.postalCodeHint}
          value={(address.zip || '').toString()}
        />
        {this._renderPostalCodeNote()}
        <Component.Input.Select
          error={this.props.error.country}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__select-country-input`}
          onChange={value => this._onChange('country', value)}
          options={this._getCountryOptions()}
          placeholder={strings.countryHint}
          value={address.country}
        />
        <Component.Input.Textbox
          error={this.props.error.latitude}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}__latitude-input`}
          onChange={value => this._onChange('latitude', value)}
          placeholder={strings.latitudeHint}
          type="number"
          value={address.latitude}
        />
        <Component.Input.Textbox
          error={this.props.error.longitude}
          id={Helper.isNullOrUndefined(id) ? undefined : `${id}longitude-input`}
          onChange={value => this._onChange('longitude', value)}
          placeholder={strings.longitudeHint}
          type="number"
          value={address.longitude}
        />
      </div>
    );
  }
}

Address.displayName = 'Address';

Address.propTypes = {
  account: PropTypes.shape({
    default_country_code: PropTypes.string,
    id: PropTypes.number,
    features_setting: PropTypes.shape({
      coordinate_retrieval_service: PropTypes.number,
    }),
  }),
  countryList: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })),
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape(),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  strings: PropTypes.shape().isRequired,
  value: PropTypes.shape(),
};

Address.defaultProps = {
  account: {},
  countryList: null,
  error: {},
  id: null,
  value: null,
};

function mapStateToProps(state) {
  const { account, constant } = state;
  return {
    account: account.account || {},
    countryList: constant.countryList || [],
  };
}

export default connect(mapStateToProps)(translate('Address')(Address));
