import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  vehicleRecordSettings: {},
  vehicleRecordSettingIds: [],
};

function vehicleRecordSetting(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VEHICLE_RECORD_SETTING':
      return {
        ...state,
        vehicleRecordSettings: ReduxHelper.mergeDataWithObject(
          state.vehicleRecordSettings, action.vehicleRecordSetting,
        ),
      };
    case 'RECEIVE_VEHICLE_RECORD_SETTINGS':
      return {
        ...state,
        vehicleRecordSettingIds: action.vehicleRecordSettings.map(
          _vehicleRecordSetting => _vehicleRecordSetting.id,
        ),
        vehicleRecordSettings: ReduxHelper.mergeDataWithList(
          state.vehicleRecordSettings, action.vehicleRecordSettings,
        ),
      };
    default:
      return state;
  }
}

export default vehicleRecordSetting;
