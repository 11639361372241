import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { Utils, translate } from 'versafleet-core';
import { connect } from 'react-redux';
import ReduxActions from 'reduxActions';
import TranslationHelper from 'utils/translationHelper';
import Color from 'utils/color';

import styles from 'stylesheets/modules/task.styl';
import BulkEditDropdownPanel from './dropdownPanel';

class BulkEditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(new Date()).format('YYYY-MM-DD'),
      showDropdown: false,
      isUpdating: false,
      editResult: {},
      showFailedModal: false,
    };
    this.button = null;
  }

  componentDidMount() {
    Utils.OutsideClickHandler.registerNode(
      this.button,
      () => this.setState({ showDropdown: false }),
    );
  }

  componentWillUnmount() {
    Utils.OutsideClickHandler.removeNode(this.button);
  }

  _editMultiple = () => {
    this.setState({ isUpdating: true }, async () => {
      const result = await this.props.dispatch(
        ReduxActions.task.editMultiple(this.props.ids, {
          time_from: this.state.selectedDate,
          time_to: this.state.selectedDate,
        }),
      );
      if (result.failed_count === 0) {
        Utils.SuccessMessage.SuccessMessageManager.flash((
          <div className={styles['vf-task__bulk-edit__success-message']}>
            <div className={styles['vf-task__bulk-edit__success-message__icon']}>
              <i className="versa-completed" />
            </div>
            {
              TranslationHelper.replaceTag(this.props.strings.page.dateWithoutSkipSuccess, {
                count: result.successful_count,
              })
            }
          </div>
        ));
      } else {
        this.setState({
          editResult: result,
          showFailedModal: true,
        });
      }

      this.props.onSuccessfulUpdate();
      this.setState({ isUpdating: false, showDropdown: false });
    });
  }

  _renderButton() {
    return (
      <Component.Button
        className={styles['vf-task__bulk-edit__button']}
        hint={this.props.strings.page.buttons.edit.hint}
        iconClassName="versa-edit"
        onClick={() => {
          this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
        }}
      >
        {this.props.strings.page.buttons.edit.hint}
      </Component.Button>
    );
  }

  _renderDropdown() {
    return this.state.showDropdown && (
      <BulkEditDropdownPanel
        editMultiple={this._editMultiple}
        ids={this.props.ids}
        isUpdating={this.state.isUpdating}
        onFormChange={(value) => {
          this.setState({ selectedDate: value });
        }}
        onSuccessfulUpdate={this._onSuccessfulUpdate}
        selectedDate={this.state.selectedDate}
      />
    );
  }

  _renderFailedModal() {
    const { strings } = this.props;
    const { editResult, showFailedModal } = this.state;

    const headerMessage = TranslationHelper.replaceTag(
      strings.page.dateWithSkipSuccess.header, {
        count: editResult.successful_count,
        total: editResult.successful_count + editResult.failed_count,
      },
    );

    const contentMessage = TranslationHelper.replaceTag(
      strings.page.dateWithSkipSuccess.description, {
        skipped: editResult.failed_count,
      },
    );

    return showFailedModal && (
      <Component.Modal.Confirmation
        headerIconClassName="versa-completed"
        headerIconColor={Color.$gold}
        headerIconOuterColor={Color.$bgLight}
        headerText={headerMessage}
        rightButtonOnClick={() => this.setState({ showFailedModal: false })}
        rightButtonText={strings.page.buttons.okay}
      >
        {contentMessage}
      </Component.Modal.Confirmation>
    );
  }

  render() {
    return (
      <div
        className={styles['vf-task__bulk-edit']}
        ref={(node) => { this.button = node; }}
      >
        {this._renderButton()}
        {this._renderDropdown()}
        {this._renderFailedModal()}
      </div>
    );
  }
}

BulkEditButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSuccessfulUpdate: PropTypes.func,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

BulkEditButton.defaultProps = {
  onSuccessfulUpdate: null,
};

export default connect()(translate('Task')(BulkEditButton));
