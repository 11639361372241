import React from 'react';
import PropTypes from 'prop-types';
import TranslationHelper from 'utils/translationHelper';
import { translate } from 'versafleet-core';

const JobCancelSuccessMessage = props => (
  <div className="vf-job__cancel-success-message">
    <div className="vf-job__cancel-success-message__icon">
      <i className="versa-danger" />
    </div>
    {
      TranslationHelper.replaceTag(props.strings.page.cancelSuccess, {
        count: props.count,
      })
    }
  </div>
);

JobCancelSuccessMessage.propTypes = {
  count: PropTypes.number.isRequired,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

export default translate('Job')(JobCancelSuccessMessage);
