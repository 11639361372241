import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  driverRecordSettings: {},
  driverRecordSettingIds: [],
};

function driverRecordSetting(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_DRIVER_RECORD_SETTING':
      return {
        ...state,
        driverRecordSettings: ReduxHelper.mergeDataWithObject(
          state.driverRecordSettings, action.driverRecordSetting,
        ),
      };
    case 'RECEIVE_DRIVER_RECORD_SETTINGS':
      return {
        ...state,
        driverRecordSettingIds: action.driverRecordSettings.map(
          _driverRecordSetting => _driverRecordSetting.id,
        ),
        driverRecordSettings: ReduxHelper.mergeDataWithList(
          state.driverRecordSettings, action.driverRecordSettings,
        ),
      };
    default:
      return state;
  }
}

export default driverRecordSetting;
