const initialState = {
  reassignTasks: null,
  successTaskNum: null,
  taskIds: [],
};

function vroom(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_ROUTE_OPTIMIZATION_SUCCESSFUL':
      return {
        ...state,
        successTaskNum: action.successTaskNum,
      };
    case 'SET_REASSIGN_TASKS_OPTION':
      return {
        ...state,
        reassignTasks: action.reassignTasks,
      };
    case 'SET_TASK_IDS':
      return {
        ...state,
        taskIds: action.taskIds || state.taskIds,
      };
    case 'RESET_TASK_IDS':
      return {
        ...state,
        taskIds: [],
      };
    case 'RESET_SUCCESS_TASK_NUM':
      return {
        ...state,
        successTaskNum: null,
      };
    default:
      return state;
  }
}

export default vroom;
