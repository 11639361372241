import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  driverRecords: {},
  driverRecordIds: [],
  archivedDriverRecordIds: [],
  driverRecordMeta: {},
  archivedDriverRecordMeta: {},
  filter: {},
};

function driverRecord(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_DRIVER_RECORD':
      return {
        ...state,
        driverRecords: ReduxHelper.mergeDataWithObject(state.driverRecords, action.driverRecord),
      };
    case 'RECEIVE_DRIVER_RECORDS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          driverRecordIds: action.driverRecords.map(
            record => record.id,
          ),
          driverRecords: ReduxHelper.mergeDataWithList(
            state.driverRecords, action.driverRecords,
          ),
          driverRecordMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_DRIVER_RECORDS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedDriverRecordIds: action.driverRecords.map(
            record => record.id,
          ),
          driverRecords: ReduxHelper.mergeDataWithList(
            state.driverRecords, action.driverRecords,
          ),
          archivedDriverRecordMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'SET_DRIVER_RECORD_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default driverRecord;
