import React from 'react';
import ListItem from './listItem';

const ViewForm = React.lazy(() => import('./viewForm'));
const EditForm = React.lazy(() => import('./editForm'));

export default {
  ListItem,
  ViewForm,
  EditForm,
};
