const initialState = {
  transactionHistories: [],
  emailRecords: [],
  smsRecords: [],
};

function billing(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_TRANSACTION_HISTORIES':
      return {
        ...state,
        transactionHistories: action.transactionHistories.map(transactionHistory => (
          {
            date: transactionHistory.transaction_time,
            data: {
              ...transactionHistory,
            },
          }
        )),
      };
    case 'RECEIVE_EMAIL_MONTHLY_COUNT':
      return {
        ...state,
        emailRecords: Object.keys(action.emailRecords).map(key => (
          {
            date: key,
            data: {
              monthly_count: action.emailRecords[key],
            },
          }
        )),
      };
    case 'RECEIVE_SMS_MONTHLY_COUNT':
      return {
        ...state,
        smsRecords: Object.keys(action.smsRecords).map(key => (
          {
            date: key,
            data: {
              monthly_count: action.smsRecords[key],
            },
          }
        )),
      };
    default:
      return state;
  }
}

export default billing;
