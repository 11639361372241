const date = moment().format('YYYY-MM-DD');

const defaultTaskStates = [
  'unassigned',
  'assigned',
  'waiting_for_acknowledgement',
  'acknowledged',
  'started',
  'arrived',
  'successful',
  'failed',
  'declined',
  'cancelled',
  'collected',
];

const initialState = {
  filter: {
    planning: {
      date,
    },
    task: {
      date: [date, date],
      state: null,
    },
    job: {
      date: [date, date],
      state: null,
    },
    archives: {
      date: [date, date],
    },
  },
  filterSettings: [],
};

function filter(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_FILTER_SETTINGS':
      return {
        ...state,
        filterSettings: action.filterSettings,
      };
    case 'RECEIVE_FILTER_SETTINGS_UPDATE':
      return {
        ...state,
        filterSettings: {
          filter_settings: state.filterSettings.filter_settings.concat(
            action.filterSettings.filter_setting,
          ),
        },
      };
    case 'RECEIVE_FILTER_SETTINGS_DELETE':
      return {
        ...state,
        filterSettings: {
          filter_settings: state.filterSettings.filter_settings.filter(
            item => item.id !== action.filterId,
          ),
        },
      };
    case 'RESET_FILTER_STATUS':
      return {
        ...state,
        filter: {
          ...state.filter,
          task: {
            ...state.filter.task,
            state: defaultTaskStates,
          },
          job: {
            ...state.filter.job,
            state: null,
          },
        },
      };
    case 'RECEIVE_PLANNING_FILTER_UPDATE': {
      return {
        ...state,
        filter: {
          ...state.filter,
          planning: action.planningFilter,
        },
      };
    }
    case 'RECEIVE_PLANNING_TASK_FILTER_UPDATE': {
      const filterTask = { ...action.planningTaskFilter };
      return {
        ...state,
        filter: {
          ...state.filter,
          task: filterTask,
          job: {
            ...state.filter.job,
            date: filterTask.date,
          },
        },
      };
    }
    case 'RECEIVE_PLANNING_JOB_FILTER_UPDATE': {
      const filterJob = { ...action.planningJobFilter };
      return {
        ...state,
        filter: {
          ...state.filter,
          task: {
            ...state.filter.task,
            date: filterJob.date,
          },
          job: filterJob,
        },
      };
    }
    case 'RECEIVE_ARCHIVES_FITLER_UPDATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          archives: action.archivesFilter,
        },
      };
    default:
      return state;
  }
}

export default filter;
