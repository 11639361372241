import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Component from 'components/component';
import Helper from 'utils/helper';

const ActionPanelFilterDateButton = (props) => {
  const {
    id, value, onChange, account, className, filterClassName, fromDate,
  } = props;
  const text = Helper.getRangeDateString(value[0], value[1], account.language);

  return (
    <Component.ActionPanel.DropdownButton
      className={className}
      id={Helper.isNullOrUndefined(id) ? undefined : `${id}__dropdown-button`}
      showArrow
      text={text}
    >
      <Component.ActionPanel.FilterDateRangePicker
        className={filterClassName}
        fromDate={fromDate}
        id={Helper.isNullOrUndefined(id) ? undefined : `${id}__filter-date-range-picker`}
        locale={account.language}
        onChange={v => onChange(v)}
        values={value}
      />
    </Component.ActionPanel.DropdownButton>
  );
};

ActionPanelFilterDateButton.displayName = 'Action Panel Filter Date Button';

ActionPanelFilterDateButton.propTypes = {
  account: PropTypes.shape({
    language: PropTypes.string,
  }),
  className: PropTypes.string,
  filterClassName: PropTypes.string,
  fromDate: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ActionPanelFilterDateButton.defaultProps = {
  account: {},
  className: null,
  filterClassName: null,
  fromDate: null,
  id: null,
  value: [],
};

function mapStateToProps(state) {
  const { account } = state;
  return {
    account: account.account,
  };
}

export default connect(mapStateToProps)(ActionPanelFilterDateButton);
