import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveDriverRecordSetting(driverRecordSetting) {
  return {
    type: 'RECEIVE_DRIVER_RECORD_SETTING',
    driverRecordSetting,
  };
}

function receiveDriverRecordSettings(driverRecordSettings) {
  return {
    type: 'RECEIVE_DRIVER_RECORD_SETTINGS',
    driverRecordSettings,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/driver_record_settings/${id}`, {
      method: 'GET',
    });
    dispatch(receiveDriverRecordSetting(result.driver_record_setting));
    return result;
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/driver_record_settings', {
      method: 'GET',
    });
    dispatch(receiveDriverRecordSettings(result.driver_record_settings));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/driver_record_settings', {
      method: 'POST',
    }, {
      driver_record_setting: query,
    });
    dispatch(receiveDriverRecordSetting(result.driver_record_setting));
    return result.driver_record_setting;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/driver_record_settings/${id}`, {
      method: 'PUT',
    }, {
      driver_record_setting: query,
    });
    dispatch(receiveDriverRecordSetting(result.driver_record_setting));
    return result.driver_record_setting;
  };
}

function archive(id) {
  return async (dispatch) => {
    const result = await request(`/driver_record_settings/${id}/archive`, {
      method: 'PUT',
    });
    dispatch(fetchList());
    return result;
  };
}

export default {
  fetch,
  fetchList,
  update,
  create,
  archive,
};
