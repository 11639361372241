import { store } from 'reduxActions';
import Helper from 'utils/helper';
import Router from 'utils/router';
import Formatter from 'utils/formatter';
import featuresSettings from 'pages/featuresConstants';
import { Utils } from 'versafleet-core';
// commenting out until eslint is all fixed - reverted eslint fix due to issue in job import
// eslint-disable-next-line
import TaskRedux from './task';

const { RequestHelper: { requestBlob, openInNewWindow } } = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveJobs(result, timestamp) {
  return {
    type: 'RECEIVE_JOBS',
    jobs: result.jobs,
    meta: result.meta,
    timestamp,
  };
}

function receiveArchivedJobs(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_JOBS',
    jobs: result.jobs,
    meta: result.meta,
    timestamp,
  };
}

function receiveJobUpdateSuccessful(job) {
  return {
    type: 'RECEIVE_JOB_UPDATE_SUCCESSFUL',
    job,
  };
}

function receiveJobGeolocationUpdateSuccessful(id, location) {
  return {
    type: 'RECEIVE_JOB_GEOLOCATION_UPDATE_SUCCESSFUL',
    id,
    location,
  };
}

function receiveExportTaskCountSuccessful(taskCount) {
  return {
    type: 'RECEIVE_EXPORT_TASK_COUNT_SUCCESSFUL',
    taskCount,
  };
}

function receiveResetJobExportTaskCount() {
  return {
    type: 'RECEIVE_RESET_JOB_EXPORT_TASK_COUNT',
  };
}

function setFilter(filter) {
  return {
    type: 'SET_JOB_FILTER',
    filter,
  };
}

function receivePendingJobNotificationCount(count) {
  return {
    type: 'RECEIVE_PENDING_JOB_NOTIFICATION_COUNT',
    count,
  };
}

function receiveImportTimestamp(timestamp) {
  return {
    type: 'RECEIVE_IMPORT_TIMESTAMP',
    timestamp,
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();

    const data = {
      page: state.job.jobMeta.page,
      ...baseFilter,
      ...state.job.filter,
      archived: false,
    };
    const queryString = Utils.RequestHelper.convertDataToParams(data);

    const result = await Utils.RequestHelper.request(`/jobs${queryString}`, { method: 'GET' });
    dispatch(receiveJobs(result, timestamp));
  };
}

function fetchPendingList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();

    const data = {
      page: state.job.jobMeta.page,
      ...baseFilter,
      ...state.job.filter,
      archived: false,
    };
    const queryString = Utils.RequestHelper.convertDataToParams(data);

    const result = await Utils.RequestHelper.request(
      `/jobs/pending${queryString}`, { method: 'GET' },
    );
    dispatch(receiveJobs(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();

    const data = {
      page: state.job.archivedJobMeta.page,
      ...baseFilter,
      ...state.job.filter,
      archived: true,
    };
    const queryString = Utils.RequestHelper.convertDataToParams(data);

    const result = await Utils.RequestHelper.request(`/jobs${queryString}`, { method: 'GET' });
    dispatch(receiveArchivedJobs(result, timestamp));
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/jobs/${id}`, { method: 'GET' });
    dispatch(receiveJobUpdateSuccessful(result.job));
  };
}

function fetchPending(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/jobs/${id}/pending`, { method: 'GET' });
    dispatch(receiveJobUpdateSuccessful(result.job));
  };
}

async function createWithGeocodedAddress(query, location) {
  let newQuery = query;
  if (!Helper.isNullOrUndefined(location)) {
    const newBaseTaskAddressAttributes = {
      ...newQuery.base_task_attributes.address_attributes,
      latitude: location.lat,
      longitude: location.lng,
    };
    newQuery = {
      ...newQuery,
      base_task_attributes: {
        ...newQuery.base_task_attributes,
        address_attributes: newBaseTaskAddressAttributes,
      },
    };
  }

  const data = { job: newQuery };
  const result = await Utils.RequestHelper.request('/jobs', { method: 'POST' }, data);
  const path = Router.getPath();
  const state = store.getState();

  Router.pop();
  store.dispatch(receiveJobUpdateSuccessful(result.job));

  if (path.indexOf('visual') !== -1) {
    await store.dispatch(TaskRedux.fetchVisualList(state.task.filter));
    store.dispatch(TaskRedux.fetchAdditionalInfo());
  } else {
    store.dispatch(fetchList());
    await store.dispatch(TaskRedux.fetchList());
    store.dispatch(TaskRedux.fetchAdditionalInfo());
    store.dispatch(TaskRedux.fetchCustomFields());
  }
}

function addGeocodedTask(i, query, location) {
  const newQuery = query;
  if (!Helper.isNullOrUndefined(location)) {
    const newTaskAddressAttributes = {
      ...newQuery.tasks_attributes[i].address_attributes,
      latitude: location.lat,
      longitude: location.lng,
    };
    newQuery.tasks_attributes[i].address_attributes = newTaskAddressAttributes;
  }
  return newQuery;
}

function create(query) {
  let newQuery = query;
  return async (dispatch, getState) => {
    const state = getState();
    const useCrs = state.account.account.features_setting.coordinate_retrieval_service
      === featuresSettings.enabled;
    const accountId = state.account.account.id;

    await Promise.all(newQuery.tasks_attributes.map(async (item, i) => {
      let location = {
        lat: item.address_attributes.latitude,
        lng: item.address_attributes.longitude,
      };
      if (Helper.isNullOrUndefined(location.lat) || Helper.isNullOrUndefined(location.lng)) {
        try {
          location = await Utils.Geocoder.geocodeAddress(
            item.address_attributes,
            useCrs,
            accountId,
          );

          if (Helper.isNullOrUndefined(location.lat)
            || Helper.isNullOrUndefined(location.lng)
          ) {
            location = Helper.parseAddressToLatLng(item.address_attributes);
          }
        } catch (error) {
          // ignore error
        }
      }
      newQuery = addGeocodedTask(i, newQuery, location);

      if (i + 1 === newQuery.tasks_attributes.length) {
        let baseTaskLocation = {
          lat: newQuery.base_task_attributes.address_attributes.latitude,
          lng: newQuery.base_task_attributes.address_attributes.longitude,
        };
        if (Helper.isNullOrUndefined(baseTaskLocation.lat)
          || Helper.isNullOrUndefined(baseTaskLocation.lng)) {
          try {
            baseTaskLocation = await Utils.Geocoder.geocodeAddress(
              newQuery.base_task_attributes.address_attributes,
              useCrs,
              accountId,
            );

            if (Helper.isNullOrUndefined(baseTaskLocation.lat)
              || Helper.isNullOrUndefined(baseTaskLocation.lng)
            ) {
              baseTaskLocation = Helper.parseAddressToLatLng(newQuery.base_task_attributes
                .address_attributes);
            }
          } catch (error) {
            // ignore error
          }
        }
        return createWithGeocodedAddress(newQuery, baseTaskLocation);
      }
      return Promise.resolve();
    }));
  };
}

function duplicate(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/jobs/${id}/duplicate`, { method: 'POST' });
    dispatch(receiveJobUpdateSuccessful(result.job));
    return result;
  };
}

async function updateGeolocation(id, location) {
  const data = { location };
  await Utils.RequestHelper.request(`/jobs/${id}/location`, { method: 'PUT' }, data);
  store.dispatch(receiveJobGeolocationUpdateSuccessful(id, location));
}

async function updateJob(id, query, location) {
  let newQuery = query;
  if (!Helper.isNullOrUndefined(location)) {
    const newBaseTaskAddressAttributes = {
      ...newQuery.base_task_attributes.address_attributes,
      latitude: location.lat,
      longitude: location.lng,
    };
    newQuery = {
      ...newQuery,
      base_task_attributes: {
        ...newQuery.base_task_attributes,
        address_attributes: newBaseTaskAddressAttributes,
      },
    };
  }

  const data = { job: newQuery };
  const result = await Utils.RequestHelper.request(`/jobs/${id}`, { method: 'PUT' }, data);
  store.dispatch(receiveJobUpdateSuccessful(result.job));
  return result.job;
}

function update(id, query) {
  return async (dispatch, getState) => {
    const state = getState();
    let location = {
      lat: query.base_task_attributes.address_attributes.latitude,
      lng: query.base_task_attributes.address_attributes.longitude,
    };

    if (!Helper.isNullOrUndefined(state.job.jobs[id])
      && Formatter.getInlineAddress(query.base_task_attributes.address_attributes)
        !== Formatter.getInlineAddress(state.job.jobs[id].base_task.address)) {
      try {
        const useCrs = state.account.account.features_setting.coordinate_retrieval_service
          === featuresSettings.enabled;
        const accountId = state.account.account.id;
        location = await Utils.Geocoder.geocodeAddress(
          query.base_task_attributes.address_attributes,
          useCrs,
          accountId,
        );

        if (Helper.isNullOrUndefined(location.lat) || Helper.isNullOrUndefined(location.lng)) {
          location = Helper.parseAddressToLatLng(query.base_task_attributes.address_attributes);
        }
      } catch (error) {
        // ignore error
      }
    }

    const result = await updateJob(id, query, location);
    return result;
  };
}

function archive(id) {
  return async () => {
    await Utils.RequestHelper.request(`/jobs/${id}/archive`, { method: 'PUT' });
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/jobs/${id}/unarchive`, { method: 'PUT' });
    Router.transitionTo('/archives/tasks/jobs');
    dispatch(receiveJobUpdateSuccessful(result.job));
    dispatch(fetchArchivedList());
  };
}

function archiveMultiple(ids) {
  return async () => {
    const data = { ids, action_type: 'archive' };
    await Utils.RequestHelper.request('/jobs/update_multiple', { method: 'PUT' }, data);
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    const data = { ids, action_type: 'unarchive' };
    await Utils.RequestHelper.request('/jobs/update_multiple', { method: 'PUT' }, data);
    dispatch(fetchArchivedList());
  };
}

function print(id) {
  openInNewWindow(`/jobs/${id}/print`);
}

async function printMultiple(query) {
  const result = await Utils.RequestHelper.request('/jobs/multiple_print', {
    method: 'POST',
  }, {
    job: query,
  });
  return result;
}

function complete(id) {
  return async () => {
    await Utils.RequestHelper.request(`/jobs/${id}/complete`);
    this.trigger({ event: 'complete' }).bind(this);
  };
}

function checkPendingJobCount() {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request('/jobs/pending_job_count', { method: 'GET' });
    dispatch(receivePendingJobNotificationCount(result.count));
  };
}

function approve(id) {
  return async (dispatch) => {
    await Utils.RequestHelper.request(`/jobs/${id}/accept`, { method: 'PUT' });
    dispatch(fetch(id));
    dispatch(fetchPendingList());
    Router.transitionTo('/planning/pending');
    dispatch(checkPendingJobCount());
  };
}

function disapprove(id, query) {
  return async (dispatch) => {
    await Utils.RequestHelper.request(`/jobs/${id}/reject`, { method: 'PUT' }, query);
    dispatch(fetchPendingList());
    Router.transitionTo('/planning/pending');
    dispatch(checkPendingJobCount());
    return id;
  };
}

function approveMultiple(ids) {
  return async (dispatch) => {
    const data = { ids, action_type: 'accept' };
    await Utils.RequestHelper.request('/jobs/update_multiple', { method: 'PUT' }, data);
    dispatch(fetchPendingList());
    dispatch(checkPendingJobCount());
  };
}

function disapproveMultiple(ids, query) {
  return async (dispatch) => {
    const data = { ...query, ids, action_type: 'reject' };
    await Utils.RequestHelper.request('/jobs/update_multiple', { method: 'PUT' }, data);
    dispatch(fetchPendingList());
    dispatch(checkPendingJobCount());
    return ids;
  };
}

async function exportJobs(query) {
  const data = await requestBlob('/jobs/export', {
    method: 'POST',
  }, query);

  Utils.Helper.saveBlob(data, 'job_export.xlsx');
}

async function getImportTemplate(query) {
  const queryString = Utils.RequestHelper.convertDataToParams(query);
  const data = await requestBlob(`/jobs/import/template${queryString}`, {
    method: 'GET',
  });

  Utils.Helper.saveBlob(data, 'Import_Jobs.xlsx');
}

function importJobs(data) {
  return async (dispatch) => {
    await Utils.RequestHelper.requestCustomBody(
      '/jobs/import', { method: 'POST' }, data,
    );
    const path = Router.getPath();
    Router.transitionTo(`${path}?success=true`);
    if (path.indexOf('visual') !== -1) {
      dispatch(receiveImportTimestamp(new Date().getTime()));
    } else {
      dispatch(fetchList());
      await dispatch(TaskRedux.fetchList());
      dispatch(TaskRedux.fetchAdditionalInfo());
    }
  };
}

function checkExportTaskCount(data) {
  return async (dispatch) => {
    const queryString = Utils.RequestHelper.convertDataToParams(data);
    const result = await Utils.RequestHelper.request(
      `/export/task_count${queryString}`, { method: 'GET' },
    );
    dispatch(receiveExportTaskCountSuccessful(result.task_count));
  };
}

function resetExportTaskCount() {
  return (dispatch) => {
    dispatch(receiveResetJobExportTaskCount());
  };
}

function cancel(id) {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(`/jobs/${id}/cancel`, { method: 'PUT' });
    dispatch(fetch(id));
    return result;
  };
}

export default {
  setFilter,
  fetchList,
  fetchPendingList,
  fetchArchivedList,
  fetch,
  fetchPending,
  create,
  update,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  print,
  printMultiple,
  complete,
  exportJobs,
  getImportTemplate,
  importJobs,
  approve,
  approveMultiple,
  disapprove,
  disapproveMultiple,
  updateGeolocation,
  checkExportTaskCount,
  resetExportTaskCount,
  checkPendingJobCount,
  cancel,
  duplicate,
};
