import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { translate, Components, Utils } from 'versafleet-core';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import FontFamily from '@tiptap/extension-font-family';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import { GithubPicker } from 'react-color';
import Placeholder from '@tiptap/extension-placeholder';

import '../../../stylesheets/stringNameStylesheets/components/tiptap.styl';

const TiptapInput = ({
  content,
  onChange,
  tagOptions = [],
  strings,
  showToolbarButtons = ['format', 'list', 'color',
    'image', 'link', 'heading',
    'fontFamily', 'mergeTags'],
  getImageParams,
  signingUrl,
  placeholder = 'Write something...',
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isToolbarFocused, setIsToolbarFocused] = useState(false);
  const [isTextColor, setIsTextColor] = useState(false);
  const [isHighlightColor, setIsHighlightColor] = useState(false);
  const [isOpenLink, setIsOpenLink] = useState(false);

  // This improve the performance of the editor
  // by helps reduce the frequency of updates when user is actively typing
  const debouncedOnChange = useCallback((() => {
    let timeout;
    return (newContent) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => onChange(newContent), 300);
    };
  })(), [onChange]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle.configure({ mergeNestedSpanStyles: false }),
      Color,
      Highlight.configure({ multicolor: true }),
      Underline,
      FontFamily,
      Image,
      Link.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder,
      }),
    ],
    content,
    onUpdate: ({ editor: curEditor }) => {
      const newContent = curEditor.getHTML();
      if (newContent !== content) {
        debouncedOnChange(newContent);
      }
    },
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content, false);
    }
  }, [content]);

  const renderMergeTagDropdown = () => {
    const insertMergeTag = (value) => {
      editor?.chain().focus().insertContent(value).run();
    };

    return tagOptions.length > 0 && (
      <Components.Input.Select
        className="tiptap__dropdown-custom"
        onChange={value => insertMergeTag(value)}
        options={tagOptions}
        placeholder={strings.insertMergeTags}
        width="narrow"
      />
    );
  };

  const renderHeadingDropdown = () => {
    const headings = [
      {
        label: 'Normal', value: 0,
      },
      {
        label: 'Heading 1', value: 1,
      },
      {
        label: 'Heading 2', value: 2,
      },
      {
        label: 'Heading 3', value: 3,
      },
    ];

    return (
      <Components.Input.Select
        className="tiptap__dropdown-custom"
        onChange={(value) => {
          const intValue = parseInt(value, 10);
          if (intValue === 0) {
            editor?.chain().focus().setParagraph().run();
          } else {
            editor?.chain().focus().setHeading({ level: intValue }).run();
          }
        }}
        options={headings.map(heading => ({
          value: heading.value.toString(),
          text: heading.label,
        }))}
        value={(headings.find(h => editor?.isActive('heading', { level: h.value }))?.value || 0).toString()}
        width="narrow"
      />
    );
  };

  const renderFontFamilyDropdown = () => {
    const fonts = [
      {
        label: 'Sans-serif', value: 'sans-serif',
      },
      {
        label: 'Helvetica', value: 'Helvetica',
      },
      {
        label: 'Lato', value: 'Lato',
      },
      {
        label: 'Monospace', value: 'monospace',
      },
      {
        label: 'Roboto', value: 'Roboto',
      },
    ];

    return (
      <Components.Input.Select
        className="tiptap__dropdown-custom"
        onChange={value => editor?.chain().focus().setFontFamily(value).run()}
        options={fonts.map(font => ({
          value: font.value,
          text: font.label,
        }))}
        value={(fonts.find(f => editor?.isActive('textStyle', { fontFamily: f.value }))?.value || 'sans-serif')}
        width="narrow"
      />
    );
  };

  const renderColorButtons = () => {
    const textColorValue = editor?.getAttributes('textStyle').color || '#000000';
    const highlightColorValue = editor?.getAttributes('textStyle').color || '#ffffff';
    const colors = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB', '#FFFFFF', '#000000DE'];

    return (
      <div>
        <Components.ButtonDropdown
          hint={strings.hint.textColor}
          iconClassName="versa-theme"
          showDropdown={isTextColor}
          toggleDropdown={() => setIsTextColor(cur => !cur)}
        >
          <GithubPicker
            color={textColorValue}
            colors={colors}
            onChange={color => editor?.chain().focus().setColor(color.hex).run()}
          />
        </Components.ButtonDropdown>

        <Components.ButtonDropdown
          hint={strings.hint.highlightColor}
          iconClassName="versa-refine"
          showDropdown={isHighlightColor}
          toggleDropdown={() => setIsHighlightColor(cur => !cur)}
        >
          <GithubPicker
            color={highlightColorValue}
            colors={colors}
            onChange={color => editor?.chain().focus().setHighlight({ color: color.hex }).run()}
          />
        </Components.ButtonDropdown>
      </div>
    );
  };

  const renderFormatButton = () => (
    <div>
      <Components.Button
        className="versa-format-font-bold"
        fillColor={!!editor?.isActive('bold')}
        hint={strings.hint.bold}
        onClick={() => editor?.chain().focus().toggleBold().run()}
      />
      <Components.Button
        className="versa-format-font-italics"
        fillColor={!!editor?.isActive('italic')}
        hint={strings.hint.italic}
        onClick={() => editor?.chain().focus().toggleItalic().run()}
      />
      <Components.Button
        className="versa-format-font-underline"
        fillColor={!!editor?.isActive('underline')}
        hint={strings.hint.underline}
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
      />
    </div>
  );

  const renderListButton = () => (
    <div>
      <Components.Button
        className="versa-format-list-number"
        fillColor={!!editor?.isActive('orderedList')}
        hint={strings.hint.ordered}
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
      />
      <Components.Button
        className="versa-format-list-bullet"
        fillColor={!!editor?.isActive('bulletList')}
        hint={strings.hint.unordered}
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
      />
    </div>
  );

  const renderImageButton = () => {
    const [imageUrl, setImageUrl] = useState('');
    const [isOpenImage, setIsOpenImage] = useState(false);

    const { convertDataToParams, request, requestUploadFile } = Utils.RequestHelper;

    const handleImageApply = () => {
      if (imageUrl) {
        editor?.chain()
          .focus()
          .setImage({ src: imageUrl })
          .run();
        setImageUrl('');
        setIsOpenImage(false);
      }
    };

    const handleImageUpload = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const param = convertDataToParams(getImageParams(file));
            const result = await request(`${signingUrl}${param}`, { method: 'GET' });
            await requestUploadFile(result.signedUrl, file);
            editor?.chain().focus().setImage({ src: result.baseUrl }).run();
          } catch (error) {
            console.error(error);
          }
        }
      };
      input.click();
    };

    return (
      <Components.ButtonDropdown
        className="versa-image"
        hint={strings.hint.image}
        showDropdown={isOpenImage}
        toggleDropdown={() => setIsOpenImage(cur => !cur)}
      >
        <div>
          <Components.Input.Textbox
            onChange={value => setImageUrl(value)}
            onEnterKeyPressed={() => handleImageApply()}
            onFocus={() => setIsFocused(true)}
            placeholder={strings.enterImageUrl}
            value={imageUrl}
            width="narrow"
          />
          <Components.Button
            onClick={handleImageApply}
          >
            {strings.apply}
          </Components.Button>
        </div>
        {getImageParams && signingUrl && (
          <Components.Button
            fillColor
            iconClassName="versa-upload"
            onClick={handleImageUpload}
          >
            {strings.uploadImage}
          </Components.Button>
        )}
      </Components.ButtonDropdown>
    );
  };

  const renderLinkButton = () => {
    const [linkUrl, setLinkUrl] = useState(editor?.getAttributes('link').href || '');

    const formatUrl = (url) => {
      if (url && !url.match(/^https?:\/\//)) {
        return `https://${url}`;
      }
      return url;
    };

    const handleLinkApply = () => {
      if (linkUrl) {
        editor?.chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: formatUrl(linkUrl) })
          .run();
        setLinkUrl('');
        setIsOpenLink(false);
      }
    };

    return (
      <Components.ButtonDropdown
        className="versa-linked"
        fillColor={!!editor?.isActive('link')}
        hint={strings.hint.link}
        showDropdown={isOpenLink}
        toggleDropdown={() => {
          setIsOpenLink(cur => !cur);
          setLinkUrl(editor?.getAttributes('link')?.href);
        }}
      >
        <div>
          <Components.Input.Textbox
            onChange={value => setLinkUrl(value)}
            onEnterKeyPressed={() => handleLinkApply()}
            onFocus={() => setIsFocused(true)}
            placeholder={strings.enterLink}
            value={linkUrl}
            width="narrow"
          />
          <Components.Button
            onClick={handleLinkApply}
          >
            {strings.apply}
          </Components.Button>
        </div>
      </Components.ButtonDropdown>
    );
  };

  const buttonComponents = {
    format: renderFormatButton,
    list: renderListButton,
    heading: renderHeadingDropdown,
    fontFamily: renderFontFamilyDropdown,
    color: renderColorButtons,
    mergeTags: renderMergeTagDropdown,
    image: renderImageButton,
    link: renderLinkButton,
  };

  const renderToolbar = () => {
    const editorRect = editor?.view?.dom?.getBoundingClientRect();

    // Check if top or bottom position of the toolbar would be cut off
    const isTopCut = editorRect?.top < 56;
    const isBottomCut = editorRect?.bottom > window.innerHeight;

    // Position the toolbar based on the active selection
    const cursorPosition = editor.view.state.selection.from;
    const cursorCoord = editor.view.coordsAtPos(cursorPosition);

    let top;
    if (isTopCut && isBottomCut) {
      top = cursorCoord?.top - 56;
    } else if (isTopCut) {
      top = editorRect?.bottom + 16;
    } else {
      top = editorRect?.top - 56;
    }

    const toolbarStyle = {
      position: 'fixed',
      top: `${top}px`,
      left: `${editorRect?.left}px`,
      zIndex: 9999,
    };

    // Need portal to avoid overflow issue
    return createPortal(
      <div
        className={`tiptap__toolbar ${isToolbarFocused || isFocused ? 'tiptap__toolbar--visible' : 'tiptap__toolbar--hidden'}`}
        onMouseEnter={() => setIsToolbarFocused(true)}
        onMouseLeave={() => setIsToolbarFocused(false)}
        style={toolbarStyle}
      >
        {showToolbarButtons.map(button => buttonComponents[button]())}
      </div>,
      document.body,
    );
  };

  return (
    <div className="tiptap">
      {renderToolbar()}
      <EditorContent editor={editor} />
    </div>
  );
};

TiptapInput.propTypes = {
  content: PropTypes.string,
  getImageParams: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showToolbarButtons: PropTypes.arrayOf(
    PropTypes.oneOf(['format', 'list', 'heading', 'fontFamily', 'color', 'mergeTags', 'image', 'link']),
  ),
  signingUrl: PropTypes.string,
  strings: PropTypes.shape({
    insertMergeTags: PropTypes.string,
    hint: PropTypes.shape({
      bold: PropTypes.string,
      italic: PropTypes.string,
      underline: PropTypes.string,
      unordered: PropTypes.string,
      ordered: PropTypes.string,
      textColor: PropTypes.string,
      highlightColor: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.string,
    }),
    enterLink: PropTypes.string,
    enterImageUrl: PropTypes.string,
    apply: PropTypes.string,
    uploadImage: PropTypes.string,
  }).isRequired,
  tagOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  })),
};

export default translate('Component.Tiptap')(TiptapInput);
