import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReduxActions from 'reduxActions';
import ListWindow from '../list';

const JobSmsTriggerEdit = React.lazy(() => import('./edit'));
const JobSmsTriggerView = React.lazy(() => import('./view'));

class JobSmsTrigger extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.statusTrigger.fetchByModelType('job', 'sms'));
  }

  render() {
    return <ListWindow data={this.props.data} model="job" type="sms" />;
  }
}

JobSmsTrigger.displayName = 'Job SMS Trigger';

JobSmsTrigger.propTypes = {
  data: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

JobSmsTrigger.Edit = JobSmsTriggerEdit;

JobSmsTrigger.View = JobSmsTriggerView;

const mapStateToProps = state => ({
  data: state.statusTrigger.jobSmsTriggers,
});

export default connect(mapStateToProps)(JobSmsTrigger);
