import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  ourLocations: {},
  ourLocationIds: [],
  ourLocationMeta: {},
  optionLocations: [],
  filter: {},
};

function ourLocation(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_OUR_LOCATION':
      return {
        ...state,
        ourLocations: ReduxHelper.mergeDataWithObject(state.ourLocations,
          action.ourLocation),
      };
    case 'RECEIVE_OUR_LOCATIONS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          ourLocationIds: action.ourLocations.map(_ourLocation => _ourLocation.id),
          ourLocations: ReduxHelper.mergeDataWithList(state.ourLocations,
            action.ourLocations),
          ourLocationMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_OPTION_LOCATIONS':
      return {
        ...state,
        optionLocations: action.ourLocations,
      };
    case 'RECEIVE_OUR_LOCATION_UPDATE_SUCCESSFUL':
      return {
        ...state,
        users: ReduxHelper.mergeDataWithObject(state.ourLocations, action.ourLocation),
        selectedId: action.ourLocation.id,
      };
    case 'SET_OUR_LOCATION_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default ourLocation;
