import { Utils } from 'versafleet-core';

function receiveLocators(result) {
  return {
    type: 'RECEIVE_LOCATORS',
    driverLocators: result.drivers,
    vehicleLocators: result.vehicle_locators,
  };
}

function receiveVehicleLocatorHistory(result) {
  return {
    type: 'RECEIVE_VEHICLE_LOCATOR_HISTORY',
    vehicleLocatorHistory: result,
  };
}

function receiveVehicleLocatorProvider(result) {
  return {
    type: 'RECEIVE_VEHICLE_LOCATOR_PROVIDER',
    vehicleLocatorProvider: result,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_VEHICLE_LOCATOR_FILTER',
    filter,
  };
}

function fetchLocatorList() {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(
      '/vehicle_locators/positions', { method: 'GET' },
    );
    dispatch(receiveLocators(result));
    return result;
  };
}

function fetchVehicleLocatorHistory(filter) {
  return async (dispatch) => {
    const param = Utils.RequestHelper.convertDataToParams({
      from_time: filter.from_time,
      to_time: filter.to_time,
    });
    const result = await Utils.RequestHelper.request(
      `/vehicle_locators/${filter.id}/history${param}`, { method: 'GET' },
    );
    dispatch(receiveVehicleLocatorHistory(result.vehicle_locator_history));
    return result.vehicle_locator_history;
  };
}

function fetchVehicleLocatorProvider() {
  return async (dispatch) => {
    const result = await Utils.RequestHelper.request(
      '/vehicle_locators/setting', { method: 'GET' },
    );
    dispatch(receiveVehicleLocatorProvider(result.account.vehicle_locator_provider_type));
    return result.account.vehicle_locator_provider_type;
  };
}

function resetVehicleLocatorHistory() {
  return (dispatch) => {
    dispatch(receiveVehicleLocatorHistory([]));
  };
}

export default {
  setFilter,
  fetchLocatorList,
  fetchVehicleLocatorHistory,
  fetchVehicleLocatorProvider,
  resetVehicleLocatorHistory,
};
