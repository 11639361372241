import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  filter: {},
  vehicles: {},
  drivers: {},
  vehicleIds: [],
  driverIds: [],
  vehicleHistory: [],
  vehicleProvider: null,
};

/**
 * Custom merger function for drivers. This is to fix a bug where `account_name` is not removed
 * when it is not anymore present in the new data (it will use the old data instead).
 */
function mergeDriversData(prevData, newData = []) {
  const mergedData = { ...prevData };

  for (let i = 0; i < newData.length; i += 1) {
    const item = newData[i];
    const existingItem = mergedData[item.id] || {};

    // Merge the new item with the existing item
    const mergedItem = {
      ...existingItem,
      ...item,
    };

    // If the new item does not have `account_name`, remove it from the merged item
    if (!('account_name' in item)) {
      delete mergedItem.account_name;
    }

    mergedData[item.id] = mergedItem;
  }
  return mergedData;
}

function vehicleLocator(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_LOCATORS':
      return {
        ...state,
        vehicleIds: action.vehicleLocators.map(
          vehicle => vehicle.id,
        ),
        vehicles: ReduxHelper.mergeDataWithList(
          state.vehicles, action.vehicleLocators,
        ),
        driverIds: action.driverLocators.map(
          driver => driver.id,
        ),
        drivers: mergeDriversData(
          state.drivers, action.driverLocators,
        ),
      };
    case 'RECEIVE_VEHICLE_LOCATOR_HISTORY':
      return {
        ...state,
        vehicleHistory: action.vehicleLocatorHistory,
      };
    case 'RECEIVE_VEHICLE_LOCATOR_PROVIDER':
      return {
        ...state,
        vehicleProvider: action.vehicleLocatorProvider,
      };
    case 'SET_VEHICLE_LOCATOR_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default vehicleLocator;
