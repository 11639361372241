import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveVehicleRecordSetting(vehicleRecordSetting) {
  return {
    type: 'RECEIVE_VEHICLE_RECORD_SETTING',
    vehicleRecordSetting,
  };
}

function receiveVehicleRecordSettings(vehicleRecordSettings) {
  return {
    type: 'RECEIVE_VEHICLE_RECORD_SETTINGS',
    vehicleRecordSettings,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/vehicle_record_settings/${id}`, {
      method: 'GET',
    });
    dispatch(receiveVehicleRecordSetting(result.vehicle_record_setting));
    return result;
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/vehicle_record_settings', {
      method: 'GET',
    });
    dispatch(receiveVehicleRecordSettings(result.vehicle_record_settings));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/vehicle_record_settings', {
      method: 'POST',
    }, {
      vehicle_record_setting: query,
    });
    dispatch(receiveVehicleRecordSetting(result.vehicle_record_setting));
    return result.vehicle_record_setting;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/vehicle_record_settings/${id}`, {
      method: 'PUT',
    }, {
      vehicle_record_setting: query,
    });
    dispatch(receiveVehicleRecordSetting(result.vehicle_record_setting));
    return result.vehicle_record_setting;
  };
}

function archive(id) {
  return async (dispatch) => {
    const result = await request(`/vehicle_record_settings/${id}/archive`, {
      method: 'PUT',
    });
    dispatch(fetchList());
    return result;
  };
}

export default {
  fetch,
  fetchList,
  update,
  create,
  archive,
};
