import React from 'react';
import ExportTemplateForm from './exportTemplateForm';

const ListItem = React.lazy(() => import('./listItem'));
const New = React.lazy(() => import('./new'));
const Edit = React.lazy(() => import('./edit'));
const View = React.lazy(() => import('./view'));

export default {
  ListItem,
  New,
  Edit,
  View,
  ExportTemplateForm,
};
