import { Utils } from 'versafleet-core';

const { ReduxHelper } = Utils;
const initialState = {
  vroomTimers: [],
  vroomTimerIds: [],
};

function vroomTimer(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VROOM_TIMER_UPDATE_SUCCESSFUL':
    case 'RECEIVE_VROOM_TIMER_SUCCESSFUL':
      return {
        ...state,
        vroomTimers: ReduxHelper.mergeDataWithObject(state.vroomTimers, action.data.vroom_timer),
      };
    case 'RECEIVE_VROOM_TIMERS_SUCCESSFUL':
      return {
        ...state,
        vroomTimers: ReduxHelper.mergeDataWithList(state.vroomTimers, action.data.vroom_timer),
        vroomTimerIds: action.data.vroom_timer.map(t => t.id),
      };
    default:
      return state;
  }
}

export default vroomTimer;
