const initialState = {
  driverSettings: null,
};

function driverSettings(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_DRIVER_SETTINGS':
      return {
        ...state,
        driverSettings: action.driverSettings,
      };
    case 'RECEIVE_DRIVER_SETTINGS_UPDATE':
      return {
        ...state,
        driverSettings: action.driverSettings,
      };
    default:
      return state;
  }
}

export default driverSettings;
