import { Utils } from 'versafleet-core';
import TaskActions from './task';

function receiveRouteOptimizationSuccessful(successTaskNum) {
  return {
    successTaskNum,
    type: 'RECEIVE_ROUTE_OPTIMIZATION_SUCCESSFUL',
  };
}

function setReassignTasksOption(reassignTasks) {
  return {
    type: 'SET_REASSIGN_TASKS_OPTION',
    reassignTasks,
  };
}

function setTaskIds(taskIds) {
  return {
    type: 'SET_TASK_IDS',
    taskIds,
  };
}

function resetTaskIds() {
  return {
    type: 'RESET_TASK_IDS',
  };
}

function resetSuccessTaskNum() {
  return {
    type: 'RESET_SUCCESS_TASK_NUM',
  };
}

function optimizeRoute(data) {
  return async (dispatch) => {
    dispatch(resetSuccessTaskNum());
    const result = await Utils.RequestHelper.request(
      '/vroom/optimize', { method: 'POST' }, data,
    );
    return result;
  };
}

function reoptimizeRoute(id, data) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(resetSuccessTaskNum());
    const result = await Utils.RequestHelper.request(
      `/vroom/task_assignments/${id}`, { method: 'POST' }, data,
    );
    dispatch(receiveRouteOptimizationSuccessful());
    dispatch(TaskActions.fetchVisualData(state.task.filter));
    return result;
  };
}

export default {
  optimizeRoute,
  reoptimizeRoute,
  setReassignTasksOption,
  setTaskIds,
  resetTaskIds,
  receiveRouteOptimizationSuccessful,
  resetSuccessTaskNum,
};
