import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Button from './authorizedButton';

const ButtonRefresh = props => (
  <Button
    className="button-refresh"
    hint={props.withDefaultText ? null : props.strings.text}
    iconClassName="versa-repeat"
    {...props}
  >
    {props.withDefaultText ? props.strings.text : props.children}
  </Button>
);

ButtonRefresh.displayName = 'Button Refresh';

ButtonRefresh.propTypes = {
  children: PropTypes.node,
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonRefresh.defaultProps = {
  children: null,
  withDefaultText: false,
};

export default translate('Component.Button.Refresh')(ButtonRefresh);
