import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utils } from 'versafleet-core';
import Component from 'components/component';
import AccessRightHelper from 'utils/accessRightHelper';
import Helper from 'utils/helper';
import ReduxActions from 'reduxActions';

class CustomInfoEdit extends React.Component {
  componentDidMount() {
    this.props.dispatch(ReduxActions.task.fetchSystemTags());
  }

  _renderHint = (isMandatory, hint) => {
    if (isMandatory) {
      return (
        <Component.Hint
          className="vf-task__edit-form__custom-info__hint"
          hintPosition={Component.Hint.Position.RIGHT}
        >
          {hint}
        </Component.Hint>
      );
    }
    return false;
  }

  _renderCustomInfoInput(item, i, customInfoError) {
    const {
      tags, data, form, customFieldGroup,
    } = this.props;
    const { input } = this.props.strings;
    const error = Helper.isNullOrUndefined(customInfoError) ? [] : customInfoError.value;
    const subvalueError = Helper.isNullOrUndefined(customInfoError) ? [] : customInfoError.subvalue;
    const customField = form.custom_fields_attributes[i] || {};
    const id = Helper.isNullOrUndefined(this.props.id) ? undefined
      : `${this.props.id}__${item.field_type}-input`;
    const subtitleClass = 'vf-task__edit-form__custom-info__numeric-dropdown__subtitle';
    let selectedOption;

    switch (item.field_type) {
      case 'text':
        return (
          <Component.Input.Textarea
            error={error}
            id={id}
            onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, value }, i)}
            placeholder={input[`${item.field_type}Hint`]}
            rows={2}
            value={customField.value}
            width="full"
          />
        );
      case 'number':
        return (
          <Component.Input.Textbox
            error={error}
            id={id}
            onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, value }, i)}
            placeholder={input[`${item.field_type}Hint`]}
            value={customField.value}
          />
        );
      case 'date':
        return (
          <Component.Input.DatePicker
            error={error}
            id={id}
            onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, value }, i)}
            placeholder={input[`${item.field_type}Hint`]}
            value={customField.value}
          />
        );
      case 'time':
        return (
          <Component.Input.TimePicker
            error={error}
            id={id}
            onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, value }, i)}
            placeholder={input[`${item.field_type}Hint`]}
            value={customField.value}
            width="narrow"
          />
        );
      case 'choice':
        return (
          <Component.Input.RadioButton
            error={error}
            id={id}
            name={item.name}
            onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, value }, i)}
            options={
              item.options.map(option => ({
                value: option.value,
                text: (
                  <Component.Input.RadioButton.Item
                    name={option.value}
                    onClick={() => this.props.onFormChange(
                      'custom_fields_attributes', { ...customField, value: option.value }, i,
                    )}
                  />
                ),
              }))
            }
            value={customField.value}
          />
        );
      case 'search_list':
        selectedOption = item.options
          .find(option => option.value === customField.value);

        return (
          <div>
            <Component.Input.Select
              error={error}
              id={id}
              onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, subvalue: null, value }, i)}
              options={item.options.map(option => ({
                text: option.value,
                value: option.value,
              }))}
              placeholder={input.optionHint}
              value={customField.value}
            />
            {
              Helper.isNullOrUndefined(selectedOption) ? null : (
                <div className="vf-task__edit-form__custom-info__search-list__sub-field-name">
                  {item.subname}
                  <div className="vf-task__edit-form__custom-info__search-list__suboption">
                    <Component.Input.Select
                      error={subvalueError}
                      onChange={value => this.props.onFormChange('custom_fields_attributes', { ...customField, subvalue: value }, i)}
                      options={selectedOption.suboptions.map(suboption => ({
                        text: suboption,
                        value: suboption,
                      }))}
                      placeholder={input.suboptionHint}
                      value={customField.subvalue}
                    />
                  </div>
                </div>
              )
            }
          </div>
        );
      case 'dropdown':
        return (
          <Component.Input.Select
            error={error}
            id={id}
            onChange={value => this.props.onFormChange(
              'custom_fields_attributes', { ...customField, value }, i,
            )}
            options={
              item.options.map(option => ({ value: option.value, text: option.value }))
            }
            value={customField.value}
          />
        );
      case 'checkbox':
        return this._renderCheckboxes(item, i, id, error);
      case 'numeric_dropdown':
        return (
          <Component.Input.Searchbox
            error={error}
            id={id}
            onChange={(text) => {
              const options = item.options.filter(option => option.text === text);
              this.props.onFormChange('custom_fields_attributes', {
                ...customField,
                value: options[0] ? options[0].value : null,
                subvalue: options[0] ? options[0].text : null,
              }, i);
            }}
            options={
              item.options.map(option => ({
                value: option.text,
                text: option.value,
                row: (
                  <div>
                    {option.text}
                    <div className={subtitleClass}>
                      {option.value}
                    </div>
                  </div>
                ),
              }))
            }
            placeholder={this.props.strings.input.emptyOptionsText}
            useValueAsText
            value={customField.subvalue}
          />
        );
      case 'rubric':
        return (
          <Component.Form.Block fontWeight="REGULAR">
            {Utils.RubricCustomFieldParser.parseRubric(
              item, data, Utils.TaskMergeTags, tags, form, customFieldGroup,
            )}
          </Component.Form.Block>
        );

      default:
        return false;
    }
  }

  _renderCheckboxes(item, i, id, error) {
    const { form, onFormChange } = this.props;
    const customField = form.custom_fields_attributes[i] || {};
    let parsedValue = Helper.isNullOrUndefined(customField.value)
      ? [] : JSON.parse(customField.value);
    return (
      <div className="vf-task__edit-form__custom-info__checkbox-container">
        {
          item.options.map(option => (
            <Component.Input.Checkbox
              id={id}
              key={`${id}__${option.value}`}
              onChange={(value) => {
                parsedValue = value ? [...parsedValue, option.value]
                  : parsedValue.filter(v => v !== option.value);
                parsedValue = parsedValue.length === 0 ? null : JSON.stringify(parsedValue);
                onFormChange('custom_fields_attributes', { ...customField, value: parsedValue }, i);
              }}
              value={parsedValue.filter(v => v === option.value).length > 0}
            >
              {option.value}
            </Component.Input.Checkbox>
          ))
        }
        {
          error ? (
            <div className="vf-task__edit-form__custom-info__checkbox-container__input-error">
              {error.join(', ')}
            </div>
          ) : false
        }
      </div>
    );
  }

  _renderCustomFields(customFieldGroup, error) {
    const newCreatedErrors = error.filter(e => Helper.isNullOrUndefined(e.id));
    let index = 0;

    const userEditableInVf = AccessRightHelper.hasAccess(
      this.props.accessRights, 'task.edit_api_editable_cf',
    );

    return (
      customFieldGroup.custom_field_descriptions
        .filter(item => item.editable_in_vf || userEditableInVf)
        .map((item, i) => {
          const customField = this.props.form.custom_fields_attributes[i];
          let customInfoError;

          if (Helper.isNullOrUndefined(customField)
            || Helper.isNullOrUndefined(customField.id) || Number.isNaN(customField.id)) {
            customInfoError = newCreatedErrors[index];
            index += 1;
          } else {
            customInfoError = error.find(e => e.id === customField.id);
          }

          return (
            <div key={item.id}>
              <Component.Form.Block fontColor="GREY" fontWeight="REGULAR" level={2}>
                {item.name}
              </Component.Form.Block>
              <Component.Form.Block fontColor="TEXT" fontWeight="REGULAR" isRequired={item.is_mandatory} level={3}>
                {this._renderHint(item.is_mandatory, this.props.strings.mandatory)}
                {this._renderCustomInfoInput(item, i, customInfoError)}
              </Component.Form.Block>
              <Component.Form.Divider size="SMALL" />
            </div>
          );
        })
    );
  }

  _renderSearchFilterDropDownParent(strings, id) {
    const { customFieldGroup } = this.props;
    return (
      <Component.Form.Block
        contentClassName="vf-task__edit-form__custom-info__parent"
        level={1}
      >
        <div className="vf-task__edit-form__custom-info__parent__title">{strings.title}</div>
        <div onClick={e => e.stopPropagation()}>
          <i className="versa-customfields" />
          {
            !this.props.isNewTask ? (
              <div className="vf-task__edit-form__custom-info__parent__filter-search">
                {Helper.isNullOrUndefined(customFieldGroup) ? false : customFieldGroup.name}
              </div>
            ) : (
              <Component.Input.Select
                className="vf-task__edit-form__custom-info__parent__filter-search"
                id={Helper.isNullOrUndefined(this.props.id) ? undefined
                  : `${this.props.id}__select-group-input`}
                onChange={value => this.props.onFormChange('custom_field_group_id', value)}
                options={this.props.options}
                placeholder="Search Custom Field Groups"
                value={id}
              />
            )
          }
        </div>
      </Component.Form.Block>
    );
  }

  render() {
    const {
      customFieldGroup, data, error, strings, form,
    } = this.props;
    let id;
    if (this.props.isNewTask) {
      id = this.props.form.custom_field_group_id;
    } else if (Helper.isNullOrUndefined(data.custom_field_group)) {
      id = null;
    } else {
      id = data.custom_field_group.id;
    }

    return (
      !this.props.isNewTask && Helper.isNullOrUndefined(id) ? false : (
        <Component.Form.Dropdown
          id={this.props.id}
          parent={this._renderSearchFilterDropDownParent(strings, id)}
          showByDefault
        >
          {
            !Helper.isNullOrUndefined(customFieldGroup) && !customFieldGroup.archived
            && form.custom_fields_attributes.length !== 0
              ? this._renderCustomFields(customFieldGroup, error) : false
          }
        </Component.Form.Dropdown>
      )
    );
  }
}

CustomInfoEdit.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  customFieldGroup: PropTypes.shape({
    archived: PropTypes.bool,
    name: PropTypes.string,
  }),
  data: PropTypes.shape({
    custom_field_group: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.arrayOf(PropTypes.shape()),
  form: PropTypes.shape({
    custom_field_group_id: PropTypes.number,
    custom_fields_attributes: PropTypes.arrayOf(PropTypes.shape()),
  }),
  id: PropTypes.string,
  isNewTask: PropTypes.bool,
  onFormChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({
    input: PropTypes.shape(),
    mandatory: PropTypes.string,
  }).isRequired,
  tags: PropTypes.shape({}),
};

CustomInfoEdit.defaultProps = {
  customFieldGroup: null,
  data: null,
  error: [],
  form: null,
  id: '',
  isNewTask: false,
  options: [],
  onFormChange: null,
  tags: null,
};

CustomInfoEdit.displayName = 'Custom Info Edit';

function mapStateToProps(state) {
  const { profile, task } = state;
  return {
    accessRights: profile.profile.access_rights,
    tags: task.systemTags,
  };
}

export default connect(mapStateToProps)(CustomInfoEdit);
