import { Utils } from 'versafleet-core';
import Helper from './helper';

class Formatter extends Utils.Formatter {
  static getGeocodeAddress(address) {
    if (Helper.isNullOrUndefined(address)) {
      return '';
    }
    return [
      Formatter.trim(address.line_1),
      address.city === 'Singapore' ? null : Formatter.trim(address.city),
    ].filter(addrElem => !Helper.isNullOrUndefined(addrElem) && Helper.isNonEmptyString(addrElem)).join(', ');
  }

  /**
   * Format time to 12 hour format
   *
   * Format the time value in minutes from the start of the day
   * to a readable 12-hour format with no leading zeros. The minutes are hidden
   * on the exact hour.
   *
   * @example
   * // returns 2 AM
   * formatTime(120);
   * // returns 2:30 AM
   * formatTime(150);
   *
   * @param minutes value in minute from the beginning of the day
   * @returns String 12 hour format time
   */
  // Overrides core
  static formatTime(minutes) {
    const hour = Math.floor(minutes / 60);
    const minute = `${(minutes % 60) < 10 ? 0 : ''}${minutes % 60}`;

    if (minute === '00') {
      return moment(`${hour}${minute}`, 'hmm').format('h A');
    }
    return moment(`${hour}${minute}`, 'hmm').format('h:mm A');
  }

  // Overrides core
  static getTime(time) {
    return moment(time).format('HH:mm');
  }

  static getRelativeDateTime(dateTime) {
    if (moment(dateTime).isSame(new Date(), 'day')) {
      return moment(dateTime).format('hh:mm a');
    }
    if (moment(dateTime).isSame(new Date(), 'year')) {
      return moment(dateTime).format('DD MMM, hh:mm a');
    }

    return moment(dateTime).format('DD MMM YYYY, hh:mm a');
  }
}

export default Formatter;
