import React from 'react';
import ListItem from './listItem';

const ViewForm = React.lazy(() => import('./viewForm'));
const EditForm = React.lazy(() => import('./editForm'));
const ImportForm = React.lazy(() => import('./importForm'));
const MyJobsEditForm = React.lazy(() => import('./myJobsActivation/editForm'));
const MyJobsViewForm = React.lazy(() => import('./myJobsActivation/viewForm'));
const AvailableHourForm = React.lazy(() => import('./availableHourForm'));

export default {
  ListItem,
  ViewForm,
  EditForm,
  ImportForm,
  MyJobsViewForm,
  MyJobsEditForm,
  AvailableHourForm,
};
