import { Utils } from 'versafleet-core';
import Helper from 'utils/helper';

const { ReduxHelper } = Utils;
const initialState = {
  timestamp: null,
  archivedTimestamp: null,
  vehicleRecords: {},
  vehicleRecordIds: [],
  archivedVehicleRecordIds: [],
  vehicleRecordMeta: {},
  archivedVehicleRecordMeta: {},
  filter: {},
};

function vehicleRecord(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VEHICLE_RECORD':
      return {
        ...state,
        vehicleRecords: ReduxHelper.mergeDataWithObject(state.vehicleRecords, action.vehicleRecord),
      };
    case 'RECEIVE_VEHICLE_RECORDS':
      if (Helper.isNullOrUndefined(state.timestamp) || state.timestamp < action.timestamp) {
        return {
          ...state,
          vehicleRecordIds: action.vehicleRecords.map(record => record.id),
          vehicleRecords: ReduxHelper
            .mergeDataWithList(state.vehicleRecords, action.vehicleRecords),
          vehicleRecordMeta: action.meta,
          timestamp: action.timestamp,
        };
      }
      return state;
    case 'RECEIVE_ARCHIVED_VEHICLE_RECORDS':
      if (Helper.isNullOrUndefined(state.archivedTimestamp)
        || state.archivedTimestamp < action.timestamp) {
        return {
          ...state,
          archivedVehicleRecordIds: action.vehicleRecords.map(record => record.id),
          vehicleRecords: ReduxHelper.mergeDataWithList(state.vehicleRecords,
            action.vehicleRecords),
          archivedVehicleRecordMeta: action.meta,
          archivedTimestamp: action.timestamp,
        };
      }
      return state;
    case 'SET_VEHICLE_RECORD_FILTER':
      return {
        ...state,
        filter: action.filter || state.filter,
      };
    default:
      return state;
  }
}

export default vehicleRecord;
