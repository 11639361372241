import React from 'react';
import PropTypes from 'prop-types';
import Helper from 'utils/helper';
import { Components } from 'versafleet-core';

class InputToggle extends React.Component {
  constructor(props) {
    super(props);
    this.switch = null;
  }

  componentDidMount() {
    if (typeof componentHandler !== 'undefined') {
      window.componentHandler.upgradeElement(this.switch);
    }
  }

  _onClick = (e) => {
    if (e.target.nodeName.toLowerCase() === 'input') {
      this.props.onChange(!this.props.toggled);
    }
    e.stopPropagation();
  }

  _renderLoading() {
    if (this.props.loading) {
      return (
        <Components.EllipsisLoading className="loading-icon" />
      );
    }
    return false;
  }

  _renderHint() {
    if (!Helper.isNullOrUndefined(this.props.hintText) && !this.props.disabled) {
      return (
        <Components.Hint
          hintPosition={this.props.hintPosition || Components.Hint.Position.TOP}
        >
          {this.props.hintText}
        </Components.Hint>
      );
    }
    return false;
  }

  render() {
    const className = classNames('v-input v-input-toggle', {
      selected: this.props.toggled,
    }, this.props.className);
    return (
      <div
        className={className}
        onClick={this._onClick}
      >
        <label
          className="mdl-switch mdl-js-switch mdl-js-ripple-effect"
          htmlFor="inputCheckbox"
          ref={(label) => { this.switch = label; }}
        >
          <input
            className="mdl-switch__input"
            disabled={this.props.disabled}
            id="inputCheckbox"
            type="checkbox"
          />
          {
            this.props.label ? (
              <span className="mdl-switch__label">
                {this.props.toggled ? this.props.label.on
                  : this.props.label.off}
              </span>
            ) : false
          }
        </label>
        {this._renderHint()}
        {this._renderLoading()}
      </div>
    );
  }
}

InputToggle.displayName = 'Input Toggle';

InputToggle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hintPosition: PropTypes.string,
  hintText: PropTypes.string,
  label: PropTypes.shape({
    on: PropTypes.string,
    off: PropTypes.string,
  }),
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
};

InputToggle.defaultProps = {
  className: '',
  disabled: false,
  hintPosition: '',
  hintText: '',
  label: null,
  loading: false,
  toggled: undefined,
};

export default InputToggle;
