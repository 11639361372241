import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Helper from 'utils/helper';
import { translate, Utils } from 'versafleet-core';
import styles from 'stylesheets/modules/task.styl';
import RejectAllocationWindowForm from './rejectAllocationWindowForm';

class RejectAllocationButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      isUpdating: false,
    };
  }

  componentDidMount() {
    Utils.OutsideClickHandler.registerNode(this.button,
      this._setDropdown.bind(this, false));
  }

  componentWillUnmount() {
    Utils.OutsideClickHandler.removeNode(this.button);
  }

  _setDropdown(showDropdown) {
    this.setState({ showDropdown });
    if (!Helper.isNullOrUndefined(this.props.setButtonPersistent)) {
      this.props.setButtonPersistent(showDropdown);
    }
  }

  _toggleDropdown() {
    const showDropdown = this.state.showDropdown;
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
    if (!Helper.isNullOrUndefined(this.props.setButtonPersistent)) {
      this.props.setButtonPersistent(!showDropdown);
    }
  }

  _renderButton() {
    return (
      <Component.Button
        accessRightKey="task.plan"
        hint={this.props.showHint ? this.props.strings.decline : undefined}
        iconClassName="versa-close"
        loading={this.state.isUpdating}
        onClick={() => this._toggleDropdown()}
      >
        {this.props.showText ? this.props.strings.decline : undefined}
      </Component.Button>
    );
  }

  _renderDropdown() {
    if (this.state.showDropdown) {
      const className = classNames({
        [styles['vf-task__reject-allocation__form-container']]: true,
        [styles['vf-task__reject-allocation__form-container--right']]: this.props.alignRight,
      });
      return (
        <Component.AutoPositionContainer className={className}>
          <RejectAllocationWindowForm
            dataIds={this.props.dataIds}
            isUpdating={this.state.isUpdating}
            onCompleted={() => this.props.onCompleted()}
            onSubmit={() => {
              // If this component is rendered from _renderActionButtons of pendingTaskContent.js,
              // it will not re-render unless the local state is updated before the onSubmit call
              // is made since shouldComponentUpdate of the TaskListItem component will return false
              this.setState({ isUpdating: true }, this.props.onSubmit());
            }}
            text={this.props.text}
          />
        </Component.AutoPositionContainer>
      );
    }

    return false;
  }

  render() {
    return (
      <div
        className={styles['vf-task__reject-allocation']}
        ref={(ref) => { this.button = ref; }}
      >
        {this._renderButton()}
        {this._renderDropdown()}
      </div>
    );
  }
}

RejectAllocationButton.displayName = 'Reject Allocation Button';

RejectAllocationButton.propTypes = {
  alignRight: PropTypes.bool,
  dataIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onCompleted: PropTypes.func, // function is called at the end of the submit actions
  onSubmit: PropTypes.func, // function is called when the submit button is pressed
  setButtonPersistent: PropTypes.func,
  showHint: PropTypes.bool,
  showText: PropTypes.bool,
  strings: PropTypes.shape({
    decline: PropTypes.string,
  }).isRequired,
  text: PropTypes.string,
};

RejectAllocationButton.defaultProps = {
  alignRight: false,
  onCompleted: undefined,
  onSubmit: undefined,
  setButtonPersistent: undefined,
  showHint: true,
  showText: true,
  text: undefined,
};

export default translate('Planning.Pending.allocation')(RejectAllocationButton);
