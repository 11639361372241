import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate, Utils } from 'versafleet-core';
import Component from 'components/component';
import styles from 'stylesheets/modules/task.styl';

const AllocateWindowForm = (props) => {
  const {
    allocate, data, isAllocatingTask, onFormChange, strings,
    transporterOptions, onMouseDown, onMouseUp,
  } = props;

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <Component.Window.FieldEditGroup>
        <Component.Window.FieldEdit.Searchbox
          dropdownClassName={styles['vf-task__allocate__form-container__dropdown']}
          fieldName={strings.transporter}
          isRequired
          onChange={value => onFormChange(value)}
          options={transporterOptions.map(transporter => ({
            text: transporter.name,
            value: transporter.id,
          }))}
          placeholder={strings.searchTransporter}
          value={data}
          width="full"
        />
      </Component.Window.FieldEditGroup>
      {!Utils.Helper.isNullOrUndefined(data) && (
        <Component.Window.FieldEditGroup>
          <Component.Button
            className={styles['vf-task__allocate__form__button']}
            iconClassName="versa-completed"
            loading={isAllocatingTask}
            onClick={allocate}
          >
            {isAllocatingTask ? strings.allocating : strings.allocate}
          </Component.Button>
        </Component.Window.FieldEditGroup>
      )}
    </div>
  );
};

AllocateWindowForm.displayName = 'AllocateWindowForm';

AllocateWindowForm.propTypes = {
  allocate: PropTypes.func.isRequired,
  data: PropTypes.number,
  isAllocatingTask: PropTypes.bool,
  onFormChange: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  strings: PropTypes.shape({
    transporter: PropTypes.string,
    searchTransporter: PropTypes.string,
    allocate: PropTypes.string,
    allocating: PropTypes.string,
  }).isRequired,
  transporterOptions: PropTypes.arrayOf(PropTypes.shape()),
};

AllocateWindowForm.defaultProps = {
  data: null,
  isAllocatingTask: false,
  onMouseDown: null,
  onMouseUp: null,
  transporterOptions: [],
};

function mapStateToProps(state) {
  const { account } = state;

  return {
    transporterOptions: account.account.direct_child_accounts,
  };
}

export default connect(mapStateToProps)(translate('Task.AllocateForm')(AllocateWindowForm));
