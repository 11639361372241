import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Helper from 'utils/helper';

class TagForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { form: { tag_list: [] } };
  }

  _onFormModified(fieldName, value) {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [fieldName]: value,
      },
    }));
  }

  render() {
    return (
      <div className="vf-task__tag__form" onClick={e => e.stopPropagation()}>
        <div className="vf-task__tag__form__title">
          Add Tags
        </div>
        <div className="vf-task__tag__form__row">
          <i className="versa-tags" />
          <Component.Input.Tag
            className="vf-task__tag__form__input"
            id={Helper.isNullOrUndefined(this.props.id) ? null : `${this.props.id}__tags-input`}
            onChange={value => this._onFormModified('tag_list', value)}
            options={(this.props.tags || []).map(tag => ({ value: tag, text: tag }))}
            placeholder="e.g. fragile, west"
            value={this.state.form.tag_list}
          />
        </div>
        <div className="vf-task__tag__form__note">
          Adding tags will not replace existing ones.
        </div>
        <Component.Button.Done
          className="vf-task__tag__form__button"
          id={Helper.isNullOrUndefined(this.props.id) ? null : `${this.props.id}__add-tags-button`}
          loading={this.props.loading}
          onClick={() => this.props.tagMultiple(this.props.taskIds, this.state.form.tag_list)}
        >
          {this.props.loading ? 'Adding Tags' : 'Add Tags'}
        </Component.Button.Done>
      </div>
    );
  }
}

TagForm.displayName = 'Task Driver Tag Form';

TagForm.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  tagMultiple: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.string),
  taskIds: PropTypes.arrayOf(PropTypes.number),
};

TagForm.defaultProps = {
  id: null,
  loading: false,
  tagMultiple: null,
  tags: null,
  taskIds: null,
};

export default TagForm;
