import React from 'react';

const View = React.lazy(() => import('./view'));
const Edit = React.lazy(() => import('./edit'));
const ChangePassword = React.lazy(() => import('./changePasswordForm'));

export default {
  View,
  Edit,
  ChangePassword,
};
