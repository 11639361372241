import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveConstants(result) {
  return {
    type: 'RECEIVE_CONSTANTS',
    constants: result,
  };
}

function receiveTimeZones(result) {
  return {
    type: 'RECEIVE_TIME_ZONES',
    time_zones: result.time_zones,
  };
}

function receiveCountries(result) {
  return {
    type: 'RECEIVE_COUNTRIES',
    countries: result.countries,
  };
}

function fetchList() {
  return async (dispatch) => {
    const result = await request('/constants', {
      method: 'GET',
    });
    dispatch(receiveConstants(result));
  };
}

function fetchTimeZoneList() {
  return async (dispatch) => {
    const result = await request('/constants/time_zones', {
      method: 'GET',
    });
    dispatch(receiveTimeZones(result));
  };
}

function fetchCountryList() {
  return async (dispatch) => {
    const result = await request('/constants/countries', {
      method: 'GET',
    });
    dispatch(receiveCountries(result));
  };
}

export default {
  fetchList,
  fetchTimeZoneList,
  fetchCountryList,
};
