import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation, useNavigate, useOutletContext, useParams, useSearchParams,
} from 'react-router-dom';

import { Utils } from 'versafleet-core';
import PageNotFound from '../pages/pageNotFound';
import AccessRightHelper from './accessRightHelper';
import ReduxActions from '../reduxActions';

export function withRouterHooks(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = useSearchParams();
    const params = useParams();
    const outletContext = useOutletContext();

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        outletContext={outletContext}
        params={params}
        searchParams={searchParams}
      />
    );
  };
}

export function ProtectedRoute({ children = null, authorizeKeys = [], redirectKeys = [] }) {
  const [isAllowed, setIsAllowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const accessRights = useSelector(state => state.profile?.profile?.access_rights ?? []);
  const currentPath = useLocation().pathname;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outletContext = useOutletContext();

  useEffect(() => {
    if (accessRights.length === 0) {
      dispatch(ReduxActions.profile.check())
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authorizeKeys.length === 0) {
      setIsAllowed(true);
      return;
    }

    if (accessRights.length > 0) {
      const hasAccess = authorizeKeys.some(key => AccessRightHelper.hasAccess(accessRights, key));
      setIsAllowed(hasAccess);
    }
  }, [authorizeKeys, accessRights]);

  useEffect(() => {
    if (redirectKeys.length > 0) {
      const redirectKey = redirectKeys.find(key => (AccessRightHelper
        .hasAccess(accessRights, key.keys[0])));
      const isCurrentPathInRedirectKeys = redirectKeys
        .some(key => (currentPath.includes(key.path)));

      if (redirectKey && !isCurrentPathInRedirectKeys) {
        navigate(redirectKey.path);
      }
    }
  }, [redirectKeys, currentPath]);

  // Prevent page not found flash
  if (isLoading) {
    return null; // Or return a loading spinner component
  }

  if (isAllowed) {
    if (children) {
      return children;
    }
    return <Outlet context={outletContext} />;
  }
  return <PageNotFound />;
}

ProtectedRoute.propTypes = {
  authorizeKeys: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  redirectKeys: PropTypes.arrayOf(PropTypes.shape({})),
};

class Router extends Utils.Router {
  static pop() {
    Utils.Router.transitionTo(-1);
  }
}

export default Router;
