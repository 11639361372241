import Color from 'utils/color';

const labelColors = {
  // Task states
  unassigned: Color.$yellow,
  assigned: Color.$yellow01,
  declined: Color.$yellow,
  cancelled: Color.$redlighter,
  waiting_for_acknowledgement: Color.$greenlighter,
  acknowledged: Color.$greenlighter,
  collected: Color.$greenlighter,
  arrived: Color.$greenlighter,
  started: Color.$greenlighter,
  successful: Color.$green,
  failed: Color.$red,

  // Task allocation states
  allocated: Color.$green,
  allocation_declined: Color.$red,
  unallocated: Color.$yellow,
  allocation_pending: Color.$greenlighter,
};

const whiteTextLabel = {
  // Task states
  unassigned: true,
  assigned: false,
  declined: true,
  cancelled: false,
  waiting_for_acknowledgement: false,
  acknowledged: false,
  collected: false,
  arrived: false,
  started: false,
  successful: true,
  failed: true,

  // Task allocation states
  allocated: true,
  allocation_declined: true,
  unallocated: true,
  allocation_pending: false,
};

export default {
  labelColors,
  whiteTextLabel,
};
