import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Router from 'utils/router';
import Helper from 'utils/helper';
import { translate } from 'versafleet-core';
import Formatter from 'utils/formatter';

class CustomerListItem extends React.Component {
  _view() {
    Router.transitionFromSubpath(null, this.props.data.id);
  }

  _renderActionButtons() {
    return this.props.renderActionButtons(this.props.data);
  }

  render() {
    const accounts = !Helper.isNullOrUndefined(this.props.data.billing_accounts)
      ? this.props.data.billing_accounts.filter(
        billingAccount => !billingAccount.archived,
      ).length
      : this.props.data.billing_account_count;

    const contactPerson = this.props.data.contact_person;
    const contactNo = this.props.data.contact_number;
    const strings = this.props.strings;

    return (
      <Component.LineItem
        checkItem={this.props.checkItem}
        checkedStatus={this.props.checkedStatus}
        id="vf-customer__list-item"
        itemID={this.props.data.id}
        onClick={() => this._view()}
        showCheckBox={this.props.showCheckBox}
      >

        <Component.LineItem.IconBlock
          iconClassName="versa-business"
        />

        <Component.LineItem.Block size="MEDIUM">
          <Component.LineItem.BlockContent
            fontColor="BLACK"
            fontSize="LARGE"
          >
            {this.props.data.name}
          </Component.LineItem.BlockContent>
          <Component.LineItem.BlockContent fontColor="TRANSLUCENT">
            {`${accounts} ${accounts > 1
              ? Formatter.capitalizeFirstLetter(strings.account)
              : Formatter.capitalizeFirstLetter(strings.accountPlural)}`}
          </Component.LineItem.BlockContent>
        </Component.LineItem.Block>

        <Component.LineItem.Divider />

        <Component.LineItem.Block>
          <Component.LineItem.BlockContent>
            <i className="versa-person" />
            {contactPerson}
          </Component.LineItem.BlockContent>
          <Component.LineItem.BlockContent>
            <i className="versa-call" />
            {contactNo}
          </Component.LineItem.BlockContent>
        </Component.LineItem.Block>

        {this._renderActionButtons()}

      </Component.LineItem>
    );
  }
}

CustomerListItem.propTypes = {
  checkItem: PropTypes.func,
  checkedStatus: PropTypes.bool,
  data: PropTypes.shape(),
  renderActionButtons: PropTypes.func.isRequired,
  showCheckBox: PropTypes.bool,
  strings: PropTypes.shape().isRequired,
};

CustomerListItem.defaultProps = {
  checkItem: null,
  checkedStatus: false,
  data: null,
  showCheckBox: true,
};

CustomerListItem.displayName = 'Customer Item';

export default translate('Customer')(CustomerListItem);
