import Router from 'utils/router';
import Helper from 'utils/helper';
import featuresSettings from 'pages/featuresConstants';
import { Utils } from 'versafleet-core';

const { RequestHelper: { convertDataToParams, request } } = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveOurLocation(ourLocation) {
  return {
    type: 'RECEIVE_OUR_LOCATION',
    ourLocation,
  };
}

function receiveOurLocations(result, timestamp) {
  return {
    type: 'RECEIVE_OUR_LOCATIONS',
    ourLocations: result.my_locations,
    meta: result.meta,
    timestamp,
  };
}

function receiveOptionLocations(result) {
  return {
    type: 'RECEIVE_OPTION_LOCATIONS',
    ourLocations: result.my_locations,
  };
}

function receiveOurLocationUpdateSuccessful(ourLocation) {
  return {
    type: 'RECEIVE_OUR_LOCATION_UPDATE_SUCCESSFUL',
    ourLocation,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_OUR_LOCATION_FILTER',
    filter,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/my_locations/${id}`, {
      method: 'GET',
    });
    dispatch(receiveOurLocation(result.my_location));
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.ourLocation.ourLocationMeta.page,
      ...baseFilter,
      ...state.ourLocation.filter,
      archived: false,
    };
    const queryString = convertDataToParams(data);
    const result = await request(`/my_locations${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveOurLocations(result, timestamp));
  };
}

function fetchOptionLocationList(query) {
  return async (dispatch) => {
    const queryString = convertDataToParams(query);
    const result = await request(`/my_locations/list${queryString}`, {
      method: 'GET',
    });
    dispatch(receiveOptionLocations(result));
  };
}

function create(query) {
  return async (dispatch, getState) => {
    let newQuery = query;
    // If there is already a latlng, skip geocoding
    if (Helper.isNullOrUndefined(newQuery.my_location.latitude)
      || Helper.isNullOrUndefined(newQuery.my_location.longitude)) {
      try {
        const state = getState();
        const account = state.account.account;
        let useCrs = false;
        let accountId = null;
        if (!Helper.isNullOrUndefined(account)) {
          useCrs = account.features_setting.coordinate_retrieval_service
            === featuresSettings.enabled;
          accountId = account.id;
        }
        const location = await Utils.Geocoder
          .geocodeAddress(newQuery.my_location, useCrs, accountId);

        if (!Helper.isNullOrUndefined(location)) {
          newQuery = {
            my_location: {
              ...newQuery.my_location,
              latitude: location.lat,
              longitude: location.lng,
            },
          };
        }
      } catch (error) {
        // Continue regardless of error
      }
    }
    const result = await request('/my_locations', {
      method: 'POST',
    }, newQuery);
    dispatch(receiveOurLocationUpdateSuccessful(result.my_location));
    dispatch(fetchList());
    Router.transitionFromSubpath('our_locations', result.my_location.id);
  };
}

function update(id, query) {
  return async (dispatch, getState) => {
    let newQuery = query;
    if (Helper.isNullOrUndefined(newQuery.my_location.latitude)
      || Helper.isNullOrUndefined(newQuery.my_location.longitude)) {
      try {
        const state = getState();
        const account = state.account.account;
        let useCrs = false;
        let accountId = null;
        if (!Helper.isNullOrUndefined(account)) {
          useCrs = account.features_setting.coordinate_retrieval_service
            === featuresSettings.enabled;
          accountId = account.id;
        }
        const location = await Utils.Geocoder
          .geocodeAddress(newQuery.my_location, useCrs, accountId);

        if (!Helper.isNullOrUndefined(location)) {
          newQuery = {
            my_location: {
              ...newQuery.my_location,
              latitude: location.lat,
              longitude: location.lng,
            },
          };
        }
      } catch (error) {
        // Continue regardless of error
      }
    }
    const result = await request(`/my_locations/${id}`, {
      method: 'PUT',
    }, newQuery);
    dispatch(receiveOurLocationUpdateSuccessful(result.my_location));
    Router.transitionFromSubpath('our_locations', result.my_location.id);
  };
}

// Couldn't use the name 'delete'; already reserved
function erase(id) {
  return async (dispatch) => {
    await request(`/my_locations/${id}`, {
      method: 'DELETE',
    });
    dispatch(fetchList());
    Router.transitionFromSubpath('our_locations');
  };
}

export default {
  fetch,
  fetchList,
  fetchOptionLocationList,
  create,
  update,
  erase,
};
