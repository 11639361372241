import { Utils } from 'versafleet-core';

const { RequestHelper: { request, convertDataToParams } } = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveVehicleRecord(vehicleRecord) {
  return {
    type: 'RECEIVE_VEHICLE_RECORD',
    vehicleRecord,
  };
}

function receiveVehicleRecords(result, timestamp) {
  return {
    type: 'RECEIVE_VEHICLE_RECORDS',
    vehicleRecords: result.vehicle_records,
    meta: result.meta,
    timestamp,
  };
}

function receiveArchivedVehicleRecords(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_VEHICLE_RECORDS',
    vehicleRecords: result.vehicle_records,
    meta: result.meta,
    timestamp,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_VEHICLE_RECORD_FILTER',
    filter,
  };
}

function fetchAudit(id) {
  return async (dispatch, getState) => {
    const auditResult = await request(`/vehicle_records/${id}/audits`, {
      method: 'GET',
    });
    const audit = auditResult.vehicle_records[0];
    const state = getState();
    const vehicleRecord = {
      ...state.vehicleRecord.vehicleRecords[id],
      user: audit.user.name,
      updated_at: audit.created_at,
    };
    dispatch(receiveVehicleRecord(vehicleRecord));
    return audit.vehicle_records;
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/vehicle_records/${id}`, {
      method: 'GET',
    });
    dispatch(receiveVehicleRecord(result.vehicle_record));
    dispatch(fetchAudit(id));
    return result.vehicle_record;
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.vehicleRecord.vehicleRecordMeta.page,
      ...baseFilter,
      ...state.vehicleRecord.filter,
      ...filter,
      archived: false,
    };
    const param = convertDataToParams(data);
    const result = await request(`/vehicle_records${param}`, {
      method: 'GET',
    });
    dispatch(receiveVehicleRecords(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.vehicleRecord.archivedVehicleRecordMeta.page,
      ...baseFilter,
      ...state.vehicleRecord.filter,
      ...filter,
      archived: true,
    };
    const param = convertDataToParams(data);
    const result = await request(`/vehicle_records${param}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedVehicleRecords(result, timestamp));
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/vehicle_records', {
      method: 'POST',
    }, {
      vehicle_record: query,
    });
    dispatch(receiveVehicleRecord(result.vehicle_record));
    dispatch(fetchList());
    return result.vehicle_record;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/vehicle_records/${id}`, {
      method: 'PUT',
    }, {
      vehicle_record: query,
    });
    dispatch(receiveVehicleRecord(result.vehicle_record));
    return result.vehicle_record;
  };
}

function archive(id) {
  return async () => {
    const result = await request(`/vehicle_records/${id}/archive`, {
      method: 'PUT',
    });
    return result.vehicle_record;
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/vehicle_records/${id}/unarchive`, {
      method: 'PUT',
    });
    dispatch(fetchArchivedList());
    return result.vehicle_record;
  };
}

function archiveMultiple(ids) {
  return async () => {
    const result = await request('/vehicle_records/archive', {
      method: 'PUT',
    }, {
      ids,
    });
    return result;
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    const result = await request('/vehicle_records/unarchive', {
      method: 'PUT',
    }, {
      ids,
    });
    dispatch(fetchArchivedList());
    return result;
  };
}

async function exportRecords(query) {
  const result = await request('/vehicle_records/export', {
    method: 'POST',
  }, {
    vehicle_record: query,
  });
  return result;
}

export default {
  setFilter,
  fetchAudit,
  fetch,
  fetchList,
  fetchArchivedList,
  create,
  update,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  exportRecords,
};
