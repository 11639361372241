import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

function receiveVfSetting(vfSetting) {
  return {
    type: 'RECEIVE_VF_SETTING',
    vfSetting,
  };
}

function fetch() {
  return async (dispatch) => {
    const result = await request('/vf_setting', {
      method: 'GET',
    });
    dispatch(receiveVfSetting(result.vf_setting));
  };
}

export default {
  fetch,
};
