import React from 'react';
import PropTypes from 'prop-types';
import { Utils, translate } from 'versafleet-core';
import { connect } from 'react-redux';

const Header = (props) => {
  const {
    actualWeight, volume, volumetricWeight, weightUnit, volumeUnit,
  } = Utils.Helper.calculateTaskVolumetrics(props.data, props.account);

  const dataLength = !Utils.Helper.isNullOrUndefined(props.data)
    // eslint-disable-next-line no-underscore-dangle
    ? props.data.filter(d => !d._destroy).length : false;

  return (
    <div className="vf-measurement-header">

      <div className="vf-measurement-header__title">
        {`${Utils.Formatter.capitalizeFirstLetter(props.strings.itemPlural)} (${dataLength})`}
      </div>

      <div className="vf-measurement-header__subtitle">
        {`${props.strings.actualWeight}: ${Utils.Helper.roundUpToTwoDecimalPlaces(
          actualWeight,
        )} ${weightUnit} | ${props.strings.volume}: ${
          Utils.Helper.roundUpToTwoDecimalPlaces(volume)} ${volumeUnit} | ${
          props.strings.volumetricWeight} : ${
          Utils.Helper.roundUpToTwoDecimalPlaces(volumetricWeight)} ${weightUnit}`}
      </div>

    </div>
  );
};

Header.propTypes = {
  account: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape({})), // typically a Measurement array belonging to Task
  strings: PropTypes.shape().isRequired,
};

Header.defaultProps = {
  account: null,
  data: null,
};

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    account: account.account,
  };
};

export default connect(mapStateToProps)(translate('Task.measurements')(Header));
