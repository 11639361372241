import React from 'react';
import PropTypes from 'prop-types';

const LeftPanel = props => (
  <div className={classNames({ 'vf-form__left-panel': true }, props.className)}>
    {props.children}
  </div>
);

LeftPanel.displayName = 'Form Left Panel';

LeftPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

LeftPanel.defaultProps = {
  children: null,
  className: null,
};

export default LeftPanel;
