import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Formatter from 'utils/formatter';
import { Utils } from 'versafleet-core';
import Router from 'utils/router';
import NewDialog from 'utils/newDialog';
import ReduxActions from 'reduxActions';
import AccessRightHelper from 'utils/accessRightHelper';
import featuresSettings from 'pages/featuresConstants';
import CancelDialog from './cancel/cancelDialog';

class JobListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: { print: false },
    };
  }

  _archive = () => {
    this.props.setIsLoading(true, async () => {
      await this.props.dispatch(ReduxActions.job.archive(this.props.data.id));
      this.props.setIsLoading(false);
    });
  }

  _unarchive = () => {
    this.props.setIsLoading(true, async () => {
      await this.props.dispatch(ReduxActions.job.unarchive(this.props.data.id));
      this.props.setIsLoading(false);
    });
  }

  _print = () => {
    this.props.dispatch(ReduxActions.job.print(this.props.data.id));
    this._disableButton('print');
  }

  _cancel = () => {
    NewDialog.DialogManager.open(<CancelDialog dataIds={[this.props.data.id]} />);
  }

  _disableButton(buttonName) {
    this.setState(prevState => ({
      disableButton: { ...prevState.disableButton, [buttonName]: true },
    }), async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.setState(prevState => ({
        disableButton: { ...prevState.disableButton, [buttonName]: false },
      }));
    });
  }

  _viewDetail = () => {
    Router.transitionFromSubpath('jobs', this.props.data.id);
  }

  _renderInProgressIcon(completedCount, totalCount) {
    const ratio = completedCount / totalCount;
    const side = 50 * Math.tan(Math.PI * 2 * (ratio % 0.25));

    if (ratio === 0) {
      const iconClassName = (this.props.data.job_type === 'delivery') ? 'versa-base-delivery'
        : 'versa-base-pickup';
      return (
        <Component.LineItem.IconBlock
          iconClassName={iconClassName}
          iconText=""
        />
      );
    }

    if (totalCount === 1 && ratio < 1) {
      return (
        <Component.LineItem.IconBlock
          iconClassName="versa-otw"
          iconText=""
        />
      );
    }

    if (ratio === 1) {
      return (
        <Component.LineItem.IconBlock
          className="vf-job__list-item__icon--completed"
          iconClassName="versa-completed"
          iconText=""
        />
      );
    }

    let part1 = false;
    let part2 = false;
    let part3 = false;

    if (ratio >= 0.5) {
      part1 = <rect height="50" width="50" x="50" y="50" />;
    } else if (ratio > 0.25) {
      part1 = <polygon points={`50,50 100,50 100,${50 + side}`} />;
    }

    if (ratio >= 0.75) {
      part2 = <rect height="50" width="50" x="0" y="50" />;
    } else if (ratio > 0.5) {
      part2 = <polygon points={`50,50 50,100 ${50 - side},100`} />;
    }

    if (ratio >= 1.0) {
      part3 = <rect height="50" width="50" x="0" y="0" />;
    } else if (ratio > 0.75) {
      part3 = <polygon points={`50,50 0,50 0,${50 - side}`} />;
    }

    return (
      <Component.LineItem.Block size="SMALL">
        <div className="vf-job__list-item__pie-svg">
          <svg viewBox="0 0 100 100">
            {
              ratio >= 0.25
                ? <rect height="50" width="50" x="50" y="0" />
                : <polygon points={`50,50 50,0 ${50 + side},0`} />
            }
            {part1}
            {part2}
            {part3}
          </svg>
        </div>
      </Component.LineItem.Block>
    );
  }

  _renderCol1() {
    const completedCount = 0;

    switch (this.props.data.state) {
      case 'completed':
        return (
          <Component.LineItem.IconBlock
            iconClassName="versa-completed"
          />
        );
      case 'in_progress':
        if (completedCount === 0) {
          return this._renderInProgressIcon(0, 1);
        }
        return this._renderInProgressIcon(
          this.props.data.task_summary.task_count,
          this.props.data.task_summary.successful_task_count,
        );

      case 'draft':
        return (
          <div className="vf-job__list-item__icon--dots">
            <span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          </div>
        );
      case 'rejected':
        return (
          <Component.LineItem.IconBlock
            iconClassName="versa-warning vf-job__list-item__icon--rejected"
            iconText=""
          />
        );
      case 'submitted':
        return (
          <Component.LineItem.IconBlock
            iconClassName="versa-business vf-job__list-item__icon--submitted"
          />
        );
        // return (
        //   <div>
        //     <i className="task__item__col__logo-container__logo material-icons
        //     status-submitted status-submitted--top">domain</i>
        //     <i className="task__item__col__logo-container__logo material-icons
        //     status-submitted status-submitted--bottom">more_horiz</i>
        //   </div>
        // );
      default:
        return (
          <Component.LineItem.IconBlock
            iconClassName="versa-otw"
            iconText=""
          />
        );
    }
  }

  _renderCol2() {
    return (
      <Component.LineItem.Block size="MEDIUM">
        <Component.LineItem.BlockContent fontColor="BLACK" fontSize="LARGE">
          {this.props.data.customer.name}
        </Component.LineItem.BlockContent>
        <Component.LineItem.BlockContent fontColor="TRANSLUCENT">
          {`${this.props.data.task_summary.task_count} ${this.props.data.job_type === 'delivery'
            ? 'Deliveries' : 'Pickups'}`}
        </Component.LineItem.BlockContent>
      </Component.LineItem.Block>
    );
  }

  _renderCol3() {
    return (
      <Component.LineItem.Block>
        <Component.LineItem.BlockContent>
          {Formatter.getInlineAddress(this.props.data.base_task.address)}
        </Component.LineItem.BlockContent>
        <Component.LineItem.BlockContent isDisplayBlock>
          <Component.Tags tags={this.props.data.tags.map(tag => tag.name)} />
        </Component.LineItem.BlockContent>
      </Component.LineItem.Block>
    );
  }

  _renderCol4() {
    return (
      <Component.LineItem.Block>
        <Component.LineItem.BlockContent>
          {Formatter.capitalizeFirstLetter(
            this.props.data.state.replace(/_/g, ' '),
          )}
        </Component.LineItem.BlockContent>
        <Component.LineItem.BlockContent fontColor="TRANSLUCENT">
          {this.props.data.task_summary.successful_task_count}
          /
          {this.props.data.task_summary.task_count}
        </Component.LineItem.BlockContent>
      </Component.LineItem.Block>
    );
  }

  _renderCol5() {
    const { account } = this.props;

    const taskSummary = this.props.data.task_summary;
    const measurementUnits = Utils.Helper.getMeasurementUnits();
    const totalVolumetricWeight = Utils.Helper.calculateVolumetricWeight(
      taskSummary.total_volume,
      account.shipping_factor,
      account.unit_system,
    );

    const volumeUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].volumeUnit : 'in³';
    const weightUnit = !Utils.Helper.isNullOrUndefined(account.unit_system)
      ? measurementUnits[account.unit_system].weightUnit : 'lb';

    const renderedTotalWeight = Utils.Helper.isNullOrUndefined(taskSummary.total_weight) ? ''
      : `, ${Utils.Helper.roundUpToTwoDecimalPlaces(taskSummary.total_weight)} ${weightUnit}`;
    const renderedTotalVolume = Utils.Helper.isNullOrUndefined(taskSummary.total_volume) ? ''
      : `, ${Utils.Helper.roundUpToTwoDecimalPlaces(taskSummary.total_volume)} ${volumeUnit}`;
    const renderedTotalVolumetricWeight = (totalVolumetricWeight === 0) ? ''
      : `, ${Utils.Helper.roundUpToTwoDecimalPlaces(totalVolumetricWeight)} ${weightUnit}`;

    const renderedText = `${taskSummary.item_count} item(s)${renderedTotalWeight}${
      renderedTotalVolume}${renderedTotalVolumetricWeight}`;
    return (
      <Component.LineItem.Block>
        <Component.LineItem.BlockContent>
          {(taskSummary.item_count > 0 ? renderedText : false)}
        </Component.LineItem.BlockContent>
      </Component.LineItem.Block>
    );
  }

  _renderActionButtons() {
    const { index, strings } = this.props;

    const printButton = (
      <Component.Button.Print
        disabled={this.state.disableButton.print}
        hintPosition={
          index === 0
          // eslint-disable-next-line camelcase
          && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
            ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
        }
        id={!Utils.Helper.isNullOrUndefined(this.props.id) ? `${this.props.id}__print-button` : null}
        onClick={this._print}
      />
    );
    const archiveButton = (this.props.data.state === 'completed' && AccessRightHelper.hasAccess(this.props.accessRights, 'job.edit_after_completed'))
      || (this.props.data.state !== 'completed' && AccessRightHelper.hasAccess(this.props.accessRights, 'job.archive')) ? (
        <Component.Archive
          archive={this._archive}
          archivePath={() => Router.transitionTo('/archives/tasks/jobs')}
          ids={[this.props.data.id]}
          modelName={this.props.modelName}
          updateContent={this.props.afterArchive}
        >
          <Component.Button
            hint={strings.archive}
            hintPosition={
              index === 0
              // eslint-disable-next-line camelcase
              && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
                ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
            }
            iconClassName="versa-archive"
            id={!Utils.Helper.isNullOrUndefined(this.props.id) ? `${this.props.id}__archive-button` : null}
          />
        </Component.Archive>
      ) : false;
    const unarchiveButton = (this.props.data.state === 'completed' && AccessRightHelper.hasAccess(this.props.accessRights, 'job.edit_after_completed'))
      || (this.props.data.state !== 'completed' && AccessRightHelper.hasAccess(this.props.accessRights, 'job.archive')) ? (
        <Component.Unarchive
          accessRightKey="job.archive"
          id={!Utils.Helper.isNullOrUndefined(this.props.id) ? `${this.props.id}__unarchive-button` : null}
          modelName={this.props.modelName}
          onClick={this._unarchive}
        />
      ) : false;
    const cancelButton = (
      <Component.Button.Cancel
        accessRightKey="job.edit"
        hintPosition={
          index === 0
          // eslint-disable-next-line camelcase
          && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
            ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
        }
        id={!Utils.Helper.isNullOrUndefined(this.props.id) ? `${this.props.id}__cancel-button` : null}
        onClick={this._cancel}
      />
    );
    return (
      <Component.LineItem.ButtonGroup>
        {this.props.archived ? unarchiveButton : archiveButton}
        {printButton}
        {!this.props.archived && this.props.data.cancellable && this.props.data.state !== 'cancelled'
          ? cancelButton : false}
      </Component.LineItem.ButtonGroup>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _renderPersistentActionButtons() {

  }

  render() {
    return (
      <div>
        <Component.LineItem
          checkItem={this.props.checkItem}
          checkedStatus={this.props.checkedStatus}
          id={this.props.id}
          itemID={this.props.data.id}
          onClick={this._viewDetail}
          showCheckBox
        >
          {this._renderCol1()}
          {this._renderCol2()}
          <Component.LineItem.Divider />
          {this._renderCol3()}
          {!this.props.archived ? <Component.LineItem.Divider /> : false}
          {!this.props.archived ? this._renderCol4() : false}
          {!this.props.archived ? <Component.LineItem.Divider /> : false}
          {!this.props.archived ? this._renderCol5() : false}
          {(!Utils.Helper.isNullOrUndefined(this.props.renderActionButtons))
            ? this.props.renderActionButtons(this.props.data)
            : this._renderActionButtons()}
          {(!Utils.Helper.isNullOrUndefined(this.props.renderPersistentActionButtons))
            ? this.props.renderPersistentActionButtons(this.props.data)
            : this._renderPersistentActionButtons()}
        </Component.LineItem>
      </div>
    );
  }
}

JobListItem.displayName = 'Job List Item';

JobListItem.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.shape()),
  account: PropTypes.shape({
    features_setting: PropTypes.shape({
      virtualized_list: PropTypes.number,
    }),
    shipping_factor: PropTypes.number,
    unit_system: PropTypes.string,
  }),
  afterArchive: PropTypes.func,
  archived: PropTypes.bool,
  checkItem: PropTypes.func,
  checkedStatus: PropTypes.bool,
  data: PropTypes.shape({
    base_task: PropTypes.shape(),
    cancellable: PropTypes.bool,
    customer: PropTypes.shape(),
    id: PropTypes.number,
    job_type: PropTypes.string,
    state: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape()),
    task_summary: PropTypes.shape(),
  }),
  dispatch: PropTypes.func,
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  modelName: PropTypes.string,
  renderActionButtons: PropTypes.func,
  renderPersistentActionButtons: PropTypes.func,
  setIsLoading: PropTypes.func.isRequired,
  strings: PropTypes.shape({
    archive: PropTypes.string,
  }).isRequired,
};

JobListItem.defaultProps = {
  afterArchive: null,
  accessRights: null,
  account: null,
  archived: false,
  checkItem: null,
  checkedStatus: false,
  data: null,
  dispatch: null,
  id: null,
  modelName: '',
  renderActionButtons: null,
  renderPersistentActionButtons: null,
};

export default JobListItem;
