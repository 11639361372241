import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import { translate } from 'versafleet-core';
import styles from 'stylesheets/modules/task.styl';

const BulkEditDropdownPanel = props => (
  <Component.AutoPositionContainer>
    <div className={styles['vf-task__bulk-edit__panel']}>
      <div className={styles['vf-task__bulk-edit__panel__content']}>
        <Component.Window.Section>
          <Component.Window.FieldEditGroup name={props.strings.page.buttons.edit.dateTitle}>
            <Component.Window.FieldEdit.DatePicker
              fromDate="2020-01-01"
              onChange={value => props.onFormChange(value)}
              trailingIcon="versa-calendar-day"
              value={props.selectedDate}
            />
            <Component.Window.Description>
              {props.strings.page.buttons.edit.description}
            </Component.Window.Description>
          </Component.Window.FieldEditGroup>
          <Component.Window.Subsection>
            <Component.Button
              className={styles['vf-task__bulk-edit__panel__content__button']}
              iconClassName="versa-completed"
              loading={props.isUpdating}
              onClick={props.editMultiple}
            >
              {props.strings.page.buttons.save}
            </Component.Button>
          </Component.Window.Subsection>
        </Component.Window.Section>
      </div>
    </div>
  </Component.AutoPositionContainer>
);

BulkEditDropdownPanel.displayName = 'Bulk Edit Dropdown Panel';

BulkEditDropdownPanel.propTypes = {
  editMultiple: PropTypes.func,
  isUpdating: PropTypes.bool,
  onFormChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  strings: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

BulkEditDropdownPanel.defaultProps = {
  isUpdating: false,
  editMultiple: () => {},
};

export default translate('Task')(BulkEditDropdownPanel);
