/* eslint-disable camelcase */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

export function useGetTasksReschedulingList() {
  const {
    isLoading: isLoadingTasksRescheduling,
    error: errorTasksRescheduling,
    data: tasksRescheduling,
  } = useQuery({
    queryKey: ['tasksRescheduling'],
    queryFn: async () => {
      const result = await request('/task_rescheduling_settings', {
        method: 'GET',
      });
      return result.task_rescheduling_settings;
    },
  });

  return {
    isLoadingTasksRescheduling,
    errorTasksRescheduling,
    tasksRescheduling,
  };
}

export function useCreateTasksRescheduling() {
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingCreateTasksRescheduling,
    error: errorCreateTasksRescheduling,
    mutateAsync: createTasksRescheduling,
  } = useMutation({
    mutationFn: data => request('/task_rescheduling_settings', {
      method: 'POST',
    }, {
      task_rescheduling_setting: data,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasksRescheduling']);
    },
  });

  return {
    isLoadingCreateTasksRescheduling,
    errorCreateTasksRescheduling,
    createTasksRescheduling,
  };
}

export function useGetTasksReschedulingId() {
  const { id } = useParams();

  const {
    isLoading: isLoadingTasksRescheduling,
    error: errorTasksRescheduling,
    data: tasksRescheduling,
  } = useQuery({
    queryKey: ['tasksReschedulingId'],
    queryFn: async () => {
      const result = await request(`/task_rescheduling_settings/${id}`, {
        method: 'GET',
      });
      return result.task_rescheduling_setting;
    },
  });

  return {
    isLoadingTasksRescheduling,
    errorTasksRescheduling,
    tasksRescheduling,
  };
}

export function useEditTasksRescheduling() {
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingEditTasksRescheduling,
    error: errorDeleteTasksRescheduling,
    mutateAsync: editTasksRescheduling,
  } = useMutation({
    mutationFn: (data) => {
      // Destructure unpermitted params
      const {
        id, account_id, deleted_at, created_at, updated_at, ...sanitizedData
      } = data;

      return request(`/task_rescheduling_settings/${id}`, {
        method: 'PUT',
      }, {
        task_rescheduling_setting: sanitizedData,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['tasksReschedulingId'],
        data.task_rescheduling_setting);
    },
  });

  return {
    isLoadingEditTasksRescheduling,
    errorDeleteTasksRescheduling,
    editTasksRescheduling,
  };
}

export function useDeleteTasksRescheduling() {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingDeleteTasksRescheduling,
    error: errorDeleteTasksRescheduling,
    mutateAsync: deleteTasksRescheduling,
  } = useMutation({
    mutationFn: data => request(`/task_rescheduling_settings/${id}`, {
      method: 'DELETE',
    }, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasksRescheduling', 'tasksReschedulingId']);
    },
  });

  return {
    isLoadingDeleteTasksRescheduling,
    errorDeleteTasksRescheduling,
    deleteTasksRescheduling,
  };
}
