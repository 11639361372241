import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { addDefinition, Utils } from 'versafleet-core';
import { HashRouter, BrowserRouter } from 'react-router-dom'; // v6
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import UtilRouter from 'utils/router';
import moment from 'moment';
import en from 'languages/en.json';

import ReduxActions, { store } from 'reduxActions';
import ReduxAccountActions from 'reduxActions/account';
import ReduxConstantActions from 'reduxActions/constant';
import ReduxCustomerActions from 'reduxActions/customer';
import ReduxCustomLayoutActions from 'reduxActions/customLayout';
import ReduxDashboardActions from 'reduxActions/dashboard';
import ReduxDriverActions from 'reduxActions/driver';
import ReduxDriverSettingsActions from 'reduxActions/driverSettings';
import ReduxDriverRecordActions from 'reduxActions/driverRecord';
import ReduxDriverRecordSettingActions from 'reduxActions/driverRecordSetting';
import ReduxJobActions from 'reduxActions/job';
import ReduxNotificationActions from 'reduxActions/notification';
import ReduxOurLocationActions from 'reduxActions/ourLocation';
import ReduxProfileActions from 'reduxActions/profile';
import ReduxTaskActions from 'reduxActions/task';
import ReduxUserActions from 'reduxActions/user';
import ReduxVehicleActions from 'reduxActions/vehicle';
import ReduxVehicleLocatorActions from 'reduxActions/vehicleLocator';
import ReduxVehicleSettingsActions from 'reduxActions/vehicleSettings';
import ReduxVehiclePartActions from 'reduxActions/vehiclePart';
import ReduxVehiclePartSettingsActions from 'reduxActions/vehiclePartSettings';
import ReduxVehicleRecordActions from 'reduxActions/vehicleRecord';
import ReduxVehicleRecordSettingActions from 'reduxActions/vehicleRecordSetting';
import ReduxVersadriveSettingsActions from 'reduxActions/versadriveSettings';
import ReduxXeroActions from 'reduxActions/xero';
import ReduxBillingActions from 'reduxActions/billing';
import ReduxRunsheetActions from 'reduxActions/runsheet';
import ReduxNotificationSettingActions from 'reduxActions/notificationSetting';
import ReduxFilterSettingsActions from 'reduxActions/filterSettings';
import ReduxExportTemplateActions from 'reduxActions/exportTemplate';
import ReduxGlobalSearchActions from 'reduxActions/globalSearch';
import ReduxBillingAccountActions from 'reduxActions/billingAccount';
import ReduxCustomFieldGroupActions from 'reduxActions/customFieldGroup';
import ReduxVfSettingActions from 'reduxActions/vfSetting';
import ReduxVroomActions from 'reduxActions/vroom';
import ReduxVroomTimerActions from 'reduxActions/vroomTimer';
import ReduxVroomTimerGroupActions from 'reduxActions/vroomTimerGroup';
import ReduxLayerActions from 'reduxActions/layer';
import ReduxStatusTriggerActions from 'reduxActions/statusTrigger';
import ReduxScanToIdentifyConfigurationActions from 'reduxActions/scanToIdentifyConfiguration';

import CacheBuster from 'react-cache-buster';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './pages/errorBoundaryFallback';

import '../stylesheets/stringNameStylesheets/main.styl';
import 'versafleet-core/assets/icons.css';

import routes from './routes';
import Appcues from './appcues';
import { version } from '../../package.json';

import 'moment/locale/hi';
import 'moment/locale/id';
import 'moment/locale/ms';
import 'moment/locale/th';
import 'moment/locale/tl-ph';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/my';

$.getScript('https://code.getmdl.io/1.1.2/material.min.js');
$('<link>')
  .appendTo('head')
  .attr({
    type: 'text/css',
    rel: 'stylesheet',
    href: 'https://code.getmdl.io/1.1.2/material.indigo-pink.min.css',
  });
$('<link>')
  .appendTo('head')
  .attr({
    type: 'text/css',
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css?family=Lato:100,300,400,700',
  });

// Merge VF language definitions with versafleet-core's
addDefinition('en', en);
moment.locale('en');

// Set Sentry configuration
if (!__DEV__) {
  Sentry.init({
    dsn: 'https://7cc1d0879a944a60a6ac868dda2bc0e5@sentry.io/4343090',
    release: __VERSION__,
    environment: process.env.sentryEnvironment,
    // ignoreErrors uses a partial match if the value is a string: https://docs.sentry.io/clients/javascript/config/
    // - "ChunkLoadError" occurs when an outdated file is loaded, usually happens after a release.
    // - Recaptcha errors are flooding Sentry everyday, possibly something wrong with
    // our implementation, but seems harmless.
    // - "Successfully created address" are errors from appcues, no idea what causes this.
    ignoreErrors: [
      'ChunkLoadError',
      'Your subscription has already expired',
      'Not subscribed to',
      'grecaptcha',
      'reCAPTCHA',
      'Successfully created address',
      'Failed to fetch',
      "Cannot read properties of null (reading 'style')",
    ],
    beforeSend(event, hint) {
      // if the error from status code 403, we will not send the error to the Sentry
      // https://versafleet.atlassian.net/browse/VF-1688
      if (event.exception.values.length > 0
        && event.exception.values[0].value === ''
        && hint.originalException.length === 0) {
        return null;
      }
      // If the error is a Timeout error, ignore it
      // https://github.com/getsentry/sentry-javascript/issues/2514
      if (hint.originalException === 'Timeout') {
        return null;
      }
      // These errors fail to be ignored when placed in `ignoreErrors` since they are arrays.
      if (Array.isArray(hint.originalException)) {
        // "Couldn't find" errors are related to missing Tasks, Jobs, Customers, etc.
        if (hint.originalException.find(e => e.includes("Couldn't find"))) {
          return null;
        }
        // "cannot transition from" are invalid state changes.
        if (hint.originalException.find(e => e.includes('cannot transition from'))) {
          return null;
        }
        // rwstsms occurs when the user is auto logged out (RWS specific).
        if (hint.originalException.find(e => e.includes('rwstsms'))) {
          return null;
        }
      }
      return event;
    },
  });
}

localStorage.removeItem('ajs_user_traits');
// this below commented for https://versafleet.atlassian.net/browse/CP-283
// const storageSettings = { localStorage: { enabled: false } };
// Segment
// var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["init","trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="3.1.0";
// analytics.init(null, storageSettings);
// analytics.load(process.env.segmentKey);
// analytics.page()
// };

// createHistory.listen((e) => {
//   analytics.page();
// });

// analytics.ready(() => {
//   Appcues.on('form_submitted', (e) => {
//     if (e.interaction.response[1].label === 'Your mobile number.') {
//       const value = e.interaction.response[1].value;
//       // Dispatch an API call
//       store.dispatch(ReduxAccountActions.setupVersadriveDemo({
//         to: value,
//       }));
//     }
//   });
// });

Utils.RequestHelper.setBaseUrl(process.env.apiUrl);

Utils.RequestHelper.setPreemptiveFunctionHandler((response) => {
  const contentType = response.headers.get('content-type');
  let data;
  if (contentType && contentType.indexOf('application/json') !== -1) {
    data = response.clone().json();
  } else {
    data = response.clone().blob();
  }
  return (
    data.then((e) => {
      if (e && e.status_code === 1001 && UtilRouter.getPath().indexOf('auth') === -1) {
        if (e.message.length > 0 && e.message[0] === 'SSO') { // SSO IdP Logout
          UtilRouter.replace(`/auth/login/sso/${e.message[1]}`);
        } else { // Auto logout when timeout
          UtilRouter.replace(`/auth/login?destination=${UtilRouter.getPath()}`);
        }
      }
      return response;
    })
  );
});

Utils.Geocoder.initializeGeocoder('mapbox', process.env.mapboxToken);

// Initialize the services base url
// Don't use spaces in the name value as it will be set as the key behind the scenes
// Documentation https://versafleet.atlassian.net/wiki/spaces/TD/pages/963772417/All+about+the+requestWithJWT+function#How-to-setup-base-url-for-each-service
const servicesUrl = [{
  name: 'crs',
  baseUrl: process.env.coordinateRetrievalServiceBaseUrl,
}];
Utils.RequestHelper.setServicesBaseUrl(servicesUrl);

/* Redux */

ReduxActions.setActions({
  account: ReduxAccountActions,
  constant: ReduxConstantActions,
  customer: ReduxCustomerActions,
  customLayout: ReduxCustomLayoutActions,
  dashboard: ReduxDashboardActions,
  driver: ReduxDriverActions,
  driverSettings: ReduxDriverSettingsActions,
  driverRecord: ReduxDriverRecordActions,
  driverRecordSetting: ReduxDriverRecordSettingActions,
  job: ReduxJobActions,
  notification: ReduxNotificationActions,
  ourLocation: ReduxOurLocationActions,
  profile: ReduxProfileActions,
  task: ReduxTaskActions,
  user: ReduxUserActions,
  vehicle: ReduxVehicleActions,
  vehicleLocator: ReduxVehicleLocatorActions,
  vehicleSettings: ReduxVehicleSettingsActions,
  vehiclePart: ReduxVehiclePartActions,
  vehiclePartSettings: ReduxVehiclePartSettingsActions,
  vehicleRecord: ReduxVehicleRecordActions,
  vehicleRecordSetting: ReduxVehicleRecordSettingActions,
  versadriveSettings: ReduxVersadriveSettingsActions,
  xero: ReduxXeroActions,
  billing: ReduxBillingActions,
  runsheet: ReduxRunsheetActions,
  notificationSetting: ReduxNotificationSettingActions,
  filterSettings: ReduxFilterSettingsActions,
  exportTemplate: ReduxExportTemplateActions,
  globalSearch: ReduxGlobalSearchActions,
  billingAccount: ReduxBillingAccountActions,
  customFieldGroup: ReduxCustomFieldGroupActions,
  vfSetting: ReduxVfSettingActions,
  vroom: ReduxVroomActions,
  vroomTimer: ReduxVroomTimerActions,
  vroomTimerGroup: ReduxVroomTimerGroupActions,
  layer: ReduxLayerActions,
  statusTrigger: ReduxStatusTriggerActions,
  scanToIdentifyConfiguration: ReduxScanToIdentifyConfigurationActions,
});

const Router = __DEV__ ? HashRouter : BrowserRouter;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render((
  <StrictMode>
    <CacheBuster
      currentVersion={version}
      isEnabled={!__DEV__}
      isVerboseMode={false}
    >
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <QueryClientProvider client={queryClient}>
          {/* By default, React Query Devtools are only included in 'development' */}
          <ReactQueryDevtools initialIsOpen={false} />
          <Provider store={store}>
            <Router>
              <Appcues>
                {routes}
              </Appcues>
            </Router>
          </Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </CacheBuster>
  </StrictMode>
), document.getElementById('main-container'));
