import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import Component from 'components/component';
import EditItem from './editItem';
import Header from './header';

class MeasurementEdit extends React.Component {
  _getInputs = () => {
    const data = [];
    let index = 0; // Counter for real data index
    for (let i = 0; i < this.props.data.length; i += 1) {
      data.push({
        id: this.props.data[i].id,
        component: (
          <EditItem
            data={this.props.data[i]}
            displayedIndex={index}
            index={i}
            key={`measurement-edit-item-${index}`}
            onFormChange={this.props.onFormChange}
          />
        ),
      });
      // eslint-disable-next-line no-underscore-dangle
      if (!this.props.data[i]._destroy) {
        index += 1;
      }
    }

    return data;
  };

  render() {
    return (
      <div className="vf-measurement-edit">
        <Header data={this.props.data} />

        <Component.Form.InputGroupList
          error={this.props.error}
          inputs={this._getInputs()}
        />
        <Component.Button
          className="vf-measurement-edit__button"
          iconClassName="versa-add"
          id={`${this.props.id}__add-item-button`}
          onClick={() => {
            this.props.onFormChange([
              ...this.props.data, {
                quantity: '',
                quantity_unit: '',
              },
            ]);
            setTimeout(() => this.bottom.scrollIntoView({ behavior: 'smooth' }), 200);
          }}
          showShadow
        >
          {this.props.strings.Task.form.buttons.addNewItems}
        </Component.Button>
        <div ref={(c) => { this.bottom = c; }} />
      </div>
    );
  }
}

MeasurementEdit.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    _destroy: PropTypes.func,
  })),
  error: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  onFormChange: PropTypes.func,
  strings: PropTypes.shape().isRequired,
};

MeasurementEdit.defaultProps = {
  error: null,
  id: 'vf-task__measurement',
  onFormChange: null,
  data: [],
};

export default translate('')(MeasurementEdit);
