import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import MeasurementModule from 'modules/measurement';
import { connect } from 'react-redux';
import AccessRightHelper from 'utils/accessRightHelper';
import Router from 'utils/router';
import ReduxActions from 'reduxActions';
import { Utils, translate } from 'versafleet-core';
import addOnsSettings from 'pages/addOns/addOnsConstants';
import CustomInfoEdit from './customInfoEdit';
import JobCustomAddress from '../job/customAddress';
import JobCustomDateTime from '../job/customDateTime';
import { withRouterHooks } from '../../utils/router';

const { Helper } = Utils;

class TaskEditForm extends Component.Form {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      form: this._getDefaultForm(props.data),
      error: [],
    };
    this.state.isEditForm = true;
    this.basePath = this.props.basePath || 'tasks';
    this._guardRefresh();
  }

  componentDidMount() {
    super.componentDidMount();
    const {
      account, dispatch, data, addonsSetting,
    } = this.props;

    const isSkillsSubscribed = !Helper.isNullOrUndefined(addonsSetting)
      && addonsSetting.skills === addOnsSettings.subscribed;

    dispatch(ReduxActions.ourLocation.fetchOptionLocationList());
    if (!Helper.isNullOrUndefined(data)
        && !Helper.isNullOrUndefined(data.billing_account)
        && !Helper.isNullOrUndefined(data.billing_account.id)) {
      dispatch(ReduxActions.billingAccount.fetch(data.billing_account.id));
    }

    if (!Helper.isNullOrUndefined(data)
        && !Helper.isNullOrUndefined(data.custom_field_group)
        && !Helper.isNullOrUndefined(data.custom_field_group.id)) {
      dispatch(ReduxActions.customFieldGroup.fetch(data.custom_field_group.id));
    } else {
      if (!Helper.isNullOrUndefined(account.default_custom_field_group_id)) {
        dispatch(ReduxActions.customFieldGroup.fetch(account.default_custom_field_group_id));
      }
      dispatch(ReduxActions.customFieldGroup.fetchOptionCustomFieldGroupList());
    }

    if (isSkillsSubscribed) {
      dispatch(ReduxActions.driver.fetchSkills());
      dispatch(ReduxActions.vehicle.fetchSkills());
      dispatch(ReduxActions.vehiclePart.fetchSkills());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customFieldGroups !== this.props.customFieldGroups
      && !Helper.isNullOrUndefined(this.props.account.addons_setting)
      && this.props.account.addons_setting.custom_field === addOnsSettings.subscribed) {
      let customFieldGroupId;
      if (this._isNewTask()) {
        customFieldGroupId = this.state.form.custom_field_group_id;
      } else if (Helper.isNullOrUndefined(this.props.data.custom_field_group)) {
        customFieldGroupId = null;
      } else {
        customFieldGroupId = this.props.data.custom_field_group.id;
      }

      this._onFormChange('custom_fields_attributes',
        this._getCustomFieldsDefaultForm(this.props.data.custom_fields,
          this.props.customFieldGroups[customFieldGroupId]));
    }
    if (
      prevProps.data !== this.props.data && !Helper.isNullOrUndefined(this.props.data)
        && !Helper.isNullOrUndefined(this.props.data.custom_field_group)
        && !Helper.isNullOrUndefined(this.props.data.custom_field_group.id)
    ) {
      this.props.dispatch(
        ReduxActions.customFieldGroup.fetch(this.props.data.custom_field_group.id),
      );
    }

    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  _closeForm = () => {
    this.props.navigate('..');
  }

  _viewTaskViewForm() {
    Router.transitionFromSubpath(this.basePath);
  }

  _getDefaultForm = () => {
    const data = this.props.data || {};
    const customFieldGroupId = Helper.isNullOrUndefined(data.custom_field_group)
      ? null : data.custom_field_group.id;
    const addOns = this.props.account.addons_setting || {};
    // The jobTimeFrom and jobTimeTo will not work when editing the task
    // only apply when create a new task
    const jobTimeFrom = !Helper.isNullOrUndefined(data.job && data.job.base_task_attributes)
      ? data.job.base_task_attributes.time_from : null;
    const jobTimeTo = !Helper.isNullOrUndefined(data.job && data.job.base_task_attributes)
      ? data.job.base_task_attributes.time_to : null;
    const cfgId = !this._isNewTask()
      || !Helper.isNullOrUndefined(data && data.custom_field_group_id)
      ? data.custom_field_group_id : this.props.account.default_custom_field_group_id;

    let driverSkillList;
    let vehicleSkillList;
    let vehiclePartSkillList;

    if (addOns.skills) {
      driverSkillList = (data.driver_skills || []).map(skill => skill.name);
      vehicleSkillList = (data.vehicle_skills || []).map(skill => skill.name);

      if (addOns.parts_and_config) {
        vehiclePartSkillList = (data.vehicle_part_skills || []).map(skill => skill.name);
      }
    }

    return {
      job_id: this._isNewTask() ? (data.job || {}).id : undefined,
      billing_account_attributes: data.billing_account_attributes,
      billing_account_id: data.billing_account_id
        || (data.billing_account && data.billing_account.id),
      address_attributes: this._getAddressDefaultForm(data),
      time_type: Helper.isNullOrUndefined(data.time_type) ? undefined : data.time_type,
      time_from: data.time_from || jobTimeFrom,
      time_to: data.time_to || jobTimeTo,
      time_window_id: Helper.isNullOrUndefined(data && data.time_window)
        ? data.time_window_id : data.time_window.id,
      tag_list: data.tag_list
        || (data.tags && data.tags.map(item => item.name)) || [],
      photos_attributes: this._getPhotosDefaultForm(data),
      measurements_attributes: this._getMeasurementDefaultForm(data),
      remarks: data.remarks,
      invoice_number: data.invoice_number,
      price: data.price,
      tracking_id: data.tracking_id,
      expected_cod: data.expected_cod,
      custom_field_group_id: cfgId, // for validation
      custom_fields_attributes: addOns.custom_field
        ? this._getCustomFieldsDefaultForm(data.custom_fields || data.custom_fields_attributes,
          this.props.customFieldGroups[data.custom_field_group_id || customFieldGroupId])
        : undefined,
      service_time: this._getServiceTimeDefaultForm(data),
      driver_skill_list: driverSkillList,
      vehicle_skill_list: vehicleSkillList,
      vehicle_part_skill_list: vehiclePartSkillList,
    };
  }

  _getAttributes() {
    const { form } = this.state;
    return {
      ...form,
      service_time: Helper.isNullOrUndefined(form.service_time)
        || !Helper.isNumber(form.service_time)
        ? form.service_time : Number(form.service_time) * 60,
    };
  }

  _getServiceTimeDefaultForm(data) {
    const addOns = this.props.account.addons_setting || {};

    if (!addOns.vroom || !addOns.scheduler || Helper.isNullOrUndefined(data.service_time)) {
      return undefined;
    }

    return Helper.roundToOneDecimalPlace(data.service_time / 60);
  }

  _getMeasurementDefaultForm = (data) => {
    const measurement = data.measurements_attributes || data.measurements || [];
    return measurement;
  }

  _getPhotosDefaultForm = (data) => {
    const photos = data.photos_attributes || data.photos || [];
    return photos.map(photo => ({
      id: photo.id,
      name: photo.name,
      url: photo.url,
    }));
  }

  _getAddressDefaultForm = (data) => {
    const address = data.address || data.address_attributes || {};
    return {
      name: address.name,
      line_1: address.line_1,
      line_2: address.line_2,
      city: address.city,
      zip: address.zip,
      country: address.country,
      latitude: address.latitude,
      longitude: address.longitude,
      contact_person: address.contact_person,
      contact_number: address.contact_number,
      country_code: address.country_code,
      email: address.email,
    };
  }

  _getCustomFieldsDefaultForm = (customFields, customFieldGroup) => {
    if (Helper.isNullOrUndefined(customFieldGroup)) {
      return [];
    }

    const userEditableInVf = AccessRightHelper.hasAccess(
      this.props.accessRights, 'task.edit_api_editable_cf',
    );

    return customFieldGroup.custom_field_descriptions
      .filter(item => item.editable_in_vf || userEditableInVf)
      .map((item) => {
        const customField = Helper.isNullOrUndefined(customFields) ? {}
          : customFields.find((field) => {
            const id = Helper.isNullOrUndefined(field.custom_field_description)
              ? field.custom_field_description_id : field.custom_field_description.id;

            return id === item.id;
          });
        const value = (customField || {}).value;
        const subvalue = (customField || {}).subvalue;
        return {
          custom_field_description_id: item.id,
          value,
          subvalue,
          id: (customField || {}).id,
        };
      });
  }

  _getAccountOptions() {
    let accountOptions = [];
    const { customer } = this.props;

    if (customer && typeof customer.billing_accounts !== 'undefined') {
      accountOptions = customer.billing_accounts.map(account => ({
        value: account.id,
        text: account.name,
      }));
    }
    return accountOptions;
  }

  _getCustomFieldGroupOptions() {
    return this.props.optionCustomFieldGroups.map(item => ({
      value: item.id,
      text: item.name,
    }));
  }

  _getAddresses() {
    const billingAccount = this.props.billingAccounts[this.state.form.billing_account_id];
    if (billingAccount && billingAccount.addresses && billingAccount.addresses.length > 0) {
      return billingAccount.addresses.concat(this.props.optionAddresses);
    }
    return this.props.optionAddresses;
  }

  _isNewTask() {
    return Helper.isNullOrUndefined(this.props.data)
      || Helper.isNullOrUndefined(this.props.data.id);
  }

  _isNewJob() {
    return this._isNewTask()
      && !Helper.isNullOrUndefined(this.props.data)
      && !Helper.isNullOrUndefined(this.props.data.job)
      && Helper.isNullOrUndefined(this.props.data.job.id);
  }

  _goBack() {
    Router.transitionFromSubpath(this.basePath, this.props.data.id);
  }

  _routeToNewJob = () => {
    this.props.onSave(this._getAttributes());
  }

  _routeToExistingJob = () => {
    if (!Helper.isNullOrUndefined(this.props.data.job)) {
      Router.transitionFromSubpath('tasks', `jobs/${this.props.data.job.id}`);
    }
  }

  _onFormChange(fieldName, value, index, isDelete, shouldChange = true) {
    const { form } = this.state;

    if (fieldName === 'billing_account_id') {
      let billingAccountId;
      if (typeof value === 'object') {
        billingAccountId = undefined;
      } else if (value === '') {
        billingAccountId = null;
      } else {
        billingAccountId = value;
        // Fetch billing account for the location, if existing
        this.props.dispatch(ReduxActions.billingAccount.fetch(value));
      }
      const newForm = {
        ...form,
        billing_account_id: billingAccountId,
        billing_account_attributes: typeof value !== 'object' ? undefined : {
          name: value.text,
          customer_id: (this.props.customer || {}).id,
        },
      };
      this.setState({ form: newForm });
    } else {
      if (fieldName === 'custom_field_group_id') {
        // eslint-disable-next-line no-underscore-dangle
        super._onFormChange('custom_fields_attributes', [], shouldChange); // Reset custom fields attributes
        if (!Helper.isNullOrUndefined(value)) {
          this.props.dispatch(ReduxActions.customFieldGroup.fetch(value));
        }
      }
      // RWS Specific feature - queries the backend to get the membership details if
      // the custom field currently being edited is the "Membership ID".
      // Then auto fill the custom fields related to the membership details.
      // https://versafleet.atlassian.net/browse/VF-2119
      if (fieldName === 'custom_fields_attributes'
        && ((value.custom_field_description_id === 325 && process.env.environment === 'production')
          // RWS IT team account
          || (value.custom_field_description_id === 1182 && process.env.environment === 'production')
          // For testing on development environment
          || (value.custom_field_description_id === 1628 && process.env.environment === 'development'))
        && value.value.length === 11
        && value.value !== '00000000000') {
        setTimeout(async () => {
          try {
            const result = await this.props.dispatch(
              ReduxActions.task.fetchMembershipDetails(value.value),
            );

            this.setState(prevState => ({
              error: [],
              form: {
                ...prevState.form,
                custom_fields_attributes: [
                  // Salutation
                  {
                    custom_field_description_id:
                      result.custom_fields[0].custom_field_description.id,
                    id: this._isNewTask() ? null : prevState.form.custom_fields_attributes[0].id,
                    value: result.custom_fields[0].value,
                  },
                  // Guest Name
                  {
                    custom_field_description_id:
                      result.custom_fields[1].custom_field_description.id,
                    id: this._isNewTask() ? null : prevState.form.custom_fields_attributes[1].id,
                    value: result.custom_fields[1].value,
                  },
                  // Membership
                  {
                    custom_field_description_id:
                      result.custom_fields[2].custom_field_description.id,
                    id: this._isNewTask() ? null : prevState.form.custom_fields_attributes[2].id,
                    value: result.custom_fields[2].value,
                  },
                  // Membership ID
                  prevState.form.custom_fields_attributes[3],
                  // Nationality
                  {
                    custom_field_description_id:
                      result.custom_fields[3].custom_field_description.id,
                    id: this._isNewTask() ? null : prevState.form.custom_fields_attributes[4].id,
                    value: result.custom_fields[3].value,
                  },
                  // Membership Class
                  {
                    custom_field_description_id:
                      result.custom_fields[4].custom_field_description.id,
                    id: this._isNewTask() ? null : prevState.form.custom_fields_attributes[5].id,
                    value: result.custom_fields[4].value,
                  },
                ].concat(prevState.form.custom_fields_attributes.slice(6)),
              },
            }));
          } catch (error) {
            if (this._isNewTask()) {
              this.setState({ error });
            } else {
              this.setState({
                error: {
                  custom_fields: [{
                    id: value.id,
                    value: ['Record does not exist'],
                  }],
                },
              });
            }
          }
        }, 2000);
      }

      this.setState(prevState => ({
        form: Helper.setFilter(prevState.form, fieldName, value, index, isDelete),
      }));
    }

    this.setState(prevState => ({ isChanged: shouldChange ? true : prevState.isChanged }));
  }

  _renderMenu() {
    const strings = this.props.strings.Task;
    if (this._isNewJob()) {
      return (
        <Component.Form.Title>
          <Component.Form.Title.Item
            id="vf-task__edit-form__title__job-return-button"
            onClick={this._routeToNewJob}
          >
            {strings.form.menu.newJob}
          </Component.Form.Title.Item>
          <Component.Form.Title.Item isHighlighted>
            {strings.form.menu.newTask}
          </Component.Form.Title.Item>
        </Component.Form.Title>
      );
    } if (this._isNewTask()) {
      return (
        <Component.Form.Title>
          {
            (Helper.isNullOrUndefined(this.props.data)
              || Helper.isNullOrUndefined(this.props.data.job))
              ? false : (
                <Component.Form.Title.Item
                  id="vf-task__edit-form__title__job-return-button"
                  onClick={this._routeToExistingJob}
                >
                  {this.props.data.job.customer.name}
                </Component.Form.Title.Item>
              )
          }
          <Component.Form.Title.Item isHighlighted>
            {strings.form.menu.newTask}
          </Component.Form.Title.Item>
        </Component.Form.Title>
      );
    }
    return (
      <Component.Form.Title>
        <Component.Form.Title.Item isHighlighted>
          {`${strings.form.menu.editTask} #${this.props.data.guid}`}
        </Component.Form.Title.Item>
      </Component.Form.Title>
    );
  }

  _renderButton() {
    const strings = this.props.strings.Task.form.buttons;
    return (
      <Component.Button.Done
        hint={null}
        id="vf-task__edit-form__right-panel__save-button"
        loading={this.props.isUpdating}
        onClick={() => this.props.onSave(this._getAttributes())}
      >
        {this.props.isUpdating ? strings.saving : strings.save}
      </Component.Button.Done>
    );
  }

  _renderAccountNameInput() {
    const value = this.state.form.billing_account_id
      || (this.state.form.billing_account_attributes || {}).name;

    return (
      <Component.Form.MainHeader
        iconClassName={
          this.props.data.job && this.props.data.job.job_type === 'pickup'
            ? 'versa-arrow-up' : 'versa-arrow-down'
        }
        title={(
          <Component.Input.Select
            allowCustom
            autoFocus
            error={this.props.error.billing_account}
            id="vf-task__edit-form__left-panel__main-info__select-account-input"
            onChange={v => this._onFormChange('billing_account_id', v)}
            options={this._getAccountOptions()}
            placeholder={this.props.strings.Task.form.mainHeader.accountHint}
            value={value}
          />
        )}
      />
    );
  }

  _renderCustomInfo() {
    let customFieldGroupId = null;
    if (this._isNewTask()) {
      customFieldGroupId = this.state.form.custom_field_group_id;
    } else if (!Helper.isNullOrUndefined(this.props.data.custom_field_group)) {
      customFieldGroupId = this.props.data.custom_field_group.id;
    }
    return (
      <div>
        <CustomInfoEdit
          customFieldGroup={this.props.customFieldGroups[customFieldGroupId]}
          data={this.props.data}
          error={this.state.error.custom_fields || []}
          form={this.state.form}
          id="vf-task__edit-form__left-panel__custom-info"
          isNewTask={this._isNewTask()}
          onFormChange={(fieldName, value, i) => this._onFormChange(fieldName, value, i)}
          options={this._getCustomFieldGroupOptions()}
          strings={this.props.strings.Task.form.customInfo}
        />
        <Component.Form.Divider size="SMALL" />
      </div>
    );
  }

  _renderSkill(strings, skills, fieldName) {
    return (
      <React.Fragment>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block level={2}>
          {strings.title}
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block level={2}>
          <Component.Input.Tag
            allowClear
            onChange={value => this._onFormChange(fieldName, value)}
            options={skills.map(skill => ({
              text: skill,
              value: skill,
            }))}
            placeholder={strings.placeholder}
            showEllipsis
            tagClassName="vf-driver__edit__tag"
            value={this.state.form[fieldName]}
          />
        </Component.Form.Block>
      </React.Fragment>
    );
  }

  _renderSkillsList() {
    const {
      addonsSetting, strings, driverSkills, vehicleSkills, partSkills,
    } = this.props;

    const isSkillsSubscribed = !Helper.isNullOrUndefined(addonsSetting)
      && addonsSetting.skills === addOnsSettings.subscribed;

    if (!isSkillsSubscribed) {
      return null;
    }

    return (
      <React.Fragment>
        <Component.Form.Block level={1}>
          {strings.Task.form.constants.requirements}
        </Component.Form.Block>
        <Component.Form.Block level={2}>
          {strings.Task.form.constants.requirementsDesc}
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        {this._renderSkill(strings.Driver.form.driverSpecifications, driverSkills, 'driver_skill_list')}
        {this._renderSkill(strings.Vehicle.form.vehicleSpecifications, vehicleSkills, 'vehicle_skill_list')}
        {this._renderSkill(strings.VehiclePart.form.partSpecifications, partSkills, 'vehicle_part_skill_list')}
        <Component.Form.Divider size="MEDIUM" />
      </React.Fragment>
    );
  }

  _renderServiceTimeInput() {
    const { error, account } = this.props;
    const strings = this.props.strings.Task;
    const addOns = account.addons_setting || {};

    if (!addOns.vroom || !addOns.scheduler) {
      return false;
    }

    return (
      <Component.Form.Block fontColor="GREY" level={2}>
        <Component.Form.Divider size="SMALL" />
        {`${strings.form.additionalInfo.serviceTime.title}:`}
        <Component.Input.Textbox
          className="vf-task__edit-form__service-time"
          id="vf-task__edit-form__left-panel__additional-info__service-time-input"
          onChange={value => this._onFormChange('service_time', value)}
          showEllipsis={false}
          value={this.state.form.service_time}
        />
        {strings.form.additionalInfo.serviceTime.mins}
        <Component.Error
          errors={error.service_time || []}
        />
      </Component.Form.Block>
    );
  }

  _renderLeftPanel() {
    if (!Helper.isNullOrUndefined(this.props.data)) {
      const { error, account } = this.props;
      const strings = this.props.strings.Task;
      const addOns = account.addons_setting || {};

      return (
        <Component.Form.LeftPanel>
          {this._renderAccountNameInput()}
          <Component.Form.Divider size="MEDIUM" />

          <Component.Form.Block level={1}>
            {
              this.props.data.job && this.props.data.job.job_type === 'pickup'
                ? strings.constants.pickupFrom : strings.constants.deliveryTo
            }
          </Component.Form.Block>

          <JobCustomAddress
            addressList={this._getAddresses()}
            error={error.address}
            iconClassName={this.props.data.job && this.props.data.job.job_type === 'pickup'
              ? 'versa-arrow-up' : 'versa-arrow-down'}
            id="vf-task__edit-form__left-panel__main-info__address"
            onChange={(value, shouldChange) => this._onFormChange(
              'address_attributes', value, undefined, undefined, shouldChange,
            )}
            value={this.state.form.address_attributes}
          />

          <Component.Form.Divider size="SMALL" />

          <Component.Form.Block iconClassName="versa-person" level={2}>
            <Component.Input.Textbox
              error={error.address && (error.address.contact_person || [])}
              id="vf-task__edit-form__left-panel__main-info__contact-person-input"
              onChange={value => this._onFormChange('address_attributes.contact_person', value)}
              placeholder={strings.form.details.contactPersonHint}
              value={this.state.form.address_attributes.contact_person}
            />
          </Component.Form.Block>
          <Component.Form.Divider size="SMALL" />
          <Component.Form.Block iconClassName="versa-call" level={2}>
            <div className="vf-task__edit-form__contact-number-input">
              <Component.Input.Textbox
                id="vf-task__edit-form__left-panel__main-info__country-code-input"
                leadingLabel={Helper.isNonEmptyString(this.state.form.address_attributes.country_code) ? '+' : null}
                onChange={value => this._onFormChange('address_attributes.country_code', value)}
                placeholder={strings.form.details.countryCodeHint}
                value={this.state.form.address_attributes.country_code}
                width="slim"
              />
              <Component.Input.Textbox
                id="vf-task__edit-form__left-panel__main-info__contact-number-input"
                onChange={value => this._onFormChange('address_attributes.contact_number', value)}
                placeholder={strings.form.details.contactNumberHint}
                value={this.state.form.address_attributes.contact_number}
                width="wide"
              />
            </div>
            <Component.Error
              errors={(error?.address?.country_code || [])
                .concat(error?.address?.contact_number || [])}
            />
          </Component.Form.Block>
          <Component.Form.Divider size="SMALL" />
          <Component.Form.Block iconClassName="versa-email" level={2}>
            <Component.Input.Textbox
              error={error.address && (error.address.email || [])}
              id="vf-task__edit-form__left-panel__main-info__contact-email-input"
              onChange={value => this._onFormChange('address_attributes.email', value)}
              placeholder={strings.form.details.emailHint}
              value={this.state.form.address_attributes.email}
            />
          </Component.Form.Block>

          <Component.Form.Divider size="SMALL" />

          <JobCustomDateTime
            error={error}
            id="vf-task__edit-form__left-panel__main-info"
            onChange={value => this._onFormChange(null, value)}
            value={this.state.form}
          />

          <Component.Form.Divider size="LARGE" />

          {/* Additional Info */}
          <Component.Form.Dropdown
            id="vf-task__edit-form__left-panel__additional-info"
            parent={(
              <Component.Form.Block level={1}>
                {strings.form.additionalInfo.title}
              </Component.Form.Block>
            )}
            showByDefault
          >
            <Component.Form.Block
              className="vf-task__edit-form__multi-select"
              iconClassName="versa-tags"
              iconContainerClassName="vf-task__edit-form__multi-select__icon"
              level={2}
            >
              <Component.Input.Tag
                error={error.tag_list}
                id="vf-task__edit-form__left-panel__additional-info__tag-input"
                onChange={value => this._onFormChange('tag_list', value)}
                options={(this.props.tags || []).map(tag => ({ value: tag, text: tag }))}
                placeholder={strings.form.additionalInfo.tagHint}
                value={this.state.form.tag_list}
              />
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block iconClassName="versa-note" level={2}>
              <Component.Input.Textarea
                error={error.remarks || []}
                id="vf-task__edit-form__left-panel__additional-info__remarks-input"
                onChange={value => this._onFormChange('remarks', value)}
                placeholder={strings.form.additionalInfo.noteHint}
                value={this.state.form.remarks}
                width={Component.Input.Width.WIDE}
              />
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block level={2}>
              <Component.InputAttachment
                id="vf-task__edit-form__left-panel__additional-info__attachment-input"
                onChange={value => this._onFormChange('photos_attributes', value)}
                removable
                value={this.state.form.photos_attributes}
              />
              <Component.Error
                errors={Array.isArray(error.photos) ? (error.photos.find(
                  photo => !Helper.isNullOrUndefined(photo.name),
                ) || {}).name : ''}
              />
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block fontColor="GREY" level={2}>
              {strings.form.additionalInfo.cod.title}
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block fontColor="TEXT" level={3}>
              <Component.Input.Textbox
                error={error.expected_cod || []}
                id="vf-task__edit-form__left-panel__additional-info__expected-cod-input"
                onChange={value => this._onFormChange('expected_cod', value)}
                placeholder={strings.form.additionalInfo.cod.expectedCODHint}
                value={this.state.form.expected_cod}
              />
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block iconClassName="versa-invoice" level={2}>
              <Component.Input.Textbox
                error={error.invoice_number || []}
                id="vf-task__edit-form__left-panel__additional-info__invoice-number-input"
                onChange={value => this._onFormChange('invoice_number', value)}
                placeholder={strings.form.additionalInfo.invoiceNumberHint}
                value={this.state.form.invoice_number}
              />
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block iconClassName="versa-money" level={2}>
              <Component.Input.Textbox
                error={error.price || []}
                id="vf-task__edit-form__left-panel__additional-info__price-input"
                onChange={value => this._onFormChange('price', value)}
                placeholder={strings.form.additionalInfo.priceHint}
                value={this.state.form.price}
              />
            </Component.Form.Block>

            <Component.Form.Divider size="SMALL" />
            <Component.Form.Block iconClassName="versa-trackid" level={2}>
              <Component.Input.Textbox
                error={error.tracking_id || []}
                id="vf-task__edit-form__left-panel__additional-info__tracking-id-input"
                onChange={value => this._onFormChange('tracking_id', value)}
                placeholder={strings.form.additionalInfo.trackingIdHint}
                value={this.state.form.tracking_id}
              />
            </Component.Form.Block>

            {this._renderServiceTimeInput()}
            <Component.Form.Divider size="MEDIUM" />
          </Component.Form.Dropdown>

          <Component.Form.Divider />

          {/* Custom Info */}
          {addOns.custom_field ? this._renderCustomInfo() : false}
          {this._renderSkillsList()}
          {this._renderItems()}
        </Component.Form.LeftPanel>
      );
    }
    return <Component.Form.LeftPanel />;
  }

  _renderItems() {
    return (
      <MeasurementModule.Edit
        data={this.state.form.measurements_attributes}
        error={(this.props.error || {}).measurements || []}
        onFormChange={(value, index, isDelete) => this._onFormChange(
          'measurements_attributes', value, index, isDelete,
        )}
      />
    );
  }
}

TaskEditForm.displayName = 'Task Edit Form';

TaskEditForm.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  account: PropTypes.shape({}).isRequired,
  addonsSetting: PropTypes.shape({}).isRequired,
  basePath: PropTypes.string,
  customFieldGroups: PropTypes.shape({}).isRequired,
  customer: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  data: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
  isUpdating: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  optionAddresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  strings: PropTypes.shape({}).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

TaskEditForm.defaultProps = {
  basePath: null,
  billingAccounts: [],
  customer: null,
  data: null,
  error: {},
  isUpdating: false,
  tags: [],
};

function mapStateToProps(state) {
  const {
    account, billingAccount, customFieldGroup, driver, ourLocation, profile, vehicle, vehiclePart,
  } = state;
  return {
    accessRights: profile.profile.access_rights,
    addonsSetting: account.account.addons_setting,
    account: account.account,
    billingAccounts: billingAccount.billingAccounts,
    driverSkills: driver.skills,
    vehicleSkills: vehicle.skills,
    partSkills: vehiclePart.skills,
    optionCustomFieldGroups: customFieldGroup.optionCustomFieldGroups,
    customFieldGroups: customFieldGroup.customFieldGroups,
    optionAddresses: Object.keys(ourLocation.optionLocations).map(
      key => ourLocation.optionLocations[key],
    ),
  };
}

export default withRouterHooks(connect(mapStateToProps)(translate('')(TaskEditForm)));
