import { useQuery } from '@tanstack/react-query';
import { Utils } from 'versafleet-core';

const { RequestHelper: { request } } = Utils;

// eslint-disable-next-line import/prefer-default-export
export function useOptionCustomersList() {
  const {
    isLoading: isLoadingOptionCustomersList,
    error: errorOptionCustomersList,
    data: optionCustomersList,
  } = useQuery({
    queryKey: ['optionCustomersList'],
    queryFn: async () => {
      const result = await request('/customers/list', {
        method: 'GET',
      });
      return result.customers;
    },
  });

  return {
    isLoadingOptionCustomersList,
    errorOptionCustomersList,
    optionCustomersList,
  };
}
