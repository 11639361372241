import Helper from 'utils/helper';
import Router from 'utils/router';
import { Utils } from 'versafleet-core';

const {
  RequestHelper: {
    request,
    convertDataToParams,
    requestCustomBody,
    requestBlob,
  },
} = Utils;

const baseFilter = {
  per_page: 20,
  sort_by: 'id',
  order_by: 'desc',
};

function receiveDriver(driver) {
  return {
    type: 'RECEIVE_DRIVER',
    driver,
  };
}

function requestDriverLocationHistory() {
  return {
    type: 'REQUEST_DRIVER_LOCATION_HISTORY',
  };
}

function receiveDrivers(result, timestamp) {
  return {
    type: 'RECEIVE_DRIVERS',
    drivers: result.drivers,
    meta: result.meta,
    timestamp,
  };
}

function receiveDriverSkills(skills) {
  return {
    type: 'RECEIVE_DRIVER_SKILLS',
    skills,
  };
}

function receiveDriverUpdateSuccessful(driver) {
  return {
    type: 'RECEIVE_DRIVER_UPDATE_SUCCESSFUL',
    driver,
  };
}

function receiveArchivedDrivers(result, timestamp) {
  return {
    type: 'RECEIVE_ARCHIVED_DRIVERS',
    drivers: result.drivers,
    meta: result.meta,
    timestamp,
  };
}

function receiveOptionDrivers(result) {
  return {
    type: 'RECEIVE_OPTION_DRIVERS',
    drivers: result.drivers,
  };
}

function receiveDriverLocationHistory(result) {
  return {
    type: 'RECEIVE_DRIVER_LOCATION_HISTORY',
    driverHistory: Helper.isNullOrUndefined(result) ? [] : result.driver_location_histories,
  };
}

function setFilter(filter) {
  return {
    type: 'SET_DRIVER_FILTER',
    filter,
  };
}

function receiveDriverSystemTags(systemTags) {
  return {
    type: 'RECEIVE_DRIVER_SYSTEM_TAGS',
    systemTags,
  };
}

function setisFetchingOptionDrivers(isFetchingOptionDrivers) {
  return {
    type: 'SET_IS_FETCHING_OPTION_DRIVERS',
    isFetchingOptionDrivers,
  };
}

function fetch(id) {
  return async (dispatch) => {
    const result = await request(`/drivers/${id}`, {
      method: 'GET',
    });
    dispatch(receiveDriver(result.driver));
    return result.driver;
  };
}

function fetchList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.driver.driverMeta.page,
      ...baseFilter,
      ...state.driver.filter,
      archived: false,
    };
    const param = convertDataToParams(data);
    const result = await request(`/drivers${param}`, {
      method: 'GET',
    });
    dispatch(receiveDrivers(result, timestamp));
  };
}

function fetchArchivedList(filter) {
  return async (dispatch, getState) => {
    dispatch(setFilter(filter));
    const timestamp = new Date();
    const state = getState();
    const data = {
      page: state.driver.archivedDriverMeta.page,
      ...baseFilter,
      ...state.driver.filter,
      archived: true,
    };
    const param = convertDataToParams(data);
    const result = await request(`/drivers${param}`, {
      method: 'GET',
    });
    dispatch(receiveArchivedDrivers(result, timestamp));
  };
}

function fetchOptionList(query) {
  return async (dispatch, getState) => {
    const { isFetchingOptionDrivers } = getState().driver;

    if (isFetchingOptionDrivers) {
      return null;
    }

    let result;
    try {
      dispatch(setisFetchingOptionDrivers(true));

      const param = convertDataToParams(query);
      result = await request(`/drivers/list${param}`, {
        method: 'GET',
      });
      dispatch(receiveOptionDrivers(result));
    } finally {
      dispatch(setisFetchingOptionDrivers(false));
    }

    return result;
  };
}

function fetchDriverLocationHistory(filter) {
  return async (dispatch) => {
    dispatch(requestDriverLocationHistory());
    const param = convertDataToParams(filter);
    const result = await request(`/drivers/${filter.id}/location_histories${param}`, {
      method: 'GET',
    });
    dispatch(receiveDriverLocationHistory(result));
    return result;
  };
}

function fetchSkills() {
  return async (dispatch) => {
    const result = await request('/drivers/skills', {
      method: 'GET',
    });
    dispatch(receiveDriverSkills(result.skills));
    return result.skills;
  };
}

function fetchSystemTags() {
  return async (dispatch) => {
    const result = await request('/drivers/system_tags', {
      method: 'GET',
    });
    dispatch(receiveDriverSystemTags(result.tags));
    return result.tags;
  };
}

function create(query) {
  return async (dispatch) => {
    const result = await request('/drivers', {
      method: 'POST',
    }, {
      driver: query,
    });
    dispatch(receiveDriverUpdateSuccessful(result.driver));
    dispatch(fetchList());
    return result.driver;
  };
}

function update(id, query) {
  return async (dispatch) => {
    const result = await request(`/drivers/${id}`, {
      method: 'PUT',
    }, {
      driver: query,
    });
    dispatch(receiveDriverUpdateSuccessful(result.driver));
    return result.driver;
  };
}

function updateAttendant(id, query) {
  return async (dispatch) => {
    const result = await request(`/drivers/${id}/driver_attendant`, {
      method: 'PUT',
    }, {
      driver: query,
    });
    dispatch(receiveDriverUpdateSuccessful(result.driver));
    return result.driver;
  };
}

function updateDriver(id, query) {
  return async (dispatch) => {
    const result = await request(`/drivers/${id}/driver`, {
      method: 'PUT',
    }, {
      driver: query,
    });
    dispatch(receiveDriverUpdateSuccessful(result.driver));
    return result.driver;
  };
}

function archive(id) {
  return async () => {
    const result = await request(`/drivers/${id}/archive`, {
      method: 'PUT',
    });
    return result.driver;
  };
}

function unarchive(id) {
  return async (dispatch) => {
    const result = await request(`/drivers/${id}/unarchive`, {
      method: 'PUT',
    });
    dispatch(receiveDriver(result.driver));
    dispatch(fetchArchivedList());
    return result.driver;
  };
}

function archiveMultiple(ids) {
  return async () => {
    const result = await request('/drivers/archive', {
      method: 'PUT',
    }, {
      ids,
    });
    return result;
  };
}

function unarchiveMultiple(ids) {
  return async (dispatch) => {
    const result = await request('/drivers/unarchive', {
      method: 'PUT',
    }, {
      ids,
    });
    dispatch(fetchArchivedList());
    return result;
  };
}

function importData(query) {
  return async (dispatch) => {
    await requestCustomBody('/drivers/import', {
      method: 'POST',
    }, query);
    Router.transitionTo('/fleet/drivers/import?success=true');
    dispatch(fetchList());
  };
}

function resetDriverLocationHistory() {
  return dispatch => dispatch(receiveDriverLocationHistory(null));
}

async function getImportTemplate() {
  const data = await requestBlob('/drivers/import/template', {
    method: 'GET',
  });

  Utils.Helper.saveBlob(data, 'Import_Driver.xlsx');
}

export default {
  setFilter,
  fetch,
  fetchList,
  fetchArchivedList,
  fetchOptionList,
  fetchDriverLocationHistory,
  fetchSkills,
  fetchSystemTags,
  getImportTemplate,
  create,
  update,
  updateAttendant,
  updateDriver,
  archive,
  unarchive,
  archiveMultiple,
  unarchiveMultiple,
  importData,
  resetDriverLocationHistory,
};
