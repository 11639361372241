// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
import { lazy } from 'react';

// eslint-disable-next-line consistent-return
const lazyWithRetry = componentImport => lazy(async () => {
  const retryKey = 'lazyWithRetryCount';
  const currentCount = parseInt(window.localStorage.getItem(retryKey) || '0', 10);

  try {
    const component = await componentImport();

    return component;
  } catch (error) {
    if (currentCount < 3) {
      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      window.localStorage.setItem(retryKey, String(currentCount + 1));

      const refreshCacheAndReload = async () => {
        try {
          if (window?.caches) {
            const { caches } = window;
            const cacheNames = await caches.keys();
            const cacheDeletionPromises = cacheNames.map(n => caches.delete(n));
            await Promise.all(cacheDeletionPromises);
            console.log('The cache has been deleted.');
            // Force a complete reload
            // currently only supported on Firefox - reload(true)
            window.location.reload(true);
          }
        } catch (err) {
          console.error('An error occurred while deleting the cache.', err);
        }
      };

      await refreshCacheAndReload();
    } else {
      // after 3 retries, reset the counter and throw the error
      window.localStorage.setItem(retryKey, '0');
      throw error;
    }
  }
});

export default lazyWithRetry;
