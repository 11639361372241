import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'versafleet-core';
import NewDialog from 'utils/newDialog';
import Button from './authorizedButton';

const ButtonArchive = (props) => {
  const { onClick, ...otherProps } = props;
  return (
    <Button
      hint={props.withDefaultText ? null : props.strings.text}
      iconClassName="versa-archive"
      onClick={() => NewDialog.DialogManager.confirm(props.strings.confirmation, onClick)}
      {...otherProps}
    >
      {props.withDefaultText ? props.strings.text : props.children}
    </Button>
  );
};

ButtonArchive.displayName = 'Button Archive';

ButtonArchive.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  strings: PropTypes.shape().isRequired,
  withDefaultText: PropTypes.bool,
};

ButtonArchive.defaultProps = {
  children: null,
  withDefaultText: false,
};

export default translate('Component.Button.Archive')(ButtonArchive);
