import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import AddressModule from 'modules/address';
import Helper from 'utils/helper';
import { translate } from 'versafleet-core';

class JobCustomAddress extends React.Component {
  constructor(props) {
    super(props);

    let address;

    if (!Helper.isNullOrUndefined(this.props.value.name)) {
      address = this.props.value.name;
    } else if (this._getAddressIndex() === -1) {
      address = '';
    } else {
      address = this._getAddressIndex();
    }

    this.state = { address };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.addressList.length > 0 || !this.props.addressList.length > 0) {
      return;
    }
    const addressIndex = this._getAddressIndex(this.props.value);
    const addressName = !Helper.isNullOrUndefined(this.props.value.name)
      ? this.props.value.name
      : '';

    if (addressIndex === -1 && this.state.address !== addressName) {
      // It is okay to use setState if there is a condition preventing infinite loops.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        address: addressName,
      });
    } else if (addressIndex !== -1 && addressIndex !== this.state.address) {
      // It is okay to use setState if there is a condition preventing infinite loops.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ address: addressIndex });
    }
  }

  _getAddressIndex() {
    return this.props.addressList.findIndex(address => address.line_1 === this.props.value.line_1
      && (!Helper.isNullOrUndefined(address.line_2) && address.line_2 === this.props.value.line_2)
      && address.country === this.props.value.country
      && (!Helper.isNullOrUndefined(address.zip) && address.zip === this.props.value.zip)
      && address.city === this.props.value.city
      && (!Helper.isNullOrUndefined(address.city) && address.city === this.props.value.city));
  }

  // eslint-disable-next-line class-methods-use-this
  _getDefaultAddress() {
    return {
      name: '',
      line_1: '',
      line_2: '',
      city: '',
      zip: '',
      country: '',
      latitude: null,
      longitude: null,
    };
  }

  _getAddressOptions() {
    return this.props.addressList.map((address, i) => ({
      value: i, // address.line_1,
      text: address.name || address.line_1,
    }));
  }

  _handleSelectAddress(value) {
    let address = value;

    if (value === '') {
      this.props.onChange({
        ...this._getDefaultAddress(),
        country: this.props.value.country,
      });
    } else if (typeof value === 'object' && !Helper.isNullOrUndefined(value.text)) {
      this.props.onChange({
        ...this._getDefaultAddress(),
        name: value.text,
        country: this.props.value.country,
      });
      address = value.text;
    } else {
      const {
        name,
        line_1: line1,
        line_2: line2,
        city,
        zip,
        country,
        latitude,
        longitude,
        contact_person: contactPerson,
        contact_number: contactNumber,
        email,
      } = this.props.addressList[value];
      this.props.onChange({
        name,
        line_1: line1,
        line_2: line2,
        city,
        zip,
        country,
        latitude,
        longitude,
        contact_person: contactPerson,
        contact_number: contactNumber,
        email,
      });
    }
    this.setState({ address });
  }

  render() {
    return (
      <Component.Form.Block
        className="vf-job__custom-address"
        fontWeight="REGULAR"
        iconClassName={this.props.iconClassName}
        iconContainerClassName="vf-job__custom-address__icon"
        level={2}
      >
        <div className="vf-job__custom-address__search-container">
          <Component.Input.Select
            allowCustom
            error={
              Helper.isNullOrUndefined(this.props.error)
              || Helper.isNullOrUndefined(this.props.error.name) ? [] : this.props.error.name
            }
            id={Helper.isNullOrUndefined(this.props.id) ? undefined
              : `${this.props.id}__select-address-input`}
            onChange={v => this._handleSelectAddress(v)}
            options={this._getAddressOptions()}
            placeholder={this.props.strings.searchLocation.hint}
            value={this.state.address}
          />
        </div>
        <AddressModule.Edit
          error={this.props.error}
          iconClassName="versa-arrow-up"
          id={this.props.id}
          onChange={this.props.onChange}
          value={this.props.value}
        />
      </Component.Form.Block>
    );
  }
}

JobCustomAddress.displayName = 'Job Custom Address';

JobCustomAddress.propTypes = {
  addressList: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
  }),
  iconClassName: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  // onSave: PropTypes.func,
  // options: PropTypes.array,
  strings: PropTypes.shape({
    searchLocation: PropTypes.shape({
      hint: PropTypes.string,
    }),
  }).isRequired,
  value: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    name: PropTypes.string,
    zip: PropTypes.string,
  }),
};

JobCustomAddress.defaultProps = {
  addressList: null,
  error: {},
  iconClassName: null,
  id: null,
  onChange: null,
  value: null,
};

export default translate('CustomAddress')(JobCustomAddress);
