import { Utils } from 'versafleet-core';

class Helper extends Utils.Helper {
  static encodeURIComponentTagValue(inputValue) {
    const value = inputValue;
    if (!this.isNullOrUndefined(value)) {
      for (let i = 0; i < value.length; i += 1) {
        value[i].value = encodeURIComponent(value[i].value);
      }
    }
    return value;
  }

  static decodeURIComponentTagValue(inputValue) {
    let value = inputValue;
    if (!this.isNullOrUndefined(value)) {
      value = decodeURIComponent(value);
    }
    return value;
  }

  /**
   * Create a new array of objects with uniq object based on `predicate`
   *
   * @param {Array} arr The array to inspect.
   * @param {Function|String} predicate The iteratee invoked per element.
   * @returns {Array} Returns the new duplicate free array.
   * @example
   *
   * Helper.uniqBy([{ 'value': 1 }, { 'value': 2 }, { 'value': 1 }], 'value');
   * // => [{ 'value': 1 }, { 'value': 2 }]
   */
  static uniqBy(arr, predicate) {
    const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

    return [...arr.reduce((map, item) => {
      const key = (item === null || item === undefined) ? item : cb(item);

      // eslint-disable-next-line no-unused-expressions
      map.has(key) || map.set(key, item);

      return map;
    }, new Map()).values()];
  }
}

export default Helper;
